export const topics = {
    "notFound": [
        {
            "id": "O9TAXTBRC2016052KG226533",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Representations of body, speech and mind"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sku gsung thugs rten/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "身语意三种所依"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226560",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Architecture"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ar las bzo skrun/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "世俗建筑"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226564",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Skills and games"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgyu rtsal dang rtsed mo/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "技艺及玩具"
                }
            ]
        },
        {
            "id": "T1PD94645",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "six symbols of longevity"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe ring rnam drug"
                }
            ]
        },
        {
            "id": "T1KG24452",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "tibetan paper"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bod shog"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226573",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Small scale handicrafts industry"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "lag shes bzo las/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "手工业"
                }
            ]
        },
        {
            "id": "T2045",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bdud rtsi yon tan sgrub pa/"
                }
            ]
        },
        {
            "id": "T2KG207415",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "a South Asian medicinal system"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe'i rig byed/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "Āyurveda"
                }
            ]
        },
        {
            "id": "T1KG80789",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "obstetrics"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bu btsa' rig pa/"
                }
            ]
        },
        {
            "id": "T2557",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "blockages (in medicine)"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gag pa/"
                }
            ]
        },
        {
            "id": "T11MS61",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "body training"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "lus sbyong /"
                }
            ]
        },
        {
            "id": "T2KG207970",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the department of information and international relations (DIIR)"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyi dril las khungs/"
                }
            ]
        },
        {
            "id": "T1KG24262",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "department of education"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "shes rig las khungs/"
                }
            ]
        },
        {
            "id": "T1GS44553",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mchod rten dkar chag"
                }
            ]
        },
        {
            "id": "T1598",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "do ha skor gsum/"
                }
            ]
        },
        {
            "id": "T1KG26395",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "comic book"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ri mo'i sgrung deb/"
                }
            ]
        },
        {
            "id": "T2KG207410",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "mythologized stories"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sngon gyi rabs/"
                },
                {
                    "@language": "sa-deva",
                    "@value": "पूराण"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226701",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Stories and letter writings"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrung dang yig bskur sogs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "故事及信函"
                }
            ]
        },
        {
            "id": "T1838",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "preface for an almanac"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "li tho 'go brjod/"
                }
            ]
        },
        {
            "id": "T2MS6123",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dal dag"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226746",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Chinese astrology"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "nag rtsis/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "五行运算"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226758",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Svarodaya"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbyangs 'char/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "占音术"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226761",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Performance"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'khrab ston/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "表演"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226769",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Music and musical notation"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rol dbyangs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "音乐"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226774",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "songs and humors"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "glu dang bzhad gad/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "歌舞相声"
                }
            ]
        },
        {
            "id": "T2MS11383",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rin chen 'byung ldan/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "宝生佛"
                }
            ]
        },
        {
            "id": "T824",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rigs gsum mgon po/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "trikulanatha"
                }
            ]
        },
        {
            "id": "T719",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the seven hero buddhas"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sangs rgyas dpa' bo bdun/"
                }
            ]
        },
        {
            "id": "T1AT489",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Gautama Buddha"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "shAkya thub pa/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "shakyamuni"
                },
                {
                    "@language": "zh-hans",
                    "@value": "释迦牟尼佛"
                }
            ]
        },
        {
            "id": "T1154",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "sixteen sthavira"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnas brtan bcu drug"
                }
            ]
        },
        {
            "id": "T564",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the sage of vajrasana and his two attendants"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "shAkya thub pa rdo rje gdan pa gtso 'khor gsum/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sakyamuni (vajrasana)"
                }
            ]
        },
        {
            "id": "T681",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "byams pa/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "maitreya"
                },
                {
                    "@language": "zh-hans",
                    "@value": "慈尊"
                }
            ]
        },
        {
            "id": "T8LS14948",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "indra b+hu ti/"
                }
            ]
        },
        {
            "id": "T2GS1131",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sa'i snying po/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "ksitigarbha"
                },
                {
                    "@language": "zh-hans",
                    "@value": "地藏王"
                }
            ]
        },
        {
            "id": "T1602",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thub pa lnga ldan/"
                }
            ]
        },
        {
            "id": "T1705",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru zhi ba bka' 'dus (rdo rje gling pa)"
                }
            ]
        },
        {
            "id": "T01AG03332",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sa yi 'jigs skyob sgrol ma/ (bar chad kun sel)"
                }
            ]
        },
        {
            "id": "T2CN4390",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "pad+ma mkha' 'gro/"
                }
            ]
        },
        {
            "id": "T906",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khyung nag dus 'khor lugs/"
                }
            ]
        },
        {
            "id": "T995",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med 'chi med rnga sgra lha 17 jo nang lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "amitayus dundubhisvara 17 devatmaka"
                }
            ]
        },
        {
            "id": "T2111",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khrus lha lnga /"
                }
            ]
        },
        {
            "id": "T1347",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "amitāyus"
                },
                {
                    "@language": "zh-hant",
                    "@value": "無量壽"
                }
            ]
        },
        {
            "id": "T1687",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe yum tsaNDa lI/"
                }
            ]
        },
        {
            "id": "T1083",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sangs rgyas mkha' 'gro lha 9"
                }
            ]
        },
        {
            "id": "T2292",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po me rlung 'khyil ba ( rat+na gling pa)"
                }
            ]
        },
        {
            "id": "T2300",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dbyangs blo 'phel shes rab ral gri/ (sangs rgyas gling pa)"
                }
            ]
        },
        {
            "id": "T569",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "klu dbang rgyal po/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "nagesvararaja"
                }
            ]
        },
        {
            "id": "T2314",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor gtum po seng sgrogs/ (chos rje gling pa)"
                }
            ]
        },
        {
            "id": "T2CZ5183",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdor dbyings lha lnga bcu nga gsum/"
                }
            ]
        },
        {
            "id": "T994",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med 'chi med rnga sgra lha 9 dze tA ri lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "amitayus dundubhisvara 9 devatmaka jetari krama"
                }
            ]
        },
        {
            "id": "T2291",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po (rdo rje gling pa)"
                }
            ]
        },
        {
            "id": "T00JR803",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgyu 'phrul drwa ba (khams lugs)"
                }
            ]
        },
        {
            "id": "T1401",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po bde gshegs 'dus pa (nyang ral nyi ma 'od zer)"
                }
            ]
        },
        {
            "id": "T551",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bla ma sku gsum bde 'dus zab tig (mkhyen brtse'i yang gter)"
                }
            ]
        },
        {
            "id": "T724",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sa'i snying po gang ba'i rdo rje'i lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "ksitigarba"
                }
            ]
        },
        {
            "id": "T353",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "yang dag he ru ka"
                }
            ]
        },
        {
            "id": "T39",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "white manjusri"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal dkar po/"
                }
            ]
        },
        {
            "id": "T1024",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal mtshan brjod rigs bsdus bla med lugs lha 53"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "manjusrinamasangiti sanksiptakula guhyaka 53 devatmaka"
                }
            ]
        },
        {
            "id": "T619",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "tāra"
                },
                {
                    "@language": "zh-hans",
                    "@value": "度母"
                }
            ]
        },
        {
            "id": "T00AG01366",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'chi med 'phags ma'i snying thig (mkhyen brtse'i dbang po/)"
                }
            ]
        },
        {
            "id": "T2GS1160",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "yellow prajnaparamita"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sher phyin ma ser mo/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "prajnaparamita pita"
                }
            ]
        },
        {
            "id": "T702",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "pratisara from the vajrapanjara tantra"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "so sor 'brang ma rdo rje gur las 'byung ba/"
                }
            ]
        },
        {
            "id": "T891",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sme ba brtsegs ma/ (mkha' 'gro ma)"
                }
            ]
        },
        {
            "id": "T563",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "yellow vajrasattva"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje sems dpa' ser po/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "pita vajrasattva"
                }
            ]
        },
        {
            "id": "T2GS1192",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "lion-mounted, green vaisravana in the tradition of jnana"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rnam sras ljang gu seng zhon ma dz+nyA na'i lugs/"
                }
            ]
        },
        {
            "id": "T00JR3557",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phur pa (rong lugs)"
                }
            ]
        },
        {
            "id": "T2174",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs chen yid bzhin nor bu phyag bzhi pa lha mang srong btsan sgam po lugs/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "avalokitesvara cintamani jagaddamara"
                }
            ]
        },
        {
            "id": "T836",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med dkar po phyag bzhi pa/ (ras chung lugs)"
                }
            ]
        },
        {
            "id": "T962",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs chen 'khor ba dong sprugs/ (gu ru chos dbang lugs)"
                }
            ]
        },
        {
            "id": "T1394",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rta phag yid bzhin nor bu ('ja' tshon snying po)"
                }
            ]
        },
        {
            "id": "T671",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med sprul sku ras chung lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "amitayus nirmanakaya"
                }
            ]
        },
        {
            "id": "T628",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog nag po lugs/"
                }
            ]
        },
        {
            "id": "T907",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khyung khra nag po ras chung lugs/"
                }
            ]
        },
        {
            "id": "T677",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "white manjughosa in the tradition of the great pandita of kashmir"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dbyangs dkar po kha che paN chen lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sita manjughosa"
                }
            ]
        },
        {
            "id": "T00AG0326",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ku ru kul+le dkar mo/"
                }
            ]
        },
        {
            "id": "T1316",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhi khro/"
                }
            ]
        },
        {
            "id": "T2293",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po kI la ya ( pad+ma dkar po)"
                }
            ]
        },
        {
            "id": "T2040",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phur pa (rgyud lugs)"
                }
            ]
        },
        {
            "id": "T1087",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ma hA mA ya 'bring po lha 5"
                }
            ]
        },
        {
            "id": "T2296",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gsang bdag nyi ma mdung gang ma g.ya' bzang lugs/"
                }
            ]
        },
        {
            "id": "T823",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor drag po sum sgril (lho brag grub chen lugs)"
                }
            ]
        },
        {
            "id": "T2164",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog lha 13 zhi byed lugs/"
                }
            ]
        },
        {
            "id": "T679",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "orange arapacana manjughosa in the sakya tradition"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dbyangs a ra pa tsa na dmar ser sa lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "manjughosa raktapita arapacana"
                }
            ]
        },
        {
            "id": "T989",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor gtum po khyung 5 ras chung lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "canda vajrapani 5 devatmaka (ras chung lugs)"
                }
            ]
        },
        {
            "id": "T2295",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "karma gu ru (byang bdag bkra shis stobs rgyal)"
                }
            ]
        },
        {
            "id": "T684",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbyangs can ma phyag bzhi zla ba gzhon nu lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sarasvati caturbhuja sita"
                }
            ]
        },
        {
            "id": "T1074",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgyu 'phrul chen mo lha 5 rngog lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "mahamaya 5 devatmaka rngog krama"
                }
            ]
        },
        {
            "id": "T902",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor nyi ma mdung gang ma bram ze lugs/"
                }
            ]
        },
        {
            "id": "T00AG0338",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "white tara"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma dkar mo/"
                }
            ]
        },
        {
            "id": "T825",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol dkar nye brgyud thun mong ma yin pa/"
                }
            ]
        },
        {
            "id": "T3CN15347",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "u pa rA tsa/"
                }
            ]
        },
        {
            "id": "T00AG01368",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdor sems snying thig"
                }
            ]
        },
        {
            "id": "T1026",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sangs rgyas mnyam sbyor rdor sems gtso bor gyur pa rigs bsdus lha 218"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "buddhasamayoga vajrasattva sanksiptakula"
                }
            ]
        },
        {
            "id": "T1393",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bka' brgyad dngos grub snying po ('ja' tshon snying po)"
                }
            ]
        },
        {
            "id": "T1KG86198",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje ma bdun ma/"
                }
            ]
        },
        {
            "id": "T00AG01277",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe lha rigs lnga /"
                }
            ]
        },
        {
            "id": "T835",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe dpag med sprul sku dang longs sku stag lung lugs/"
                }
            ]
        },
        {
            "id": "T1767",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje sems dpa' yab rkyang /"
                }
            ]
        },
        {
            "id": "T2156",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mkha' 'gro lnga rigs bsdus lha 49 rngog lugs/"
                }
            ]
        },
        {
            "id": "T1041",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs chen gtso 'khor gsum pa/"
                }
            ]
        },
        {
            "id": "T1007",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gdugs dkar po can ma lha 17"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sitatapatra 17 devatmaka"
                }
            ]
        },
        {
            "id": "T649",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "five-deity tara"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma lha lnga /"
                }
            ]
        },
        {
            "id": "T654",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "blue-clad vajrapani from the dharani"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor gos sngon can gyi gzungs las byung ba/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrapani nilambaradhara"
                }
            ]
        },
        {
            "id": "T1061",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog sbyangs pa'i yon tan lha 13 lwa ba'i lugs/"
                }
            ]
        },
        {
            "id": "T2GS1087",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ma hA mA ya/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "mahamaya"
                }
            ]
        },
        {
            "id": "T00JR3352",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rgya dkar ral gcig ma/"
                }
            ]
        },
        {
            "id": "T2163",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs dam bde gshegs bcu gnyis zhi byed lugs/"
                }
            ]
        },
        {
            "id": "T1351",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the five classes of dakinis"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "mkha' 'gro sde lnga /"
                }
            ]
        },
        {
            "id": "T1038",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbyangs can dkar mo bo dong lugs/"
                }
            ]
        },
        {
            "id": "T656",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "exceeding fierce (canda-maharosana) defensive-fort vajrapani in the tradition of rechungpa"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor gtum chen bsrung ba'i mkhar ras chung lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrapani candamaharosana"
                }
            ]
        },
        {
            "id": "T2246",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal khro chu sman gdong /"
                }
            ]
        },
        {
            "id": "T432",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "many+dzu shrI peaceful and wrathful"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal zhi khro/"
                }
            ]
        },
        {
            "id": "T2593",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs chen 'khor ba dbyings grol/ (legs ldan rje)"
                }
            ]
        },
        {
            "id": "T1118",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po mthing nag gnam lcags thog gi ral gri (chos rgyal rdo rje)"
                }
            ]
        },
        {
            "id": "T1069",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje phag mo lha 17 'jigs med grags pa'i lugs/"
                }
            ]
        },
        {
            "id": "T660",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "vajrapani spirit-subduer"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor 'byung po 'dul byed/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrapani bhutadamara"
                }
            ]
        },
        {
            "id": "T646",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma nor sbyin kha che paN chen lugs/"
                },
                {
                    "@language": "sa-x-ewts",
                    "@value": "tara d+hanada/"
                }
            ]
        },
        {
            "id": "T1CZ6",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje 'od ldan dkar po/"
                }
            ]
        },
        {
            "id": "T1GS122411",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal zhi ba (gter bdag gling pa 'gyur med rdo rje)"
                }
            ]
        },
        {
            "id": "T2101",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "eight manifestations of padmasambhava"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru mtshan brgyad/"
                }
            ]
        },
        {
            "id": "T1638",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "lha mo bu rdzi ma (rdo rje gling pa)"
                }
            ]
        },
        {
            "id": "T1664",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnyen po lha lnga (rta mgrin)"
                }
            ]
        },
        {
            "id": "T956",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog dkar po kha che paN chen lugs/"
                }
            ]
        },
        {
            "id": "T692",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "secret sadhana of kurukulla"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ku ru kulle gsang sgrub/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "kurukulla guhyasadhana"
                }
            ]
        },
        {
            "id": "T2044",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rta mgrin/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "hayagrīva"
                }
            ]
        },
        {
            "id": "T2153",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "kye rdor lha dgu jo nang zhi sbas lugs/"
                }
            ]
        },
        {
            "id": "T00JR2666",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru zhi ba/"
                }
            ]
        },
        {
            "id": "T1749",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs rje chen po bde gshegs kun 'dus/"
                }
            ]
        },
        {
            "id": "T920",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dzambha la yab yum dgu/"
                }
            ]
        },
        {
            "id": "T00AG0327",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam pa'i rdo rje/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "manjusrivajra"
                }
            ]
        },
        {
            "id": "T3JT12423",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "web of illusion"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgyu 'phrul drwa ba/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "mahayoga tantra"
                }
            ]
        },
        {
            "id": "T1040",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbyangs can dmar mo gsang sgrub kha che biksa pa ra ma'i lugs/"
                }
            ]
        },
        {
            "id": "T2145",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "kye rdo rje lha dgu mtsho skyes lugs/"
                }
            ]
        },
        {
            "id": "T2149",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "kye rdo rje lha dgu DombhI lugs/"
                }
            ]
        },
        {
            "id": "T572",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ma hA mA ya gtso rkyang /"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "mahamaya ekantanayaka"
                }
            ]
        },
        {
            "id": "T1152",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po/"
                }
            ]
        },
        {
            "id": "T2071",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "nags sman ma/"
                }
            ]
        },
        {
            "id": "T2165",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khros ma rigs lnga zhi byed lugs/"
                }
            ]
        },
        {
            "id": "T00AG0329",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rta mchog rol pa/"
                }
            ]
        },
        {
            "id": "T986",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor 'khor chen lha 18"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrapani mahacakra 18 devatmaka"
                }
            ]
        },
        {
            "id": "T1045",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mgon po gur thang rgyal nye brgyud/"
                }
            ]
        },
        {
            "id": "T2306",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal pha rol rgol 'joms (mchog gyur gling pa)"
                }
            ]
        },
        {
            "id": "T1037",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbyangs can dkar mo dge lugs lugs/"
                }
            ]
        },
        {
            "id": "T991",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gshin rje gshed lha 13 dpal 'dzin lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "rakta yamari 13 devatmaka mahasiddha sridhara krama"
                }
            ]
        },
        {
            "id": "T2GS1234",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "lion seated ganapati mahakala"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshogs bdag mgon po seng+ge'i gdan can/"
                }
            ]
        },
        {
            "id": "T01AG03283",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gtum po thugs sgrub/"
                }
            ]
        },
        {
            "id": "T1052",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog nang dkyil dril bu zhabs lugs/"
                }
            ]
        },
        {
            "id": "T4CZ326967",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rigs can dra ma lnga /"
                }
            ]
        },
        {
            "id": "T1GS124517",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gtum po nA ga rak+sha (skyid grong chos mdzad)"
                }
            ]
        },
        {
            "id": "T45",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "storied mansion (kutagara) vajrapani"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rdor khang bu brtsegs pa/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrapani kutagara"
                }
            ]
        },
        {
            "id": "T519",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gu ru drag po rtsal (byang gter)"
                }
            ]
        },
        {
            "id": "T4CZ15215",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma phreng 'dzin ma/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "tarasragdhara"
                }
            ]
        },
        {
            "id": "T1PD181202",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "nor bdag dmar po skor gsum/"
                }
            ]
        },
        {
            "id": "T440",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jigs byed lhan skyes/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajrabhairava sahaja"
                }
            ]
        },
        {
            "id": "T1049",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "pu tra ming sring ngor lugs/"
                }
            ]
        },
        {
            "id": "T854",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol dkar kaM tshang lugs/"
                }
            ]
        },
        {
            "id": "T2198",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rta phag (chos rgyal ngag gi dbang po'i dgongs gter)"
                }
            ]
        },
        {
            "id": "T722",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "samantabhadra (buddha according to purnavajra transmission)"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "kun tu bzang po gang ba'i rdo rje lugs/"
                }
            ]
        },
        {
            "id": "T00AG0323",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "red manjusri"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dbyangs dmar po/"
                }
            ]
        },
        {
            "id": "T618",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mi g.yo ba/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "ācala"
                },
                {
                    "@language": "zh-hant",
                    "@value": "不動明王"
                }
            ]
        },
        {
            "id": "T2180",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog dril bu lugs/"
                }
            ]
        },
        {
            "id": "T2049",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "'jam dpal zhi ba (rgyud lugs bka' ma)"
                }
            ]
        },
        {
            "id": "T003JR4506",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bdud rtsi lha/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "anuttara"
                }
            ]
        },
        {
            "id": "T1025",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sangs rgyas mnyam sbyor rigs 6 bsdus pa lha 135 (127)"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sarvabuddhasamayoga 135(127) devatmaka"
                }
            ]
        },
        {
            "id": "T873",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the six vajrasana yidam deities"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje gdan pa'i lha drug"
                }
            ]
        },
        {
            "id": "T1071",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje phag mo 'byung ba lha 19 (21) zhi sbas lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajravarahi 19 (21) devatmaka santigupta krama"
                }
            ]
        },
        {
            "id": "T2310",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thugs rje chen po pad+ma gar dbang /(mchog gyur gling pa)"
                }
            ]
        },
        {
            "id": "T2170",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog snyan brgyud he ru ka yab yum 10 dwags po snyan brgyud/"
                }
            ]
        },
        {
            "id": "T1239",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje phur pa yang gsang bla med (rat+na gling pa)"
                }
            ]
        },
        {
            "id": "T1GS148157",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "lo ma gyon ma/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "parnasabari"
                },
                {
                    "@language": "zh-hans",
                    "@value": "叶衣佛母"
                }
            ]
        },
        {
            "id": "T1466",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gtum po phur gcig ma/"
                }
            ]
        },
        {
            "id": "T988",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mi g.yo gtum chen lha 9 bla med lugs/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "candamaharosana acala 9 devatmaka"
                }
            ]
        },
        {
            "id": "T012AG03389",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "sita cakrasamvara according to the jo nang tradition"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog dkar po/ (jo nang lugs/)"
                }
            ]
        },
        {
            "id": "T4CZ2733",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Agnideva, god of fire, gods in general, fire deity associated with the fire offering rites"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "me lha/"
                }
            ]
        },
        {
            "id": "T2138",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbang gi lha mo/"
                }
            ]
        },
        {
            "id": "T415",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "white tara tsinta ma ni stag phu dag snang /"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol dkar tsinta ma Ni dag snang can/"
                }
            ]
        },
        {
            "id": "T828",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgrol ma 'jigs pa kun sel/ (jo bo lugs)"
                }
            ]
        },
        {
            "id": "T2KG207953",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ge khod gsang ba drag chen/"
                }
            ]
        },
        {
            "id": "T4CZ58781",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "stag lha me 'bar/"
                }
            ]
        },
        {
            "id": "T1KG583",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "zhi khro (bon tradition)"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhi khro (bon)"
                }
            ]
        },
        {
            "id": "T1KG624",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbal gsas/"
                }
            ]
        },
        {
            "id": "T1728",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "lha rgod thog pa/"
                }
            ]
        },
        {
            "id": "T2KG207954",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khro rgyal rag sha spyi 'dul/"
                }
            ]
        },
        {
            "id": "T1711",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "byams ma (yi dam/ bon)"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227926",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bon lha/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "苯教神明"
                }
            ]
        },
        {
            "id": "T1CZ18",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ser bdag bcu gsum/"
                }
            ]
        },
        {
            "id": "T2CN5007",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "ye shes mgon po/ (nyang gter/)"
                }
            ]
        },
        {
            "id": "T1620",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rma rgyal spom ra/"
                }
            ]
        },
        {
            "id": "T2CN4717",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dam can sde bdun/"
                }
            ]
        },
        {
            "id": "T2268",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "a myes mdzangs ra can/"
                }
            ]
        },
        {
            "id": "T2GS1111",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "wer ma/"
                }
            ]
        },
        {
            "id": "T2CN4867",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "lha gcig nyi ma gzhon nu/"
                }
            ]
        },
        {
            "id": "T00JR774",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "goddess with iron ritual dagger for vitality-practice, the self-arisen queen"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "lha mo srog sgrub lcags phur ma rang byung rgyal mo/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "devi pranasadhana ayahkila svayambhurajni"
                }
            ]
        },
        {
            "id": "T2160",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gur gyi mgon po lha brgyad/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "vajramahakalapanjaranatha 8-devatmaka"
                }
            ]
        },
        {
            "id": "T1927",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rma chen spom ra/"
                }
            ]
        },
        {
            "id": "T2CN4865",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "lha gcig nyi ma mun sel/"
                }
            ]
        },
        {
            "id": "T2CN4861",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhing skyong ma sku mched gnyis/"
                }
            ]
        },
        {
            "id": "T1CZ165",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sngo lha g.yu rtse/"
                }
            ]
        },
        {
            "id": "T2CN3469",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje 'od ldan dkar po/"
                }
            ]
        },
        {
            "id": "T441",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gza' nyi ma gdung gang ma/"
                }
            ]
        },
        {
            "id": "T2587",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "mahakala in the form of an acarya"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "las mgon a tsa ra/"
                }
            ]
        },
        {
            "id": "T2KG207433",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbang thang rgya ma/"
                }
            ]
        },
        {
            "id": "T4PD931",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnyen sgrig"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG228406",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "marriage"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnyen sgrig"
                }
            ]
        },
        {
            "id": "T4PD930",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bca' khrims/"
                }
            ]
        },
        {
            "id": "T3CN9711",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Islam"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "kha che'i chos lugs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "伊斯兰"
                }
            ]
        },
        {
            "id": "T1KG22572",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "liberalism"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rang mos ring lugs/"
                }
            ]
        },
        {
            "id": "T1KG89007",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "hinduism"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "hin+du'i chos/"
                },
                {
                    "@language": "sa-deva",
                    "@value": "हिन्दू धर्म"
                },
                {
                    "@language": "zh-hans",
                    "@value": "印度教"
                }
            ]
        },
        {
            "id": "T371",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "public relations"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "spyi 'brel/"
                }
            ]
        },
        {
            "id": "T2229",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "sino-tibetan relations"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bod rgya 'brel lam/"
                }
            ]
        },
        {
            "id": "T1151",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "freedom struggle"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rang dbang 'thab rtsod/"
                }
            ]
        },
        {
            "id": "T1CZ57",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "text on reasoning by dignaga"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "dmigs pa brtag pa/"
                }
            ]
        },
        {
            "id": "T2244",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "knot prognostication"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'ju thig"
                }
            ]
        },
        {
            "id": "T1775",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "criteria for horses"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gyi gling brtag thabs/"
                }
            ]
        },
        {
            "id": "T10MS14648",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "survey"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhib bsher/"
                }
            ]
        },
        {
            "id": "T2208",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "commerce"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshong las/"
                }
            ]
        },
        {
            "id": "T3CN2025",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "indian logic works"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshad ma'i rgya gzhung /"
                },
                {
                    "@language": "zh-hans",
                    "@value": "印度因明典籍"
                }
            ]
        },
        {
            "id": "T2252",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "places in tantric geography"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnas yul chen po/"
                }
            ]
        },
        {
            "id": "T1168",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "hidden lands"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sbas yul/"
                }
            ]
        },
        {
            "id": "T1KG26414",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "world"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'dzam gling /"
                }
            ]
        },
        {
            "id": "T1KG24263",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "central school for tibetans"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gzhi rim slob grwa/"
                }
            ]
        },
        {
            "id": "T2CZ10052",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "nunnery"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "btsun dgon/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "尼姑寺"
                }
            ]
        },
        {
            "id": "T1587",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "su kha chos drug"
                }
            ]
        },
        {
            "id": "T1479",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "khyung po mgur brgyad/"
                }
            ]
        },
        {
            "id": "T1596",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rgyal po bka' 'bum/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227150",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Non-sectarian"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ris med/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "利美"
                }
            ]
        },
        {
            "id": "T1PD181203",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "byang gter bka' brgyad/"
                }
            ]
        },
        {
            "id": "T1093",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mdo dgongs 'dus/"
                }
            ]
        },
        {
            "id": "T1193",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bka' brgyad/"
                }
            ]
        },
        {
            "id": "T1370",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bka' brgyad bka' ma lugs/"
                }
            ]
        },
        {
            "id": "T00AG01281",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "grol thig dgongs pa rang grol/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226937",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Terma"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "zab pa gter ma/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "伏藏部"
                }
            ]
        },
        {
            "id": "T1GS135820",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gcod yul nyong mongs zhi byed/"
                }
            ]
        },
        {
            "id": "T1GS107984",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "nam mkha' sgo 'byed/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "akasadhisthana"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227070",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Sakya"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sa skya/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "萨系"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227076",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Ngorpa"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ngor pa/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "俄系"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227078",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Tsarpa"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshar pa/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "才系"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227069",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Sakya"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sa skya/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "萨迦"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227097",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Drukpa Kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "brug pa bka' brgyud/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "竹巴噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227110",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Shugseb Kagyue"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "shug gseb/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "修赛噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227095",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Trophu Kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "khro phu/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "绰浦噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227091",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Drigung Kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bri gung /"
                },
                {
                    "@language": "zh-hans",
                    "@value": "直贡噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227087",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Barom Kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ba' rom bka' brgyud/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "拔绒噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227112",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Kamtshang Kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "kaM tshang bka' brgyud/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "噶玛噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227116",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "tshalpa kagyu"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshal pa bka' brgyud/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "采巴噶举"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG227062",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Gelug"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "dge lugs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "格鲁"
                }
            ]
        },
        {
            "id": "T1357",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dga' ldan phyag chen/"
                }
            ]
        },
        {
            "id": "T1KG22573",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "life sciences"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe srog tshan rig"
                },
                {
                    "@language": "zh-hans",
                    "@value": "生命科学"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG228441",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Life sciences"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe srog tshan rig"
                },
                {
                    "@language": "zh-hans",
                    "@value": "生命科学"
                }
            ]
        },
        {
            "id": "T1KG26410",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "animals"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ri dwags dang gcan gzan/"
                }
            ]
        },
        {
            "id": "T1KG26412",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "birds"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'dab chags/"
                }
            ]
        },
        {
            "id": "T1KG26411",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "domestic animal"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "sgo phyugs/"
                }
            ]
        },
        {
            "id": "T3CN11351",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag mchan/"
                }
            ]
        },
        {
            "id": "T61",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "detailed commentary"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rnam bshad/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "vyākhyā"
                },
                {
                    "@language": "zh-hant",
                    "@value": "論"
                }
            ]
        },
        {
            "id": "T1AT670",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "explanation and elucidation of crucial points and concepts"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gnad 'gag gsal byed/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226334",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Root text and commentarie"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rtsa ba dang 'grel pa/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "根本论及注疏"
                }
            ]
        },
        {
            "id": "T307",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "tantra"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gsang sngags/"
                }
            ]
        },
        {
            "id": "T1460",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bla med rgyud/"
                },
                {
                    "@language": "sa-x-iast",
                    "@value": "anuttarayoga tantra"
                }
            ]
        },
        {
            "id": "T867",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "tantric incantations"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rig sngags/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "vidyā"
                },
                {
                    "@language": "zh-hans",
                    "@value": "明咒"
                }
            ]
        },
        {
            "id": "T309",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rig 'dzin sngags kyi sde snod/"
                }
            ]
        },
        {
            "id": "T202",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gsang snying /"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "guhyagarbha"
                }
            ]
        },
        {
            "id": "T837",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe sgrub zhag bdun ma (rong ston lugs)"
                }
            ]
        },
        {
            "id": "T546",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bla ma gsang 'dus snyan brgyud zhag bdun ma/"
                }
            ]
        },
        {
            "id": "T1993",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo rje'i rigs kyi rgyud/"
                }
            ]
        },
        {
            "id": "T2315",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phur pa'i gnad tig"
                }
            ]
        },
        {
            "id": "T00UNK53",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rnal 'byor sgo gsum/"
                }
            ]
        },
        {
            "id": "T01AG02845",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "a combination of multiple sadhana"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "dril sgrub/"
                }
            ]
        },
        {
            "id": "T1099",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "kun bzang thugs gter gyi bla sgrub/ (byang chub gling pa)"
                }
            ]
        },
        {
            "id": "T2308",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe sgrub 'chi med srog thig (mchog gyur gling pa)"
                }
            ]
        },
        {
            "id": "T01AG03135",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mkha' 'gro snyan brgyud/"
                }
            ]
        },
        {
            "id": "T00JR775",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bsgral las/"
                }
            ]
        },
        {
            "id": "T2188",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bka' gtad/"
                }
            ]
        },
        {
            "id": "T1258",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo'i bcud len/"
                }
            ]
        },
        {
            "id": "T872",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "rain making rituals"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "char 'bebs cho ga"
                }
            ]
        },
        {
            "id": "T4CZ363957",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "arga"
                }
            ]
        },
        {
            "id": "T00KG02665",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "arrangement for a sadhana practice"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "las khrigs/"
                }
            ]
        },
        {
            "id": "T1044",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "blessing"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "byin rlabs/"
                }
            ]
        },
        {
            "id": "T10MS14317",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbang bskur/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "abhiṣeka"
                },
                {
                    "@language": "zh-hant",
                    "@value": "灌頂"
                }
            ]
        },
        {
            "id": "T1CZ67",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mig gzungs/"
                }
            ]
        },
        {
            "id": "T4CZ359084",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "nang mchod/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "rakta"
                }
            ]
        },
        {
            "id": "T1256",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "chu'i bcud len/"
                }
            ]
        },
        {
            "id": "T2625",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "gto/"
                }
            ]
        },
        {
            "id": "T2352",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dngos gzhi/"
                }
            ]
        },
        {
            "id": "T1257",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "me tog gi bcud len/"
                }
            ]
        },
        {
            "id": "T00KG02746",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bsad mnan/"
                }
            ]
        },
        {
            "id": "T00JR140",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dbang bzhi pa/"
                }
            ]
        },
        {
            "id": "T844",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "hair burning"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "skra bsreg pa'i cho ga"
                }
            ]
        },
        {
            "id": "T1225",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "tshe 'gugs/"
                }
            ]
        },
        {
            "id": "T1CZ27",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "ritual of the south gate"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "lho sgo'i cho ga"
                }
            ]
        },
        {
            "id": "T1888",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bla ma yang tig"
                }
            ]
        },
        {
            "id": "T1532",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "spyan ras gzigs kyi dmar khrid dpal mo lugs/"
                }
            ]
        },
        {
            "id": "T1550",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag rgya chen po yi ge med pa (khrid)"
                }
            ]
        },
        {
            "id": "T1577",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "bde mchog dkar po (khrid)"
                }
            ]
        },
        {
            "id": "T1267",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag chen zur mang snyan rgyud lugs/"
                }
            ]
        },
        {
            "id": "T1698",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mal gcod/"
                }
            ]
        },
        {
            "id": "T1273",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag chen yan lag bdun ldan/"
                }
            ]
        },
        {
            "id": "T1272",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "phyag chen tshal lugs/"
                }
            ]
        },
        {
            "id": "T1555",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sems kyi bar chad sel ba (khrid)"
                }
            ]
        },
        {
            "id": "T2464",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dge 'dun/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "sangha"
                },
                {
                    "@language": "zh-hans",
                    "@value": "僧伽"
                }
            ]
        },
        {
            "id": "T58",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "instruction"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhal gdams/"
                }
            ]
        },
        {
            "id": "T59",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mngon pa/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "abhidharma"
                },
                {
                    "@language": "zh-hans",
                    "@value": "俱舍"
                }
            ]
        },
        {
            "id": "T2212",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "stong nyid snying rje/"
                }
            ]
        },
        {
            "id": "T2273",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "11 types of liberation"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "grol ba bcu gcig"
                }
            ]
        },
        {
            "id": "T2MS14792",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "don dam bden pa/"
                },
                {
                    "@language": "sa-x-ndia",
                    "@value": "paramaartha satya"
                }
            ]
        },
        {
            "id": "T2271",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "mthong grol 'khor lo/"
                }
            ]
        },
        {
            "id": "T2261",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "document bestowing name"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "mtshan gsol ba/"
                }
            ]
        },
        {
            "id": "T2274",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "six liberations, the six types of liberation"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "grol ba drug"
                }
            ]
        },
        {
            "id": "T1KG9082",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "yang dag mtha'"
                }
            ]
        },
        {
            "id": "T1414",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "kun rdzob bden pa/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "samvrtisatya"
                }
            ]
        },
        {
            "id": "T2MS14938",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "eightfold noble path"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'phags lam yan lag brgyad/"
                }
            ]
        },
        {
            "id": "T2090",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "conventional bodhichitta"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "kun rdzob byang sems/"
                }
            ]
        },
        {
            "id": "T2027",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "rtag pa dang mi rtag pa/"
                }
            ]
        },
        {
            "id": "T2KG2755",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "dge slong ma'i bslab bya/"
                }
            ]
        },
        {
            "id": "T1KG4314",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "the 18 renowned scriptures"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "grags chen bco brgyad/"
                }
            ]
        },
        {
            "id": "T1KG22693",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "nepali"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ne pa li/"
                },
                {
                    "@language": "sa-deva",
                    "@value": "नेपाली"
                }
            ]
        },
        {
            "id": "T2119",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "alphabets and scripts"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ka mad/"
                }
            ]
        },
        {
            "id": "T1KG6138",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "chinese script"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rgya yig"
                }
            ]
        },
        {
            "id": "T2KG200021",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "burmese"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "b+har ma'i skad yig"
                }
            ]
        },
        {
            "id": "T2KG200020",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "inscription"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "brkos yig"
                }
            ]
        },
        {
            "id": "T3CN16182",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "stone inscriptions"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rdo brkos yi ge"
                },
                {
                    "@language": "zh-hans",
                    "@value": "石刻碑文"
                }
            ]
        },
        {
            "id": "T143",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "explanation of terms"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ming don brda bkrol/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226460",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Orthography and Translation"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "dag yig dang sgra sgyur/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "正字学及翻译"
                }
            ]
        },
        {
            "id": "T1KG26408",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "sign"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "brda rtags/"
                }
            ]
        },
        {
            "id": "T3CN21895",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "pandita"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "paN+Di ta/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "班智达"
                }
            ]
        },
        {
            "id": "T1KG24275",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "schedule"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "mdzad rim/"
                }
            ]
        },
        {
            "id": "T1KG26409",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "travel"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "'grim 'grul/"
                }
            ]
        },
        {
            "id": "T177",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "teacher-student transmission"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "bla brgyud/"
                },
                {
                    "@language": "sa-alalc97",
                    "@value": "guru-sisya-parampara"
                }
            ]
        },
        {
            "id": "T2KG209122",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "thal phreng /"
                }
            ]
        },
        {
            "id": "T4CZ366107",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "successively"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "rim byon/"
                }
            ]
        },
        {
            "id": "T1KG89005",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "buddhist texts in pali"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "pA li'i chos dpe/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "巴利文佛书"
                }
            ]
        },
        {
            "id": "T3CN21715",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "palm leaves manuscripts"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "ta la'i lo ma'i dpe cha/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "贝叶经"
                }
            ]
        },
        {
            "id": "T8LS41949",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "report"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "snyan tho/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226473",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "General Terminology"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "spyir btang tha snyad/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "一般术语"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226489",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Terminology related to the scriptures"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "gsung rab 'brel yod tha snyad/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "经论相关术语"
                }
            ]
        },
        {
            "id": "T404",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "masks"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "zhal 'bag"
                }
            ]
        },
        {
            "id": "T1398",
            "prefLabel": [
                {
                    "@language": "bo-x-ewts",
                    "@value": "sangs rgyas dus chen/"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226496",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Races and customs"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "mi rigs dang yul srol/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "民族及民俗"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226520",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Offering and Ritual materials"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "mchod pa'i yo byad sogs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "法器法物"
                }
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226472",
            "prefLabel": [
                {
                    "@language": "en",
                    "@value": "Various Terminology"
                },
                {
                    "@language": "bo-x-ewts",
                    "@value": "tha snyad sna tshogs/"
                },
                {
                    "@language": "zh-hans",
                    "@value": "各种术语"
                }
            ]
        }
    ],
    "multiple": [
        {
            "id": "O9TAXTBRC2016052KG226587",
            "filtered": [
                [
                    "1",
                    "261"
                ],
                [
                    "1",
                    "300"
                ],
                [
                    "1",
                    "323"
                ],
                [
                    "1",
                    "503"
                ],
                [
                    "1",
                    "753"
                ],
                [
                    "1",
                    "833"
                ],
                [
                    "1",
                    "1341"
                ]
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG228265",
            "filtered": [
                [
                    "na",
                    "7311"
                ],
                [
                    "1",
                    "7316"
                ]
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG228264",
            "filtered": [
                [
                    "na",
                    "7311"
                ],
                [
                    "1",
                    "7316"
                ]
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG226385",
            "filtered": [
                [
                    "1",
                    "261"
                ],
                [
                    "1",
                    "300"
                ],
                [
                    "1",
                    "323"
                ],
                [
                    "1",
                    "503"
                ],
                [
                    "1",
                    "753"
                ],
                [
                    "1",
                    "833"
                ],
                [
                    "1",
                    "1341"
                ]
            ]
        },
        {
            "id": "O9TAXTBRC2016052KG225384",
            "filtered": [
                [
                    "1",
                    "261"
                ],
                [
                    "1",
                    "300"
                ],
                [
                    "1",
                    "323"
                ],
                [
                    "1",
                    "503"
                ],
                [
                    "1",
                    "753"
                ],
                [
                    "1",
                    "833"
                ],
                [
                    "1",
                    "1341"
                ]
            ]
        }
    ],
    "noLabel": [
        {
            "id": "T611",
            "json": {
                "id": "bdr:T611",
                "type": "Topic",
                "tmp:entityScore": 20,
                "@context": "http://purl.bdrc.io/context.jsonld"
            }
        },
        {
            "id": "O9TAXTBRC2016052KG225829",
            "json": {
                "id": "bdr:O9TAXTBRC2016052KG225829",
                "type": "Taxonomy",
                "@context": "http://purl.bdrc.io/context.jsonld"
            }
        },
        {
            "id": "O9TAXTBRC201605",
            "json": {
                "id": "bdr:O9TAXTBRC201605",
                "type": "Taxonomy",
                "taxHasSubClass": [
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226531"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG225324"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226586"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226784"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG228264"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG225372"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226667"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG227157"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG228290"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226384"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG228444"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226920"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG228410"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG225383"
                    },
                    {
                        "id": "bdr:O9TAXTBRC2016052KG226418"
                    }
                ],
                "@context": "http://purl.bdrc.io/context.jsonld"
            }
        }
    ],
    "O9TAXTBRC201605": {
        "sub": [
            "O9TAXTBRC2016052KG226531",
            "O9TAXTBRC2016052KG225324",
            "O9TAXTBRC2016052KG226586",
            "O9TAXTBRC2016052KG226784",
            "O9TAXTBRC2016052KG228264",
            "O9TAXTBRC2016052KG225372",
            "O9TAXTBRC2016052KG226667",
            "O9TAXTBRC2016052KG227157",
            "O9TAXTBRC2016052KG228290",
            "O9TAXTBRC2016052KG226384",
            "O9TAXTBRC2016052KG228444",
            "O9TAXTBRC2016052KG226920",
            "O9TAXTBRC2016052KG228410",
            "O9TAXTBRC2016052KG225383",
            "O9TAXTBRC2016052KG226418"
        ]
    },
    "O9TAXTBRC2016052KG226531": {
        "sub": [
            "T361",
            "O9TAXTBRC2016052KG226533",
            "O9TAXTBRC2016052KG226560",
            "O9TAXTBRC2016052KG226564",
            "O9TAXTBRC2016052KG226573"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bzo rig pa/"
            },
            {
                "@language": "en",
                "@value": "Arts and Crafts"
            },
            {
                "@language": "zh-hans",
                "@value": "工巧明"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "cha",
        "rank": 4104
    },
    "T361": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bzo rig"
            },
            {
                "@language": "sa-alalc97",
                "@value": "silpasastra"
            },
            {
                "@language": "en",
                "@value": "fine arts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226531",
        "tbrcId": "T361",
        "rank": 4109
    },
    "O9TAXTBRC2016052KG226533": {
        "sub": [
            "T2401",
            "T1771",
            "T1014",
            "T215",
            "T1846",
            "T1GS149121",
            "T2035",
            "T1125",
            "T30",
            "T1474",
            "T1428",
            "T00KG03985",
            "T943",
            "T20",
            "T195",
            "T1845",
            "T1311",
            "T2257",
            "T00AG0337",
            "T935",
            "T2130",
            "T1475",
            "T1444",
            "T00JR139",
            "T1421",
            "T230"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku gsung thugs rten/"
            },
            {
                "@language": "zh-hans",
                "@value": "身语意三种所依"
            },
            {
                "@language": "en",
                "@value": "Representations of body, speech and mind"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226531"
    },
    "T2401": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "karma sgar bris/"
            },
            {
                "@language": "en",
                "@value": "Karma Gadri Thangka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T2401",
        "rank": 4203
    },
    "T1771": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'bur sku phyag tshad/"
            },
            {
                "@language": "en",
                "@value": "sculpture canonical proportions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1771",
        "rank": 4179
    },
    "T1014": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog shing 'dzugs pa/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1014",
        "rank": 4217
    },
    "T215": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thig ris/"
            },
            {
                "@language": "en",
                "@value": "drawing proportions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T215",
        "rank": 4184
    },
    "T1846": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri mo bri yig"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1846",
        "rank": 4125
    },
    "T1GS149121": {
        "label": [
            {
                "@language": "en",
                "@value": "colors"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshos/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1GS149121",
        "rank": 4194
    },
    "T2035": {
        "label": [
            {
                "@language": "en",
                "@value": "murals"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ldeb ris/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T2035",
        "rank": 4174
    },
    "T1125": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thang ka"
            },
            {
                "@language": "en",
                "@value": "traditional paintings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1125",
        "rank": 4154
    },
    "T30": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bris yig"
            },
            {
                "@language": "en",
                "@value": "iconographic description"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T30",
        "rank": 4226
    },
    "T1474": {
        "label": [
            {
                "@language": "en",
                "@value": "bronze images"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sku brnyan/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1474",
        "rank": 4298
    },
    "T1428": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha 'bri ba'i man ngag"
            },
            {
                "@language": "en",
                "@value": "artistic instruction for drawing deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1428",
        "rank": 4328
    },
    "T00KG03985": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "snang brnyan/"
            },
            {
                "@language": "en",
                "@value": "picture, illustration"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T00KG03985",
        "rank": 4293
    },
    "T943": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khor lo bri thabs/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T943",
        "rank": 4199
    },
    "T20": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thang ka'i kha byang /"
            },
            {
                "@language": "en",
                "@value": "thangka labels & inscriptions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T20",
        "rank": 4169
    },
    "T195": {
        "label": [
            {
                "@language": "en",
                "@value": "measurement of circumambulation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skor tshad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T195",
        "rank": 4054
    },
    "T1845": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri mo/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1845",
        "rank": 4120
    },
    "T1311": {
        "label": [
            {
                "@language": "en",
                "@value": "drawing & painting"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thig tshon/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1311",
        "rank": 4159
    },
    "T2257": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srid pa'i 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T2257",
        "rank": 4303
    },
    "T00AG0337": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchod rten sde brgyad/"
            },
            {
                "@language": "zh-hans",
                "@value": "八塔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T00AG0337",
        "rank": 4264
    },
    "T935": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sAtst+sha"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T935",
        "rank": 4269
    },
    "T2130": {
        "label": [
            {
                "@language": "en",
                "@value": "reliquaries"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dngul gdung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T2130",
        "rank": 4134
    },
    "T1475": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdul tshon/"
            },
            {
                "@language": "en",
                "@value": "colored sand"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1475",
        "rank": 3846
    },
    "T1444": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "cha tshad/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1444",
        "rank": 4164
    },
    "T00JR139": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku gsung thugs rten bzhengs thabs/"
            },
            {
                "@language": "en",
                "@value": "methods of constructing \"supports\" of enlightened body, speech, and mind"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T00JR139",
        "rank": 4113
    },
    "T1421": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gdung rten/"
        },
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T1421",
        "rank": 4222
    },
    "T230": {
        "label": [
            {
                "@language": "en",
                "@value": "painting"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshon ris/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226533",
        "tbrcId": "T230",
        "rank": 4189
    },
    "O9TAXTBRC2016052KG226560": {
        "sub": [
            "T2516",
            "T2182",
            "T2256"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ar las bzo skrun/"
            },
            {
                "@language": "en",
                "@value": "Architecture"
            },
            {
                "@language": "zh-hans",
                "@value": "世俗建筑"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226531"
    },
    "T2516": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khang bzo rig pa/"
            },
            {
                "@language": "en",
                "@value": "architecture"
            },
            {
                "@language": "sa-x-iast",
                "@value": "Vāstuśāstra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226560",
        "tbrcId": "T2516",
        "rank": 4207
    },
    "T2182": {
        "label": [
            {
                "@language": "en",
                "@value": "renovation (of sacred objects)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "a rg+ha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226560",
        "tbrcId": "T2182",
        "rank": 4045
    },
    "T2256": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam bzo/"
            },
            {
                "@language": "en",
                "@value": "road construction"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226560",
        "tbrcId": "T2256",
        "rank": 3974
    },
    "O9TAXTBRC2016052KG226564": {
        "sub": [
            "T2399",
            "T1KG6084",
            "T2414",
            "T1KG6083",
            "T1KG476",
            "T368",
            "T387",
            "T1KG477"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgyu rtsal dang rtsed mo/"
            },
            {
                "@language": "zh-hans",
                "@value": "技艺及玩具"
            },
            {
                "@language": "en",
                "@value": "Skills and games"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226531"
    },
    "T2399": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sho"
            },
            {
                "@language": "en",
                "@value": "dice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T2399",
        "rank": 4018
    },
    "T1KG6084": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsed chas/"
            },
            {
                "@language": "en",
                "@value": "games and sports equipment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T1KG6084",
        "rank": 4254
    },
    "T2414": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mda' rtsed/"
            },
            {
                "@language": "en",
                "@value": "archery"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T2414",
        "rank": 4231
    },
    "T1KG6083": {
        "label": [
            {
                "@language": "en",
                "@value": "games"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtsed mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T1KG6083",
        "rank": 3876
    },
    "T1KG476": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mig mangs/"
        },
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T1KG476",
        "rank": 4250
    },
    "T368": {
        "label": [
            {
                "@language": "en",
                "@value": "art"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgyu rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T368",
        "rank": 4049
    },
    "T387": {
        "label": [
            {
                "@language": "en",
                "@value": "sports"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lus rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T387",
        "rank": 4236
    },
    "T1KG477": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal po'i rgyal rtsed/"
        },
        "parent": "O9TAXTBRC2016052KG226564",
        "tbrcId": "T1KG477",
        "rank": 4246
    },
    "O9TAXTBRC2016052KG226573": {
        "sub": [
            "T1684",
            "T3JT12482",
            "T1178",
            "T548",
            "T00KG02661",
            "T1CZ68",
            "T1946",
            "T2433",
            "T1PD94645",
            "T1KG24452",
            "T2403",
            "T1839"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lag shes bzo las/"
            },
            {
                "@language": "en",
                "@value": "Small scale handicrafts industry"
            },
            {
                "@language": "zh-hans",
                "@value": "手工业"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226531"
    },
    "T1684": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bkra shis rtags brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T1684",
        "rank": 4308
    },
    "T3JT12482": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal srid sna bdun/"
            },
            {
                "@language": "en",
                "@value": "seven royal possessions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T3JT12482",
        "rank": 4283
    },
    "T1178": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phrul chas/"
            },
            {
                "@language": "en",
                "@value": "machinery"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T1178",
        "rank": 4241
    },
    "T548": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpe'u ris/"
            },
            {
                "@language": "en",
                "@value": "illustrations"
            },
            {
                "@language": "zh-hans",
                "@value": "图案"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T548",
        "rank": 4288
    },
    "T00KG02661": {
        "label": [
            {
                "@language": "en",
                "@value": "technique of making banner"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ru mtshon bca' thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T00KG02661",
        "rank": 4323
    },
    "T1CZ68": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bkra shis rdzas brgyad/"
            },
            {
                "@language": "en",
                "@value": "eight auspicious articles or substances"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T1CZ68",
        "rank": 4313
    },
    "T1946": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lag shes/"
            },
            {
                "@language": "en",
                "@value": "small scale handicrafts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T1946",
        "rank": 4278
    },
    "T2433": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rkos/"
            },
            {
                "@language": "en",
                "@value": "stone carvings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T2433",
        "rank": 4144
    },
    "T1PD94645": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe ring rnam drug"
            },
            {
                "@language": "en",
                "@value": "six symbols of longevity"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573"
    },
    "T1KG24452": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan paper"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod shog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573"
    },
    "T2403": {
        "label": [
            {
                "@language": "en",
                "@value": "pottery handicraft"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdza bzo'i lag rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T2403",
        "rank": 4139
    },
    "T1839": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spos sbyor/"
            },
            {
                "@language": "en",
                "@value": "incense preparation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226573",
        "tbrcId": "T1839",
        "rank": 4273
    },
    "O9TAXTBRC2016052KG225324": {
        "sub": [
            "O9TAXTBRC2016052KG225325",
            "O9TAXTBRC2016052KG225333",
            "O9TAXTBRC2016052KG225348",
            "O9TAXTBRC2016052KG225360"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkar chag"
            },
            {
                "@language": "zh-hans",
                "@value": "目录"
            },
            {
                "@language": "en",
                "@value": "Catalogs and Bibliographies"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ka",
        "rank": 5
    },
    "O9TAXTBRC2016052KG225325": {
        "sub": [
            "T298",
            "T13",
            "T1485",
            "T4CZ2366",
            "T00KG02662",
            "T2196",
            "T2123"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "General Bibliographies"
            },
            {
                "@language": "zh-hans",
                "@value": "一般书目"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyir btang dkar chag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225324",
        "tbrcId": "1",
        "rank": 10
    },
    "T298": {
        "label": [
            {
                "@language": "en",
                "@value": "bibliography"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpe tho/"
            },
            {
                "@language": "zh-hans",
                "@value": "书目"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T298",
        "rank": 19
    },
    "T13": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "目录"
            },
            {
                "@language": "en",
                "@value": "notice of contents"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dkar chag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T13",
        "rank": 15
    },
    "T1485": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kha byang /"
            },
            {
                "@language": "en",
                "@value": "title, list"
            },
            {
                "@language": "zh-hans",
                "@value": "标题｜单子"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T1485",
        "rank": 23
    },
    "T4CZ2366": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "them byang /"
        },
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T4CZ2366",
        "rank": 27
    },
    "T00KG02662": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos skor dkar chag"
        },
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T00KG02662",
        "rank": 35
    },
    "T2196": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpe mdzod khang gi dkar chag"
            },
            {
                "@language": "en",
                "@value": "library catalogs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T2196",
        "rank": 39
    },
    "T2123": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung 'bum dkar chag"
            },
            {
                "@language": "en",
                "@value": "contents of collected writings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225325",
        "tbrcId": "T2123",
        "rank": 31
    },
    "O9TAXTBRC2016052KG225333": {
        "sub": [
            "T1GS108153",
            "T4CZ71",
            "T1PD113671",
            "T1GS9767",
            "T24",
            "T3CN2027",
            "T2411",
            "T2DB4572",
            "T1PD113672",
            "T3CN1331",
            "T1731",
            "T2091",
            "T1PD108902",
            "T1PD108958"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "版本目录"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "par gzhi'i dkar chag"
            },
            {
                "@language": "en",
                "@value": "Version Bibliographies"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225324",
        "tbrcId": "2",
        "rank": 44
    },
    "T1GS108153": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' rgya ma/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1GS108153",
        "rank": 77
    },
    "T4CZ71": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpe dkon/"
            },
            {
                "@language": "en",
                "@value": "rare texts"
            },
            {
                "@language": "zh-hans",
                "@value": "珍本、孤本"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T4CZ71",
        "rank": 73
    },
    "T1PD113671": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsdur mchan/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1PD113671",
        "rank": 85
    },
    "T1GS9767": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "deb yig mgo rgyan/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1GS9767",
        "rank": 65
    },
    "T24": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "par byang smon tshig"
            },
            {
                "@language": "en",
                "@value": "benedictory verses (books)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T24",
        "rank": 57
    },
    "T3CN2027": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "蒙文佛典"
            },
            {
                "@language": "en",
                "@value": "buddhist texts in mongolian"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sog yig chos dpe/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T3CN2027",
        "rank": 101
    },
    "T2411": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "legs sbyar chos dpe/"
            },
            {
                "@language": "zh-hans",
                "@value": "梵文佛典"
            },
            {
                "@language": "en",
                "@value": "buddhist texts in sanskrit"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T2411",
        "rank": 93
    },
    "T2DB4572": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpe bsdur ma/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T2DB4572",
        "rank": 81
    },
    "T1PD113672": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsdur 'bras re'u mig"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1PD113672",
        "rank": 89
    },
    "T3CN1331": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgya yig chos dpe/"
            },
            {
                "@language": "zh-hans",
                "@value": "中文佛典"
            },
            {
                "@language": "en",
                "@value": "Buddhist texts in Chinese"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T3CN1331",
        "rank": 97
    },
    "T1731": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "par brko mkhan/"
            },
            {
                "@language": "en",
                "@value": "block carvers"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1731",
        "rank": 53
    },
    "T2091": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "glegs bam/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T2091",
        "rank": 61
    },
    "T1PD108902": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "par khang /"
            },
            {
                "@language": "zh-hans",
                "@value": "印经院"
            },
            {
                "@language": "en",
                "@value": "printery"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1PD108902",
        "rank": 49
    },
    "T1PD108958": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbu lha/"
        },
        "parent": "O9TAXTBRC2016052KG225333",
        "tbrcId": "T1PD108958",
        "rank": 69
    },
    "O9TAXTBRC2016052KG225348": {
        "sub": [
            "T00JR980",
            "T514",
            "T89",
            "T1GS135825",
            "T1CZ4664",
            "T1AT268",
            "T2330",
            "T1KG3171",
            "T3JT12483",
            "T1CZ4662",
            "T1CZ50"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ched sgrig sa bcad/"
            },
            {
                "@language": "zh-hans",
                "@value": "专题纲目"
            },
            {
                "@language": "en",
                "@value": "Thematic Outlines"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225324",
        "tbrcId": "3",
        "rank": 106
    },
    "T00JR980": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo chings/"
        },
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T00JR980",
        "rank": 123
    },
    "T514": {
        "label": [
            {
                "@language": "en",
                "@value": "descriptive outline"
            },
            {
                "@language": "zh-hans",
                "@value": "总纲"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi chings/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T514",
        "rank": 115
    },
    "T89": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa bcad/"
            },
            {
                "@language": "en",
                "@value": "thematic outline"
            },
            {
                "@language": "zh-hans",
                "@value": "纲目"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T89",
        "rank": 111
    },
    "T1GS135825": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dzud byang /"
            },
            {
                "@language": "en",
                "@value": "short verse presentation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1GS135825",
        "rank": 147
    },
    "T1CZ4664": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tha mar dge ba/"
            },
            {
                "@language": "en",
                "@value": "final benefit"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1CZ4664",
        "rank": 135
    },
    "T1AT268": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngon chings dang mjug chings/"
        },
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1AT268",
        "rank": 119
    },
    "T2330": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod brjod/"
        },
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T2330",
        "rank": 143
    },
    "T1KG3171": {
        "label": [
            {
                "@language": "en",
                "@value": "foreword"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gleng brjod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1KG3171",
        "rank": 139
    },
    "T3JT12483": {
        "label": [
            {
                "@language": "en",
                "@value": "propitious in the middle"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bar du dge ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T3JT12483",
        "rank": 131
    },
    "T1CZ4662": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thog mar dge ba/"
            },
            {
                "@language": "en",
                "@value": "propitious in the beginning"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1CZ4662",
        "rank": 127
    },
    "T1CZ50": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "stong thun/"
            },
            {
                "@language": "en",
                "@value": "collects the essence of a text, the essential meaning of an expanded or great text"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225348",
        "tbrcId": "T1CZ50",
        "rank": 3237
    },
    "O9TAXTBRC2016052KG225360": {
        "sub": [
            "T1446",
            "T2176",
            "T2332",
            "T2062",
            "T254",
            "T451",
            "T1AT299",
            "T00AG0572",
            "T1124",
            "T1453",
            "T1AT671"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod gzhi/"
            },
            {
                "@language": "en",
                "@value": "Related Topics"
            },
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225324",
        "tbrcId": "4",
        "rank": 152
    },
    "T1446": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brjed byang /"
            },
            {
                "@language": "en",
                "@value": "memorandum; notes of points to remember"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T1446",
        "rank": 185
    },
    "T2176": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tho yig"
        },
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T2176",
        "rank": 157
    },
    "T2332": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan yig"
            },
            {
                "@language": "en",
                "@value": "document names"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T2332",
        "rank": 169
    },
    "T2062": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshig le'ur byas pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "偈品"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "karika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T2062",
        "rank": 193
    },
    "T254": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "re'u mig"
            },
            {
                "@language": "en",
                "@value": "table"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T254",
        "rank": 197
    },
    "T451": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brgyud yig"
        },
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T451",
        "rank": 161
    },
    "T1AT299": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "reg gzig"
        },
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T1AT299",
        "rank": 189
    },
    "T00AG0572": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kha skong /"
            },
            {
                "@language": "en",
                "@value": "addendum, supplement"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T00AG0572",
        "rank": 173
    },
    "T1124": {
        "label": [
            {
                "@language": "en",
                "@value": "description"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bkod yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T1124",
        "rank": 165
    },
    "T1453": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lhan thabs/"
            },
            {
                "@language": "en",
                "@value": "supplement"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T1453",
        "rank": 177
    },
    "T1AT671": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyab chos/"
        },
        "parent": "O9TAXTBRC2016052KG225360",
        "tbrcId": "T1AT671",
        "rank": 181
    },
    "O9TAXTBRC2016052KG226586": {
        "sub": [
            "O9TAXTBRC2016052KG226642",
            "O9TAXTBRC2016052KG226651",
            "O9TAXTBRC2016052KG226589",
            "O9TAXTBRC2016052KG226615",
            "O9TAXTBRC2016052KG226610",
            "O9TAXTBRC2016052KG226602",
            "O9TAXTBRC2016052KG226654",
            "O9TAXTBRC2016052KG226587",
            "O9TAXTBRC2016052KG226630",
            "O9TAXTBRC2016052KG226661"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gso ba rig pa/"
            },
            {
                "@language": "en",
                "@value": "Medicine and Pharmacology"
            },
            {
                "@language": "zh-hans",
                "@value": "医方明"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ja",
        "rank": 4334
    },
    "O9TAXTBRC2016052KG226642": {
        "sub": [
            "T1102",
            "T2MS13793",
            "T237",
            "T2045",
            "T2428",
            "T86",
            "T1714",
            "T88"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "药方"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sman sbyor/"
            },
            {
                "@language": "en",
                "@value": "Pharmacognosy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "7",
        "rank": 4560
    },
    "T1102": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman mar/"
            },
            {
                "@language": "en",
                "@value": "medicinal ointments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T1102",
        "rank": 4589
    },
    "T2MS13793": {
        "label": [
            {
                "@language": "en",
                "@value": "medicine"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sman/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T2MS13793",
        "rank": 4573
    },
    "T237": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khrungs dpe/"
            },
            {
                "@language": "en",
                "@value": "materia medica"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T237",
        "rank": 4569
    },
    "T2045": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud rtsi yon tan sgrub pa/"
        },
        "parent": "O9TAXTBRC2016052KG226642"
    },
    "T2428": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rin chen ril bu/"
            },
            {
                "@language": "en",
                "@value": "rinchen medicine"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T2428",
        "rank": 4581
    },
    "T86": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman sbyor/"
            },
            {
                "@language": "en",
                "@value": "medicinal preparation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T86",
        "rank": 4565
    },
    "T1714": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mig sman/"
            },
            {
                "@language": "en",
                "@value": "treatment of the eyes"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T1714",
        "rank": 4585
    },
    "T88": {
        "label": [
            {
                "@language": "en",
                "@value": "mercury preparations (medicine)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dngul chu sbyor ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226642",
        "tbrcId": "T88",
        "rank": 4577
    },
    "O9TAXTBRC2016052KG226651": {
        "sub": [
            "T3JT12433",
            "T2DB35092"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman pa dang yo byad/"
            },
            {
                "@language": "en",
                "@value": "Doctors and Medical Instruments"
            },
            {
                "@language": "zh-hans",
                "@value": "医生与器械"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "8",
        "rank": 4594
    },
    "T3JT12433": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman pa/"
            },
            {
                "@language": "en",
                "@value": "doctors"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226651",
        "tbrcId": "T3JT12433",
        "rank": 4599
    },
    "T2DB35092": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman thang /"
        },
        "parent": "O9TAXTBRC2016052KG226651",
        "tbrcId": "T2DB35092",
        "rank": 4603
    },
    "O9TAXTBRC2016052KG226589": {
        "sub": [
            "T10MS14643",
            "T241",
            "T3JT13430",
            "T1PD95",
            "T1114",
            "T279",
            "T1795",
            "T012AG03520",
            "T10MS14651",
            "T1PD106851",
            "T2KG207415",
            "T1KG80789"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Medical Texts"
            },
            {
                "@language": "zh-hans",
                "@value": "医典"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sman gzhung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "2",
        "rank": 4349
    },
    "T10MS14643": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyams yig"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T10MS14643",
        "rank": 4386
    },
    "T241": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T241",
        "rank": 4354
    },
    "T3JT13430": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "man ngag rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T3JT13430",
        "rank": 4362
    },
    "T1PD95": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T1PD95",
        "rank": 4358
    },
    "T1114": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyi ma rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T1114",
        "rank": 4370
    },
    "T279": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshad rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T279",
        "rank": 4366
    },
    "T1795": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "g.yu thog snying thig"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T1795",
        "rank": 4374
    },
    "T012AG03520": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zin tig"
            },
            {
                "@language": "en-x-mixed",
                "@value": "explanation of medical practices"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T012AG03520",
        "rank": 4378
    },
    "T10MS14651": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman yig"
            },
            {
                "@language": "en",
                "@value": "medical text"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T10MS14651",
        "rank": 4382
    },
    "T1PD106851": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdel 'grel/"
        },
        "parent": "O9TAXTBRC2016052KG226589",
        "tbrcId": "T1PD106851",
        "rank": 4390
    },
    "T2KG207415": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe'i rig byed/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Āyurveda"
            },
            {
                "@language": "en",
                "@value": "a South Asian medicinal system"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226589"
    },
    "T1KG80789": {
        "label": [
            {
                "@language": "en",
                "@value": "obstetrics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bu btsa' rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226589"
    },
    "O9TAXTBRC2016052KG226615": {
        "sub": [
            "T2557",
            "T1KG21678",
            "T1654",
            "T2558",
            "T2460",
            "T1PD106855",
            "T1PD106856",
            "T1141",
            "T1KG21679",
            "T2407",
            "T1469",
            "T1106",
            "T1978",
            "T2142"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Ailments"
            },
            {
                "@language": "zh-hans",
                "@value": "疾病"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nad rigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "5",
        "rank": 4452
    },
    "T2557": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gag pa/"
            },
            {
                "@language": "en",
                "@value": "blockages (in medicine)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615"
    },
    "T1KG21678": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mo nad/"
            },
            {
                "@language": "en",
                "@value": "gynecology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1KG21678",
        "rank": 4481
    },
    "T1654": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyan rims bcos thabs/"
        },
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1654",
        "rank": 4501
    },
    "T2558": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rims tshad/"
            },
            {
                "@language": "en",
                "@value": "contagious fevers"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T2558",
        "rank": 4505
    },
    "T2460": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchin nad/"
            },
            {
                "@language": "en",
                "@value": "liver ailments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T2460",
        "rank": 4473
    },
    "T1PD106855": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshal skyugs/"
        },
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1PD106855",
        "rank": 4461
    },
    "T1PD106856": {
        "label": [
            {
                "@language": "en",
                "@value": "dysentery"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dmar bshal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1PD106856",
        "rank": 4465
    },
    "T1141": {
        "label": [
            {
                "@language": "en",
                "@value": "rheumatism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "grang ba'i nad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1141",
        "rank": 4477
    },
    "T1KG21679": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pu nad/"
            },
            {
                "@language": "zh-hans",
                "@value": "布鲁氏菌病"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1KG21679",
        "rank": 4485
    },
    "T2407": {
        "label": [
            {
                "@language": "en",
                "@value": "digestive ailments"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'ju byed nad rigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T2407",
        "rank": 4457
    },
    "T1469": {
        "label": [
            {
                "@language": "en",
                "@value": "leprosy"
            },
            {
                "@language": "zh-hant",
                "@value": "麻風"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mdze nad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1469",
        "rank": 4489
    },
    "T1106": {
        "label": [
            {
                "@language": "en",
                "@value": "abnormal swelling usually in the legs"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'bam nad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1106",
        "rank": 4493
    },
    "T1978": {
        "label": [
            {
                "@language": "en",
                "@value": "diseases of the joints"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rus tshigs chen po'i nad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T1978",
        "rank": 4497
    },
    "T2142": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pho ba'i nad/"
            },
            {
                "@language": "en",
                "@value": "stomach ailments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226615",
        "tbrcId": "T2142",
        "rank": 4469
    },
    "O9TAXTBRC2016052KG226610": {
        "sub": [
            "T1972",
            "T1188",
            "T1189",
            "T2GS1326"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brtag thabs/"
            },
            {
                "@language": "en",
                "@value": "Examination Methods"
            },
            {
                "@language": "zh-hans",
                "@value": "诊断"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "4",
        "rank": 4430
    },
    "T1972": {
        "label": [
            {
                "@language": "en",
                "@value": "diagnosis of disease"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nad rtags/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226610",
        "tbrcId": "T1972",
        "rank": 4447
    },
    "T1188": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa brtag"
            },
            {
                "@language": "en",
                "@value": "pulse examination"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226610",
        "tbrcId": "T1188",
        "rank": 4435
    },
    "T1189": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chu brtag"
            },
            {
                "@language": "en",
                "@value": "urine examination"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226610",
        "tbrcId": "T1189",
        "rank": 4439
    },
    "T2GS1326": {
        "label": [
            {
                "@language": "en",
                "@value": "taste, flavor"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ro/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rāsa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226610",
        "tbrcId": "T2GS1326",
        "rank": 4443
    },
    "O9TAXTBRC2016052KG226602": {
        "sub": [
            "T2124",
            "T1122",
            "T1952",
            "T1140",
            "T1690",
            "T831",
            "T1KG21177"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "人体"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lus gnad/"
            },
            {
                "@language": "en",
                "@value": "Physical points"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "3",
        "rank": 4395
    },
    "T2124": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa dbu ma/"
        },
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T2124",
        "rank": 4421
    },
    "T1122": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lus gnad/"
            },
            {
                "@language": "en",
                "@value": "acupuncture points"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T1122",
        "rank": 4405
    },
    "T1952": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srog"
            },
            {
                "@language": "en",
                "@value": "life force"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T1952",
        "rank": 4409
    },
    "T1140": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gso bya lus/"
            },
            {
                "@language": "en",
                "@value": "anatomy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T1140",
        "rank": 4401
    },
    "T1690": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nadi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T1690",
        "rank": 4417
    },
    "T831": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ring 'tsho/"
            },
            {
                "@language": "en",
                "@value": "life prolongation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T831",
        "rank": 4413
    },
    "T1KG21177": {
        "label": [
            {
                "@language": "en",
                "@value": "neuroscience"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbang rtsa'i tshan rig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226602",
        "tbrcId": "T1KG21177",
        "rank": 4425
    },
    "O9TAXTBRC2016052KG226654": {
        "sub": [
            "T1142",
            "T1KG21162",
            "T11MS61",
            "T1730",
            "T1250",
            "T1PD106854"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phrod bsten/"
            },
            {
                "@language": "en",
                "@value": "Health and Hygiene"
            },
            {
                "@language": "zh-hans",
                "@value": "健康与卫生"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "9",
        "rank": 4608
    },
    "T1142": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phrod bsten/"
            },
            {
                "@language": "en",
                "@value": "health and hygiene"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226654",
        "tbrcId": "T1142",
        "rank": 4613
    },
    "T1KG21162": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rlung sbyong /"
            },
            {
                "@language": "en",
                "@value": "pranayama exercises"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226654",
        "tbrcId": "T1KG21162",
        "rank": 4625
    },
    "T11MS61": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lus sbyong /"
            },
            {
                "@language": "en",
                "@value": "body training"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226654"
    },
    "T1730": {
        "label": [
            {
                "@language": "en",
                "@value": "harms caused by alcoholic consumption"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "myos byed nyes dmigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226654",
        "tbrcId": "T1730",
        "rank": 4633
    },
    "T1250": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bcud/"
        },
        "parent": "O9TAXTBRC2016052KG226654",
        "tbrcId": "T1250",
        "rank": 4629
    },
    "T1PD106854": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa sbyongs/"
        },
        "parent": "O9TAXTBRC2016052KG226654",
        "tbrcId": "T1PD106854",
        "rank": 4621
    },
    "O9TAXTBRC2016052KG226587": {
        "sub": [
            "T85"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586"
    },
    "T85": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gso rig"
            },
            {
                "@language": "en",
                "@value": "science of medicine"
            },
            {
                "@language": "zh-hans",
                "@value": "医学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226587",
        "tbrcId": "T85",
        "rank": 4344
    },
    "O9TAXTBRC2016052KG226630": {
        "sub": [
            "T1GS108145",
            "T1661",
            "T1121",
            "T1GS139157",
            "T1PD106853",
            "T1656",
            "T1468",
            "T94",
            "T2626",
            "T1139",
            "T1153"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Treatment Methods"
            },
            {
                "@language": "zh-hans",
                "@value": "治疗"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bcos thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "6",
        "rank": 4510
    },
    "T1GS108145": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog rlung bcos pa/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1GS108145",
        "rank": 4531
    },
    "T1661": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhog bcos/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1661",
        "rank": 4523
    },
    "T1121": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtar rtsa/"
            },
            {
                "@language": "en",
                "@value": "acupuncture channels"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1121",
        "rank": 4543
    },
    "T1GS139157": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'brum nad bzlog sgyur/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1GS139157",
        "rank": 4539
    },
    "T1PD106853": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rma bcos/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1PD106853",
        "rank": 4519
    },
    "T1656": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyug bcos/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1656",
        "rank": 4527
    },
    "T1468": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sder bshal/"
        },
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1468",
        "rank": 4535
    },
    "T94": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bcos thabs/"
            },
            {
                "@language": "en",
                "@value": "method of treatment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T94",
        "rank": 4515
    },
    "T2626": {
        "label": [
            {
                "@language": "en",
                "@value": "massage (medicine)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bsku mnye/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T2626",
        "rank": 4555
    },
    "T1139": {
        "label": [
            {
                "@language": "en",
                "@value": "acupuncture"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skam khab/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1139",
        "rank": 4551
    },
    "T1153": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "me btsa'"
            },
            {
                "@language": "en",
                "@value": "moxibustion"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226630",
        "tbrcId": "T1153",
        "rank": 4547
    },
    "O9TAXTBRC2016052KG226661": {
        "sub": [
            "T295",
            "T301",
            "T1660",
            "T1PD106857",
            "T00EGS1017630"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyugs gso/"
            },
            {
                "@language": "en",
                "@value": "Veterinary Sciences"
            },
            {
                "@language": "zh-hans",
                "@value": "兽医"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226586",
        "tbrcId": "10",
        "rank": 4638
    },
    "T295": {
        "label": [
            {
                "@language": "en",
                "@value": "veterinary medicine"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyugs nad bcos thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226661",
        "tbrcId": "T295",
        "rank": 4643
    },
    "T301": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyugs las/"
            },
            {
                "@language": "en",
                "@value": "animal husbandry"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226661",
        "tbrcId": "T301",
        "rank": 4651
    },
    "T1660": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khyi smyon bcos thabs/"
            },
            {
                "@language": "en",
                "@value": "rabies treatment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226661",
        "tbrcId": "T1660",
        "rank": 4655
    },
    "T1PD106857": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "glo rtsi/"
        },
        "parent": "O9TAXTBRC2016052KG226661",
        "tbrcId": "T1PD106857",
        "rank": 4647
    },
    "T00EGS1017630": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgo phyugs mi gsod pa/"
            },
            {
                "@language": "en",
                "@value": "animal rights"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226661",
        "tbrcId": "T00EGS1017630",
        "rank": 4659
    },
    "O9TAXTBRC2016052KG226784": {
        "sub": [
            "O9TAXTBRC2016052KG226810",
            "O9TAXTBRC2016052KG226860",
            "O9TAXTBRC2016052KG226911",
            "O9TAXTBRC2016052KG226913",
            "O9TAXTBRC2016052KG226917",
            "O9TAXTBRC2016052KG226905",
            "O9TAXTBRC2016052KG226873",
            "O9TAXTBRC2016052KG226907",
            "O9TAXTBRC2016052KG226886",
            "O9TAXTBRC2016052KG226892",
            "O9TAXTBRC2016052KG226785",
            "O9TAXTBRC2016052KG226909"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "史传"
            },
            {
                "@language": "en",
                "@value": "Historical Writings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lo rgyus dang rnam thar sogs/"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ta",
        "rank": 5191
    },
    "O9TAXTBRC2016052KG226810": {
        "sub": [
            "O9TAXTBRC2016052KG226811",
            "O9TAXTBRC2016052KG226831",
            "O9TAXTBRC2016052KG226856"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "las khungs dang go ming /"
            },
            {
                "@language": "en",
                "@value": "Offices and Titles"
            },
            {
                "@language": "zh-hans",
                "@value": "官府与名衔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "2",
        "rank": 5323
    },
    "O9TAXTBRC2016052KG226811": {
        "sub": [
            "T1PD108613",
            "T2KG207970",
            "T2233",
            "T00AG02680",
            "T2224",
            "T1947",
            "T2203",
            "T1949",
            "T2205",
            "T2234",
            "T2200",
            "T2495",
            "T2497",
            "T2494",
            "T1KG24262",
            "T1944",
            "T2222",
            "T2215",
            "T2213"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "las khungs/"
            },
            {
                "@language": "en",
                "@value": "office"
            },
            {
                "@language": "zh-hans",
                "@value": "官府"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226810",
        "tbrcId": "1",
        "rank": 5329
    },
    "T1PD108613": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' shag"
            },
            {
                "@language": "zh-hans",
                "@value": "噶厦"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T1PD108613",
        "rank": 5355
    },
    "T2KG207970": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyi dril las khungs/"
            },
            {
                "@language": "en",
                "@value": "the department of information and international relations (DIIR)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811"
    },
    "T2233": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gro mo spyi khyab/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2233",
        "rank": 5410
    },
    "T00AG02680": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' ldan pho brang /"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T00AG02680",
        "rank": 5340
    },
    "T2224": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhol pa las khungs/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2224",
        "rank": 5375
    },
    "T1947": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ga dang dmag sgar/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T1947",
        "rank": 5350
    },
    "T2203": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yig tshang /"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2203",
        "rank": 5345
    },
    "T1949": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "hor spyi khyab/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T1949",
        "rank": 5395
    },
    "T2205": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhol gnyer/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2205",
        "rank": 5380
    },
    "T2234": {
        "label": [
            {
                "@language": "en",
                "@value": "government"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "srid gzhung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2234",
        "rank": 5334
    },
    "T2200": {
        "label": [
            {
                "@language": "en",
                "@value": "economic affairs office"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dod dpal las khungs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2200",
        "rank": 5360
    },
    "T2495": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snang rtse shag las khungs/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2495",
        "rank": 5365
    },
    "T2497": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tar khang /"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2497",
        "rank": 5385
    },
    "T2494": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo smad spyi khyab/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2494",
        "rank": 5415
    },
    "T1KG24262": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes rig las khungs/"
            },
            {
                "@language": "en",
                "@value": "department of education"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811"
    },
    "T1944": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lho kha spyi khyab/"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T1944",
        "rank": 5390
    },
    "T2222": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku srung dmag sgar/"
            },
            {
                "@language": "en",
                "@value": "dalai lama's bodyguard"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2222",
        "rank": 5370
    },
    "T2215": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan army headquarters"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod ljongs dmag spyi khang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2215",
        "rank": 5405
    },
    "T2213": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sog phogs khang /"
        },
        "parent": "O9TAXTBRC2016052KG226811",
        "tbrcId": "T2213",
        "rank": 5400
    },
    "O9TAXTBRC2016052KG226831": {
        "sub": [
            "T2219",
            "T1829",
            "T2097",
            "T2MS14684",
            "T1204",
            "T2383",
            "T2CZ10402",
            "T00AG01326",
            "T2218",
            "T2MS15106",
            "T2380",
            "T2MS13833",
            "T107",
            "T01AG03220",
            "T2405",
            "T1162",
            "T2MS14680",
            "T1KG6182",
            "T2223",
            "T2MS14681",
            "T1PD108963",
            "T1043",
            "T1410",
            "T2075"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "title"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "go ming /"
            },
            {
                "@language": "zh-hans",
                "@value": "名衔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226810",
        "tbrcId": "2",
        "rank": 5421
    },
    "T2219": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gar pa/"
            },
            {
                "@language": "en",
                "@value": "dancer"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2219",
        "rank": 5451
    },
    "T1829": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhwa dmar/"
            },
            {
                "@language": "en",
                "@value": "red hat"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1829",
        "rank": 5461
    },
    "T2097": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rigs ldan gyi rgyal po/"
            },
            {
                "@language": "en",
                "@value": "kings of shambhala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2097",
        "rank": 5446
    },
    "T2MS14684": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' ldan khri pa/"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2MS14684",
        "rank": 5491
    },
    "T1204": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khams sprul (khams pa sgar)"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1204",
        "rank": 5511
    },
    "T2383": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan officer who served tibetan gorvernment before and after 1959"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung zhabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2383",
        "rank": 5476
    },
    "T2CZ10402": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyan drug mchog gnyis/"
            },
            {
                "@language": "en",
                "@value": "six ornaments and the two supreme ones"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2CZ10402",
        "rank": 5526
    },
    "T00AG01326": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam 'phrul bcu gnyis/"
            },
            {
                "@language": "en",
                "@value": "twelve manifestations of padmasambhava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T00AG01326",
        "rank": 5536
    },
    "T2218": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag ru dpon tshang /"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2218",
        "rank": 5481
    },
    "T2MS15106": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "si tu/"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2MS15106",
        "rank": 5466
    },
    "T2380": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "paN chen bla ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "班禅额尔德尼"
            },
            {
                "@language": "en",
                "@value": "panchen lama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2380",
        "rank": 5501
    },
    "T2MS13833": {
        "label": [
            {
                "@language": "en",
                "@value": "dalai lama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tA la'i bla ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2MS13833",
        "rank": 5496
    },
    "T107": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "karma pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "噶玛巴"
            },
            {
                "@language": "en",
                "@value": "karmapa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T107",
        "rank": 5506
    },
    "T01AG03220": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkhan slob chos gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T01AG03220",
        "rank": 5516
    },
    "T2405": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo gru pa (sde srid)"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2405",
        "rank": 5436
    },
    "T1162": {
        "label": [
            {
                "@language": "en",
                "@value": "pandavas"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skya seng bu lnga /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1162",
        "rank": 5531
    },
    "T2MS14680": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sde srid/"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2MS14680",
        "rank": 5426
    },
    "T1KG6182": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmag dpon/"
            },
            {
                "@language": "en",
                "@value": "army commander"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1KG6182",
        "rank": 5471
    },
    "T2223": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chu bzhi sgang drug"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2223",
        "rank": 5486
    },
    "T2MS14681": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' blon/"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2MS14681",
        "rank": 5431
    },
    "T1PD108963": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa skya gong ma rnam lnga /"
        },
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1PD108963",
        "rank": 5441
    },
    "T1043": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grub chen brgyad cu rtsa bzhi/"
            },
            {
                "@language": "en",
                "@value": "84 mahasiddhas"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1043",
        "rank": 5521
    },
    "T1410": {
        "label": [
            {
                "@language": "en",
                "@value": "rje tsong kha pa and his two chief disciples"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rje yab sras gsum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T1410",
        "rank": 5541
    },
    "T2075": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhwa nag"
            },
            {
                "@language": "en",
                "@value": "black hat"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226831",
        "tbrcId": "T2075",
        "rank": 5456
    },
    "O9TAXTBRC2016052KG226856": {
        "sub": [
            "T1672",
            "T8LS1426",
            "T2583"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod gzhi/"
            },
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "en",
                "@value": "Related Topic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226810",
        "tbrcId": "4",
        "rank": 152
    },
    "T1672": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tham ka"
            },
            {
                "@language": "zh-hans",
                "@value": "印章"
            },
            {
                "@language": "en",
                "@value": "seals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226856",
        "tbrcId": "T1672",
        "rank": 3801
    },
    "T8LS1426": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal dar/"
        },
        "parent": "O9TAXTBRC2016052KG226856",
        "tbrcId": "T8LS1426",
        "rank": 3866
    },
    "T2583": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal rtags/"
            },
            {
                "@language": "en",
                "@value": "national emblems"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226856",
        "tbrcId": "T2583",
        "rank": 3861
    },
    "O9TAXTBRC2016052KG226860": {
        "sub": [
            "T2227",
            "T1PD95800",
            "T2207",
            "T1959",
            "T2496",
            "T2510",
            "T2254",
            "T1PD105574",
            "T1192",
            "T2243",
            "T611",
            "T2607"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Clans and Corporate Bodies"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rus dang khyim tshang /"
            },
            {
                "@language": "zh-hans",
                "@value": "姓氏与家族"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "3",
        "rank": 5548
    },
    "T2227": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshad sgra (sger)"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2227",
        "rank": 5603
    },
    "T1PD95800": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mi ming /"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T1PD95800",
        "rank": 5619
    },
    "T2207": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha smon (sger pa)"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2207",
        "rank": 5598
    },
    "T1959": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku drag"
            },
            {
                "@language": "en",
                "@value": "aristocracy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T1959",
        "rank": 5588
    },
    "T2496": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "se grong g.yul smad pa/"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2496",
        "rank": 5608
    },
    "T2510": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rlangs/"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2510",
        "rank": 5558
    },
    "T2254": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo ring (sger)"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2254",
        "rank": 5578
    },
    "T1PD105574": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ban gya/"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T1PD105574",
        "rank": 5593
    },
    "T1192": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnyos/"
            },
            {
                "@language": "en",
                "@value": "nyo"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T1192",
        "rank": 5563
    },
    "T2243": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khon (gdung)"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2243",
        "rank": 5553
    },
    "T611": {
        "parent": "O9TAXTBRC2016052KG226860"
    },
    "T2607": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lce (gdung)"
        },
        "parent": "O9TAXTBRC2016052KG226860",
        "tbrcId": "T2607",
        "rank": 5568
    },
    "O9TAXTBRC2016052KG226911": {
        "sub": [
            "T25"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas yig"
            },
            {
                "@language": "en",
                "@value": "Sacred place"
            },
            {
                "@language": "zh-hans",
                "@value": "寺志｜朝圣录"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "11",
        "rank": 5803
    },
    "T25": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas yig"
            },
            {
                "@language": "en-x-mixed",
                "@value": "guides"
            },
            {
                "@language": "sa-x-iast",
                "@value": "mahātmya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226911",
        "tbrcId": "T25",
        "rank": 5808
    },
    "O9TAXTBRC2016052KG226913": {
        "sub": [
            "T2037",
            "T276",
            "T1PD108881"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam yig"
            },
            {
                "@language": "zh-hans",
                "@value": "游记"
            },
            {
                "@language": "en",
                "@value": "Guide book"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "12",
        "rank": 5813
    },
    "T2037": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam yig ('gro lam mi nor ba'i lam yig)"
            },
            {
                "@language": "zh-hans",
                "@value": "旅游指南，旅途纪实"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226913",
        "tbrcId": "T2037",
        "rank": 5818
    },
    "T276": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtam rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG226913",
        "tbrcId": "T276",
        "rank": 5828
    },
    "T1PD108881": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "护照，通行证"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lag khyer/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226913",
        "tbrcId": "T1PD108881",
        "rank": 5823
    },
    "O9TAXTBRC2016052KG226917": {
        "sub": [
            "T1218",
            "T1GS44553"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkar chag"
            },
            {
                "@language": "zh-hans",
                "@value": "目录"
            },
            {
                "@language": "en",
                "@value": "Table of contents"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "ka",
        "rank": 5
    },
    "T1218": {
        "label": [
            {
                "@language": "en",
                "@value": "funeral reliquary choten"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sku gdung dkar chag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226917",
        "tbrcId": "T1218",
        "rank": 5839
    },
    "T1GS44553": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod rten dkar chag"
        },
        "parent": "O9TAXTBRC2016052KG226917"
    },
    "O9TAXTBRC2016052KG226905": {
        "sub": [
            "T16"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdan rabs/"
            },
            {
                "@language": "en",
                "@value": "Abbatial lineage"
            },
            {
                "@language": "zh-hans",
                "@value": "法嗣记"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "8",
        "rank": 5773
    },
    "T16": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdan rabs/"
            },
            {
                "@language": "en",
                "@value": "monastic chronicles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226905",
        "tbrcId": "T16",
        "rank": 5778
    },
    "O9TAXTBRC2016052KG226873": {
        "sub": [
            "T793",
            "O9TAXTBRC2016052KG226875",
            "O9TAXTBRC2016052KG226880"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal rabs/"
            },
            {
                "@language": "en",
                "@value": "Historical Chronicles"
            },
            {
                "@language": "zh-hans",
                "@value": "王统记"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "5",
        "rank": 5635
    },
    "T793": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal rabs/"
            },
            {
                "@language": "en",
                "@value": "royal chronicles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226873",
        "tbrcId": "T793",
        "rank": 5641
    },
    "O9TAXTBRC2016052KG226875": {
        "sub": [
            "T00JR696",
            "T1941",
            "T1KG6174",
            "T1PD110065"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod kyi rgyal rabs lo tshigs/"
            },
            {
                "@language": "en",
                "@value": "Tibetan chronological period"
            },
            {
                "@language": "zh-hans",
                "@value": "藏史时期"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226873",
        "tbrcId": "1",
        "rank": 5646
    },
    "T00JR696": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "btsan po'i dus rabs/"
            },
            {
                "@language": "en",
                "@value": "tibetan imperial period (c. 600-842)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226875",
        "tbrcId": "T00JR696",
        "rank": 5654
    },
    "T1941": {
        "label": [
            {
                "@language": "en",
                "@value": "prehistory"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gna' bo'i lo rgyus/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226875",
        "tbrcId": "T1941",
        "rank": 5649
    },
    "T1KG6174": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus rabs bar ma/"
            },
            {
                "@language": "en",
                "@value": "medieval period"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226875",
        "tbrcId": "T1KG6174",
        "rank": 5659
    },
    "T1PD110065": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bod sil bu'i skabs/"
        },
        "parent": "O9TAXTBRC2016052KG226875",
        "tbrcId": "T1PD110065",
        "rank": 5664
    },
    "O9TAXTBRC2016052KG226880": {
        "sub": [
            "T2MS14926",
            "T2MS14923",
            "T2MS14925",
            "T2MS13271",
            "T8LS16965"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgya nag rgyal rabs lo tshigs/"
            },
            {
                "@language": "en",
                "@value": "Chinese chronological period"
            },
            {
                "@language": "zh-hans",
                "@value": "汉史时期"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226873",
        "tbrcId": "2",
        "rank": 5670
    },
    "T2MS14926": {
        "label": [
            {
                "@language": "en",
                "@value": "ming dynasty"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ming rgyal rabs/"
            },
            {
                "@language": "zh-hans",
                "@value": "明朝"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226880",
        "tbrcId": "T2MS14926",
        "rank": 5688
    },
    "T2MS14923": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "清朝"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ching rgyal rabs/"
            },
            {
                "@language": "en",
                "@value": "qing dynasty"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226880",
        "tbrcId": "T2MS14923",
        "rank": 5693
    },
    "T2MS14925": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yon rgyal rabs/"
            },
            {
                "@language": "en",
                "@value": "yuan dynasty"
            },
            {
                "@language": "zh-hans",
                "@value": "元朝"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226880",
        "tbrcId": "T2MS14925",
        "rank": 5683
    },
    "T2MS13271": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thang rgyal rabs/"
            },
            {
                "@language": "zh-hans",
                "@value": "唐朝"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226880",
        "tbrcId": "T2MS13271",
        "rank": 5673
    },
    "T8LS16965": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "song rgyal rabs/"
            },
            {
                "@language": "en",
                "@value": "song dynasty"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226880",
        "tbrcId": "T8LS16965",
        "rank": 5678
    },
    "O9TAXTBRC2016052KG226907": {
        "sub": [
            "T1673"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdung rabs/"
            },
            {
                "@language": "en",
                "@value": "Genealogy"
            },
            {
                "@language": "zh-hans",
                "@value": "血统记"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "9",
        "rank": 5783
    },
    "T1673": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdung rabs/"
            },
            {
                "@language": "en",
                "@value": "genealogy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226907",
        "tbrcId": "T1673",
        "rank": 5788
    },
    "O9TAXTBRC2016052KG226886": {
        "sub": [
            "T12",
            "T2MS13232",
            "T4CZ16845",
            "T1688",
            "T00AG02710"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos 'byung /"
            },
            {
                "@language": "en",
                "@value": "Religious Histories"
            },
            {
                "@language": "zh-hans",
                "@value": "法脉源流"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "6",
        "rank": 5700
    },
    "T12": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos 'byung /"
            },
            {
                "@language": "en",
                "@value": "religious histories"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226886",
        "tbrcId": "T12",
        "rank": 5705
    },
    "T2MS13232": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bstan pa phyi dar/"
        },
        "parent": "O9TAXTBRC2016052KG226886",
        "tbrcId": "T2MS13232",
        "rank": 5719
    },
    "T4CZ16845": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtad rabs/"
        },
        "parent": "O9TAXTBRC2016052KG226886",
        "tbrcId": "T4CZ16845",
        "rank": 5724
    },
    "T1688": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsar ma/"
        },
        "parent": "O9TAXTBRC2016052KG226886",
        "tbrcId": "T1688",
        "rank": 5714
    },
    "T00AG02710": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bstan pa snga dar/"
        },
        "parent": "O9TAXTBRC2016052KG226886",
        "tbrcId": "T00AG02710",
        "rank": 5709
    },
    "O9TAXTBRC2016052KG226892": {
        "sub": [
            "T2331",
            "T3",
            "T1779",
            "T1342",
            "T1CZ60",
            "T1702",
            "T2CZ7865",
            "T1203",
            "T1868",
            "T17",
            "T1751",
            "T2620"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam thar/"
            },
            {
                "@language": "en",
                "@value": "Biographical Writings"
            },
            {
                "@language": "zh-hans",
                "@value": "传记"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "7",
        "rank": 5730
    },
    "T2331": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yang srid ngos 'dzin/"
            },
            {
                "@language": "en",
                "@value": "reincarnation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T2331",
        "rank": 3731
    },
    "T3": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam thar/"
            },
            {
                "@language": "en",
                "@value": "biography"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T3",
        "rank": 5736
    },
    "T1779": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtogs brjod/"
            },
            {
                "@language": "sa-x-iast",
                "@value": "avadāna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1779",
        "rank": 5744
    },
    "T1342": {
        "label": [
            {
                "@language": "en",
                "@value": "birth stories"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skyes rabs/"
            },
            {
                "@language": "sa-x-iast",
                "@value": "jātaka"
            },
            {
                "@language": "zh-hans",
                "@value": "本生"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1342",
        "rank": 5740
    },
    "T1CZ60": {
        "label": [
            {
                "@language": "en",
                "@value": "autobiography"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rang rnam/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1CZ60",
        "rank": 5768
    },
    "T1702": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma brgyud pa'i rnam thar/"
        },
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1702",
        "rank": 5752
    },
    "T2CZ7865": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku phreng /"
        },
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T2CZ7865",
        "rank": 3726
    },
    "T1203": {
        "label": [
            {
                "@language": "en",
                "@value": "return from death experiences"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'das log"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1203",
        "rank": 5764
    },
    "T1868": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang ba'i rnam thar/"
            },
            {
                "@language": "en",
                "@value": "esoteric experiences"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1868",
        "rank": 5756
    },
    "T17": {
        "label": [
            {
                "@language": "en",
                "@value": "prayers for speedy rebirth"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yang srid myur byon/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T17",
        "rank": 3736
    },
    "T1751": {
        "label": [
            {
                "@language": "en",
                "@value": "twelve acts of the buddha"
            },
            {
                "@language": "zh-hans",
                "@value": "十二宏化"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thub pa'i mdzad pa bcu gnyis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T1751",
        "rank": 5748
    },
    "T2620": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gser 'phreng /"
            },
            {
                "@language": "en",
                "@value": "golden rosary (transmission)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226892",
        "tbrcId": "T2620",
        "rank": 5760
    },
    "O9TAXTBRC2016052KG226785": {
        "sub": [
            "T1KG5906",
            "T349",
            "T8LS1427",
            "T1968",
            "T11MS16",
            "T1PD108974",
            "T1970",
            "T1910",
            "T00JR816",
            "T1942",
            "T2382",
            "T1127",
            "T255",
            "T1PD108901",
            "T1134",
            "T00EGS1016987",
            "T1969",
            "T10MS12314",
            "T1948",
            "T1449",
            "T1354",
            "T2032",
            "T1943",
            "T342"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lo rgyus/"
            },
            {
                "@language": "en",
                "@value": "History"
            },
            {
                "@language": "zh-hans",
                "@value": "历史"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "1",
        "rank": 5196
    },
    "T1KG5906": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tun hong yig rnying /"
            },
            {
                "@language": "en",
                "@value": "dunhuang manuscripts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1KG5906",
        "rank": 5237
    },
    "T349": {
        "label": [
            {
                "@language": "en",
                "@value": "memoirs"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dran tho/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T349",
        "rank": 5242
    },
    "T8LS1427": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chings yig"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T8LS1427",
        "rank": 5262
    },
    "T1968": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bod sog gi rig gnas 'brel ba/"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1968",
        "rank": 5312
    },
    "T11MS16": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rus mdzod/"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T11MS16",
        "rank": 5232
    },
    "T1PD108974": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dril bu'i kha byang /"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1PD108974",
        "rank": 5282
    },
    "T1970": {
        "label": [
            {
                "@language": "en",
                "@value": "peaceful liberation of tibet"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod zhi bas bcings 'grol/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1970",
        "rank": 5292
    },
    "T1910": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus gzer/"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1910",
        "rank": 5307
    },
    "T00JR816": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gter 'byung /"
            },
            {
                "@language": "en",
                "@value": "account of a treasure revelation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T00JR816",
        "rank": 5277
    },
    "T1942": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbon zhang rdo ring /"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1942",
        "rank": 5297
    },
    "T2382": {
        "label": [
            {
                "@language": "en",
                "@value": "oral history"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ngag rgyun lo rgyus/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T2382",
        "rank": 5221
    },
    "T1127": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdung rus/"
            },
            {
                "@language": "en",
                "@value": "clans and lineages"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1127",
        "rank": 5247
    },
    "T255": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bstan rtsis/"
            },
            {
                "@language": "en",
                "@value": "chronology of the buddha's teaching"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T255",
        "rank": 5216
    },
    "T1PD108901": {
        "label": [
            {
                "@language": "en",
                "@value": "archival documents"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yig tshags/"
            },
            {
                "@language": "zh-hans",
                "@value": "档案"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1PD108901",
        "rank": 5211
    },
    "T1134": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lo rgyus/"
            },
            {
                "@language": "en",
                "@value": "history"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1134",
        "rank": 5201
    },
    "T00EGS1016987": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "btsan byol/"
            },
            {
                "@language": "en",
                "@value": "tibetan refugees"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T00EGS1016987",
        "rank": 5302
    },
    "T1969": {
        "label": [
            {
                "@language": "en",
                "@value": "17 point agreement for the peaceful liberation of tibet"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gros mthun don tshan bcu bdun/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1969",
        "rank": 5287
    },
    "T10MS12314": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "deb ther/"
            },
            {
                "@language": "en",
                "@value": "chronicle"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T10MS12314",
        "rank": 5206
    },
    "T1948": {
        "label": [
            {
                "@language": "en",
                "@value": "peasant revolts"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mi ser gyi gyen log"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1948",
        "rank": 5317
    },
    "T1449": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "地方志"
            },
            {
                "@language": "en",
                "@value": "gazetteer"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lo rgyus rig gnas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1449",
        "rank": 5226
    },
    "T1354": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khri 'don legs 'bul/"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1354",
        "rank": 3856
    },
    "T2032": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan origins"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod mi'i 'byung khungs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T2032",
        "rank": 5257
    },
    "T1943": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skar chung rdo ring /"
        },
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T1943",
        "rank": 5252
    },
    "T342": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyin tho/"
            },
            {
                "@language": "en",
                "@value": "diaries"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226785",
        "tbrcId": "T342",
        "rank": 5267
    },
    "O9TAXTBRC2016052KG226909": {
        "sub": [
            "T192"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsan yig"
            },
            {
                "@language": "en",
                "@value": "Recorded Teachings"
            },
            {
                "@language": "zh-hans",
                "@value": "受法记"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226784",
        "tbrcId": "10",
        "rank": 5793
    },
    "T192": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thob yig"
            },
            {
                "@language": "en",
                "@value": "record of teachings received"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226909",
        "tbrcId": "T192",
        "rank": 5798
    },
    "O9TAXTBRC2016052KG228264": {
        "sub": [
            "O9TAXTBRC2016052KG228288",
            "O9TAXTBRC2016052KG228271",
            "O9TAXTBRC2016052KG228273",
            "O9TAXTBRC2016052KG228275",
            "O9TAXTBRC2016052KG228265",
            "O9TAXTBRC2016052KG228286",
            "O9TAXTBRC2016052KG228269",
            "O9TAXTBRC2016052KG228267"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyogs bsgrigs/"
            },
            {
                "@language": "zh-hans",
                "@value": "汇编"
            },
            {
                "@language": "en",
                "@value": "Collections"
            }
        ],
        "parent": "O9TAXTBRC201605"
    },
    "O9TAXTBRC2016052KG228288": {
        "sub": [
            "T1KG10014"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "E. Gene Smith's Green Books"
            },
            {
                "@language": "zh-hans",
                "@value": "吉恩绿皮书"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "8",
        "rank": 7438
    },
    "T1KG10014": {
        "label": {
            "@language": "en",
            "@value": "e. gene smith's green books"
        },
        "parent": "O9TAXTBRC2016052KG228288",
        "tbrcId": "T1KG10014",
        "rank": 7443
    },
    "O9TAXTBRC2016052KG228271": {
        "sub": [
            "T003JR48"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyud 'bum/"
            },
            {
                "@language": "en",
                "@value": "Collected Tantras"
            },
            {
                "@language": "zh-hans",
                "@value": "密续总集"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "4",
        "rank": 7349
    },
    "T003JR48": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud 'bum/"
        },
        "parent": "O9TAXTBRC2016052KG228271",
        "tbrcId": "T003JR48",
        "rank": 7354
    },
    "O9TAXTBRC2016052KG228273": {
        "sub": [
            "T184"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgur 'bum/"
            },
            {
                "@language": "en",
                "@value": "Collections of Spiritual Songs"
            },
            {
                "@language": "zh-hans",
                "@value": "道歌集"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "5",
        "rank": 7360
    },
    "T184": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgur 'bum/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "collection spiritual songs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228273",
        "tbrcId": "T184",
        "rank": 7365
    },
    "O9TAXTBRC2016052KG228275": {
        "sub": [
            "T1605",
            "T1484",
            "T951",
            "T1774",
            "T10MS11187",
            "T00KG02671",
            "T1890",
            "T483",
            "T947",
            "T1033"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Cycles of Teachings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos skor/"
            },
            {
                "@language": "zh-hans",
                "@value": "专题法集"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "6",
        "rank": 7371
    },
    "T1605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lus med mkha' 'gro'i chos skor dgu/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T1605",
        "rank": 7386
    },
    "T1484": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs chos skor/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T1484",
        "rank": 7381
    },
    "T951": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ba ri brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T951",
        "rank": 7401
    },
    "T1774": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mi tra brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T1774",
        "rank": 7406
    },
    "T10MS11187": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos skor/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T10MS11187",
        "rank": 7376
    },
    "T00KG02671": {
        "label": [
            {
                "@language": "en",
                "@value": "six profound crucial means"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zab pa'i gnad drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T00KG02671",
        "rank": 7421
    },
    "T1890": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang ti nag po gser gyi 'bru gcig"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T1890",
        "rank": 7411
    },
    "T483": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum (dgongs pa yang zab)"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T483",
        "rank": 7416
    },
    "T947": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khro phu brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T947",
        "rank": 7391
    },
    "T1033": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snar thang brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG228275",
        "tbrcId": "T1033",
        "rank": 7396
    },
    "O9TAXTBRC2016052KG228265": {
        "sub": [
            "T1081"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyogs bsgrigs/"
            },
            {
                "@language": "en",
                "@value": "Collections"
            },
            {
                "@language": "zh-hans",
                "@value": "汇编"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264"
    },
    "T1081": {
        "label": [
            {
                "@language": "en",
                "@value": "collections (composite)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyogs bsgrigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228265",
        "tbrcId": "T1081",
        "rank": 7321
    },
    "O9TAXTBRC2016052KG228286": {
        "sub": [
            "T1KG3927"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzungs 'bum/"
            },
            {
                "@language": "en",
                "@value": "Mantra Collections"
            },
            {
                "@language": "zh-hans",
                "@value": "咒语集"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "7",
        "rank": 7427
    },
    "T1KG3927": {
        "label": [
            {
                "@language": "en",
                "@value": "hundred thousand mantras"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzungs 'bum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228286",
        "tbrcId": "T1KG3927",
        "rank": 7432
    },
    "O9TAXTBRC2016052KG228269": {
        "sub": [
            "T1202"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "be'u bum/"
            },
            {
                "@language": "en",
                "@value": "Collections of Rituals"
            },
            {
                "@language": "zh-hans",
                "@value": "秘笈"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "3",
        "rank": 7338
    },
    "T1202": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "be'u bum/"
        },
        "parent": "O9TAXTBRC2016052KG228269",
        "tbrcId": "T1202",
        "rank": 7343
    },
    "O9TAXTBRC2016052KG228267": {
        "sub": [
            "T1PD128746"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dems sgrig"
            },
            {
                "@language": "en",
                "@value": "Selected Works"
            },
            {
                "@language": "zh-hans",
                "@value": "选编"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228264",
        "tbrcId": "2",
        "rank": 7327
    },
    "T1PD128746": {
        "label": [
            {
                "@language": "en",
                "@value": "selected works"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dems sgrig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228267",
        "tbrcId": "T1PD128746",
        "rank": 7332
    },
    "O9TAXTBRC2016052KG225372": {
        "sub": [
            "O9TAXTBRC2016052KG225373",
            "O9TAXTBRC2016052KG225375",
            "O9TAXTBRC2016052KG225377"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "大藏经"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' bstan/"
            },
            {
                "@language": "en",
                "@value": "Buddhist Canonical Texts"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "kha",
        "rank": 203
    },
    "O9TAXTBRC2016052KG225373": {
        "sub": [
            "T2423"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' 'gyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "甘珠尔"
            },
            {
                "@language": "en",
                "@value": "Kajur"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225372",
        "tbrcId": "1",
        "rank": 209
    },
    "T2423": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' 'gyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "甘珠尔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225373",
        "tbrcId": "T2423",
        "rank": 214
    },
    "O9TAXTBRC2016052KG225375": {
        "sub": [
            "T3CN2023"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bstan 'gyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "丹珠尔"
            },
            {
                "@language": "en",
                "@value": "Tenjur"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225372",
        "tbrcId": "2",
        "rank": 219
    },
    "T3CN2023": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bstan 'gyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "丹珠尔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225375",
        "tbrcId": "T3CN2023",
        "rank": 224
    },
    "O9TAXTBRC2016052KG225377": {
        "sub": [
            "T1129",
            "T1777",
            "T1GS147796",
            "T1905",
            "T2566"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod gzhi/"
            },
            {
                "@language": "en",
                "@value": "Related Topics"
            },
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225372",
        "tbrcId": "4",
        "rank": 152
    },
    "T1129": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "有关藏文大藏经"
            },
            {
                "@language": "en",
                "@value": "about kanjur and tenjur"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' bstan skor/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225377",
        "tbrcId": "T1129",
        "rank": 234
    },
    "T1777": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bstan bcos/"
            },
            {
                "@language": "en",
                "@value": "treatise"
            },
            {
                "@language": "zh-hant",
                "@value": "論"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "śāstra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225377",
        "tbrcId": "T1777",
        "rank": 242
    },
    "T1GS147796": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'gyur bcos/"
            },
            {
                "@language": "en",
                "@value": "corrections to the translation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225377",
        "tbrcId": "T1GS147796",
        "rank": 246
    },
    "T1905": {
        "label": [
            {
                "@language": "en",
                "@value": "canon and shastra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' dang bstan bcos/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225377",
        "tbrcId": "T1905",
        "rank": 238
    },
    "T2566": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phun tshogs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG225377",
        "tbrcId": "T2566",
        "rank": 250
    },
    "O9TAXTBRC2016052KG226667": {
        "sub": [
            "O9TAXTBRC2016052KG226668",
            "O9TAXTBRC2016052KG226719",
            "O9TAXTBRC2016052KG226723",
            "O9TAXTBRC2016052KG226725",
            "O9TAXTBRC2016052KG226760"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rig gnas chung ba lnga /"
            },
            {
                "@language": "en",
                "@value": "Five Minor Sciences"
            },
            {
                "@language": "zh-hans",
                "@value": "小五明"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "nya ",
        "rank": 4665
    },
    "O9TAXTBRC2016052KG226668": {
        "sub": [
            "O9TAXTBRC2016052KG226669",
            "O9TAXTBRC2016052KG226701"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "snyan ngag"
            },
            {
                "@language": "en",
                "@value": "Writing Styles"
            },
            {
                "@language": "zh-hans",
                "@value": "诗学及写作"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226667",
        "tbrcId": "1",
        "rank": 4670
    },
    "O9TAXTBRC2016052KG226669": {
        "sub": [
            "T1KG6098",
            "T2575",
            "T2559",
            "T1445",
            "T1979",
            "T1343",
            "T1GS147799",
            "T003JR155",
            "T436",
            "T100",
            "T1156",
            "T205",
            "T2379",
            "T204",
            "T445",
            "T281",
            "T1418",
            "T190",
            "T1964",
            "T219",
            "T3CZ1478",
            "T1KG6100",
            "T4CZ2184",
            "T258",
            "T1KG6097",
            "T1884",
            "T217",
            "T6",
            "T2417",
            "T1PD96324",
            "T1598"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Writing Styles"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "snyan gzhung dang rtsom lus/"
            },
            {
                "@language": "zh-hans",
                "@value": "诗学及写作"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226668",
        "tbrcId": "1",
        "rank": 4670
    },
    "T1KG6098": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bar dus rtsom rig"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1KG6098",
        "rank": 4869
    },
    "T2575": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdza' gzhas/"
            },
            {
                "@language": "en",
                "@value": "love poetry"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T2575",
        "rank": 4879
    },
    "T2559": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gab tshig"
            },
            {
                "@language": "sa-alalc97",
                "@value": "prahelika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T2559",
        "rank": 4739
    },
    "T1445": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "don rgyan so lnga /"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1445",
        "rank": 4733
    },
    "T1979": {
        "label": [
            {
                "@language": "en",
                "@value": "literature"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtsom rig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1979",
        "rank": 4680
    },
    "T1343": {
        "label": [
            {
                "@language": "en",
                "@value": "versification"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshigs bcad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1343",
        "rank": 4700
    },
    "T1GS147799": {
        "label": [
            {
                "@language": "en",
                "@value": "small verse works"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs chung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1GS147799",
        "rank": 4899
    },
    "T003JR155": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpyad rtsom/"
            },
            {
                "@language": "en",
                "@value": "essay"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T003JR155",
        "rank": 4718
    },
    "T436": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyams dbyangs/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T436",
        "rank": 4777
    },
    "T100": {
        "label": [
            {
                "@language": "en",
                "@value": "narrative"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'bel gtam/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T100",
        "rank": 4759
    },
    "T1156": {
        "label": {
            "@language": "en",
            "@value": "chinese literature"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1156",
        "rank": 4904
    },
    "T205": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dper brjod/"
            },
            {
                "@language": "en",
                "@value": "poetic examples"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T205",
        "rank": 4749
    },
    "T2379": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zlos sgra/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T2379",
        "rank": 4714
    },
    "T204": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "snyan ngag"
            },
            {
                "@language": "en",
                "@value": "poetics"
            },
            {
                "@language": "sa-x-iast",
                "@value": "kāvya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T204",
        "rank": 4675
    },
    "T445": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtam tshogs/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T445",
        "rank": 4884
    },
    "T281": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snyan rtsom/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T281",
        "rank": 4695
    },
    "T1418": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun bzang 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1418",
        "rank": 4710
    },
    "T190": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtam dpe/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "proverbs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T190",
        "rank": 3670
    },
    "T1964": {
        "label": [
            {
                "@language": "en",
                "@value": "esthetics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mdzes nyams/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1964",
        "rank": 4894
    },
    "T219": {
        "label": [
            {
                "@language": "en",
                "@value": "alphabetic verse"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ka bshad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T219",
        "rank": 4754
    },
    "T3CZ1478": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "do ha mdzod/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T3CZ1478",
        "rank": 4783
    },
    "T1KG6100": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod kyi deng rabs rtsom rig"
            },
            {
                "@language": "en",
                "@value": "contemporary tibetan writings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1KG6100",
        "rank": 4874
    },
    "T4CZ2184": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje'i glu/"
            },
            {
                "@language": "en",
                "@value": "song of the vajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T4CZ2184",
        "rank": 4764
    },
    "T258": {
        "label": [
            {
                "@language": "en",
                "@value": "essays"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtsom yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T258",
        "rank": 4690
    },
    "T1KG6097": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gna' rtsom/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1KG6097",
        "rank": 4864
    },
    "T1884": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lhug rtsom/"
            },
            {
                "@language": "en",
                "@value": "prose"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1884",
        "rank": 4705
    },
    "T217": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan literature"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod kyi rtsom rig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T217",
        "rank": 4685
    },
    "T6": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgur/"
            },
            {
                "@language": "en",
                "@value": "songs of realization"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T6",
        "rank": 4771
    },
    "T2417": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "blo ze/"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T2417",
        "rank": 4889
    },
    "T1PD96324": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmangs khrod rtsom rig"
        },
        "parent": "O9TAXTBRC2016052KG226669",
        "tbrcId": "T1PD96324",
        "rank": 4728
    },
    "T1598": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "do ha skor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226669"
    },
    "O9TAXTBRC2016052KG226701": {
        "sub": [
            "T1159",
            "T1KG26395",
            "T1PD96322",
            "T2404",
            "T207",
            "T1PD108883",
            "T2KG207410",
            "T14",
            "T286",
            "T1679",
            "T221",
            "T260",
            "T1869",
            "T248",
            "T262",
            "T257",
            "T216"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrung dang yig bskur sogs/"
            },
            {
                "@language": "zh-hans",
                "@value": "故事及信函"
            },
            {
                "@language": "en",
                "@value": "Stories and letter writings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226668"
    },
    "T1159": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khe/"
            },
            {
                "@language": "en",
                "@value": "riddles"
            },
            {
                "@language": "sa-alalc97",
                "@value": "prahelika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T1159",
        "rank": 4744
    },
    "T1KG26395": {
        "label": [
            {
                "@language": "en",
                "@value": "comic book"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ri mo'i sgrung deb/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701"
    },
    "T1PD96322": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gter sgrung /"
        },
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T1PD96322",
        "rank": 4825
    },
    "T2404": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha sgrung /"
        },
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T2404",
        "rank": 4820
    },
    "T207": {
        "label": [
            {
                "@language": "en",
                "@value": "letter writing"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yig bskur/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T207",
        "rank": 4844
    },
    "T1PD108883": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "呈文"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "snyan zhu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T1PD108883",
        "rank": 4854
    },
    "T2KG207410": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sngon gyi rabs/"
            },
            {
                "@language": "en",
                "@value": "mythologized stories"
            },
            {
                "@language": "sa-deva",
                "@value": "पूराण"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701"
    },
    "T14": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spring yig"
            },
            {
                "@language": "en",
                "@value": "'phrin yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T14",
        "rank": 4839
    },
    "T286": {
        "label": [
            {
                "@language": "en",
                "@value": "fiction"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bcos sgrung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T286",
        "rank": 4794
    },
    "T1679": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ro sgrung /"
        },
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T1679",
        "rank": 4814
    },
    "T221": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chab shog"
            },
            {
                "@language": "en",
                "@value": "official letter"
            },
            {
                "@language": "zh-hans",
                "@value": "公文｜公涵"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T221",
        "rank": 4849
    },
    "T260": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brtsams sgrung /"
            },
            {
                "@language": "en",
                "@value": "novel"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T260",
        "rank": 4789
    },
    "T1869": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'bul yig"
            },
            {
                "@language": "en",
                "@value": "list of offerings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T1869",
        "rank": 4094
    },
    "T248": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ge sar sgrung /"
        },
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T248",
        "rank": 4829
    },
    "T262": {
        "label": [
            {
                "@language": "en",
                "@value": "folk stories"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dmangs sgrung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T262",
        "rank": 4799
    },
    "T257": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrung thung /"
            },
            {
                "@language": "en",
                "@value": "short stories"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T257",
        "rank": 4809
    },
    "T216": {
        "label": [
            {
                "@language": "en",
                "@value": "stories"
            },
            {
                "@language": "sa-x-iast",
                "@value": "kathā"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrung gtam/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226701",
        "tbrcId": "T216",
        "rank": 4804
    },
    "O9TAXTBRC2016052KG226719": {
        "sub": [
            "T372",
            "T1867",
            "T2554"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon brjod/"
            },
            {
                "@language": "en",
                "@value": "Abhidhana/Synonyms"
            },
            {
                "@language": "zh-hans",
                "@value": "词藻学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226667",
        "tbrcId": "2",
        "rank": 4909
    },
    "T372": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon brjod/"
            },
            {
                "@language": "en",
                "@value": "synonyms"
            },
            {
                "@language": "sa-alalc97",
                "@value": "abhidhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226719",
        "tbrcId": "T372",
        "rank": 4914
    },
    "T1867": {
        "label": [
            {
                "@language": "en",
                "@value": "ornate poetry recalling personal names"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan gyi mngon brjod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226719",
        "tbrcId": "T1867",
        "rank": 4919
    },
    "T2554": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grangs kyi mngon brjod/"
        },
        "parent": "O9TAXTBRC2016052KG226719",
        "tbrcId": "T2554",
        "rank": 4923
    },
    "O9TAXTBRC2016052KG226723": {
        "sub": [
            "T206"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sdeb sbyor/"
            },
            {
                "@language": "en",
                "@value": "Chanda/Poetic metrics"
            },
            {
                "@language": "zh-hans",
                "@value": "韵律学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226667",
        "tbrcId": "3",
        "rank": 4928
    },
    "T206": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sdeb sbyor/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "chanda"
            },
            {
                "@language": "en",
                "@value": "poetic metrics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226723",
        "tbrcId": "T206",
        "rank": 4933
    },
    "O9TAXTBRC2016052KG226725": {
        "sub": [
            "O9TAXTBRC2016052KG226726",
            "O9TAXTBRC2016052KG226746",
            "O9TAXTBRC2016052KG226758"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skar rtsis/"
            },
            {
                "@language": "en",
                "@value": "Astronomy/Astrology"
            },
            {
                "@language": "zh-hans",
                "@value": "天文历算学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226667",
        "tbrcId": "4",
        "rank": 4939
    },
    "O9TAXTBRC2016052KG226726": {
        "sub": [
            "T1837",
            "T2630",
            "T1909",
            "T1838",
            "T2MS6123",
            "T2477",
            "T1GS148160",
            "T1834",
            "T2052",
            "T1793",
            "T222",
            "T203",
            "T2574",
            "T2341",
            "T1836",
            "T1GS147803",
            "T2570",
            "T1GS147802"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skar rtsis/"
            },
            {
                "@language": "en",
                "@value": "Astronomy/Astrology"
            },
            {
                "@language": "zh-hans",
                "@value": "天文历算学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226725",
        "tbrcId": "4",
        "rank": 4939
    },
    "T1837": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyi 'dzin/"
            },
            {
                "@language": "en",
                "@value": "solar eclipse"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1837",
        "rank": 4993
    },
    "T2630": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyu skar/"
            },
            {
                "@language": "en",
                "@value": "constellation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2630",
        "rank": 5017
    },
    "T1909": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'das lo/"
            },
            {
                "@language": "en",
                "@value": "calculation of the parinirvana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1909",
        "rank": 5054
    },
    "T1838": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "li tho 'go brjod/"
            },
            {
                "@language": "en",
                "@value": "preface for an almanac"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726"
    },
    "T2MS6123": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dal dag"
        },
        "parent": "O9TAXTBRC2016052KG226726"
    },
    "T2477": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus bzang dus ngan/"
        },
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2477",
        "rank": 5077
    },
    "T1GS148160": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rkun ma ngos 'dzin/"
            },
            {
                "@language": "en",
                "@value": "method for the prediction of the identity of thieves"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1GS148160",
        "rank": 5069
    },
    "T1834": {
        "label": [
            {
                "@language": "en",
                "@value": "astrology"
            },
            {
                "@language": "sa-x-iast",
                "@value": "jyotiṣa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dkar rtsis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1834",
        "rank": 4949
    },
    "T2052": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo ming re'u mig"
        },
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2052",
        "rank": 5013
    },
    "T1793": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lnga bsdus/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pancanga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1793",
        "rank": 5049
    },
    "T222": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "li tho/"
            },
            {
                "@language": "en",
                "@value": "calendar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T222",
        "rank": 5008
    },
    "T203": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsis/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "gaṇanā"
            },
            {
                "@language": "en",
                "@value": "calculation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T203",
        "rank": 4944
    },
    "T2574": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyin khyim bcu gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2574",
        "rank": 5064
    },
    "T2341": {
        "label": [
            {
                "@language": "en",
                "@value": "monastic calendar"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dus tshigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2341",
        "rank": 5044
    },
    "T1836": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zla 'dzin/"
            },
            {
                "@language": "en",
                "@value": "lunar eclipse"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1836",
        "rank": 4998
    },
    "T1GS147803": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsis kyi 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1GS147803",
        "rank": 5031
    },
    "T2570": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gza' skar/"
        },
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T2570",
        "rank": 5022
    },
    "T1GS147802": {
        "label": [
            {
                "@language": "en",
                "@value": "calculation of solar and lunar eclipses"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nyi zlar sgra gcan 'jug tshul/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226726",
        "tbrcId": "T1GS147802",
        "rank": 5003
    },
    "O9TAXTBRC2016052KG226746": {
        "sub": [
            "T1PD106858",
            "T2CN4966",
            "T1PD106859",
            "T1443",
            "T2463",
            "T1794",
            "T2509",
            "T1835",
            "T1911",
            "T188",
            "T2462"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nag rtsis/"
            },
            {
                "@language": "en",
                "@value": "Chinese astrology"
            },
            {
                "@language": "zh-hans",
                "@value": "五行运算"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226725"
    },
    "T1PD106858": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshes rtsis/"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1PD106858",
        "rank": 4974
    },
    "T2CN4966": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo 'khor bcu gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T2CN4966",
        "rank": 5059
    },
    "T1PD106859": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rtsis/"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1PD106859",
        "rank": 4978
    },
    "T1443": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'byung rtsis/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "element divination"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1443",
        "rank": 4959
    },
    "T2463": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sme ba (rtsis)"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T2463",
        "rank": 5036
    },
    "T1794": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gza' lnga (rtsis)"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1794",
        "rank": 5027
    },
    "T2509": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "keg rtsis/"
            },
            {
                "@language": "en",
                "@value": "calculation of obstacles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T2509",
        "rank": 4988
    },
    "T1835": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag rtsis/"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1835",
        "rank": 4954
    },
    "T1911": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bras rtsis/"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T1911",
        "rank": 4983
    },
    "T188": {
        "label": [
            {
                "@language": "en",
                "@value": "chinese calculations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgya rtsis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T188",
        "rank": 4969
    },
    "T2462": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spar kha (rtsis)"
        },
        "parent": "O9TAXTBRC2016052KG226746",
        "tbrcId": "T2462",
        "rank": 5040
    },
    "O9TAXTBRC2016052KG226758": {
        "sub": [
            "T1KG3981"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs 'char/"
            },
            {
                "@language": "en",
                "@value": "Svarodaya"
            },
            {
                "@language": "zh-hans",
                "@value": "占音术"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226725"
    },
    "T1KG3981": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs 'char/"
        },
        "parent": "O9TAXTBRC2016052KG226758",
        "tbrcId": "T1KG3981",
        "rank": 4964
    },
    "O9TAXTBRC2016052KG226760": {
        "sub": [
            "O9TAXTBRC2016052KG226761",
            "O9TAXTBRC2016052KG226769",
            "O9TAXTBRC2016052KG226774"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zlos gar/"
            },
            {
                "@language": "en",
                "@value": "Performing Arts"
            },
            {
                "@language": "zh-hans",
                "@value": "戏剧学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226667",
        "tbrcId": "5",
        "rank": 5082
    },
    "O9TAXTBRC2016052KG226761": {
        "sub": [
            "T256",
            "T1GS135819",
            "T1AT303",
            "T2034",
            "T1954",
            "T1089",
            "T1166"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khrab ston/"
            },
            {
                "@language": "zh-hans",
                "@value": "表演"
            },
            {
                "@language": "en",
                "@value": "Performance"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226760"
    },
    "T256": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zlos gar/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nataka"
            },
            {
                "@language": "en",
                "@value": "drama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T256",
        "rank": 5087
    },
    "T1GS135819": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bro/"
        },
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T1GS135819",
        "rank": 5106
    },
    "T1AT303": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos bro/"
        },
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T1AT303",
        "rank": 5114
    },
    "T2034": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan folk drama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "a lce lha mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T2034",
        "rank": 5096
    },
    "T1954": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bro chog"
        },
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T1954",
        "rank": 5110
    },
    "T1089": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gar/"
            },
            {
                "@language": "en",
                "@value": "sacred dance (indic)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T1089",
        "rank": 5101
    },
    "T1166": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'chams/"
            },
            {
                "@language": "en",
                "@value": "sacred dances"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226761",
        "tbrcId": "T1166",
        "rank": 5091
    },
    "O9TAXTBRC2016052KG226769": {
        "sub": [
            "T1AT301",
            "T1746",
            "T1232",
            "T1143"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rol dbyangs/"
            },
            {
                "@language": "en",
                "@value": "Music and musical notation"
            },
            {
                "@language": "zh-hans",
                "@value": "音乐"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226760"
    },
    "T1AT301": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs rol/"
        },
        "parent": "O9TAXTBRC2016052KG226769",
        "tbrcId": "T1AT301",
        "rank": 5128
    },
    "T1746": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs yig"
            },
            {
                "@language": "en",
                "@value": "musical notation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226769",
        "tbrcId": "T1746",
        "rank": 5118
    },
    "T1232": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rol mo/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "musical instruments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226769",
        "tbrcId": "T1232",
        "rank": 5123
    },
    "T1143": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgra snyan/"
        },
        "parent": "O9TAXTBRC2016052KG226769",
        "tbrcId": "T1143",
        "rank": 5133
    },
    "O9TAXTBRC2016052KG226774": {
        "sub": [
            "T2030",
            "T2581",
            "T2576",
            "T261",
            "T251",
            "T246",
            "T1KG15415",
            "T1180",
            "T1674"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "songs and humors"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "glu dang bzhad gad/"
            },
            {
                "@language": "zh-hans",
                "@value": "歌舞相声"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226760"
    },
    "T2030": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "glu rol/"
        },
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T2030",
        "rank": 5148
    },
    "T2581": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chang gzhas/"
            },
            {
                "@language": "en",
                "@value": "drinking songs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T2581",
        "rank": 5152
    },
    "T2576": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ngal rtsol glu gzhas/"
        },
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T2576",
        "rank": 5161
    },
    "T261": {
        "label": [
            {
                "@language": "en",
                "@value": "humorous stories"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dgod gtam/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T261",
        "rank": 5175
    },
    "T251": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "la gzhas/"
        },
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T251",
        "rank": 5170
    },
    "T246": {
        "label": [
            {
                "@language": "en",
                "@value": "songs"
            },
            {
                "@language": "sa-x-iast",
                "@value": "saṅgīta"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "glu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T246",
        "rank": 5143
    },
    "T1KG15415": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stod gzhas/"
        },
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T1KG15415",
        "rank": 5157
    },
    "T1180": {
        "label": [
            {
                "@language": "en",
                "@value": "wit and humor"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bzhad gad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T1180",
        "rank": 5180
    },
    "T1674": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmangs gzhas/"
            },
            {
                "@language": "en",
                "@value": "folk songs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226774",
        "tbrcId": "T1674",
        "rank": 5165
    },
    "O9TAXTBRC2016052KG227157": {
        "sub": [
            "O9TAXTBRC2016052KG227158",
            "O9TAXTBRC2016052KG227200",
            "O9TAXTBRC2016052KG227926",
            "O9TAXTBRC2016052KG227934"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha tshogs/"
            },
            {
                "@language": "en",
                "@value": "Deities"
            },
            {
                "@language": "zh-hans",
                "@value": "诸佛神"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "6",
        "rank": 1842
    },
    "O9TAXTBRC2016052KG227158": {
        "sub": [
            "T952",
            "T390",
            "T1761",
            "T871",
            "T63",
            "T566",
            "T931",
            "T963",
            "T863",
            "T725",
            "T1078",
            "T2MS11383",
            "T106",
            "T2GS1130",
            "T824",
            "T719",
            "T720",
            "T365",
            "T1AT489",
            "T1154",
            "T1211",
            "T00JR3337",
            "T564",
            "T635",
            "T414",
            "T2474",
            "T012AG03552",
            "T568",
            "T1855",
            "T681",
            "T2GS1159",
            "T953",
            "T567",
            "T1804",
            "T8LS14948",
            "T1903",
            "T2GS1131",
            "T688",
            "T1602",
            "T4CZ57129",
            "T69"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Buddhas and Bodhisattvas"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas byang sems/"
            },
            {
                "@language": "zh-hans",
                "@value": "佛菩萨"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227157",
        "tbrcId": "1",
        "rank": 1847
    },
    "T952": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thub dbang nye sras brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T952",
        "rank": 1871
    },
    "T390": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor/"
            },
            {
                "@language": "zh-hans",
                "@value": "金刚手"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajrapāṇi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T390",
        "rank": 1878
    },
    "T1761": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "de bzhin gshegs pa lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1761",
        "rank": 1859
    },
    "T871": {
        "label": [
            {
                "@language": "sa-x-iast",
                "@value": "अक्षोभ्य"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mi 'khrugs pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "不动佛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T871",
        "rank": 1861
    },
    "T63": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "amitayus (with retinue)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'od dpag med gtso 'khor/"
            },
            {
                "@language": "en",
                "@value": "lord of boundless light with retinue"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T63",
        "rank": 1886
    },
    "T566": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahamuni (astadasasthaviraparivara)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thub pa gnas brtan bcu drug dang bcas pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T566",
        "rank": 1887
    },
    "T931": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "don yod grub pa/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T931",
        "rank": 1863
    },
    "T963": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T963",
        "rank": 1856
    },
    "T863": {
        "label": [
            {
                "@language": "en",
                "@value": "the medicine guru (bhaisajya guru) and his six brothers"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas sman bla mched bdun jo bo rje'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "bhaisajyaguru"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T863",
        "rank": 1865
    },
    "T725": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrib pa rnam sel/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nivaranaviskambhin"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T725",
        "rank": 1880
    },
    "T1078": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang chub sems dpa'"
            },
            {
                "@language": "sa-alalc97",
                "@value": "bodhisattva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1078",
        "rank": 1873
    },
    "T2MS11383": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "宝生佛"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rin chen 'byung ldan/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T106": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "觀世音"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "avalokiteśvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T106",
        "rank": 1874
    },
    "T2GS1130": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nam mkha'i snying po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "khagarba"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T2GS1130",
        "rank": 1876
    },
    "T824": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rigs gsum mgon po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "trikulanatha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T719": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas dpa' bo bdun/"
            },
            {
                "@language": "en",
                "@value": "the seven hero buddhas"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T720": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'od dpag med/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "amitabha"
            },
            {
                "@language": "zh-hans",
                "@value": "无量光佛｜阿弥陀佛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T720",
        "rank": 1862
    },
    "T365": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sadaprarudita"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtag tu ngu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T365",
        "rank": 1883
    },
    "T1AT489": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "释迦牟尼佛"
            },
            {
                "@language": "en",
                "@value": "Gautama Buddha"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "shakyamuni"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "shAkya thub pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T1154": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas brtan bcu drug"
            },
            {
                "@language": "en",
                "@value": "sixteen sthavira"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T1211": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas 35"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1211",
        "rank": 1857
    },
    "T00JR3337": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ston pa bcu gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T00JR3337",
        "rank": 1868
    },
    "T564": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shAkya thub pa rdo rje gdan pa gtso 'khor gsum/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sakyamuni (vajrasana)"
            },
            {
                "@language": "en",
                "@value": "the sage of vajrasana and his two attendants"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T635": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "jo bo (lha sa)"
            },
            {
                "@language": "en",
                "@value": "Jowo Shakyamuni"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T635",
        "rank": 1889
    },
    "T414": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' gdams lha bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T414",
        "rank": 1885
    },
    "T2474": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "jo bo (skyid grong)"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T2474",
        "rank": 1891
    },
    "T012AG03552": {
        "label": [
            {
                "@language": "en",
                "@value": "the seven historical buddhas"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "de bzhin gshegs pa bdun/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T012AG03552",
        "rank": 1858
    },
    "T568": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sman bla/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "bhaisajyaguru"
            },
            {
                "@language": "en",
                "@value": "medicine buddha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T568",
        "rank": 1864
    },
    "T1855": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman bla mched brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1855",
        "rank": 1866
    },
    "T681": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byams pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "maitreya"
            },
            {
                "@language": "zh-hans",
                "@value": "慈尊"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T2GS1159": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rje btsun byams pa ni ri ti pa'i lugs/"
            },
            {
                "@language": "en",
                "@value": "venerable maitreya in the tradition of nirrtipada"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T2GS1159",
        "rank": 1884
    },
    "T953": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nye ba'i sras brgyad/"
            },
            {
                "@language": "en",
                "@value": "the eight close sons"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T953",
        "rank": 1872
    },
    "T567": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shAkya thub pa bka' gdams lha bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T567",
        "rank": 1853
    },
    "T1804": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kun tu bzang po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samantabhadra"
            },
            {
                "@language": "en",
                "@value": "the ever-excellent"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1804",
        "rank": 1877
    },
    "T8LS14948": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "indra b+hu ti/"
        },
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T1903": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "毗盧遮那"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vairocana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam par snang mdzad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T1903",
        "rank": 1860
    },
    "T2GS1131": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa'i snying po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ksitigarbha"
            },
            {
                "@language": "zh-hans",
                "@value": "地藏王"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T688": {
        "label": [
            {
                "@language": "en",
                "@value": "the sage of meru peak"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thub pa me ru shi sa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "merusikhara muni"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T688",
        "rank": 1854
    },
    "T1602": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thub pa lnga ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227158"
    },
    "T4CZ57129": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ra mo che'i jo bo/"
            },
            {
                "@language": "en",
                "@value": "Jowo Mikyo Dorje"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T4CZ57129",
        "rank": 1890
    },
    "T69": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mañjuśrī"
            },
            {
                "@language": "zh-hans",
                "@value": "曼殊室利"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227158",
        "tbrcId": "T69",
        "rank": 1879
    },
    "O9TAXTBRC2016052KG227200": {
        "sub": [
            "T992",
            "T1705",
            "T01AG03332",
            "T2CN4390",
            "T670",
            "T1813",
            "T435",
            "T906",
            "T2614",
            "T2605",
            "T418",
            "T593",
            "T1725",
            "T737",
            "T1PD108846",
            "T00AG01322",
            "T1327",
            "T2114",
            "T2GS1213",
            "T676",
            "T601",
            "T1392",
            "T2GS1084",
            "T995",
            "T2111",
            "T1347",
            "T487",
            "T1687",
            "T461",
            "T1803",
            "T1083",
            "T2292",
            "T2300",
            "T1011",
            "T2GS1014",
            "T1066",
            "T569",
            "T741",
            "T1290",
            "T727",
            "T00AG0324",
            "T2314",
            "T2CZ5183",
            "T994",
            "T985",
            "T1GS138764",
            "T1086",
            "T2GS1203",
            "T2291",
            "T1002",
            "T691",
            "T00AG01174",
            "T1006",
            "T747",
            "T695",
            "T1337",
            "T1070",
            "T853",
            "T00AG01280",
            "T678",
            "T1GS108143",
            "T428",
            "T2GS1086",
            "T1GS147809",
            "T1085",
            "T2GS1206",
            "T2294",
            "T00JR803",
            "T788",
            "T856",
            "T1786",
            "T1482",
            "T322",
            "T469",
            "T1021",
            "T2230",
            "T1401",
            "T551",
            "T1GS129604",
            "T724",
            "T904",
            "T2GS1205",
            "T1194",
            "T2GS1221",
            "T353",
            "T39",
            "T749",
            "T855",
            "T8LS15614",
            "T1432",
            "T484",
            "T8LS15611",
            "T1024",
            "T1053",
            "T2GS1208",
            "T003JR5329",
            "T619",
            "T2046",
            "T1PD110133",
            "T00AG0336",
            "T1GS2519",
            "T858",
            "T4CZ15540",
            "T714",
            "T1023",
            "T130",
            "T582",
            "T711",
            "T1018",
            "T1260",
            "T00AG01366",
            "T4CZ15556",
            "T645",
            "T650",
            "T2304",
            "T01AG03006",
            "T4CZ15633",
            "T2542",
            "T1GS122410",
            "T2155",
            "T00AG0339",
            "T2GS1212",
            "T1409",
            "T1GS124512",
            "T559",
            "T588",
            "T8LS15658",
            "T1346",
            "T2GS1160",
            "T665",
            "T702",
            "T40",
            "T2GS1218",
            "T1055",
            "T1667",
            "T2175",
            "T335",
            "T4CZ15531",
            "T00JR3170",
            "T2303",
            "T606",
            "T891",
            "T305",
            "T1075",
            "T1060",
            "T2087",
            "T558",
            "T563",
            "T00AG0281",
            "T716",
            "T2GS1192",
            "T4CZ15537",
            "T1AT287",
            "T00JR3557",
            "T2174",
            "T974",
            "T2CN4770",
            "T1005",
            "T652",
            "T894",
            "T417",
            "T836",
            "T736",
            "T1036",
            "T78",
            "T520",
            "T1282",
            "T2110",
            "T704",
            "T667",
            "T672",
            "T2CN4988",
            "T1669",
            "T658",
            "T2CN4663",
            "T908",
            "T003JR4606",
            "T2GS1157",
            "T962",
            "T4CZ15354",
            "T1065",
            "T595",
            "T526",
            "T603",
            "T1062",
            "T1394",
            "T2113",
            "T1313",
            "T489",
            "T671",
            "T4CZ15539",
            "T628",
            "T2549",
            "T657",
            "T2523",
            "T907",
            "T2289",
            "T899",
            "T4CZ15630",
            "T2565",
            "T2181",
            "T976",
            "T981",
            "T2167",
            "T1826",
            "T594",
            "T2GS1210",
            "T677",
            "T729",
            "T00AG0326",
            "T1GS124510",
            "T662",
            "T1631",
            "T2093",
            "T2112",
            "T1316",
            "T2GS1202",
            "T2293",
            "T2CZ5182",
            "T2GS996",
            "T964",
            "T979",
            "T2290",
            "T1067",
            "T1072",
            "T00AG01095",
            "T2040",
            "T597",
            "T1662",
            "T513",
            "T1AT501",
            "T00AG0330",
            "T1396",
            "T1GS124502",
            "T399",
            "T1059",
            "T2144",
            "T2GS1201",
            "T1087",
            "T2296",
            "T1003",
            "T2MS9698",
            "T983",
            "T2GS1018",
            "T1000",
            "T823",
            "T2164",
            "T679",
            "T00AG0328",
            "T00AG0333",
            "T664",
            "T4CZ15561",
            "T1716",
            "T989",
            "T2GS1155",
            "T1CZ14",
            "T2GS1207",
            "T2295",
            "T684",
            "T1GS102896",
            "T1074",
            "T600",
            "T857",
            "T00AG0332",
            "T708",
            "T902",
            "T1378",
            "T710",
            "T2048",
            "T01AG03032",
            "T2154",
            "T641",
            "T00AG0338",
            "T2472",
            "T825",
            "T830",
            "T3CN15347",
            "T4CZ15620",
            "T2151",
            "T311",
            "T00AG0335",
            "T580",
            "T00AG01274",
            "T584",
            "T1GS129608",
            "T128",
            "T713",
            "T2GS1209",
            "T00AG01368",
            "T4CZ15558",
            "T647",
            "T2010",
            "T2365",
            "T525",
            "T1026",
            "T2157",
            "T2GS1214",
            "T602",
            "T1780",
            "T1393",
            "T2GS1085",
            "T1KG86198",
            "T462",
            "T1057",
            "T1042",
            "T00AG01277",
            "T712",
            "T608",
            "T2GS1174",
            "T651",
            "T1012",
            "T54",
            "T835",
            "T2GS1178",
            "T1767",
            "T970",
            "T912",
            "T2156",
            "T00AG0325",
            "T832",
            "T876",
            "T74",
            "T2148",
            "T2317",
            "T1041",
            "T586",
            "T571",
            "T700",
            "T1007",
            "T744",
            "T649",
            "T654",
            "T896",
            "T838",
            "T2012",
            "T1GS124494",
            "T1061",
            "T2GS1087",
            "T577",
            "T135",
            "T1452",
            "T706",
            "T1376",
            "T00JR3352",
            "T1039",
            "T653",
            "T4CZ15356",
            "T1320",
            "T2163",
            "T1822",
            "T1866",
            "T1064",
            "T1891",
            "T576",
            "T76",
            "T659",
            "T1351",
            "T1613",
            "T4CZ15632",
            "T154",
            "T1565",
            "T1009",
            "T1111",
            "T1038",
            "T656",
            "T1828",
            "T592",
            "T2246",
            "T596",
            "T012AG03337",
            "T1063",
            "T432",
            "T1054",
            "T579",
            "T751",
            "T003JR4624",
            "T2593",
            "T452",
            "T1118",
            "T2GS1161",
            "T1069",
            "T41",
            "T2115",
            "T599",
            "T757",
            "T003JR402",
            "T1632",
            "T583",
            "T1715",
            "T2146",
            "T1700",
            "T660",
            "T4CZ15557",
            "T1CZ13",
            "T646",
            "T4CZ15634",
            "T1CZ6",
            "T927",
            "T1GS122411",
            "T2101",
            "T1638",
            "T598",
            "T2354",
            "T589",
            "T756",
            "T1GS135818",
            "T703",
            "T666",
            "T2GS1215",
            "T1664",
            "T01JR252",
            "T1056",
            "T956",
            "T692",
            "T2019",
            "T1CZ16",
            "T997",
            "T43",
            "T1076",
            "T2044",
            "T2GS1231",
            "T560",
            "T2GS1158",
            "T2269",
            "T1182",
            "T2150",
            "T759",
            "T00AG0334",
            "T4CZ15534",
            "T698",
            "T821",
            "T1GS129603",
            "T4CZ15611",
            "T2520",
            "T2133",
            "T1CZ15",
            "T2137",
            "T1PD114149",
            "T1324",
            "T4CZ15554",
            "T643",
            "T1AT454",
            "T2GS1128",
            "T2153",
            "T01AG03331",
            "T2197",
            "T668",
            "T697",
            "T709",
            "T00AG01276",
            "T1734",
            "T1568",
            "T4CZ15355",
            "T2GS1222",
            "T1CZ47",
            "T2GS1173",
            "T8LS14946",
            "T53",
            "T00JR2666",
            "T00JR996",
            "T1987",
            "T527",
            "T00AG01321",
            "T50",
            "T00AG0340",
            "T875",
            "T1749",
            "T2152",
            "T2143",
            "T630",
            "T464",
            "T00AG01279",
            "T2396",
            "T920",
            "T2GS1176",
            "T1827",
            "T4CZ15544",
            "T1677",
            "T332",
            "T740",
            "T4CZ15541",
            "T00AG0327",
            "T636",
            "T834",
            "T878",
            "T2GS1044",
            "T131",
            "T3JT12423",
            "T984",
            "T00AG01278",
            "T573",
            "T965",
            "T746",
            "T731",
            "T4CZ15547",
            "T1040",
            "T012AG03390",
            "T1950",
            "T00JR647",
            "T833",
            "T2145",
            "T1454",
            "T1GS147808",
            "T2149",
            "T572",
            "T1956",
            "T1152",
            "T730",
            "T655",
            "T4CZ15546",
            "T2071",
            "T1030",
            "T00JR73",
            "T47",
            "T1785",
            "T2165",
            "T00UNK195",
            "T00AG0329",
            "T1GS129607",
            "T986",
            "T2334",
            "T750",
            "T1045",
            "T2306",
            "T575",
            "T2GS1220",
            "T38",
            "T748",
            "T733",
            "T591",
            "T1365",
            "T1037",
            "T991",
            "T2GS1234",
            "T429",
            "T434",
            "T397",
            "T511",
            "T739",
            "T687",
            "T01AG03283",
            "T1766",
            "T565",
            "T00AG01405",
            "T590",
            "T642",
            "T449",
            "T1GS147787",
            "T990",
            "T012AG03338",
            "T104",
            "T1811",
            "T581",
            "T1013",
            "T585",
            "T2248",
            "T738",
            "T00AG01365",
            "T648",
            "T4CZ15559",
            "T1052",
            "T2020",
            "T4CZ15636",
            "T1671",
            "T1PD181163",
            "T996",
            "T2GS1211",
            "T674",
            "T1817",
            "T2264",
            "T01JR186",
            "T993",
            "T149",
            "T1345",
            "T758",
            "T003JR403",
            "T2GS1217",
            "T1058",
            "T690",
            "T4CZ15530",
            "T694",
            "T605",
            "T4CZ326967",
            "T852",
            "T2GS1179",
            "T1768",
            "T955",
            "T1GS124517",
            "T2GS1088",
            "T999",
            "T2GS1165",
            "T1816",
            "T45",
            "T2GS903",
            "T519",
            "T4CZ15536",
            "T4CZ15521",
            "T1084",
            "T587",
            "T4CZ15215",
            "T1PD181202",
            "T1704",
            "T701",
            "T745",
            "T693",
            "T1GS129602",
            "T851",
            "T389",
            "T998",
            "T440",
            "T518",
            "T1681",
            "T765",
            "T707",
            "T2GS1219",
            "T699",
            "T2GS1204",
            "T1391",
            "T8LS15660",
            "T1653",
            "T1377",
            "T2GS1156",
            "T1049",
            "T696",
            "T1338",
            "T4CZ15357",
            "T854",
            "T8LS15613",
            "T4CZ15551",
            "T2GS1172",
            "T52",
            "T314",
            "T2198",
            "T722",
            "T00AG0323",
            "T4CZ15538",
            "T193",
            "T618",
            "T2180",
            "T980",
            "T2049",
            "T2166",
            "T003JR4506",
            "T1GS2518",
            "T4CZ15529",
            "T8LS15612",
            "T1025",
            "T1430",
            "T2GS1171",
            "T4CZ15543",
            "T728",
            "T661",
            "T1022",
            "T2171",
            "T2102",
            "T686",
            "T873",
            "T423",
            "T967",
            "T00JR770",
            "T2GS1177",
            "T1675",
            "T4CZ15528",
            "T2GS1162",
            "T42",
            "T8LS15615",
            "T1071",
            "T8LS15657",
            "T2310",
            "T4CZ15542",
            "T187",
            "T2170",
            "T1205",
            "T570",
            "T2302",
            "T1239",
            "T574",
            "T1PD181162",
            "T982",
            "T609",
            "T1GS148157",
            "T732",
            "T8LS14949",
            "T1466",
            "T1032",
            "T2GS1169",
            "T8LS15656",
            "T715",
            "T663",
            "T2GS1216",
            "T919",
            "T1330",
            "T1KG5607",
            "T988",
            "T1019",
            "T973",
            "T00JR885",
            "T2CN4940",
            "T1299",
            "T735",
            "T00AG01320",
            "T1073",
            "T44",
            "T669",
            "T2GS1168",
            "T012AG03389",
            "T4CZ2733",
            "T4CZ15535",
            "T624",
            "T822",
            "T2CN4662",
            "T1207",
            "T972",
            "T1KG1599",
            "T1564",
            "T2138",
            "T2307",
            "T415",
            "T2047",
            "T734",
            "T4CZ15555",
            "T644",
            "T673",
            "T828",
            "T1GS147789"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "本尊天"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yi dam lha tshogs/"
            },
            {
                "@language": "en",
                "@value": "Tutelary Deitys"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227157",
        "tbrcId": "2",
        "rank": 1894
    },
    "T992": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtsug tor dri med lha 5 ngor lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vimalosnisa 5 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T992",
        "rank": 2183
    },
    "T1705": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru zhi ba bka' 'dus (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T01AG03332": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa yi 'jigs skyob sgrol ma/ (bar chad kun sel)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2CN4390": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma mkha' 'gro/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T670": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba spre'u brgyad ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala astakapa"
            },
            {
                "@language": "en",
                "@value": "acala of the eight monkeys"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T670",
        "rank": 2176
    },
    "T1813": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro bdud 'dul drag mo (stag sham)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1813",
        "rank": 2006
    },
    "T435": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kye rdor lhan skyes/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hevajra sahaja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T435",
        "rank": 2058
    },
    "T906": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyung nag dus 'khor lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2614": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mkha' 'gro/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "dakini"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2614",
        "rank": 2000
    },
    "T2605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rdo rje gro lod (a 'dzom 'brug pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2605",
        "rank": 2469
    },
    "T418": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 62 lU hi pa'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 62 devatmaka luhipa krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T418",
        "rank": 1916
    },
    "T593": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara sadaksara (atisa-krama)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen yi ge drug pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T593",
        "rank": 2246
    },
    "T1725": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "白傘蓋佛頂"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sitātapatrā"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gdugs dkar mo can/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1725",
        "rank": 2430
    },
    "T737": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin dkar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva sita"
            },
            {
                "@language": "en",
                "@value": "white hayagriva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T737",
        "rank": 2102
    },
    "T1PD108846": {
        "label": [
            {
                "@language": "en",
                "@value": "wrathful deities"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yi dam drag po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1PD108846",
        "rank": 1901
    },
    "T00AG01322": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dzaM b+ha la ljang khu (byang gter lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01322",
        "rank": 2514
    },
    "T1327": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru spros med don sgrub (rol pa'i rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1327",
        "rank": 2490
    },
    "T2114": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo lha mo bco lnga snyan rgyud lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2114",
        "rank": 1973
    },
    "T2GS1213": {
        "label": [
            {
                "@language": "en",
                "@value": "tara the heroic"
            },
            {
                "@language": "sa-alalc97",
                "@value": "praviratara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma rab tu dpa' ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1213",
        "rank": 2379
    },
    "T676": {
        "label": [
            {
                "@language": "en",
                "@value": "deathless vajra goddess"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'chi med rdo rje lha mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahapratyangira"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T676",
        "rank": 1944
    },
    "T601": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "gaganaraja avalokitesvara"
            },
            {
                "@language": "en",
                "@value": "avalokita king of space"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po nam mkha'i rgyal po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T601",
        "rank": 2262
    },
    "T1392": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med gnam lcags rdo rje ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1392",
        "rank": 2137
    },
    "T2GS1084": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar yid bzhin 'khor lo gnyan lugs/"
            },
            {
                "@language": "en",
                "@value": "wish-fulfilling-wheel white tara in the tradition of gnyan lo tsa ba"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1084",
        "rank": 2393
    },
    "T995": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "amitayus dundubhisvara 17 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med 'chi med rnga sgra lha 17 jo nang lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2111": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrus lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1347": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "amitāyus"
            },
            {
                "@language": "zh-hant",
                "@value": "無量壽"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T487": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnam 'joms mthing ga"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nila vajravidarana"
            },
            {
                "@language": "en",
                "@value": "blue vajravidarana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T487",
        "rank": 2192
    },
    "T1687": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe yum tsaNDa lI/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T461": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro (gnam chos)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T461",
        "rank": 2459
    },
    "T1803": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo shes rab gsal byed/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1803",
        "rank": 1961
    },
    "T1083": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas mkha' 'gro lha 9"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2292": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po me rlung 'khyil ba ( rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2300": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs blo 'phel shes rab ral gri/ (sangs rgyas gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1011": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "abhisambodhikarunodgata vairocana 122 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam snang mngon byang lha 122 mtshan ma gnyis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1011",
        "rank": 2122
    },
    "T2GS1014": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma rdo rje sems dpa' lha 9"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1014",
        "rank": 2133
    },
    "T1066": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog khro bo rdo rje sems dpa' lha 51 bshad rgyud mngon brjod bla ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1066",
        "rank": 1936
    },
    "T569": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "klu dbang rgyal po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nagesvararaja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T741": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vetali devi"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ro langs ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T741",
        "rank": 1978
    },
    "T1290": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thub pa lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1290",
        "rank": 2127
    },
    "T727": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "visvesa avalokitesvara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po sna tshogs dbang po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T727",
        "rank": 2242
    },
    "T00AG0324": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "the 21 taras according to the tradition of suryagupta"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma nyer gcig (nyi sbas lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0324",
        "rank": 2347
    },
    "T2314": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum po seng sgrogs/ (chos rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2CZ5183": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdor dbyings lha lnga bcu nga gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T994": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "amitayus dundubhisvara 9 devatmaka jetari krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med 'chi med rnga sgra lha 9 dze tA ri lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T985": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja lokesvara 19 devatmaka atisa krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus spyan ras gzigs dbang phyug lha 19 jo bo lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T985",
        "rank": 1910
    },
    "T1GS138764": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "karma gu ru (bkra shis stobs rgyal)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS138764",
        "rank": 2488
    },
    "T1086": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma hA mA ya lha 5"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1086",
        "rank": 2110
    },
    "T2GS1203": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma dngos grub 'byung ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "siddhisambhavatara"
            },
            {
                "@language": "en",
                "@value": "tara source of attainments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1203",
        "rank": 2370
    },
    "T2291": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1002": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "nila vajravidarana 15 devatmaka ('brug lugs)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnam 'joms sngon po lha 15 'brug lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1002",
        "rank": 2186
    },
    "T691": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thub pa dam tshig gsum bkod gtso rkyang /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "trisamayavyuha ekantanayaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T691",
        "rank": 2125
    },
    "T00AG01174": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gos sngon can/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani nilambaradhara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01174",
        "rank": 2305
    },
    "T1006": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gdugs dkar po can ma lha 27"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1006",
        "rank": 2445
    },
    "T747": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "jambhala krsna"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la nag po kha che paN chen lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T747",
        "rank": 2511
    },
    "T695": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo ti nu/"
            },
            {
                "@language": "en",
                "@value": "the goddess tinu"
            },
            {
                "@language": "sa-alalc97",
                "@value": "prthivi devi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T695",
        "rank": 2440
    },
    "T1337": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma 'jigs pa brgyad skyob/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1337",
        "rank": 2334
    },
    "T1070": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahavarahamukhi 37 devatmaka dakarnavamahayoginitantra krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje phag gdong chen mo lha 37"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1070",
        "rank": 1953
    },
    "T853": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed zhal gcig phyag gnyis rwa lugs nye brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T853",
        "rank": 2038
    },
    "T00AG01280": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyen po lha bzhi (dag snang rgya can lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01280",
        "rank": 2108
    },
    "T678": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs dkar po shes rab 'khor lo kha che pan chen gyi man ngag"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjughosa prajnacakra sita"
            },
            {
                "@language": "en",
                "@value": "white manjughosa of the wisdom wheel"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T678",
        "rank": 2281
    },
    "T1GS108143": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdor lha dgu/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS108143",
        "rank": 2059
    },
    "T428": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vajrayoginī"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnal 'byor ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "金刚瑜伽母"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T428",
        "rank": 1956
    },
    "T2GS1086": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma dkar mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sita sarasvati"
            },
            {
                "@language": "en",
                "@value": "white sarasvati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1086",
        "rank": 2409
    },
    "T1GS147809": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med sprul sku stag lung bka' brgyud lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS147809",
        "rank": 2151
    },
    "T1085": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ku ru kulle lha 15"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1085",
        "rank": 2401
    },
    "T2GS1206": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "sukhasiddhitara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma bde sgrub ma/"
            },
            {
                "@language": "en",
                "@value": "tara accomplisher of bliss"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1206",
        "rank": 2373
    },
    "T2294": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po dbu dgu (legs ldan rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2294",
        "rank": 2473
    },
    "T00JR803": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgyu 'phrul drwa ba (khams lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T788": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "garuḍa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khyung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T788",
        "rank": 2209
    },
    "T856": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma rnal 'byor zhi sbas lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T856",
        "rank": 2345
    },
    "T1786": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje phur pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrakila"
            },
            {
                "@language": "zh-hans",
                "@value": "金刚橛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1786",
        "rank": 2074
    },
    "T1482": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khros ma lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1482",
        "rank": 1970
    },
    "T322": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen 'gro 'dul (nyang ral)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T322",
        "rank": 2267
    },
    "T469": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog sangs rgyas mnyam sbyor/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T469",
        "rank": 1931
    },
    "T1021": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava 9 or 17 devatmaka (skyo lugs)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed lha 9 'am 17 skyo lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1021",
        "rank": 2025
    },
    "T2230": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs dkar po shes rab blo 'phel/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2230",
        "rank": 2283
    },
    "T1401": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po bde gshegs 'dus pa (nyang ral nyi ma 'od zer)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T551": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma sku gsum bde 'dus zab tig (mkhyen brtse'i yang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS129604": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po gnam lcags me 'khor (zhig po gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS129604",
        "rank": 2468
    },
    "T724": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "ksitigarba"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa'i snying po gang ba'i rdo rje'i lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T904": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin dmar po grub rgyal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T904",
        "rank": 2103
    },
    "T2GS1205": {
        "label": [
            {
                "@language": "en",
                "@value": "tara of increase"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma rab rgyas ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prapustitara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1205",
        "rank": 2372
    },
    "T1194": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang dag he ru ka"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1194",
        "rank": 2460
    },
    "T2GS1221": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma mya ngan sel ma/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "tara dispeller of sorrow"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sokavinodanatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1221",
        "rank": 2384
    },
    "T353": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang dag he ru ka"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T39": {
        "label": [
            {
                "@language": "en",
                "@value": "white manjusri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal dkar po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T749": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la dmar po sa lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jambhala pranasadhana rakta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T749",
        "rank": 2509
    },
    "T855": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar tshe khrid jo bo lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T855",
        "rank": 2364
    },
    "T8LS15614": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dbang rig 'dzin/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15614",
        "rank": 2530
    },
    "T1432": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la/"
            },
            {
                "@language": "zh-hant",
                "@value": "毗沙門天"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "jambhala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1432",
        "rank": 2500
    },
    "T484": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar yid bzhin nor mchog"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T484",
        "rank": 2395
    },
    "T8LS15611": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dga' rab rdo rje/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prahevajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15611",
        "rank": 2493
    },
    "T1024": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal mtshan brjod rigs bsdus bla med lugs lha 53"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjusrinamasangiti sanksiptakula guhyaka 53 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1053": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 13 devatmaka maitripada krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 13 mai tri lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1053",
        "rank": 1922
    },
    "T2GS1208": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahasantitara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma zhi ba chen mo/"
            },
            {
                "@language": "en",
                "@value": "tara the great peaceful one"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1208",
        "rank": 2375
    },
    "T003JR5329": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa (rgyal po lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T003JR5329",
        "rank": 2084
    },
    "T619": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "度母"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "tāra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2046": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rje gdong drug gnubs lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2046",
        "rank": 2033
    },
    "T1PD110133": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyan ras gzigs lha bcu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1PD110133",
        "rank": 2231
    },
    "T00AG0336": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru pad+ma rgyal po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0336",
        "rank": 2483
    },
    "T1GS2519": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ku ru ku l+le sngon mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS2519",
        "rank": 2400
    },
    "T858": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma ser mo bkra shis don grub ma tsandra go mi'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T858",
        "rank": 2365
    },
    "T4CZ15540": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsil ba'i tshal chen mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahacitavati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15540",
        "rank": 2435
    },
    "T714": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khyung ser bka' gdams lugs/"
            },
            {
                "@language": "en",
                "@value": "yellow garuda in the bka' gdams tradition"
            },
            {
                "@language": "sa-alalc97",
                "@value": "garuda pita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T714",
        "rank": 2213
    },
    "T1023": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava ekantanayaka ekanvira (tsong kha pa'i nye brgyud)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed gtso rkyang tsong kha pa'i nye rgyud lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1023",
        "rank": 2026
    },
    "T130": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "hevajra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kye rdo rje/"
            },
            {
                "@language": "zh-hant",
                "@value": "喜金剛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T130",
        "rank": 2052
    },
    "T582": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed bsdus pa/"
            },
            {
                "@language": "en",
                "@value": "condensed vajrabhairava"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava sanksipta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T582",
        "rank": 2030
    },
    "T711": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog rdo rje mkha' lding /"
            },
            {
                "@language": "en",
                "@value": "vajra garuda sambara"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajragaruda samvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T711",
        "rank": 1932
    },
    "T1018": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sanmukha manjusri yamari 21 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed gdong pa drug rwa lugs lha 21"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1018",
        "rank": 2028
    },
    "T1260": {
        "label": [
            {
                "@language": "en",
                "@value": "wish-fulfilling-wheel white tara in the tradition of ba ri lo tsa ba"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar yid bzhin 'khor lo ba ri lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1260",
        "rank": 2392
    },
    "T00AG01366": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med 'phags ma'i snying thig (mkhyen brtse'i dbang po/)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15556": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "tailokavijaya vijaya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten gsum las rnam par rgyal ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15556",
        "rank": 2202
    },
    "T645": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma dmar mo sa lugs/"
            },
            {
                "@language": "en",
                "@value": "red tara in the sakya tradition"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rakta tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T645",
        "rank": 2356
    },
    "T650": {
        "label": [
            {
                "@language": "en",
                "@value": "eight-armed tara"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara astabhuja"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma phyag brgyad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T650",
        "rank": 2336
    },
    "T2304": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje gro lod hUM sgrub ('jam mgon)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2304",
        "rank": 2467
    },
    "T01AG03006": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin yang phyungs dmar po (chos kyi blo gros)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T01AG03006",
        "rank": 2105
    },
    "T4CZ15633": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "krodhavajrapatala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje sa 'og"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15633",
        "rank": 2219
    },
    "T2542": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum chen/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2542",
        "rank": 2307
    },
    "T1GS122410": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can dmar mo (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS122410",
        "rank": 2412
    },
    "T2155": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mkha' 'gro lnga rigs bsdus pa lha 49 rdo rje gur nas gsungs pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hevajra-sanksipta-pancadaka 49-devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2155",
        "rank": 2005
    },
    "T00AG0339": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma thod 'phreng rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0339",
        "rank": 2474
    },
    "T2GS1212": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma 'phongs pa sel ba/"
            },
            {
                "@language": "en",
                "@value": "tara summoner of beings"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jagadakarsanatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1212",
        "rank": 2378
    },
    "T1409": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thub pa lha so lnga (kha 'bar ma'i gzungs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1409",
        "rank": 2128
    },
    "T1GS124512": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal nA ga rak+sha (sprul sku mi 'gyur rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS124512",
        "rank": 2296
    },
    "T559": {
        "label": [
            {
                "@language": "en",
                "@value": "six-armed tara after gnyan lo tsa ba"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara (syama sadanga), gnyan lugs"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ljang yan lag drug pa gnyan lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T559",
        "rank": 2397
    },
    "T588": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo kurma pA dI/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kurmapadi-vajravarahi"
            },
            {
                "@language": "en",
                "@value": "tortoise-legged varahi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T588",
        "rank": 1977
    },
    "T8LS15658": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grub ldan ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15658",
        "rank": 2525
    },
    "T1346": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje mkha' 'gro/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajradaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1346",
        "rank": 1999
    },
    "T2GS1160": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sher phyin ma ser mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prajnaparamita pita"
            },
            {
                "@language": "en",
                "@value": "yellow prajnaparamita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T665": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba sngon po phyag gnyis pa pus btsugs ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala (krodharaja avaninihitajanu nila)"
            },
            {
                "@language": "en",
                "@value": "kneeling, blue, wrathful king arya-acala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T665",
        "rank": 2177
    },
    "T702": {
        "label": [
            {
                "@language": "en",
                "@value": "pratisara from the vajrapanjara tantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "so sor 'brang ma rdo rje gur las 'byung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T40": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs smra ba'i seng+ge"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjusri vadisimha"
            },
            {
                "@language": "en",
                "@value": "manjughosa, lion of debaters"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T40",
        "rank": 2279
    },
    "T2GS1218": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma 'jig rten gsum rgyal/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "trailokyavijayatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1218",
        "rank": 2358
    },
    "T1055": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi 5 devatmaka kaM tshang krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo lha 5 kaM tshang lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1055",
        "rank": 1951
    },
    "T1667": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta khyung 'bar ba ('jigs med gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1667",
        "rank": 2487
    },
    "T2175": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma dkar mo lha 9 'chi med 'phags ma'i snying tig"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2175",
        "rank": 2387
    },
    "T335": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong chen snying thig dpal chen 'dus pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T335",
        "rank": 2497
    },
    "T4CZ15531": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal rdo rje lus med/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajrananga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15531",
        "rank": 2288
    },
    "T00JR3170": {
        "label": [
            {
                "@language": "en",
                "@value": "thirteen deity vajrabhairava"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed lha 13"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR3170",
        "rank": 2021
    },
    "T2303": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rdo rje drag rtsal (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2303",
        "rank": 2464
    },
    "T606": {
        "label": [
            {
                "@language": "en",
                "@value": "tara as one of the four bka' gdams deities"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma bka' gdams lha bzhi las byung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T606",
        "rank": 2386
    },
    "T891": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sme ba brtsegs ma/ (mkha' 'gro ma)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T305": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje sems dpa' sgyu 'phrul zhi ba/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T305",
        "rank": 2134
    },
    "T1075": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdan bzhi yab bka' lha 77 (97) rngog lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "catuhpitha 77 (99) devatmaka rngog krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1075",
        "rank": 2116
    },
    "T1060": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog rdo rje mkha' 'gro he ru ka lha 62 bshad rgyud nas bshad pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1060",
        "rank": 1923
    },
    "T2087": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed lha 13 rol pa'i rdo rje'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2087",
        "rank": 2037
    },
    "T558": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar yid bzhin 'khor lo jo bo lugs/"
            },
            {
                "@language": "en",
                "@value": "wish-fulfilling-wheel white tara in the tradition of atisa"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara (cintamani sukla)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T558",
        "rank": 2391
    },
    "T563": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje sems dpa' ser po/"
            },
            {
                "@language": "en",
                "@value": "yellow vajrasattva"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pita vajrasattva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00AG0281": {
        "label": [
            {
                "@language": "en",
                "@value": "red manjusri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal dmar po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0281",
        "rank": 2294
    },
    "T716": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs nag po/"
            },
            {
                "@language": "en",
                "@value": "black manjughosa"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjughosa krsna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T716",
        "rank": 2278
    },
    "T2GS1192": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras ljang gu seng zhon ma dz+nyA na'i lugs/"
            },
            {
                "@language": "en",
                "@value": "lion-mounted, green vaisravana in the tradition of jnana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15537": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje tsa rtsi ka"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajracarcika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15537",
        "rank": 2428
    },
    "T1AT287": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin rdo rje me char/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1AT287",
        "rank": 2088
    },
    "T00JR3557": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa (rong lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2174": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "avalokitesvara cintamani jagaddamara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen yid bzhin nor bu phyag bzhi pa lha mang srong btsan sgam po lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T974": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbyong rgyud nas gsungs pa'i dkyil 'khor 12"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sarvadurgatiparisodhana mandalas"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T974",
        "rank": 2121
    },
    "T2CN4770": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru gzi brjid rdo rje/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CN4770",
        "rank": 2476
    },
    "T1005": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "rasmivimala 6 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'od zer dri med lha 6"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1005",
        "rank": 2182
    },
    "T652": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma tsun+da/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara cunda"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T652",
        "rank": 2363
    },
    "T894": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khrus lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T894",
        "rank": 2451
    },
    "T417": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "guhyasamaja"
            },
            {
                "@language": "zh-hans",
                "@value": "密集金刚"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T417",
        "rank": 1905
    },
    "T836": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med dkar po phyag bzhi pa/ (ras chung lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T736": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzhan gyis mi thub pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "aparajita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T736",
        "rank": 2179
    },
    "T1036": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma dkar mo bram ze phur bu lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sita sarasvati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1036",
        "rank": 2414
    },
    "T78": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jig rten gsum las rnam par rgyal ba lha 1105"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T78",
        "rank": 2266
    },
    "T520": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med (lung lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T520",
        "rank": 2150
    },
    "T1282": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog snyan brgyud yum bka' ras chung lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1282",
        "rank": 1949
    },
    "T2110": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhag pa'i lha nyer gnyis ('brug pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2110",
        "rank": 2156
    },
    "T704": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri khrod ma lo ma gyon ma ser mo/"
            },
            {
                "@language": "en",
                "@value": "yellow parna sabari"
            },
            {
                "@language": "sa-alalc97",
                "@value": "parnasabari pita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T704",
        "rank": 2423
    },
    "T667": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "acala (nila candarosana)"
            },
            {
                "@language": "en",
                "@value": "wrathful fierce blue acala in the kadampa tradition"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba khro bo gtum po jo bo rje'i lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T667",
        "rank": 2173
    },
    "T672": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med ba ri lugs/"
            },
            {
                "@language": "en",
                "@value": "amitayus in the tradition of ba ri lo ts+tsha ba"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T672",
        "rank": 2154
    },
    "T2CN4988": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma bde chen dbang phyug"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CN4988",
        "rank": 2522
    },
    "T1669": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag rdo rje be con (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1669",
        "rank": 2207
    },
    "T658": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gtum chung dmar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani rakta alpacanda"
            },
            {
                "@language": "en",
                "@value": "slightly fierce red vajrapani"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T658",
        "rank": 2320
    },
    "T2CN4663": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag gtum po me 'bar/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CN4663",
        "rank": 2324
    },
    "T908": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyung dmar/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T908",
        "rank": 2206
    },
    "T003JR4606": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa (rog lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T003JR4606",
        "rank": 2079
    },
    "T2GS1157": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyan ras gzigs yi ge phyed dang bzhi pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara ardhacaturthaksara"
            },
            {
                "@language": "en",
                "@value": "three-and-a-half-syllable avalokita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1157",
        "rank": 2227
    },
    "T962": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen 'khor ba dong sprugs/ (gu ru chos dbang lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15354": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zla ba'i lha mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "candradevi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15354",
        "rank": 2441
    },
    "T1065": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara laksabhuta raudra 62 devatmaka abhidhanottara krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog drag po phyag 'bum lha 62 bshad mngon brjod bla ma lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1065",
        "rank": 1935
    },
    "T595": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don yod zhags pa lha lnga ba ri lugs/"
            },
            {
                "@language": "en",
                "@value": "amoghapasa avalokitesvara (ba ri lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T595",
        "rank": 2270
    },
    "T526": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara jinasagara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po rgyal ba rgya mtsho lha dgu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T526",
        "rank": 2233
    },
    "T603": {
        "label": [
            {
                "@language": "en",
                "@value": "eleven-faced arya-avalokita protecting from the eight perils"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po bcu gcig zhal 'jigs pa brgyad skyob/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara ekadasamukha astabhayatrana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T603",
        "rank": 2238
    },
    "T1062": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog 'khor los sgyur drug lha 71"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1062",
        "rank": 1934
    },
    "T1394": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta phag yid bzhin nor bu ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2113": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe rta/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2113",
        "rank": 2129
    },
    "T1313": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1313",
        "rank": 2496
    },
    "T489": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal nA ga rak+Sha/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nagaraksa manjusri"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T489",
        "rank": 2299
    },
    "T671": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "amitayus nirmanakaya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med sprul sku ras chung lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15539": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang sngags kyi rjes su 'brang ba chen mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahamantranusarini"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15539",
        "rank": 2434
    },
    "T628": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog nag po lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2549": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnam 'joms/"
            },
            {
                "@language": "zh-hant",
                "@value": "摧破金剛"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajravidāraṇa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2549",
        "rank": 2189
    },
    "T657": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gtum po khra thogs/"
            },
            {
                "@language": "en",
                "@value": "fierce hawk-bearing vajrapani"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani canda"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T657",
        "rank": 2317
    },
    "T2523": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzungs grwa lnga rdo rje 'phreng ba'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2523",
        "rank": 2166
    },
    "T907": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyung khra nag po ras chung lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2289": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru dar nag ber chen (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2289",
        "rank": 2491
    },
    "T899": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs ngag gi dbang phyug"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T899",
        "rank": 2286
    },
    "T4CZ15630": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ral gcig ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "cuklaikajata"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15630",
        "rank": 2342
    },
    "T2565": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rje gshed phyag rgya zil gnon (rgya zhang khrom)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2565",
        "rank": 2045
    },
    "T2181": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus 'khor lha dgu/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2181",
        "rank": 2067
    },
    "T976": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kun rig"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sarvavid"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T976",
        "rank": 2119
    },
    "T981": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal gsang ldan rigs bsdus lha 57 (52)"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjughosa guhyaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T981",
        "rank": 2301
    },
    "T2167": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog lha 5 ni gu lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2167",
        "rank": 1930
    },
    "T1826": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' spyod dkar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1826",
        "rank": 1987
    },
    "T594": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po 'jig rten dbang phyug (bka' gdams lha bzhi)"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokita lokesvara"
            },
            {
                "@language": "en",
                "@value": "avalokita as one of the four ka-dam deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T594",
        "rank": 2240
    },
    "T2GS1210": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma yongs su smin mdzad ma/"
            },
            {
                "@language": "en",
                "@value": "tara the ripener"
            },
            {
                "@language": "sa-alalc97",
                "@value": "paripacanatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1210",
        "rank": 2376
    },
    "T677": {
        "label": [
            {
                "@language": "en",
                "@value": "white manjughosa in the tradition of the great pandita of kashmir"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sita manjughosa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs dkar po kha che paN chen lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T729": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara amoghankusa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po don yod lcags kyu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T729",
        "rank": 2248
    },
    "T00AG0326": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ku ru kul+le dkar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS124510": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal klu bdud nA ga rak+sha (me ban rin chen gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS124510",
        "rank": 2303
    },
    "T662": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin khyung gshog can jo bo'i lugs/"
            },
            {
                "@language": "en",
                "@value": "garuda-winged hayagriva in the tradition of atisa"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva garudapaksavat"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T662",
        "rank": 2094
    },
    "T1631": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dga' bo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "nanda"
            },
            {
                "@language": "zh-hant",
                "@value": "難陀"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1631",
        "rank": 2215
    },
    "T2093": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2093",
        "rank": 1926
    },
    "T2112": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta phyag khyung gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2112",
        "rank": 2222
    },
    "T1316": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1202": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "paripuranatara"
            },
            {
                "@language": "en",
                "@value": "tara the perfecter"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma yongs rdzogs byed ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1202",
        "rank": 2369
    },
    "T2293": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po kI la ya ( pad+ma dkar po)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2CZ5182": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdag med lha mo nyer gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CZ5182",
        "rank": 1976
    },
    "T2GS996": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdo rje rngog lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS996",
        "rank": 2054
    },
    "T964": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin dbang gi rgyal po (gu ru tshe brtan rgyal mtshan lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T964",
        "rank": 2104
    },
    "T979": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thub pa gtsug tor dgu pa lha 37"
            },
            {
                "@language": "sa-alalc97",
                "@value": "navosnisa sakyamuni"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T979",
        "rank": 2184
    },
    "T2290": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal ba zhi khro tshe dpag med lha bcu gsum (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2290",
        "rank": 2142
    },
    "T1067": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog mkha' 'gro rgya mtsho 'khor lo bcu gnyis lha 1500"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1067",
        "rank": 1941
    },
    "T1072": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang dag he ru ka zla gam dgu pa 'khon so lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1072",
        "rank": 1945
    },
    "T00AG01095": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rje 'bangs nyer lnga /"
            },
            {
                "@language": "en",
                "@value": "twenty-five disciples of padmasambhava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01095",
        "rank": 2495
    },
    "T2040": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa (rgyud lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T597": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "khasarpana avalokitesvara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen ka sarpa Ni lha lnga /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T597",
        "rank": 2244
    },
    "T1662": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyen po lha gsum (rta mgrin)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1662",
        "rank": 2098
    },
    "T513": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal tshe bdag rnon po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T513",
        "rank": 2292
    },
    "T1AT501": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje sder mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1AT501",
        "rank": 1983
    },
    "T00AG0330": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "blo ldan mchog sred/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0330",
        "rank": 2479
    },
    "T1396": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rdo rje gro lod ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1396",
        "rank": 2461
    },
    "T1GS124502": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal shin tu khros pa nA ga rak+sha (myang ral nyi ma 'od zer)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS124502",
        "rank": 2302
    },
    "T399": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la nag po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jambhala (krsna)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T399",
        "rank": 2504
    },
    "T1059": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog sdom pa 'byung ba lha 13"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara mahasamvarodayatantra 13 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1059",
        "rank": 1937
    },
    "T2144": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus 'khor sdom pa chen po lha 73"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kalacakra mahasamvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2144",
        "rank": 2068
    },
    "T2GS1201": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dam tshig gsum bkod lha lnga /"
            },
            {
                "@language": "en",
                "@value": "lord of the array of the triple samaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1201",
        "rank": 2123
    },
    "T1087": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma hA mA ya 'bring po lha 5"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2296": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag nyi ma mdung gang ma g.ya' bzang lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1003": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor 'gro bzang lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani jagadbhadra krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1003",
        "rank": 2318
    },
    "T2MS9698": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phur pa (byang gter lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2MS9698",
        "rank": 2078
    },
    "T983": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja aksobhyavajra 32-devatmaka (rngog lugs)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus mi bskyod rdo rje lha 32 rngog lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T983",
        "rank": 1907
    },
    "T2GS1018": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed lha 9 rol pa'i rdo rje'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1018",
        "rank": 2018
    },
    "T1000": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje rnam 'joms dkar po lha 19 sa ba ri pa'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1000",
        "rank": 2185
    },
    "T823": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor drag po sum sgril (lho brag grub chen lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2164": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog lha 13 zhi byed lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T679": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs a ra pa tsa na dmar ser sa lugs/"
            },
            {
                "@language": "en",
                "@value": "orange arapacana manjughosa in the sakya tradition"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjughosa raktapita arapacana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00AG0328": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal rdo rje/"
            },
            {
                "@language": "en",
                "@value": "manjusrivajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0328",
        "rank": 2289
    },
    "T00AG0333": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru nyi ma 'od zer/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0333",
        "rank": 2481
    },
    "T664": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin kha che paN chen lugs/"
            },
            {
                "@language": "en",
                "@value": "hayagriva in the tradition of the great pandita of kashmir"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T664",
        "rank": 2101
    },
    "T4CZ15561": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' lding /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15561",
        "rank": 2073
    },
    "T1716": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma g.yul bzlog"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1716",
        "rank": 2348
    },
    "T989": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gtum po khyung 5 ras chung lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "canda vajrapani 5 devatmaka (ras chung lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1155": {
        "label": [
            {
                "@language": "en",
                "@value": "hayagriva with an iron sword"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin lcags ral ran/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1155",
        "rank": 2097
    },
    "T1CZ14": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo 'od zer can ma/"
            },
            {
                "@language": "en",
                "@value": "the goddess marici from the dharani"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1CZ14",
        "rank": 2007
    },
    "T2GS1207": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma chags pa 'joms ma/"
            },
            {
                "@language": "en",
                "@value": "tara destroyer of attachment"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sanganasanitara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1207",
        "rank": 2374
    },
    "T2295": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "karma gu ru (byang bdag bkra shis stobs rgyal)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T684": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sarasvati caturbhuja sita"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma phyag bzhi zla ba gzhon nu lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS102896": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus 'khor gtso rkyang /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kalacakra ekavira"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS102896",
        "rank": 2064
    },
    "T1074": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgyu 'phrul chen mo lha 5 rngog lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahamaya 5 devatmaka rngog krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T600": {
        "label": [
            {
                "@language": "en",
                "@value": "secret practice lokesvara in the tradition of mitrayogin"
            },
            {
                "@language": "sa-alalc97",
                "@value": "lokesvara guhyasadhana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten dbang phyug gsang sgrub mi tra dzwa ki'i lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T600",
        "rank": 2230
    },
    "T857": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma btson 'don ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T857",
        "rank": 2338
    },
    "T00AG0332": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shAkya seng+ge"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0332",
        "rank": 2480
    },
    "T708": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khro bo rme brtsegs ljang khu jo bo rje'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "bhurkunkuta syama krodha"
            },
            {
                "@language": "en",
                "@value": "green wrathful ucchusma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T708",
        "rank": 2196
    },
    "T902": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor nyi ma mdung gang ma bram ze lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1378": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dzaM b+ha la dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1378",
        "rank": 2502
    },
    "T710": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog khro bo reg tshig"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samvara krodha bhayanasana"
            },
            {
                "@language": "en",
                "@value": "wrathful sambara burning on touch"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T710",
        "rank": 1943
    },
    "T2048": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal gshed dmar (bka' ma)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2048",
        "rank": 2041
    },
    "T01AG03032": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med 'phags ma sgrol ma ('chi med 'phags ma snying thig)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T01AG03032",
        "rank": 2366
    },
    "T2154": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdor lha dgu stag lung dz+nyA na lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2154",
        "rank": 2056
    },
    "T641": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrakila"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje phur pa ('khon lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T641",
        "rank": 2076
    },
    "T00AG0338": {
        "label": [
            {
                "@language": "en",
                "@value": "white tara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma dkar mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2472": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med mda' 'phel ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2472",
        "rank": 2140
    },
    "T825": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar nye brgyud thun mong ma yin pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T830": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can ma dkar mo sa man+ta shrI lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T830",
        "rank": 2413
    },
    "T3CN15347": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "u pa rA tsa/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15620": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnod mdzes/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sumbha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15620",
        "rank": 2199
    },
    "T2151": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdo rje lha dgu mar lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2151",
        "rank": 2055
    },
    "T311": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "manjusri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T311",
        "rank": 2274
    },
    "T00AG0335": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru mtsho skyes rdo rje/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0335",
        "rank": 2482
    },
    "T580": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje shugs/"
            },
            {
                "@language": "en",
                "@value": "wrathful king krodha raja vajravega"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravega"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T580",
        "rank": 2070
    },
    "T00AG01274": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ye shes mtsho rgyal (dag snang rgya can)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01274",
        "rank": 2494
    },
    "T584": {
        "label": [
            {
                "@language": "en",
                "@value": "vajravarahi according to the dpyal tradition"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo zhal gnyis ma dpyal lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T584",
        "rank": 1963
    },
    "T1GS129608": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po phur mjug can (gnam chos)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS129608",
        "rank": 2471
    },
    "T128": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "jambhala ekantanayaka pita"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la ser po gtso rkyang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T128",
        "rank": 2501
    },
    "T713": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khyung khra man ngag lugs/"
            },
            {
                "@language": "en",
                "@value": "many-colored garuda in the tradition of the instructions from the thirteen golden dharmas of the sakyapas"
            },
            {
                "@language": "sa-alalc97",
                "@value": "garuda upadesakrama sabala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T713",
        "rank": 2212
    },
    "T2GS1209": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma khro gnyer g.yo ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "caladbhrkutitara"
            },
            {
                "@language": "en",
                "@value": "shaking frowning tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1209",
        "rank": 2354
    },
    "T00AG01368": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdor sems snying thig"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15558": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mchog"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "paramasva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15558",
        "rank": 2109
    },
    "T647": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma ser mo skor gsum kha che paN chen lugs/"
            },
            {
                "@language": "en",
                "@value": "tara pita according to the transmission of kha che paN chen"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T647",
        "rank": 2361
    },
    "T2010": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo lha 13 gsum nA ro lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2010",
        "rank": 1974
    },
    "T2365": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po gsang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2365",
        "rank": 2258
    },
    "T525": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po bcu gcig zhal/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara ekadasamuka"
            },
            {
                "@language": "en",
                "@value": "eleven faced avalokita in the tradition of the nun laksmi"
            },
            {
                "@language": "zh-hans",
                "@value": "十一面观世音"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T525",
        "rank": 2235
    },
    "T1026": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "buddhasamayoga vajrasattva sanksiptakula"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas mnyam sbyor rdor sems gtso bor gyur pa rigs bsdus lha 218"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2157": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrasamputa 37-devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje dbyings lha 37 yang dag par sbyor ba'i rgyud nas gsungs pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2157",
        "rank": 2117
    },
    "T2GS1214": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma dkar mo zla mdangs ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "candraujahsuklatara"
            },
            {
                "@language": "en",
                "@value": "tara white as the moon"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1214",
        "rank": 2390
    },
    "T602": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po yid bzhin 'khor lo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara cintamanicakra"
            },
            {
                "@language": "en",
                "@value": "wish-fulfilling wheel (cintamani-cakra) avalokita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T602",
        "rank": 2224
    },
    "T1780": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtsug tor rnam rgyal lha dgu/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "usnisavijaya"
            },
            {
                "@language": "en",
                "@value": "nine-deity usnisavijaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1780",
        "rank": 2163
    },
    "T1393": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad dngos grub snying po ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1085": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar nags kyi rin chen lugs/"
            },
            {
                "@language": "en",
                "@value": "wish-fulfilling-wheel white tara in the tradition of mahapandita vanaratna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1085",
        "rank": 2394
    },
    "T1KG86198": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje ma bdun ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T462": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro (bde chen gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T462",
        "rank": 2457
    },
    "T1057": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 62 devatmaka vajrapani krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 62 phyag rdor stod 'grel lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1057",
        "rank": 1920
    },
    "T1042": {
        "label": [
            {
                "@language": "en",
                "@value": "deities propitiated for the achievement of transcendental wisdom (prajna)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "shes rab kyi lha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1042",
        "rank": 2406
    },
    "T00AG01277": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe lha rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T712": {
        "label": [
            {
                "@language": "en",
                "@value": "many-colored kalacakra-garuda in the tradition of the tantra"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sabala kalacakra garuda"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khyung khra rgyud lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T712",
        "rank": 2211
    },
    "T608": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar kha che paN chen lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara (sita)"
            },
            {
                "@language": "en",
                "@value": "sita tara according to the tradition of kha che paN chen"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T608",
        "rank": 2359
    },
    "T2GS1174": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor 'byung po 'dul byed gsar ma/"
            },
            {
                "@language": "en",
                "@value": "vajrapani spirit-subduer, new tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1174",
        "rank": 2309
    },
    "T651": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma kA pa li/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kapala tara"
            },
            {
                "@language": "en",
                "@value": "venerabla kapali-tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T651",
        "rank": 2337
    },
    "T1012": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs a ra pa tsa na lha 5"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1012",
        "rank": 2285
    },
    "T54": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma gsang 'dus thod 'phreng rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T54",
        "rank": 1913
    },
    "T835": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med sprul sku dang longs sku stag lung lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1178": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri khrod ma lo ma gyon ma nag mo/"
            },
            {
                "@language": "en",
                "@value": "black parna sabari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1178",
        "rank": 2421
    },
    "T1767": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje sems dpa' yab rkyang /"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T970": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khams gsum rnam rgyal lha 1037"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T970",
        "rank": 2268
    },
    "T912": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtsug tor 'bar ba/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T912",
        "rank": 2180
    },
    "T2156": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro lnga rigs bsdus lha 49 rngog lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00AG0325": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ku ru kul+le/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0325",
        "rank": 2398
    },
    "T832": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med grub rgyal lugs (tshar lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T832",
        "rank": 2149
    },
    "T876": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam tshig rdo rje/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T876",
        "rank": 2205
    },
    "T74": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "kālacakra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dus 'khor/"
            },
            {
                "@language": "zh-hant",
                "@value": "時輪"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T74",
        "rank": 2063
    },
    "T2148": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phag mo (bstan gnyis gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2148",
        "rank": 1957
    },
    "T2317": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med tsaNDa li (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2317",
        "rank": 2162
    },
    "T1041": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen gtso 'khor gsum pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T586": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "dvimukhi varahi indra khecari"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "in+dra mkha' spyod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T586",
        "rank": 1990
    },
    "T571": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus lhan skyes/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja sahaja"
            },
            {
                "@language": "en",
                "@value": "innate sahaja guhyasamaja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T571",
        "rank": 1912
    },
    "T700": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khro bo bgegs pa mthar byed gsang 'dus las byung ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "krodha vighnantaka"
            },
            {
                "@language": "en",
                "@value": "wrathful vignantaka (hindrance slayer) from the guhyasamaja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T700",
        "rank": 1914
    },
    "T1007": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdugs dkar po can ma lha 17"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sitatapatra 17 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T744": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma nyer gcig"
            },
            {
                "@language": "en",
                "@value": "21 forms of tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T744",
        "rank": 2333
    },
    "T649": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma lha lnga /"
            },
            {
                "@language": "en",
                "@value": "five-deity tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T654": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani nilambaradhara"
            },
            {
                "@language": "en",
                "@value": "blue-clad vajrapani from the dharani"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gos sngon can gyi gzungs las byung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T896": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srung ba'i lha mo lnga /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pancaraksadevi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T896",
        "rank": 2011
    },
    "T838": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med lha dgu (sa lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T838",
        "rank": 2144
    },
    "T2012": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas thod pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "buddhakapala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2012",
        "rank": 2111
    },
    "T1GS124494": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med 'chi med sku gsum 'dus pa ('phreng po gter ston shes rab 'od zer)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS124494",
        "rank": 2146
    },
    "T1061": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog sbyangs pa'i yon tan lha 13 lwa ba'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1087": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ma hA mA ya/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahamaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T577": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog dkar po tshe sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samvara sita (ayuhsadhana)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T577",
        "rank": 1942
    },
    "T135": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 62 devatmaka krsnacari krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 62 nag po lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T135",
        "rank": 1919
    },
    "T1452": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyad par lha gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1452",
        "rank": 2158
    },
    "T706": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gza' yum rig pa'i rgyal mo/"
            },
            {
                "@language": "en",
                "@value": "queen of knowledge, the mother of the planets"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vidyarajni grahamatrka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T706",
        "rank": 2446
    },
    "T1376": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku gsum zhi khro (klong gsal snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1376",
        "rank": 2458
    },
    "T00JR3352": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgya dkar ral gcig ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1039": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can dmar mo gsang sgrub bo dong lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1039",
        "rank": 2408
    },
    "T653": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor u tsa rya/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ucarya vajrapani"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T653",
        "rank": 2312
    },
    "T4CZ15356": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gang ba bzang po/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "purnabhadra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15356",
        "rank": 2443
    },
    "T1320": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med dwangs ma bcud 'dren (dag snang rgya can)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1320",
        "rank": 2157
    },
    "T2163": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs dam bde gshegs bcu gnyis zhi byed lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1822": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs bla med lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1822",
        "rank": 2277
    },
    "T1866": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma gsang 'dus (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1866",
        "rank": 5958
    },
    "T1064": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog bong zhal can lha 37"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara kharamukha 37 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1064",
        "rank": 1929
    },
    "T1891": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yum chen mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1891",
        "rank": 2403
    },
    "T576": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog dkar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara (sita)"
            },
            {
                "@language": "en",
                "@value": "white sambara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T576",
        "rank": 1928
    },
    "T76": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 5 devatmaka ghantapada krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 5 dril bu zhabs lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T76",
        "rank": 1917
    },
    "T659": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gos sngon can 'gro bzang lugs/"
            },
            {
                "@language": "en",
                "@value": "blue-clad vajrapani in the tradition of sugatigarbha"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani nilambaradhara sugatigarbha krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T659",
        "rank": 2328
    },
    "T1351": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mkha' 'gro sde lnga /"
            },
            {
                "@language": "en",
                "@value": "the five classes of dakinis"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1613": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "rakta yamari"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed dmar po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1613",
        "rank": 2035
    },
    "T4CZ15632": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje snang byed gser/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15632",
        "rank": 2218
    },
    "T154": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava-ekavira"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed gtso rkyang dpa' bo gcig phyag mtshan 32 kyis bskor ba/"
            },
            {
                "@language": "en",
                "@value": "vajrabhairava alone"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T154",
        "rank": 2020
    },
    "T1565": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rje gshed 'char ka nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1565",
        "rank": 2048
    },
    "T1009": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "pancaraksadevi 56 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "srung ba'i lha mo lnga lha 56"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1009",
        "rank": 2008
    },
    "T1111": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin yang gsang khros pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1111",
        "rank": 2091
    },
    "T1038": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can dkar mo bo dong lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T656": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gtum chen bsrung ba'i mkhar ras chung lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani candamaharosana"
            },
            {
                "@language": "en",
                "@value": "exceeding fierce (canda-maharosana) defensive-fort vajrapani in the tradition of rechungpa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1828": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro sde lnga (shangs pa bka' brgyud)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1828",
        "rank": 2014
    },
    "T592": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "suklavarahi prajnalokakrtya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo dkar po shes rab gsal byed/"
            },
            {
                "@language": "en",
                "@value": "wisdom-illuminating white varahi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T592",
        "rank": 1965
    },
    "T2246": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal khro chu sman gdong /"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T596": {
        "label": [
            {
                "@language": "en",
                "@value": "amoghapasa according to the system of kha che paN chen"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "don yod zhags pa lha lnga kha che paN chen lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T596",
        "rank": 2273
    },
    "T012AG03337": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma rnal 'byor ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T012AG03337",
        "rank": 2349
    },
    "T1063": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khor lo bde mchog 'jam pa'i rdo rje/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "manjusrivajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1063",
        "rank": 1925
    },
    "T432": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dpal zhi khro/"
            },
            {
                "@language": "en",
                "@value": "many+dzu shrI peaceful and wrathful"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1054": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lha 5 kaM tshang lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara 5 devatmaka kaM tshang krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1054",
        "rank": 1938
    },
    "T579": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus 'khor lhan skyes/"
            },
            {
                "@language": "en",
                "@value": "innate (sahaja) kalacakra"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sahaja kalacakra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T579",
        "rank": 2066
    },
    "T751": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la ljang gu dus 'khor las byung ba/"
            },
            {
                "@language": "en",
                "@value": "green jambhala according to the kalacakra tradition"
            },
            {
                "@language": "sa-alalc97",
                "@value": "syamajambhala (kalacakrakrama)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T751",
        "rank": 2512
    },
    "T003JR4624": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro phur gsum (kaH thog)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T003JR4624",
        "rank": 2456
    },
    "T2593": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen 'khor ba dbyings grol/ (legs ldan rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T452": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje sems dpa'"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrasattva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T452",
        "rank": 2131
    },
    "T1118": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po mthing nag gnam lcags thog gi ral gri (chos rgyal rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1161": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sher phyin ma dkar mo/"
            },
            {
                "@language": "zh-hans",
                "@value": "白般若佛母"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prajnaparamita (sita)"
            },
            {
                "@language": "en",
                "@value": "white prajnaparamita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1161",
        "rank": 2405
    },
    "T1069": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phag mo lha 17 'jigs med grags pa'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T41": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo zhal gnyis ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T41",
        "rank": 1958
    },
    "T2115": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo bden gnyis zhal can/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2115",
        "rank": 1962
    },
    "T599": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara cittavisramana"
            },
            {
                "@language": "en",
                "@value": "mind at rest khasarpana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po sems nyid ngal gso/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T599",
        "rank": 2260
    },
    "T757": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor rgyun ma dza ma ri'i lugs/"
            },
            {
                "@language": "en",
                "@value": "vasundhara in the tradition of jamari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T757",
        "rank": 2515
    },
    "T003JR402": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje rnam par 'joms pa khro dkar/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T003JR402",
        "rank": 2187
    },
    "T1632": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu rgyal mi mgon dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1632",
        "rank": 2217
    },
    "T583": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "金刚瑜伽母"
            },
            {
                "@language": "en",
                "@value": "dakini according to the tradition of naropa"
            },
            {
                "@language": "sa-alalc97",
                "@value": "nadakhecari"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nA ro mkha' spyod ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T583",
        "rank": 1985
    },
    "T1715": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phag mo lha 13 ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1715",
        "rank": 1948
    },
    "T2146": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdor sku rdo rje zhal gcig phyag gnyis pa nag po lugs lha dgu/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2146",
        "rank": 2057
    },
    "T1700": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mi 'khrugs pa lha 13"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1700",
        "rank": 2170
    },
    "T660": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor 'byung po 'dul byed/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani bhutadamara"
            },
            {
                "@language": "en",
                "@value": "vajrapani spirit-subduer"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15557": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vajrajvlanalarka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje me dang nyi ma 'bar ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15557",
        "rank": 2201
    },
    "T1CZ13": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgon sbrag sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1CZ13",
        "rank": 2107
    },
    "T646": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma nor sbyin kha che paN chen lugs/"
            },
            {
                "@language": "sa-x-ewts",
                "@value": "tara d+hanada/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15634": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje dbyug pa sngon po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15634",
        "rank": 2220
    },
    "T1CZ6": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje 'od ldan dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T927": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "manjusri krsna yamari 13 devatmaka (rwa lugs)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed dgra nag lha 13 rwa lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T927",
        "rank": 2031
    },
    "T1GS122411": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal zhi ba (gter bdag gling pa 'gyur med rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2101": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gu ru mtshan brgyad/"
            },
            {
                "@language": "en",
                "@value": "eight manifestations of padmasambhava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1638": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo bu rdzi ma (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T598": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara cintamani"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen yid bzhin nor bu lha lnga /"
            },
            {
                "@language": "en",
                "@value": "five-deity wish-granting gem according to the tradition of atisa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T598",
        "rank": 2247
    },
    "T2354": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khro bcu/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2354",
        "rank": 2049
    },
    "T589": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo bram ze dpal 'dzin lugs/"
            },
            {
                "@language": "en",
                "@value": "vajravarahi according to the tradition of the brahmin sridhara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T589",
        "rank": 1979
    },
    "T756": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor rgyun ma rtsa ba/"
            },
            {
                "@language": "en",
                "@value": "the goddess vasudhara from the dharani"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vasudhārā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T756",
        "rank": 2520
    },
    "T1GS135818": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phag mo gsang sgrub (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS135818",
        "rank": 1955
    },
    "T703": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtsug tor gdugs dkar/"
            },
            {
                "@language": "en",
                "@value": "usnisa white umbrella lady"
            },
            {
                "@language": "sa-alalc97",
                "@value": "usnisasitatapatra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T703",
        "rank": 2429
    },
    "T666": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba dkar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala (sita)"
            },
            {
                "@language": "en",
                "@value": "white acala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T666",
        "rank": 2171
    },
    "T2GS1215": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma gser mdog can/"
            },
            {
                "@language": "en",
                "@value": "golden-colored tara"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kanakavarnatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1215",
        "rank": 2380
    },
    "T1664": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyen po lha lnga (rta mgrin)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T01JR252": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa jo mo lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T01JR252",
        "rank": 2075
    },
    "T1056": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnal 'byor ma lha 37 rwa lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrayogini 37 devatmaka rwa krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1056",
        "rank": 1952
    },
    "T956": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog dkar po kha che paN chen lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T692": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ku ru kulle gsang sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kurukulla guhyasadhana"
            },
            {
                "@language": "en",
                "@value": "secret sadhana of kurukulla"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2019": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med lha lnga 'jam lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2019",
        "rank": 2143
    },
    "T1CZ16": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyung nag"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1CZ16",
        "rank": 2208
    },
    "T997": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen zhal bcu gcig lha 37"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara mahakarunika 37 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T997",
        "rank": 2251
    },
    "T43": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo dbu bcad ma/"
            },
            {
                "@language": "en",
                "@value": "vajrayogini of the severed head"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi chinnamunda"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T43",
        "rank": 1959
    },
    "T1076": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "catuhpitha yum bka' 13 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gdan bzhi yum bka' ye shes dbang phyug ma lha 13 rngog lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1076",
        "rank": 2115
    },
    "T2044": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "hayagrīva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1231": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo tsaN+Di ka"
            },
            {
                "@language": "en",
                "@value": "the goddess candika, attendant of four armed gnosis mahakala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1231",
        "rank": 1981
    },
    "T560": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ku ru kul+le dmar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rakta kurukulla"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T560",
        "rank": 2399
    },
    "T2GS1158": {
        "label": [
            {
                "@language": "en",
                "@value": "four-armed white sarasvati"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma dkar mo phyag bzhi pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1158",
        "rank": 2415
    },
    "T2269": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kha 'bar ma dkar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2269",
        "rank": 2160
    },
    "T1182": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rje'i gshed khro chu dug gdong nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1182",
        "rank": 2034
    },
    "T2150": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje bdag med ma lha mo 15"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2150",
        "rank": 2062
    },
    "T759": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor rgyun ma ba lang rdzi/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vasudhara gopala"
            },
            {
                "@language": "en",
                "@value": "vasudhara the cowherd"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T759",
        "rank": 2517
    },
    "T00AG0334": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru seng+ge sgra sgrog"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0334",
        "rank": 2484
    },
    "T4CZ15534": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vajrasrinkhala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje lcags sgrog ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15534",
        "rank": 1994
    },
    "T698": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnam 'joms ljang sngon/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravidarana syamanila"
            },
            {
                "@language": "en",
                "@value": "blue-green vajravidarana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T698",
        "rank": 2191
    },
    "T821": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokita amoghapasa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs chen don yod zhags pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T821",
        "rank": 2269
    },
    "T1GS129603": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje gro lod hUM sgrub ('bri gung rin chen phun tshogs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS129603",
        "rank": 2466
    },
    "T4CZ15611": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma dmar mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "raktatara jagadvasamkara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15611",
        "rank": 2341
    },
    "T2520": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma (stag phu dag snang)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2520",
        "rank": 2343
    },
    "T2133": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin rdo rje me 'bar (yongs dge)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2133",
        "rank": 2089
    },
    "T1CZ15": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum po khyung lnga (dge ldan lugs )"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1CZ15",
        "rank": 2322
    },
    "T2137": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje sgrol ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2137",
        "rank": 2352
    },
    "T1PD114149": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtsug tor dri med/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1PD114149",
        "rank": 2181
    },
    "T1324": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma yid bzhin nor bu (rnam gnyis chu bo gcig 'dus)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1324",
        "rank": 2498
    },
    "T4CZ15554": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "cinakramaryatara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgya nag rim pa'i sgrol ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15554",
        "rank": 2339
    },
    "T643": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "o rgyan sgrol ma gnas kyi dbang phyug ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara pithisvari uddiyana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T643",
        "rank": 2355
    },
    "T1AT454": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta phag"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1AT454",
        "rank": 2099
    },
    "T2GS1128": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nam mkha'i gos can (lha mo)"
            },
            {
                "@language": "en",
                "@value": "the sky-clad goddess"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1128",
        "rank": 2425
    },
    "T2153": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdor lha dgu jo nang zhi sbas lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T01AG03331": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sme ba brtsegs pa (bar chad kun sel)"
            },
            {
                "@language": "en",
                "@value": "bhurkumkuta from the revelations of the bar chad kun sel of mchog gyur gling pa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T01AG03331",
        "rank": 2194
    },
    "T2197": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro bdud 'dul drag mo (mati rat+na )"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2197",
        "rank": 2001
    },
    "T668": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba phyag bzhi pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala caturbhuja"
            },
            {
                "@language": "en",
                "@value": "four-armed acala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T668",
        "rank": 2174
    },
    "T697": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang ba ye shes/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyajnana dakini"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T697",
        "rank": 1998
    },
    "T709": {
        "label": [
            {
                "@language": "en",
                "@value": "wrathful female ucchusma"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mkha' 'gro rme brtsegs khro mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T709",
        "rank": 2012
    },
    "T00AG01276": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po 'jigs rten dbang phyug (dag snang rgya can lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01276",
        "rank": 2263
    },
    "T1734": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "yamāntaka"
            },
            {
                "@language": "zh-hant",
                "@value": "大威德金剛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1734",
        "rank": 2019
    },
    "T1568": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phur pa spu gri reg gcod (gar dbang rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1568",
        "rank": 2080
    },
    "T4CZ15355": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vicalanetri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyan yangs ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15355",
        "rank": 2442
    },
    "T2GS1222": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma mchog stsol ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "varadatara"
            },
            {
                "@language": "en",
                "@value": "tara granter of boons"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1222",
        "rank": 2385
    },
    "T1CZ47": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha bzhi dril sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1CZ47",
        "rank": 2253
    },
    "T2GS1173": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma nor sbyin ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara dhanada"
            },
            {
                "@language": "en",
                "@value": "wealth-granting tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1173",
        "rank": 2357
    },
    "T8LS14946": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal ba rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS14946",
        "rank": 1904
    },
    "T53": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma gsang 'dus thun mong /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T53",
        "rank": 2499
    },
    "T00JR2666": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru zhi ba/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00JR996": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po phyag stong spyan stong /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR996",
        "rank": 2241
    },
    "T1987": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "執金剛"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajradhāra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje 'chang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1987",
        "rank": 1903
    },
    "T527": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum po drag po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T527",
        "rank": 2327
    },
    "T00AG01321": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la nag po (chos dbang lugs)"
            },
            {
                "@language": "en",
                "@value": "jambhala krsna in the tradition of guru chos dbang"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01321",
        "rank": 2513
    },
    "T50": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin gsang sgrub skyer sgang lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva guhyasahana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T50",
        "rank": 2092
    },
    "T00AG0340": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mtsho skye rdo rje/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG0340",
        "rank": 2475
    },
    "T875": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "so sor 'brang ma dkar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T875",
        "rank": 1982
    },
    "T1749": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po bde gshegs kun 'dus/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2152": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje bdag med ma lha mo 15 mar lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2152",
        "rank": 1996
    },
    "T2143": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "kalacakra kaya-vak-citta-parinispanna"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dus 'khor sku gsung thugs yongs rdzogs lha 634"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2143",
        "rank": 2065
    },
    "T630": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdag med lha mo bco lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T630",
        "rank": 1997
    },
    "T464": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro (mchog gling)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T464",
        "rank": 2455
    },
    "T00AG01279": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar (dag snang rgya can lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01279",
        "rank": 2389
    },
    "T2396": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal tshe bdag me'i spu gri/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2396",
        "rank": 2293
    },
    "T920": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dzambha la yab yum dgu/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1176": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri khrod ma lo ma gyon ma dmar mo/"
            },
            {
                "@language": "en",
                "@value": "red parna sabari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1176",
        "rank": 2419
    },
    "T1827": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' spyod dmar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1827",
        "rank": 1986
    },
    "T4CZ15544": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum po cher khros pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15544",
        "rank": 2198
    },
    "T1677": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro gsang ba kun 'dus (jo mo sman mo)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1677",
        "rank": 2002
    },
    "T332": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgyu 'phrul khro bo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T332",
        "rank": 2204
    },
    "T740": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal mtshan rtse mo'i dpung rgyan/"
            },
            {
                "@language": "en",
                "@value": "banner tip armlet"
            },
            {
                "@language": "sa-alalc97",
                "@value": "dhvajagrakeyura"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T740",
        "rank": 2447
    },
    "T4CZ15541": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "mahapratyangira"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyir zlog ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15541",
        "rank": 2436
    },
    "T00AG0327": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam pa'i rdo rje/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjusrivajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T636": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs gtso 'khor lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T636",
        "rank": 2276
    },
    "T834": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med sprul sku sa skya gser chos lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T834",
        "rank": 2155
    },
    "T878": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "za byed rdo rje mkha' 'gro/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T878",
        "rank": 2015
    },
    "T2GS1044": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin khams gsum yongs sgrol/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1044",
        "rank": 2090
    },
    "T131": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje gur rigs bsdus/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapanjara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T131",
        "rank": 2061
    },
    "T3JT12423": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgyu 'phrul drwa ba/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahayoga tantra"
            },
            {
                "@language": "en",
                "@value": "web of illusion"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T984": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja manjuvajra jnanapada krama 19 devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus 'jam pa'i rdo rje ye shes zhabs lugs lha 19"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T984",
        "rank": 1908
    },
    "T00AG01278": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po sems nyid ngal bso (dag snang rgya can lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01278",
        "rank": 2225
    },
    "T573": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog lhan skyes/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara sahaja"
            },
            {
                "@language": "en",
                "@value": "innate (sahaj) sambara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T573",
        "rank": 1927
    },
    "T965": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyir bzlog 'khor lo 'bar ba/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T965",
        "rank": 2221
    },
    "T746": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la lha mang dpa' bo tshig sgrub las byung ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jambhala bahudeva ekavirasadhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T746",
        "rank": 2510
    },
    "T731": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor lhan cig skyes pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sahaja guhyapati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T731",
        "rank": 2329
    },
    "T4CZ15547": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vajrahumkara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje hUM mdzad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15547",
        "rank": 2200
    },
    "T1040": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can dmar mo gsang sgrub kha che biksa pa ra ma'i lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T012AG03390": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "mahakarunika from the visions of thang stong rgyal po"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po (thang stong lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T012AG03390",
        "rank": 2261
    },
    "T1950": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yi dam zhi ba/"
            },
            {
                "@language": "en",
                "@value": "peaceful deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1950",
        "rank": 1900
    },
    "T00JR647": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dbyangs rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR647",
        "rank": 2275
    },
    "T833": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med grub rgyal lugs (sa lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T833",
        "rank": 2138
    },
    "T2145": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdo rje lha dgu mtsho skyes lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1454": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdo rje rigs bsdus/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1454",
        "rank": 2060
    },
    "T1GS147808": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "孔雀明王"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahāmāyūrī"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rig pa rma bya chen mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS147808",
        "rank": 2432
    },
    "T2149": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdo rje lha dgu DombhI lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T572": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ma hA mA ya gtso rkyang /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahamaya ekantanayaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1956": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde chen rgyal mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1956",
        "rank": 1966
    },
    "T1152": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T730": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po don yod mchod pa nor bu/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara amoghapujamani"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T730",
        "rank": 2249
    },
    "T655": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani nilambaradhara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor gos sngon can sa lugs/"
            },
            {
                "@language": "en",
                "@value": "vajrapani in the sakya tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T655",
        "rank": 2316
    },
    "T4CZ15546": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "kṛṣṇayamāri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed nag po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15546",
        "rank": 2046
    },
    "T2071": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nags sman ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1030": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub nye brgyud thang rgyal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1030",
        "rank": 2147
    },
    "T00JR73": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po gsang ba 'dus pa (chags med lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR73",
        "rank": 2250
    },
    "T47": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor dregs 'dul/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T47",
        "rank": 2323
    },
    "T1785": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje gro lod/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1785",
        "rank": 2485
    },
    "T2165": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khros ma rigs lnga zhi byed lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00UNK195": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "(Avalokitesvara) Lokanatha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'phags mchog 'jig rten mgon po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00UNK195",
        "rank": 2257
    },
    "T00AG0329": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mchog rol pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS129607": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang gsang karma drag po (dag snang rgya can)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS129607",
        "rank": 2470
    },
    "T986": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor 'khor chen lha 18"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani mahacakra 18 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2334": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po raksha thod phreng (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2334",
        "rank": 2463
    },
    "T750": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la dmar po grwa pa mngon shes gter ma'i lugs/"
            },
            {
                "@language": "en",
                "@value": "jambhala rakta in the treasure tradition of grwa pa mngon shes"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T750",
        "rank": 2507
    },
    "T1045": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po gur thang rgyal nye brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2306": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal pha rol rgol 'joms (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T575": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog dang 'brel ba'i go cha'i dpa' mo drug"
            },
            {
                "@language": "en",
                "@value": "kavaca yogini connected with the cakrasamvara tantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T575",
        "rank": 1947
    },
    "T2GS1220": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma mchog ster ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "paramabhisekadatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1220",
        "rank": 2383
    },
    "T38": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma zhi ba nyang lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T38",
        "rank": 2523
    },
    "T748": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la dkar po lha lnga jo bo rje'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jambhala pancadeva sita"
            },
            {
                "@language": "en",
                "@value": "five-deity white jambhala in the tradition of atisa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T748",
        "rank": 2505
    },
    "T733": {
        "label": [
            {
                "@language": "en",
                "@value": "the goddess pandaravasini"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pandaravasini devi"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gos dkar mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T733",
        "rank": 2424
    },
    "T591": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi kalikruddha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo khros ma nag mo lha dgu/"
            },
            {
                "@language": "en",
                "@value": "nine deity wrathful black varahi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T591",
        "rank": 1971
    },
    "T1365": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen (tshem bu lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1365",
        "rank": 2239
    },
    "T1037": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyangs can dkar mo dge lugs lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T991": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed lha 13 dpal 'dzin lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rakta yamari 13 devatmaka mahasiddha sridhara krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1234": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag mgon po seng+ge'i gdan can/"
            },
            {
                "@language": "en",
                "@value": "lion seated ganapati mahakala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T429": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed lha 13 rwa tshar gnyis kyi lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T429",
        "rank": 2022
    },
    "T434": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "hevajramandala navadevatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kye rdo rje man ngag lugs lha dgu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T434",
        "rank": 2053
    },
    "T397": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "garuda (sabala garuda)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khyung khra/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T397",
        "rank": 2210
    },
    "T511": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dzaM b+ha la dmar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T511",
        "rank": 2503
    },
    "T739": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'od zer can ma rta ljang can/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "marici syamasva"
            },
            {
                "@language": "en",
                "@value": "marici with green (bay) horses"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T739",
        "rank": 2417
    },
    "T687": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "marici"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'od zer can ma khab skud can/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T687",
        "rank": 2416
    },
    "T01AG03283": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum po thugs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1766": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje sems dpa' yab yum (bla med lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1766",
        "rank": 2135
    },
    "T565": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dam tshig gsum bkod lha lnga /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "trisamayavyuha sakyamuni"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T565",
        "rank": 2124
    },
    "T00AG01405": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar (jo nang lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01405",
        "rank": 2396
    },
    "T590": {
        "label": [
            {
                "@language": "en",
                "@value": "sahaja reversed"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "go bzlog lhan cig skyes ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T590",
        "rank": 1980
    },
    "T642": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po yi ge drug pa/"
            },
            {
                "@language": "en",
                "@value": "six-syllable avalokita in the tradition of atisa"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara sadaksara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T642",
        "rank": 2234
    },
    "T449": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pad+ma 'byung gnas/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "padmasambhava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T449",
        "rank": 2478
    },
    "T1GS147787": {
        "label": [
            {
                "@language": "sa-x-rma",
                "@value": "pita vajrabhairava"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed ser po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS147787",
        "rank": 2036
    },
    "T990": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed dmar lha 5 bir lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rakta yamari 5 devatmaka mahasiddha virupa krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T990",
        "rank": 2042
    },
    "T012AG03338": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal chos dbyings gsung dbang /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T012AG03338",
        "rank": 2291
    },
    "T104": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T104",
        "rank": 2016
    },
    "T1811": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum po gsang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1811",
        "rank": 2330
    },
    "T581": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gshin rje gshed dmar lhan skyes/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "yamari sahaja (rakta)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T581",
        "rank": 2040
    },
    "T1013": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dri med rnam gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1013",
        "rank": 2126
    },
    "T585": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mai tri mkha' spyod/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "maitri khecari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T585",
        "rank": 1989
    },
    "T2248": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor 'chi bdag 'joms pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2248",
        "rank": 2308
    },
    "T738": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba dmar po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala rakta"
            },
            {
                "@language": "en",
                "@value": "red acala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T738",
        "rank": 2178
    },
    "T00AG01365": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zab bdun rtsa gsum tshe dpag med dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01365",
        "rank": 2153
    },
    "T648": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rmi lam ston pa'i sgrol ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "tara svapnadesaka"
            },
            {
                "@language": "en",
                "@value": "dream-showing tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T648",
        "rank": 2362
    },
    "T4CZ15559": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje lu gu rgyu/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajrashrikhale"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15559",
        "rank": 2438
    },
    "T1052": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog nang dkyil dril bu zhabs lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2020": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen phyag bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2020",
        "rank": 2245
    },
    "T4CZ15636": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgan byad ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15636",
        "rank": 2439
    },
    "T1671": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin gsang ba 'dus pa ( rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1671",
        "rank": 2087
    },
    "T1PD181163": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dug phur/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1PD181163",
        "rank": 2081
    },
    "T996": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don yod zhags pa lha 16"
            },
            {
                "@language": "sa-alalc97",
                "@value": "amoghapasa 16 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T996",
        "rank": 2272
    },
    "T2GS1211": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma bkra shis snang ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manggalavabhasatara"
            },
            {
                "@language": "en",
                "@value": "tara light of prosperity"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1211",
        "rank": 2377
    },
    "T674": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med dkar po mi tra dzo ki'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "amitayus (sita)"
            },
            {
                "@language": "en",
                "@value": "white amitayus in the tradition of mitrayogin"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T674",
        "rank": 2141
    },
    "T1817": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma 'jigs pa kun sel (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1817",
        "rank": 2368
    },
    "T2264": {
        "label": [
            {
                "@language": "en",
                "@value": "khadiravani tara with two attendants"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "seng ldeng nags kyi sgrol ma gtso 'khor gsum/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "khadiravani tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2264",
        "rank": 2346
    },
    "T01JR186": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T01JR186",
        "rank": 2367
    },
    "T993": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med lha 9 dze ta ri'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "amitayus 9 devatmaka jetari krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T993",
        "rank": 2145
    },
    "T149": {
        "label": {
            "@language": "en",
            "@value": "'jam dbyangs dmar ser gtso rkyang"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T149",
        "rank": 2284
    },
    "T1345": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bde 'jigs gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1345",
        "rank": 2027
    },
    "T758": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor rgyun ma yid 'phrog"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vasudhara manohara"
            },
            {
                "@language": "en",
                "@value": "captivating manohara vasudhara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T758",
        "rank": 2516
    },
    "T003JR403": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje rnam par 'joms pa khro nag lha bco lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T003JR403",
        "rank": 2188
    },
    "T2GS1217": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma hUM sgra sgrog ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "humsabdinitara"
            },
            {
                "@language": "en",
                "@value": "tara proclaiming the sound of hum"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1217",
        "rank": 2381
    },
    "T1058": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara bhramaharasadhana 62 devatmaka abhayakaragupta krama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog 'khrul spong lha 62 'jigs med 'byung gnas lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1058",
        "rank": 1933
    },
    "T690": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas spyan ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "buddhalocana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T690",
        "rank": 2426
    },
    "T4CZ15530": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten dbang phyug dmar po/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "raktalokecvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15530",
        "rank": 2256
    },
    "T694": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor rgyun ma dmar mo/"
            },
            {
                "@language": "en",
                "@value": "red vasudhara"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vasudhara rakta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T694",
        "rank": 2519
    },
    "T605": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara simhanada"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po seng+ge sgra/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T605",
        "rank": 2236
    },
    "T4CZ326967": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rigs can dra ma lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T852": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khros ma nag mo gsang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T852",
        "rank": 1969
    },
    "T2GS1179": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri khrod ma lo ma gyon ma ljang gu/"
            },
            {
                "@language": "en",
                "@value": "green parna sabari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1179",
        "rank": 2422
    },
    "T1768": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ye shes mkha' 'gro/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "jnanadakini"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1768",
        "rank": 2004
    },
    "T955": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe lha rnam gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T955",
        "rank": 2159
    },
    "T1GS124517": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum po nA ga rak+sha (skyid grong chos mdzad)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS1088": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten dbang phyug yi ge bdun pa mi tra'i lugs/"
            },
            {
                "@language": "en",
                "@value": "seven syllable lokesvara in the tradition of mitrayogin"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1088",
        "rank": 2226
    },
    "T999": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mi 'khrugs pa lha 9 jo bo lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T999",
        "rank": 2169
    },
    "T2GS1165": {
        "label": [
            {
                "@language": "en",
                "@value": "green tara in the tradition of the great pandita of kashmir"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ljang (kha che paN chen lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1165",
        "rank": 2353
    },
    "T1816": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sme ba brtsegs pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1816",
        "rank": 2193
    },
    "T45": {
        "label": [
            {
                "@language": "en",
                "@value": "storied mansion (kutagara) vajrapani"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rdor khang bu brtsegs pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrapani kutagara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2GS903": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phag mo lha bco lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS903",
        "rank": 1950
    },
    "T519": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rtsal (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15536": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "cunda"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skul byed ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15536",
        "rank": 2431
    },
    "T4CZ15521": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "krodharajojjvalavajrashani"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje gnams lcags 'bar ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15521",
        "rank": 2203
    },
    "T1084": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas thod pa lha 9"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1084",
        "rank": 2112
    },
    "T587": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo gnam zhabs ma/"
            },
            {
                "@language": "en",
                "@value": "vajravarahi with raised leg"
            },
            {
                "@language": "sa-alalc97",
                "@value": "urdhvapada vajra varahi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T587",
        "rank": 1975
    },
    "T4CZ15215": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "tarasragdhara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma phreng 'dzin ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1PD181202": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nor bdag dmar po skor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1704": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nA ro gsang spyod/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1704",
        "rank": 1988
    },
    "T701": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sna tshogs yum dkar mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "visvamatr sita"
            },
            {
                "@language": "en",
                "@value": "white visvamatr"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T701",
        "rank": 2069
    },
    "T745": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dzaM b+ha la ser po gsang 'dus las byung ba/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T745",
        "rank": 2506
    },
    "T693": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sri garbhasuvarnasutra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mo snying gi gser thig can/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T693",
        "rank": 1993
    },
    "T1GS129602": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po pad+ma gtum po (mi 'gyur las 'phro gling pa; mkhyen brtse'i dbang po'i yang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS129602",
        "rank": 2465
    },
    "T851": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo skor gsum dpyal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T851",
        "rank": 1964
    },
    "T389": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "辯才天"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sarasvatī"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T389",
        "rank": 2407
    },
    "T998": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi 'khrugs pa lha 9"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajraksobhya 9 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T998",
        "rank": 2168
    },
    "T440": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava sahaja"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed lhan skyes/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T518": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag dmar ('o bran lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T518",
        "rank": 2486
    },
    "T1681": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po ngan song kun skyobs (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1681",
        "rank": 2264
    },
    "T765": {
        "label": [
            {
                "@language": "en",
                "@value": "naga king apalala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "klu rgyal sog ma med jo bo rje'i lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "apalala nagaraja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T765",
        "rank": 2216
    },
    "T707": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khro bo'i rgyal po rme brtsegs dud kha ba ri lo tsA ba'i lugs/"
            },
            {
                "@language": "en",
                "@value": "smoke colored wrathful king ucchusma"
            },
            {
                "@language": "sa-alalc97",
                "@value": "dhumavarna-krodha-bhurkunkuta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T707",
        "rank": 2195
    },
    "T2GS1219": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma rgol ba 'joms ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vadipramardanitara"
            },
            {
                "@language": "en",
                "@value": "tara crushing disputants"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1219",
        "rank": 2382
    },
    "T699": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rnam 'joms dkar po/"
            },
            {
                "@language": "en",
                "@value": "white vajravidarana"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravidarana sita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T699",
        "rank": 2190
    },
    "T2GS1204": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma sdug bsngal bsreg ma/"
            },
            {
                "@language": "en",
                "@value": "tara consumer of suffering"
            },
            {
                "@language": "sa-alalc97",
                "@value": "duhkhadahanatara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1204",
        "rank": 2371
    },
    "T1391": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen ngan song rang grol ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1391",
        "rank": 2237
    },
    "T8LS15660": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yongs 'khyud bde ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15660",
        "rank": 2524
    },
    "T1653": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nad bdag stobs 'joms/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1653",
        "rank": 2106
    },
    "T1377": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas mnyam sbyor/"
            },
            {
                "@language": "sa-x-rma",
                "@value": "sarvabuddhasamayoga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1377",
        "rank": 2452
    },
    "T2GS1156": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can ma dmar mo/"
            },
            {
                "@language": "en",
                "@value": "red sarasvati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1156",
        "rank": 2411
    },
    "T1049": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pu tra ming sring ngor lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T696": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thod pa rgyan/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kapalini"
            },
            {
                "@language": "en",
                "@value": "venerable lady with skull ornaments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T696",
        "rank": 2450
    },
    "T1338": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yi dam/"
            },
            {
                "@language": "en",
                "@value": "tutelary deities"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "iṣṭadevatā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1338",
        "rank": 1899
    },
    "T4CZ15357": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bcom ldan 'das ral pa gcig pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dpal bcom ldan 'das ral pa gcig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15357",
        "rank": 2444
    },
    "T854": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol dkar kaM tshang lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T8LS15613": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rgya'i rig 'dzin/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15613",
        "rank": 2529
    },
    "T4CZ15551": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "nilakankharyo avaloketesavara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyan ras gzigs dbang phyug mgrin pa sngon po can/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15551",
        "rank": 2229
    },
    "T2GS1172": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyan ras gzigs rgyal ba rgya mtsho/"
            },
            {
                "@language": "en",
                "@value": "jinasagara avalokita known as the four sugatas combined"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1172",
        "rank": 2232
    },
    "T52": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin nag po lcags ral can/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva lohakhadga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T52",
        "rank": 2093
    },
    "T314": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po sdug bsngal rang grol/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T314",
        "rank": 2259
    },
    "T2198": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta phag (chos rgyal ngag gi dbang po'i dgongs gter)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T722": {
        "label": [
            {
                "@language": "en",
                "@value": "samantabhadra (buddha according to purnavajra transmission)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kun tu bzang po gang ba'i rdo rje lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T00AG0323": {
        "label": [
            {
                "@language": "en",
                "@value": "red manjusri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs dmar po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15538": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "mahasahasrapramardani"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "stong chen mo rab tu 'joms pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15538",
        "rank": 2433
    },
    "T193": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun rig rtsa ba lha so bdun/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T193",
        "rank": 2120
    },
    "T618": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba/"
            },
            {
                "@language": "zh-hant",
                "@value": "不動明王"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "ācala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2180": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog dril bu lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T980": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "dharmadhatuvagisvara manjusri"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos dbyings gsung gi dbang phyug lha 219"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T980",
        "rank": 2072
    },
    "T2049": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal zhi ba (rgyud lugs bka' ma)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2166": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud sde lnga rigs bsdus shangs lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2166",
        "rank": 2050
    },
    "T003JR4506": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bdud rtsi lha/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "anuttara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS2518": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed klu brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS2518",
        "rank": 2032
    },
    "T4CZ15529": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten dbang phyug"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "trailokyavashamkaralokeshvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15529",
        "rank": 2252
    },
    "T8LS15612": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhun grub rig 'dzin/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15612",
        "rank": 2528
    },
    "T1025": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sarvabuddhasamayoga 135(127) devatmaka"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas mnyam sbyor rigs 6 bsdus pa lha 135 (127)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1430": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang 'dus 'phags lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1430",
        "rank": 1911
    },
    "T2GS1171": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyan ras gzigs rdo rje snying po rab tu 'joms pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokita vajrasarapramardin"
            },
            {
                "@language": "en",
                "@value": "avalokita destroying with the vajra essence"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1171",
        "rank": 2228
    },
    "T4CZ15543": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "prasannataraya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma rab tu dang ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15543",
        "rank": 2340
    },
    "T728": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thugs rje chen po su kha wa ti/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "avalokitesvara sukhavati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T728",
        "rank": 2243
    },
    "T661": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin lhan cig skyes pa jo bo lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva sahaja ekavira"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T661",
        "rank": 2100
    },
    "T1022": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jigs byed lha 49 zhang lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrabhairava 49 devatmaka (zhang lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1022",
        "rank": 2023
    },
    "T2171": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog snyan brgyud he ru ka yab yum 10 ngam rdzong snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2171",
        "rank": 1939
    },
    "T2102": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin rje gshed dmar spros med zab khrid/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2102",
        "rank": 2047
    },
    "T686": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "marici dharanivinirgata"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'od zer can ma gzungs las byung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T686",
        "rank": 2418
    },
    "T873": {
        "label": [
            {
                "@language": "en",
                "@value": "the six vajrasana yidam deities"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje gdan pa'i lha drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T423": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog"
            },
            {
                "@language": "zh-hant",
                "@value": "勝樂"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "cakrasaṃvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T423",
        "rank": 1915
    },
    "T967": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje dbyings lha 1037"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T967",
        "rank": 2118
    },
    "T00JR770": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru dpa' bo sde lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR770",
        "rank": 2489
    },
    "T2GS1177": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri khrod ma lo ma gyon ma sngon mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "parnasabari nila"
            },
            {
                "@language": "en",
                "@value": "blue parna sabari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1177",
        "rank": 2420
    },
    "T1675": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal gshin rje gshed yang bzlog me'i spu gri/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1675",
        "rank": 2043
    },
    "T4CZ15528": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ha la ha la 'jig rten dbang phyug"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15528",
        "rank": 2254
    },
    "T2GS1162": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ku ru ku l+le gsang sgrub/"
            },
            {
                "@language": "en",
                "@value": "secret practice kurukulla"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1162",
        "rank": 2402
    },
    "T42": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag mo don grub ma/"
            },
            {
                "@language": "en",
                "@value": "accomplishing varahi"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi arthasiddha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T42",
        "rank": 1960
    },
    "T8LS15615": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam smin rig 'dzin/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15615",
        "rank": 2531
    },
    "T1071": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje phag mo 'byung ba lha 19 (21) zhi sbas lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi 19 (21) devatmaka santigupta krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T8LS15657": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdzes ldan ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15657",
        "rank": 2526
    },
    "T2310": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po pad+ma gar dbang /(mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15542": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje dri ldan ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajragandhari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15542",
        "rank": 2437
    },
    "T187": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed lha 9 mal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T187",
        "rank": 2024
    },
    "T2170": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog snyan brgyud he ru ka yab yum 10 dwags po snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1205": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gdan bzhi/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "catuhpitha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1205",
        "rank": 2114
    },
    "T570": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus 'jam pa'i rdo rje/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja manjuvajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T570",
        "rank": 1906
    },
    "T2302": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po ye shes zil gnon (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2302",
        "rank": 2462
    },
    "T1239": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje phur pa yang gsang bla med (rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T574": {
        "label": [
            {
                "@language": "en",
                "@value": "kavaca-vira connected with the cakrasamvara tantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog dang 'brel ba'i go cha'i dpa' bo drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T574",
        "rank": 1946
    },
    "T1PD181162": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po 'gro ba kun grol/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1PD181162",
        "rank": 2265
    },
    "T982": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus mi bskyod rdo rje lha 32 (ngor lugs)"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyasamaja aksobhyavajra 32 devatmaka (ngor lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T982",
        "rank": 1909
    },
    "T609": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma nyin zhi mtshan khro/"
            },
            {
                "@language": "en",
                "@value": "tara peaceful by day and wrathful by night"
            },
            {
                "@language": "sa-alalc97",
                "@value": "divasanta ratrikruddha tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T609",
        "rank": 2360
    },
    "T1GS148157": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "叶衣佛母"
            },
            {
                "@language": "sa-alalc97",
                "@value": "parnasabari"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lo ma gyon ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T732": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mA ma kI/"
            },
            {
                "@language": "en",
                "@value": "the goddess mamaki"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mamaki devi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T732",
        "rank": 2009
    },
    "T8LS14949": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtsug tor rnam rgyal/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "usnisavijaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS14949",
        "rank": 2164
    },
    "T1466": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum po phur gcig ma/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1032": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grong khyer ngan song las sgrol ba jo bo lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1032",
        "rank": 2344
    },
    "T2GS1169": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don zhags ser po/"
            },
            {
                "@language": "en",
                "@value": "yellow amoghapasa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1169",
        "rank": 2271
    },
    "T8LS15656": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phra men rgya mtsho/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T8LS15656",
        "rank": 2527
    },
    "T715": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo shra ma Na/"
            },
            {
                "@language": "en",
                "@value": "ascetic goddess"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sramana devi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T715",
        "rank": 2427
    },
    "T663": {
        "label": [
            {
                "@language": "en",
                "@value": "hayagriva with four dog-faced attendants in the tradition of atisa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin shwa na bzhi bskor du grags pa jo bo lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T663",
        "rank": 2096
    },
    "T2GS1216": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma gtsug tor rnam rgyal ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "usnisavijayatara"
            },
            {
                "@language": "en",
                "@value": "tara the victorious usnisa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1216",
        "rank": 2350
    },
    "T919": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nor rgyun ma ser mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T919",
        "rank": 2518
    },
    "T1330": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal gshin rje gshed dregs pa 'joms byed (gter bdag gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1330",
        "rank": 2044
    },
    "T1KG5607": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po me'i spu gri (nyang lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1KG5607",
        "rank": 2472
    },
    "T988": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo gtum chen lha 9 bla med lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "candamaharosana acala 9 devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1019": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs byed ro langs brgyad skor dang phyag mtshan 32 gyis bskor ba rwa lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1019",
        "rank": 2029
    },
    "T973": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mchog dang po rigs bsdus lha 339"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sri-paramadya kulasangraha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T973",
        "rank": 2071
    },
    "T00JR885": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje phag mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajravārāhī"
            },
            {
                "@language": "zh-hant",
                "@value": "金剛亥母"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00JR885",
        "rank": 1954
    },
    "T2CN4940": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma rdo rje thod pa rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CN4940",
        "rank": 2521
    },
    "T1299": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud rtsi 'khyil pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1299",
        "rank": 2197
    },
    "T735": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cunda devi sita"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tsun+da dkar po (lha mo)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T735",
        "rank": 1984
    },
    "T00AG01320": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dzaM b+ha la ser po (klong gsal lugs)"
            },
            {
                "@language": "en",
                "@value": "jambhala pita in the tradition of klong gsal snying po"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T00AG01320",
        "rank": 2508
    },
    "T1073": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas thod pa lha 25 rngog lugs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "buddhakapala 25 devatmaka rngog krama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1073",
        "rank": 2113
    },
    "T44": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vajravarahi kalikruddha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khros ma nag mo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T44",
        "rank": 1968
    },
    "T669": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi g.yo ba zhabs bzhi pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "acala catuspada"
            },
            {
                "@language": "en",
                "@value": "four-legged acala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T669",
        "rank": 2175
    },
    "T2GS1168": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag dkar shes rab gsal byed yig rnying nas bshad pa ltar/"
            },
            {
                "@language": "en",
                "@value": "wisdom-illuminating white varahi as taught in the ancient texts tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2GS1168",
        "rank": 2410
    },
    "T012AG03389": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog dkar po/ (jo nang lugs/)"
            },
            {
                "@language": "en",
                "@value": "sita cakrasamvara according to the jo nang tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ2733": {
        "label": [
            {
                "@language": "en",
                "@value": "Agnideva, god of fire, gods in general, fire deity associated with the fire offering rites"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "me lha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T4CZ15535": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phags ma dug sel ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "janguli"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15535",
        "rank": 2448
    },
    "T624": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bdag med ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "nairātmya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T624",
        "rank": 1995
    },
    "T822": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor 'khor chen gtso rkyang (dge ldan lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T822",
        "rank": 2310
    },
    "T2CN4662": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag bdud rtsi thigs pa/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2CN4662",
        "rank": 2331
    },
    "T1207": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rta mgrin pad+ma gar dbang /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hayagriva padmanatesvara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1207",
        "rank": 2095
    },
    "T972": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje rtse mo rigs bsdus lha 1271"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrasekhara kulasangraha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T972",
        "rank": 2051
    },
    "T1KG1599": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro skal pa bzang mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1KG1599",
        "rank": 2003
    },
    "T1564": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal tshe bdag nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1564",
        "rank": 2300
    },
    "T2138": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang gi lha mo/"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2307": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa yang gsang bcud dril (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2307",
        "rank": 2085
    },
    "T415": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol dkar tsinta ma Ni dag snang can/"
            },
            {
                "@language": "en",
                "@value": "white tara tsinta ma ni stag phu dag snang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T2047": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jam dpal kha thun nag po (bka' ma)"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T2047",
        "rank": 2297
    },
    "T734": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtsug tor rnam rgyal phyag gnyis ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "usnisavijaya dvibhuja"
            },
            {
                "@language": "en",
                "@value": "two-armed usnisavijaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T734",
        "rank": 2165
    },
    "T4CZ15555": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "pancadevita"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo lnga /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T4CZ15555",
        "rank": 2010
    },
    "T644": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrol ma yid bzhin nor bu/"
            },
            {
                "@language": "en",
                "@value": "wish-fulfilling gem tara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T644",
        "rank": 2335
    },
    "T673": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe dpag med dkar po phyag bzhi pa/"
            },
            {
                "@language": "en",
                "@value": "four-armed white amitayus"
            },
            {
                "@language": "sa-alalc97",
                "@value": "amitayus caturbhuja sita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T673",
        "rank": 2139
    },
    "T828": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma 'jigs pa kun sel/ (jo bo lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227200"
    },
    "T1GS147789": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog lha 62"
        },
        "parent": "O9TAXTBRC2016052KG227200",
        "tbrcId": "T1GS147789",
        "rank": 1924
    },
    "O9TAXTBRC2016052KG227926": {
        "sub": [
            "T2KG207953",
            "T4CZ58781",
            "T1KG583",
            "T1KG624",
            "T1728",
            "T2KG207954",
            "T1711"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bon lha/"
            },
            {
                "@language": "zh-hans",
                "@value": "苯教神明"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227157"
    },
    "T2KG207953": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ge khod gsang ba drag chen/"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T4CZ58781": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stag lha me 'bar/"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T1KG583": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhi khro (bon)"
            },
            {
                "@language": "en",
                "@value": "zhi khro (bon tradition)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T1KG624": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbal gsas/"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T1728": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha rgod thog pa/"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T2KG207954": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khro rgyal rag sha spyi 'dul/"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "T1711": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byams ma (yi dam/ bon)"
        },
        "parent": "O9TAXTBRC2016052KG227926"
    },
    "O9TAXTBRC2016052KG227934": {
        "sub": [
            "T929",
            "T1CZ18",
            "T512",
            "T4CZ15352",
            "T500",
            "T480",
            "T1933",
            "T1GS147931",
            "T1497",
            "T763",
            "T1KG8477",
            "T411",
            "T743",
            "T960",
            "T492",
            "T92",
            "T1GS139159",
            "T1599",
            "T1AT289",
            "T1KG6064",
            "T01AG02844",
            "T2GS1129",
            "T925",
            "T1050",
            "T1274",
            "T2372",
            "T4CZ15233",
            "T2CN4589",
            "T1107",
            "T2GS223",
            "T554",
            "T506",
            "T1434",
            "T1279",
            "T2GS1198",
            "T1885",
            "T1863",
            "T2CN5007",
            "T1GS108074",
            "T2GS1136",
            "T1GS141059",
            "T1415",
            "T2CN4932",
            "T174",
            "T1403",
            "T1620",
            "T2GS1186",
            "T2161",
            "T1110",
            "T1GS108639",
            "T2103",
            "T2573",
            "T1314",
            "T1GS147795",
            "T773",
            "T1278",
            "T01JR254",
            "T1GS108075",
            "T761",
            "T2108",
            "T00KG02743",
            "T2CN5006",
            "T490",
            "T1623",
            "T2GS981",
            "T1438",
            "T1601",
            "T00KG02580",
            "T1440",
            "T1KG5606",
            "T00KG02674",
            "T923",
            "T2CN4717",
            "T1928",
            "T774",
            "T1642",
            "T1636",
            "T742",
            "T928",
            "T491",
            "T012AG03590",
            "T2268",
            "T1KG5702",
            "T1566",
            "T2GS1194",
            "T2567",
            "T198",
            "T2GS1111",
            "T2007",
            "T1GS10762",
            "T1843",
            "T1GS138848",
            "T1AT288",
            "T1047",
            "T1500",
            "T911",
            "T924",
            "T2041",
            "T1718",
            "T2524",
            "T1GS1588",
            "T1448",
            "T1GS108650",
            "T2338",
            "T488",
            "T2CN4867",
            "T1862",
            "T1641",
            "T1AT293",
            "T753",
            "T1916",
            "T2GS1189",
            "T401",
            "T1860",
            "T950",
            "T1615",
            "T1436",
            "T2CN4931",
            "T2251",
            "T495",
            "T2172",
            "T00JR774",
            "T2160",
            "T1GS108638",
            "T1738",
            "T120",
            "T2572",
            "T2391",
            "T00AG01404",
            "T1GS108634",
            "T1926",
            "T1PD98454",
            "T772",
            "T1GS108152",
            "T1904",
            "T1622",
            "T498",
            "T2GS1188",
            "T510",
            "T2326",
            "T1100",
            "T561",
            "T1841",
            "T2GS1190",
            "T1KG5605",
            "T2259",
            "T2519",
            "T012AG03667",
            "T2237",
            "T1927",
            "T00KG02745",
            "T1105",
            "T1424",
            "T1GS108649",
            "T1406",
            "T2267",
            "T2GS1193",
            "T486",
            "T499",
            "T2CN4865",
            "T1GS148162",
            "T1854",
            "T1329",
            "T1842",
            "T1GS138847",
            "T459",
            "T764",
            "T910",
            "T1729",
            "T1932",
            "T2CN4264",
            "T408",
            "T1GS147930",
            "T2CN4861",
            "T493",
            "T2GS1196",
            "T1AT436",
            "T1GS139158",
            "T1GS147819",
            "T1201",
            "T2GS1181",
            "T2CN4853",
            "T2628",
            "T1CZ165",
            "T1GS108648",
            "T926",
            "T2458",
            "T1305",
            "T1AT292",
            "T112",
            "T752",
            "T767",
            "T612",
            "T1741",
            "T494",
            "T2GS1184",
            "T2GS1199",
            "T2CN4100",
            "T1227",
            "T505",
            "T2CN4818",
            "T1864",
            "T2616",
            "T1925",
            "T01AG03136",
            "T1GS135701",
            "T1744",
            "T1GS122423",
            "T755",
            "T2GS1135",
            "T1416",
            "T1923",
            "T97",
            "T497",
            "T118",
            "T00KG02672",
            "T2GS1187",
            "T1852",
            "T1740",
            "T2534",
            "T122",
            "T2CN4822",
            "T1919",
            "T2236",
            "T1930",
            "T1745",
            "T406",
            "T2CN4953",
            "T2GS1145",
            "T442",
            "T2GS1230",
            "T509",
            "T1GS139156",
            "T1KG4535",
            "T848",
            "T1499",
            "T331",
            "T1GS147801",
            "T2239",
            "T1AT290",
            "T1652",
            "T1929",
            "T110",
            "T2GS1139",
            "T1GS139029",
            "T1931",
            "T2GS1146",
            "T00JR3358",
            "T1AT435",
            "T012AG03494",
            "T1GS147818",
            "T503",
            "T2GS1144",
            "T1844",
            "T1CZ10",
            "T197",
            "T1319",
            "T126",
            "T501",
            "T1934",
            "T1046",
            "T4CZ15353",
            "T1GS108651",
            "T2GS1183",
            "T012AG03495",
            "T2319",
            "T504",
            "T00JR3350",
            "T2GS1223",
            "T114",
            "T2190",
            "T1CZ7",
            "T1922",
            "T8LS14982",
            "T2094",
            "T2GS1134",
            "T2159",
            "T400",
            "T1108",
            "T507",
            "T1435",
            "T2173",
            "T1CZ91",
            "T2GS1226",
            "T306",
            "T172",
            "T2CN3469",
            "T2GS1233",
            "T2CZ7874",
            "T1GS148133",
            "T1450",
            "T1CZ1062",
            "T2533",
            "T1AT297",
            "T1759",
            "T1CZ8",
            "T2GS1137",
            "T771",
            "T1276",
            "T8LS14983",
            "T441",
            "T2070",
            "T2327",
            "T508",
            "T562",
            "T01AG03034",
            "T2470",
            "T2GS1191",
            "T2162",
            "T1670",
            "T2GS1133",
            "T2238",
            "T2587",
            "T2085",
            "T1496",
            "T012AG03666",
            "T2GS1138",
            "T2069",
            "T1419",
            "T00KG02744",
            "T00AG01162",
            "T2116",
            "T2532",
            "T2KG207433",
            "T1439",
            "T2288",
            "T1423",
            "T502",
            "T2GS1143"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos skyong srung ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "护法神明"
            },
            {
                "@language": "en",
                "@value": "Protectors"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227157",
        "tbrcId": "3",
        "rank": 2533
    },
    "T929": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "beg tse lcam sring tshar lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T929",
        "rank": 2700
    },
    "T1CZ18": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ser bdag bcu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T512": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang phyug chen po (lha chen)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T512",
        "rank": 2719
    },
    "T4CZ15352": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "klu mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "nagi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T4CZ15352",
        "rank": 2796
    },
    "T500": {
        "label": [
            {
                "@language": "en",
                "@value": "vajra wild-winged remati"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje gshog rgod ma (srung ma)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T500",
        "rank": 2690
    },
    "T480": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol byed nag po (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T480",
        "rank": 2784
    },
    "T1933": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje dgra 'dul/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1933",
        "rank": 2809
    },
    "T1GS147931": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po beng dmar lha gnyis pa (zhwa lu lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147931",
        "rank": 2571
    },
    "T1497": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge bsnyen ri bo che/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1497",
        "rank": 2848
    },
    "T763": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag dkar po phyag bzhi jo bo'i lugs bsdus pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ganapati sita caturbhuja"
            },
            {
                "@language": "en",
                "@value": "four armed, white ganapati, condensed in the tradition of atisa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T763",
        "rank": 2708
    },
    "T1KG8477": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyan po g.yu rtse/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG8477",
        "rank": 2840
    },
    "T411": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po phyag drug"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahakala sadbhuja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T411",
        "rank": 2541
    },
    "T743": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag ser po/"
            },
            {
                "@language": "en",
                "@value": "yellow ganapati"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ganapati pita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T743",
        "rank": 2707
    },
    "T960": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkar bdud lcam dral 'khon lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T960",
        "rank": 2730
    },
    "T492": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahakala sita cintamanicakra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po dkar po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T492",
        "rank": 2597
    },
    "T92": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yam shud dmar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T92",
        "rank": 2685
    },
    "T1GS139159": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'o de gung rgyal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS139159",
        "rank": 2828
    },
    "T1599": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gur gyi mgon po/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahakala panjaranatha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1599",
        "rank": 2560
    },
    "T1AT289": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge bsnyen ston rgyal dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT289",
        "rank": 2835
    },
    "T1KG6064": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "孙悟空"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lha sprel/"
            },
            {
                "@language": "en",
                "@value": "hanuman"
            },
            {
                "@language": "sa-deva",
                "@value": "हनुमान"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG6064",
        "rank": 2858
    },
    "T01AG02844": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srid pa'i lha chen/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T01AG02844",
        "rank": 2756
    },
    "T2GS1129": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ye shes kyi mgon po phyag drug pa nag po bar chad kun sel/"
            },
            {
                "@language": "en",
                "@value": "black, six-armed, quick acting gnosis mahakala, remover of all obstacles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1129",
        "rank": 2550
    },
    "T925": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po legs ldan mched gsum/"
            },
            {
                "@language": "en",
                "@value": "excellent mahakala, the three brothers"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T925",
        "rank": 2590
    },
    "T1050": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog bdag dmar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1050",
        "rank": 2758
    },
    "T1274": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa (tshal lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1274",
        "rank": 2553
    },
    "T2372": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zangs ri btsan rgod/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2372",
        "rank": 2812
    },
    "T4CZ15233": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dud sol lha mo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dhumangari"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T4CZ15233",
        "rank": 2650
    },
    "T2CN4589": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud mgon rong lha rgyal mtshan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4589",
        "rank": 2813
    },
    "T1107": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bar ba rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1107",
        "rank": 2643
    },
    "T2GS223": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpal mgon don lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS223",
        "rank": 2609
    },
    "T554": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen sde bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T554",
        "rank": 2623
    },
    "T506": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dur khrod bdag mo (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T506",
        "rank": 2672
    },
    "T1434": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can rnam gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1434",
        "rank": 2613
    },
    "T1279": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "dhumavati devi"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo dud sol ma (dwags po lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1279",
        "rank": 2652
    },
    "T2GS1198": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhal bzhi pa sgrub dus dang 'brel ba/"
            },
            {
                "@language": "en",
                "@value": "four faced sri mahakala connected with the period of accomplishment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1198",
        "rank": 2603
    },
    "T1885": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'phrin las thogs med rtsal (yul lha)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1885",
        "rank": 2778
    },
    "T1863": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ge sar (lha)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1863",
        "rank": 2826
    },
    "T2CN5007": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ye shes mgon po/ (nyang gter/)"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1GS108074": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po gri gug (chos skyong)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108074",
        "rank": 2562
    },
    "T2GS1136": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnod sbyin re ma ti/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "yaksi remati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1136",
        "rank": 2687
    },
    "T1GS141059": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po legs ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS141059",
        "rank": 2598
    },
    "T1415": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyes lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1415",
        "rank": 2811
    },
    "T2CN4932": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skrag med nyi shar/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4932",
        "rank": 2641
    },
    "T174": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a phyi chos kyi sgrol ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T174",
        "rank": 2692
    },
    "T1403": {
        "label": [
            {
                "@language": "en",
                "@value": "indra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brgya byin/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1403",
        "rank": 2754
    },
    "T1620": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rma rgyal spom ra/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2GS1186": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mgon zhal bzhi pa dkar po tshe 'phel/"
            },
            {
                "@language": "en",
                "@value": "white, four-faced mahakala, life increasing"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1186",
        "rank": 2578
    },
    "T2161": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gur gyi mgon po lha 12"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajramahakalapanjaranatha 12-devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2161",
        "rank": 2565
    },
    "T1110": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa ('bri gung lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1110",
        "rank": 2545
    },
    "T1GS108639": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "se sdug"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108639",
        "rank": 2839
    },
    "T2103": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmar po skor gsum/"
            },
            {
                "@language": "en",
                "@value": "the three red deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2103",
        "rank": 2702
    },
    "T2573": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bya 'gur (drag btsan)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2573",
        "rank": 2808
    },
    "T1314": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo spyi/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1314",
        "rank": 2657
    },
    "T1GS147795": {
        "label": [
            {
                "@language": "en",
                "@value": "worldly deities"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jig rten pa'i lha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147795",
        "rank": 2750
    },
    "T773": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahakala syama ayuspati"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po ljang khu tshe bdag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T773",
        "rank": 2600
    },
    "T1278": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa (snyan brgyud)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1278",
        "rank": 2542
    },
    "T01JR254": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma mgon lcam dral/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T01JR254",
        "rank": 2684
    },
    "T1GS108075": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tsher gseb chos skyong bstan ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108075",
        "rank": 2659
    },
    "T761": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "a pa ra dzi ta/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "aparajita (yaksa)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T761",
        "rank": 2712
    },
    "T2108": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po khro bcu/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2108",
        "rank": 2567
    },
    "T00KG02743": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po lha dgu/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02743",
        "rank": 2592
    },
    "T2CN5006": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud mo nyi shar me long /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN5006",
        "rank": 2794
    },
    "T490": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po legs ldan tshogs kyi bdag po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T490",
        "rank": 2591
    },
    "T1623": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun 'khyil mched gsum (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1623",
        "rank": 2783
    },
    "T2GS981": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dri za/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS981",
        "rank": 2857
    },
    "T1438": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgra lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1438",
        "rank": 2759
    },
    "T1601": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po sku rags ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1601",
        "rank": 2557
    },
    "T00KG02580": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po gcer bu zangs gri can/"
            },
            {
                "@language": "en",
                "@value": "naked mahakala with copper knife"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02580",
        "rank": 2589
    },
    "T1440": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bog to ha kang a kwo la/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1440",
        "rank": 2781
    },
    "T1KG5606": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam sras drag po rta sngon ma (gnubs chen lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG5606",
        "rank": 2636
    },
    "T00KG02674": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zangs gri skor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02674",
        "rank": 2610
    },
    "T923": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po gur lha gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T923",
        "rank": 2561
    },
    "T2CN4717": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can sde bdun/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1928": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje g.yu sgron ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1928",
        "rank": 2682
    },
    "T774": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ye shes mgon po phyag bzhi pa (klu sgrub lugs)"
            },
            {
                "@language": "en",
                "@value": "four armed gnosis mahakala great protector of the doctrine in the tradition of nagarjuna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T774",
        "rank": 2551
    },
    "T1642": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag po skor gsum ( pad+ma gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1642",
        "rank": 2742
    },
    "T1636": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhing lha tshangs pa/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1636",
        "rank": 2753
    },
    "T742": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phrog ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "hariti yaksini"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T742",
        "rank": 2696
    },
    "T928": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "smasana adhipati"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dur khrod bdag po yab yum (srung ma)"
            },
            {
                "@language": "en",
                "@value": "master of the charnal ground brother and sister"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T928",
        "rank": 2673
    },
    "T491": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahakala caturbhuja (santigupta krama)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po phyag bzhi zhi ba sbas pa lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T491",
        "rank": 2546
    },
    "T012AG03590": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T012AG03590",
        "rank": 2622
    },
    "T2268": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a myes mdzangs ra can/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1KG5702": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje blo gros/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG5702",
        "rank": 2787
    },
    "T1566": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha rtse yid bzhin bdud 'joms (yul lha)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1566",
        "rank": 2773
    },
    "T2GS1194": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sras rgyal ba khyu mchog gser 'od las byung ba/"
            },
            {
                "@language": "en",
                "@value": "prince jinarsabha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1194",
        "rank": 2642
    },
    "T2567": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'brong ri smug po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2567",
        "rank": 2849
    },
    "T198": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can chos rgyal (chos srung)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T198",
        "rank": 2612
    },
    "T2GS1111": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "wer ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2007": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha btsan (stag lung bka' srung)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2007",
        "rank": 2801
    },
    "T1GS10762": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gil bar jo bo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS10762",
        "rank": 2762
    },
    "T1843": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahakala simhamukha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhing skyong dbang po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1843",
        "rank": 2604
    },
    "T1GS138848": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal po byes chas ke tu/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS138848",
        "rank": 2843
    },
    "T1AT288": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje ngan ne ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT288",
        "rank": 2699
    },
    "T1047": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo dud sol ma (rngog lugs)"
            },
            {
                "@language": "en",
                "@value": "the lady of the desire realm known as the smoke-clad glorious goddess"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1047",
        "rank": 2651
    },
    "T1500": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyes ri/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1500",
        "rank": 2841
    },
    "T911": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "seng gdong ma ba ri lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T911",
        "rank": 2669
    },
    "T924": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po zhal bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T924",
        "rank": 2543
    },
    "T2041": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can lcam dral/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2041",
        "rank": 2618
    },
    "T1718": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen ngal bsos po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1718",
        "rank": 2626
    },
    "T2524": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bstan ma bcu gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2524",
        "rank": 2679
    },
    "T1GS1588": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phrin las thogs med rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS1588",
        "rank": 2774
    },
    "T1448": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa bdag"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1448",
        "rank": 2842
    },
    "T1GS108650": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu bdud dug gi spu gri/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108650",
        "rank": 2800
    },
    "T2338": {
        "label": [
            {
                "@language": "en",
                "@value": "black-cloaked mahakala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po ber nag can/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2338",
        "rank": 2595
    },
    "T488": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo k+tri pA la (drang srong)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T488",
        "rank": 2745
    },
    "T2CN4867": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha gcig nyi ma gzhon nu/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1862": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma nor lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1862",
        "rank": 2724
    },
    "T1641": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1641",
        "rank": 2854
    },
    "T1AT293": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku khyams/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT293",
        "rank": 2802
    },
    "T753": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "nartakavara vaisravana"
            },
            {
                "@language": "en",
                "@value": "red nada kubera vaisravana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras dmar po gar mkhan mchog gsang sgrub/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T753",
        "rank": 2629
    },
    "T1916": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje de ne ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1916",
        "rank": 2788
    },
    "T2GS1189": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po trak+Shad gsang sgrub shwa na'i zhal can/"
            },
            {
                "@language": "en",
                "@value": "dog faced traksad mahakala for secret practice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1189",
        "rank": 2583
    },
    "T401": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal ldan lha mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sridevi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T401",
        "rank": 2645
    },
    "T1860": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'ba' lung gangs mtsho gnyan po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1860",
        "rank": 2776
    },
    "T950": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahākāla"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T950",
        "rank": 2538
    },
    "T1615": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs bdag (gnam chos)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1615",
        "rank": 2709
    },
    "T1436": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshangs pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "brahma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1436",
        "rank": 2752
    },
    "T2CN4931": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzong btsan pa/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4931",
        "rank": 2814
    },
    "T2251": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje rab brtan ma (lha mo)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2251",
        "rank": 2648
    },
    "T495": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po beng gter ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "dandadhara mahakala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T495",
        "rank": 2599
    },
    "T2172": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa kaM tshang lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2172",
        "rank": 2547
    },
    "T00JR774": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo srog sgrub lcags phur ma rang byung rgyal mo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "devi pranasadhana ayahkila svayambhurajni"
            },
            {
                "@language": "en",
                "@value": "goddess with iron ritual dagger for vitality-practice, the self-arisen queen"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2160": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gur gyi mgon po lha brgyad/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajramahakalapanjaranatha 8-devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1GS108638": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bar ba rdo rje/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108638",
        "rank": 2838
    },
    "T1738": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sprul pa'i chos rgyal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1738",
        "rank": 2733
    },
    "T120": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras drag byed/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana raudra"
            },
            {
                "@language": "en",
                "@value": "terrible vaisravana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T120",
        "rank": 2639
    },
    "T2572": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje bdud 'dul (lha btsan)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2572",
        "rank": 2817
    },
    "T2391": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje chos sgron/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2391",
        "rank": 2677
    },
    "T00AG01404": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' srung sman btsun mched lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00AG01404",
        "rank": 2674
    },
    "T1GS108634": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhog lha phyug po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108634",
        "rank": 2837
    },
    "T1926": {
        "label": [
            {
                "@language": "en",
                "@value": "great yellow vaisravana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam thos sras (zangs dkar lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1926",
        "rank": 2625
    },
    "T1PD98454": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khro zil khrom/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1PD98454",
        "rank": 2806
    },
    "T772": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po dmar po dbang gi rgyal po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahakala rakta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T772",
        "rank": 2548
    },
    "T1GS108152": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ne ser jo bo chen po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108152",
        "rank": 2765
    },
    "T1904": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bkra shis do kha ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1904",
        "rank": 2786
    },
    "T1622": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bse rag"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1622",
        "rank": 2856
    },
    "T498": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "頂髻母"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "e ka dzA TI/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "ekajatī"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T498",
        "rank": 2644
    },
    "T2GS1188": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po trak+Shad 'brong zhal can/"
            },
            {
                "@language": "en",
                "@value": "yak-faced traksad mahakala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1188",
        "rank": 2582
    },
    "T510": {
        "label": [
            {
                "@language": "en",
                "@value": "the yaksa vajra-mara-dama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhang blon rdo rje bdud 'dul phyi sgrub/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T510",
        "rank": 2779
    },
    "T2326": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo 'dod khams dbang phyug ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2326",
        "rank": 2653
    },
    "T1100": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spu gri rnam gsum (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1100",
        "rank": 2741
    },
    "T561": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dod pa'i rgyal po/"
            },
            {
                "@language": "en",
                "@value": "red sri takkiraja"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kamaraja (rakta)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T561",
        "rank": 2704
    },
    "T1841": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po mthing ba/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1841",
        "rank": 2570
    },
    "T2GS1190": {
        "label": [
            {
                "@language": "en",
                "@value": "eunuch traksad mahakala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po trak+Shad ma ning /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1190",
        "rank": 2584
    },
    "T1KG5605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam sras ser chen sprin gseb ma (gnyal pa nyi ma shes rab)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG5605",
        "rank": 2635
    },
    "T2259": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma 'od 'bar/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2259",
        "rank": 2819
    },
    "T2519": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bse rgod (gzhi bdag)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2519",
        "rank": 2770
    },
    "T012AG03667": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyes bu lung btsan lcam sring /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T012AG03667",
        "rank": 2815
    },
    "T2237": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po che chung (sa lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2237",
        "rank": 2601
    },
    "T1927": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rma chen spom ra/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T00KG02745": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag po chen po khro bo bcu gcig"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02745",
        "rank": 2549
    },
    "T1105": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rab brjid (dge bsnyen)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1105",
        "rank": 2847
    },
    "T1424": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal po (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1424",
        "rank": 2761
    },
    "T1GS108649": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "btsan rgod mthu stobs pa/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108649",
        "rank": 2767
    },
    "T1406": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kong btsun de mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1406",
        "rank": 2655
    },
    "T2267": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2267",
        "rank": 2855
    },
    "T2GS1193": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sras gar mkhan mchog"
            },
            {
                "@language": "en",
                "@value": "prince nada kubera"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1193",
        "rank": 2640
    },
    "T486": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gza' (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T486",
        "rank": 2731
    },
    "T499": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "re ma tI/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T499",
        "rank": 2686
    },
    "T2CN4865": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha gcig nyi ma mun sel/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1GS148162": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'phying dkar jo bo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS148162",
        "rank": 2764
    },
    "T1854": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnod sbyin lha mang /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1854",
        "rank": 2823
    },
    "T1329": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gter srung /"
            },
            {
                "@language": "en",
                "@value": "gter ma protectors"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1329",
        "rank": 2822
    },
    "T1842": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po trak+Shad/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1842",
        "rank": 2581
    },
    "T1GS138847": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang g+ha bza' (gnod sbyin)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS138847",
        "rank": 2718
    },
    "T459": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sde bdun (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T459",
        "rank": 2743
    },
    "T764": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag dmar po phyag bzhi pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ganapati rakta caturbhuja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T764",
        "rank": 2710
    },
    "T910": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "seng gdong rigs lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T910",
        "rank": 2667
    },
    "T1729": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ye brdzu gnyan po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1729",
        "rank": 2833
    },
    "T1932": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje 'bar ba rtsal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1932",
        "rank": 2831
    },
    "T2CN4264": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srid pa'i rgyal mo mu kha le/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4264",
        "rank": 2793
    },
    "T408": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yul lha/"
            },
            {
                "@language": "en",
                "@value": "local deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T408",
        "rank": 2757
    },
    "T1GS147930": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po stag zhon (jo bo rje lha gcig nas brgyud pa)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147930",
        "rank": 2573
    },
    "T2CN4861": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhing skyong ma sku mched gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T493": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po ma ning /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T493",
        "rank": 2594
    },
    "T2GS1196": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jur 'gegs sel ba'i tshogs bdag chags pa rdo rje/"
            },
            {
                "@language": "en",
                "@value": "authority-averting (ajna-vinivarta) ganapati ragavajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1196",
        "rank": 2706
    },
    "T1AT436": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "seng gdong ma dmar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT436",
        "rank": 2666
    },
    "T1GS139158": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'od chen 'bar ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS139158",
        "rank": 2646
    },
    "T1GS147819": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po bram nag bshan pa drug bskor/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147819",
        "rank": 2575
    },
    "T1201": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje shugs ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1201",
        "rank": 2852
    },
    "T2GS1181": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po gri gug kha che paN chen gyi lugs/"
            },
            {
                "@language": "en",
                "@value": "knife mahakala in the tradition of the great pandita of kashmir"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1181",
        "rank": 2572
    },
    "T2CN4853": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhing skyong srog bdud nag mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4853",
        "rank": 2608
    },
    "T2628": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha srin sde brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2628",
        "rank": 2748
    },
    "T1CZ165": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngo lha g.yu rtse/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1GS108648": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "karma 'phrin las/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108648",
        "rank": 2766
    },
    "T926": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmag zor rgyal mo/"
            },
            {
                "@language": "en",
                "@value": "glorious army-repulsing queen"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T926",
        "rank": 2647
    },
    "T2458": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag mo chen mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2458",
        "rank": 2662
    },
    "T1305": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "mahābala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ma hA ba la/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1305",
        "rank": 2720
    },
    "T1AT292": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mer chen gnas srung rdo rje 'od dkar/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT292",
        "rank": 2803
    },
    "T112": {
        "label": {
            "@language": "sa-alalc97",
            "@value": "rnam sras ser chen lha dgu"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T112",
        "rank": 2630
    },
    "T752": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nor bu bzang po/"
            },
            {
                "@language": "en",
                "@value": "excellent gem"
            },
            {
                "@language": "sa-alalc97",
                "@value": "manibhadra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T752",
        "rank": 2820
    },
    "T767": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khol po kun+d+ha li/"
            },
            {
                "@language": "en",
                "@value": "the servant kundhali"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kundhali"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T767",
        "rank": 2785
    },
    "T612": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras mdung dmar rta sngon can/"
            },
            {
                "@language": "en",
                "@value": "vaisravana with red spear and blue horse"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana nilasva raktasula"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T612",
        "rank": 2627
    },
    "T1741": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje grags ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1741",
        "rank": 2737
    },
    "T494": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po stag zhon ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T494",
        "rank": 2556
    },
    "T2GS1184": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mgon zhal bzhi pa dmar po dbang sdud/"
            },
            {
                "@language": "en",
                "@value": "red, four faced sri-mahakala subjugating"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1184",
        "rank": 2577
    },
    "T2GS1199": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mgon zhal bzhi pa bsnyen dus dang 'brel ba/"
            },
            {
                "@language": "en",
                "@value": "four faced mahakala connected with the period of service"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1199",
        "rank": 2587
    },
    "T2CN4100": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mal gro gzi can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4100",
        "rank": 2797
    },
    "T1227": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo bu rdzi ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1227",
        "rank": 2725
    },
    "T505": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "seng gdong can (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T505",
        "rank": 2671
    },
    "T2CN4818": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo duk+ti ka"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4818",
        "rank": 2654
    },
    "T1864": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a rab yul lha ra nyag gnyan po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1864",
        "rank": 2777
    },
    "T2616": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "u ma de wI/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2616",
        "rank": 2792
    },
    "T1925": {
        "label": [
            {
                "@language": "en",
                "@value": "raven faced karma mahakala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po bya rog gdong /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1925",
        "rank": 2539
    },
    "T01AG03136": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ye shes mgon po phyag bzhi pa zhi ba sbas pa'i lugs/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahakala caturbhuja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T01AG03136",
        "rank": 2552
    },
    "T1GS135701": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbid lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS135701",
        "rank": 2760
    },
    "T1744": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "karma ba dz+ra/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1744",
        "rank": 2846
    },
    "T1GS122423": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mthu chen sde bzhi (rgya zhang khrom)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS122423",
        "rank": 2821
    },
    "T755": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo dpal chen mo/"
            },
            {
                "@language": "en",
                "@value": "the goddess mahalaksmi"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahalaksmi devi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T755",
        "rank": 2726
    },
    "T2GS1135": {
        "label": [
            {
                "@language": "en",
                "@value": "blood-colored red karma-yama"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las gshin dmar po khrag mdog (srung ma)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1135",
        "rank": 2621
    },
    "T1416": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pho lha dung skyong dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1416",
        "rank": 2790
    },
    "T1923": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu bdud nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1923",
        "rank": 2798
    },
    "T97": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T97",
        "rank": 2780
    },
    "T497": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags srung sde lnga (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T497",
        "rank": 2695
    },
    "T118": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras gsung mchog lus ngan po/"
            },
            {
                "@language": "en",
                "@value": "kubera supreme speech vaisravana"
            },
            {
                "@language": "sa-x-ewts",
                "@value": "vaisravana kubera svarottama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T118",
        "rank": 2633
    },
    "T00KG02672": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bran bdud gshin rje nag po/"
            },
            {
                "@language": "en",
                "@value": "black servant-demon yama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02672",
        "rank": 2616
    },
    "T2GS1187": {
        "label": [
            {
                "@language": "en",
                "@value": "four-faced mahakala for practical application"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po zhal bzhi pa las sbyor mgon po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1187",
        "rank": 2580
    },
    "T1852": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "gaṇeśa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1852",
        "rank": 2701
    },
    "T1740": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bse khrab can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1740",
        "rank": 2729
    },
    "T2534": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje drag rgyal ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2534",
        "rank": 2660
    },
    "T122": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras nag po rta sngon can/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana nilasva krsna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T122",
        "rank": 2634
    },
    "T2CN4822": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stobs ldan drag shul dbang po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4822",
        "rank": 2607
    },
    "T1919": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkor bdag ma mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1919",
        "rank": 2795
    },
    "T2236": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "li byin ha ra/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2236",
        "rank": 2791
    },
    "T1930": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can snyon kha chen po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1930",
        "rank": 2614
    },
    "T1745": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tha 'og chos rgyal/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1745",
        "rank": 2740
    },
    "T406": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos srung /"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dharmapāla"
            },
            {
                "@language": "en",
                "@value": "protective deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T406",
        "rank": 2749
    },
    "T2CN4953": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bya gdong ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CN4953",
        "rank": 2656
    },
    "T2GS1145": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po zhal bzhi pa gdong gnyan can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1145",
        "rank": 2558
    },
    "T442": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las kyi gshin rje/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T442",
        "rank": 2617
    },
    "T2GS1230": {
        "label": [
            {
                "@language": "en",
                "@value": "eight deity panjara mahakala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gur mgon lha brgyad ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajramahakalapanjaranatha 8-devatmaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1230",
        "rank": 2564
    },
    "T509": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam sras nang sgrub mdung dmar can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T509",
        "rank": 2628
    },
    "T1GS139156": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po bram gzugs can/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS139156",
        "rank": 2559
    },
    "T1KG4535": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khyab 'jug"
            },
            {
                "@language": "en",
                "@value": "vishnu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1KG4535",
        "rank": 2734
    },
    "T848": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla bslu/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T848",
        "rank": 2850
    },
    "T1499": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "g.yag ri/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1499",
        "rank": 2853
    },
    "T331": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tsi'u dmar po (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T331",
        "rank": 2738
    },
    "T1GS147801": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje khyung lung ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147801",
        "rank": 2698
    },
    "T2239": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgra 'dul bstan skyong /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2239",
        "rank": 2772
    },
    "T1AT290": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshwa sman klu 'od 'bar ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT290",
        "rank": 2799
    },
    "T1652": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhi bdag rten bkol/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1652",
        "rank": 2771
    },
    "T1929": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "cig car dmar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1929",
        "rank": 2832
    },
    "T110": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras dkar po tshe 'dzin/"
            },
            {
                "@language": "en",
                "@value": "life-holding white vaisravana"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana ayurdhara sita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T110",
        "rank": 2632
    },
    "T2GS1139": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "klu mo re ma ti phyag bzhi ma/"
            },
            {
                "@language": "en",
                "@value": "four-armed nagi remati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1139",
        "rank": 2689
    },
    "T1GS139029": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbyu gu 64"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS139029",
        "rank": 2714
    },
    "T1931": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje kun skyob/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1931",
        "rank": 2805
    },
    "T2GS1146": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sha za ce spyang gdong /"
            },
            {
                "@language": "en",
                "@value": "jackal-faced pisaca"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1146",
        "rank": 2661
    },
    "T00JR3358": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "drang srong nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00JR3358",
        "rank": 2721
    },
    "T1AT435": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags srung /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT435",
        "rank": 2694
    },
    "T012AG03494": {
        "label": [
            {
                "@language": "en",
                "@value": "wealth deities according to the bar chad kun sel treasure cycle of mchog gyur gling pa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma nor lha (bar chad kun sel)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T012AG03494",
        "rank": 2723
    },
    "T1GS147818": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gur gyi mgon po sprin gseb ma (thang stong nye brgyud)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS147818",
        "rank": 2569
    },
    "T503": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshan pa dmar nag (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T503",
        "rank": 2619
    },
    "T2GS1144": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos rgyal las kyi gshin rje mthing ga"
            },
            {
                "@language": "en",
                "@value": "blue dharmaraja karmayama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1144",
        "rank": 2615
    },
    "T1844": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kha ba dkar po (gnas bdag)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1844",
        "rank": 2789
    },
    "T1CZ10": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhi bdag lha'i dge bsnyen gzi brjid 'phags/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1CZ10",
        "rank": 2769
    },
    "T197": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras/"
            },
            {
                "@language": "zh-hant",
                "@value": "毗沙門天"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vaiśravaṇa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T197",
        "rank": 2624
    },
    "T1319": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun thub rgyal mo (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1319",
        "rank": 2664
    },
    "T126": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras yang gsang phyag mtshan bzhi pa/"
            },
            {
                "@language": "en",
                "@value": "four emblem very secret vaisravana"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana caturyudha atiguhya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T126",
        "rank": 2631
    },
    "T501": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma mo srog sgrub ma (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T501",
        "rank": 2739
    },
    "T1934": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ya ba skya bdun/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1934",
        "rank": 2717
    },
    "T1046": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po bram nag shan pa drug thang rgyal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1046",
        "rank": 2574
    },
    "T4CZ15353": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "yaksha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnod sbyin/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T4CZ15353",
        "rank": 2716
    },
    "T1GS108651": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' gdong chos skyong /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS108651",
        "rank": 2755
    },
    "T2GS1183": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal mgon zhal bzhi pa sngon po nyams sgrol/"
            },
            {
                "@language": "en",
                "@value": "blue, four-faced sri-mahakala, soul releasing"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1183",
        "rank": 2576
    },
    "T012AG03495": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rag nyag gnyan po/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "local deity of a rab yul"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T012AG03495",
        "rank": 2816
    },
    "T2319": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud 'dul seng gdong dmar mo (klong gsal snying po)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2319",
        "rank": 2670
    },
    "T504": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje legs pa/ (srung ma/)"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vajrasadhu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T504",
        "rank": 2824
    },
    "T00JR3350": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyan po rdo rje brag btsan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00JR3350",
        "rank": 2810
    },
    "T2GS1223": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po trak+Sad rkang thang ma/"
            },
            {
                "@language": "en",
                "@value": "traksad mahakala on foot"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1223",
        "rank": 2585
    },
    "T114": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "vaisravana gadadharasyamapita"
            },
            {
                "@language": "en",
                "@value": "yellow green vaisravana with a club"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam sras ljang ser be con/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T114",
        "rank": 2637
    },
    "T2190": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyan chen thang lha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2190",
        "rank": 2830
    },
    "T1CZ7": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phying dkar jo bo rgyal mtshan (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1CZ7",
        "rank": 2763
    },
    "T1922": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu dge"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1922",
        "rank": 2851
    },
    "T8LS14982": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha thams cad mkhyen pa/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T8LS14982",
        "rank": 2751
    },
    "T2094": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa lha 13"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2094",
        "rank": 2544
    },
    "T2GS1134": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pu tra lcam dral (srung ma)"
            },
            {
                "@language": "en",
                "@value": "putra brother and sister"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1134",
        "rank": 2728
    },
    "T2159": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gur gyi mgon po gri gug lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2159",
        "rank": 2563
    },
    "T400": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "seng gdong ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "simhamukhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T400",
        "rank": 2665
    },
    "T1108": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kha 'bar ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1108",
        "rank": 2680
    },
    "T507": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brtan ma bcu gnyis (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T507",
        "rank": 2681
    },
    "T1435": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ku be ra/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1435",
        "rank": 2638
    },
    "T2173": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ye shes mgon po phyag bzhi pa lha 30 dwags po snyan brgyud lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2173",
        "rank": 2555
    },
    "T1CZ91": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'go ba'i lha lnga /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1CZ91",
        "rank": 2703
    },
    "T2GS1226": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gur gyi mgon po lcam dral/"
            },
            {
                "@language": "en",
                "@value": "panjara mahakala brother and sister"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1226",
        "rank": 2568
    },
    "T306": {
        "label": [
            {
                "@language": "en",
                "@value": "five sisters of long life"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshe ring mched lnga (srung ma)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T306",
        "rank": 2675
    },
    "T172": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'brom bu jo bo (yul lha)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T172",
        "rank": 2775
    },
    "T2CN3469": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje 'od ldan dkar po/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2GS1233": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po trak+Shad dwags po'i lugs/"
            },
            {
                "@language": "en",
                "@value": "traksad mahakala in the dwags po tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1233",
        "rank": 2586
    },
    "T2CZ7874": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "rgyal chen karma badz+ra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "srog bdag rdo rje drag po rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2CZ7874",
        "rank": 2836
    },
    "T1GS148133": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po zhal gcig phyag drug"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1GS148133",
        "rank": 2540
    },
    "T1450": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lce spyang sngon mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1450",
        "rank": 2663
    },
    "T1CZ1062": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "k+She Ta pA la/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1CZ1062",
        "rank": 2818
    },
    "T2533": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje klu mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2533",
        "rank": 2683
    },
    "T1AT297": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' srung mdzod lnga stag rtse/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1AT297",
        "rank": 2804
    },
    "T1759": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1759",
        "rank": 2834
    },
    "T1CZ8": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen bkwan yun chang /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1CZ8",
        "rank": 2844
    },
    "T2GS1137": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha mo dung skyong ma/"
            },
            {
                "@language": "en",
                "@value": "conch-protector goddess"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sridevi sankhapali"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1137",
        "rank": 2649
    },
    "T771": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "mahakala pita mativardhana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mgon po ser po blo 'phel/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T771",
        "rank": 2588
    },
    "T1276": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po phyag bzhi pa (thel lugs dang tshal lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1276",
        "rank": 2554
    },
    "T8LS14983": {
        "label": [
            {
                "@language": "en",
                "@value": "possessed of 6 faces"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhon nu gdong drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T8LS14983",
        "rank": 2722
    },
    "T441": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gza' nyi ma gdung gang ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2070": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje brag rdzong ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2070",
        "rank": 2697
    },
    "T2327": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dug gdong nag po (srung ma)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2327",
        "rank": 2768
    },
    "T508": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pe ha ra (srung ma)"
            },
            {
                "@language": "en",
                "@value": "pehar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T508",
        "rank": 2736
    },
    "T562": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "ganapati (maharakta)"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs bdag dmar po/"
            },
            {
                "@language": "en",
                "@value": "great red sri ganapati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T562",
        "rank": 2711
    },
    "T01AG03034": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yul skyong chen po rdo rje nga 'dra seng chen rgyal po mched brgyad bsang gsol/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T01AG03034",
        "rank": 2827
    },
    "T2470": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnod sbyin 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2470",
        "rank": 2605
    },
    "T2GS1191": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyug gu ma spun gsum/"
            },
            {
                "@language": "en",
                "@value": "the three yuk gu ma sisters"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1191",
        "rank": 2713
    },
    "T2162": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gur gyi mgon po lha 17"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2162",
        "rank": 2566
    },
    "T1670": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags srung ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1670",
        "rank": 2678
    },
    "T2GS1133": {
        "label": [
            {
                "@language": "en",
                "@value": "sun and moon gauri, sister and brother"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dkar mo nyi zla lcam dral (srung ma)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1133",
        "rank": 2691
    },
    "T2238": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen karma 'phrin las/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2238",
        "rank": 2845
    },
    "T2587": {
        "label": [
            {
                "@language": "en",
                "@value": "mahakala in the form of an acarya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las mgon a tsa ra/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T2085": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spu gri gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2085",
        "rank": 2579
    },
    "T1496": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgar ba nag po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1496",
        "rank": 2746
    },
    "T012AG03666": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gter srung chen po dge bsnyen smyo kha/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T012AG03666",
        "rank": 2747
    },
    "T2GS1138": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bdud mo re ma ti/"
            },
            {
                "@language": "en",
                "@value": "demoness remati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1138",
        "rank": 2688
    },
    "T2069": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnod sbyin ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2069",
        "rank": 2715
    },
    "T1419": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gre bo klu btsan drag po/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1419",
        "rank": 2782
    },
    "T00KG02744": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pu tra ming sring /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00KG02744",
        "rank": 2611
    },
    "T00AG01162": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nags rin lugs kyi seng gdong dmar mo/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T00AG01162",
        "rank": 2668
    },
    "T2116": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po lha mang /"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2116",
        "rank": 2593
    },
    "T2532": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kha rag khyung btsun/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2532",
        "rank": 2693
    },
    "T2KG207433": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang thang rgya ma/"
        },
        "parent": "O9TAXTBRC2016052KG227934"
    },
    "T1439": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lcam sring (chos skyong)"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1439",
        "rank": 2727
    },
    "T2288": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma mgon sde brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2288",
        "rank": 2744
    },
    "T1423": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "btsan/"
        },
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T1423",
        "rank": 2825
    },
    "T502": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gza' bdud rA hu la (srung ma)"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rahula"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T502",
        "rank": 2732
    },
    "T2GS1143": {
        "label": [
            {
                "@language": "en",
                "@value": "red karma-yama surrounded by four marutse"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las gshin dmar po ma ru tse bzhis skor ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227934",
        "tbrcId": "T2GS1143",
        "rank": 2620
    },
    "O9TAXTBRC2016052KG228290": {
        "sub": [
            "O9TAXTBRC2016052KG228306",
            "O9TAXTBRC2016052KG228406",
            "O9TAXTBRC2016052KG228312",
            "O9TAXTBRC2016052KG228362",
            "O9TAXTBRC2016052KG228372",
            "O9TAXTBRC2016052KG228377",
            "O9TAXTBRC2016052KG228370",
            "O9TAXTBRC2016052KG228334",
            "O9TAXTBRC2016052KG228317",
            "O9TAXTBRC2016052KG228350",
            "O9TAXTBRC2016052KG228291",
            "O9TAXTBRC2016052KG228308",
            "O9TAXTBRC2016052KG228380",
            "O9TAXTBRC2016052KG228330",
            "O9TAXTBRC2016052KG228374",
            "T10MS14648",
            "O9TAXTBRC2016052KG228321",
            "O9TAXTBRC2016052KG228368",
            "O9TAXTBRC2016052KG228310"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi tshogs rig gnas/"
            },
            {
                "@language": "zh-hans",
                "@value": "社会科学"
            },
            {
                "@language": "en",
                "@value": "Social Sciences"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "pa",
        "rank": 7450
    },
    "O9TAXTBRC2016052KG228306": {
        "sub": [
            "T2CZ8071"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi 'bor/_mi grangs/"
            },
            {
                "@language": "en",
                "@value": "Demography"
            },
            {
                "@language": "zh-hans",
                "@value": "人口"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "2",
        "rank": 7526
    },
    "T2CZ8071": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi 'bor/ mi grangs/"
            },
            {
                "@language": "en",
                "@value": "population , total people, the number of people"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228306",
        "tbrcId": "T2CZ8071",
        "rank": 7531
    },
    "O9TAXTBRC2016052KG228406": {
        "sub": [
            "T2018",
            "T4PD931"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnyen sgrig"
            },
            {
                "@language": "en",
                "@value": "marriage"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290"
    },
    "T2018": {
        "label": [
            {
                "@language": "en",
                "@value": "kinship and family"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnyen sgrig lam srol/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228406",
        "tbrcId": "T2018",
        "rank": 3771
    },
    "T4PD931": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyen sgrig"
        },
        "parent": "O9TAXTBRC2016052KG228406"
    },
    "O9TAXTBRC2016052KG228312": {
        "sub": [
            "T2201",
            "T2214",
            "T1GS148158",
            "T1165"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmag"
            },
            {
                "@language": "en",
                "@value": "War"
            },
            {
                "@language": "zh-hans",
                "@value": "战争"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "5",
        "rank": 7559
    },
    "T2201": {
        "label": [
            {
                "@language": "en",
                "@value": "armed conflict"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dmag 'khrug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228312",
        "tbrcId": "T2201",
        "rank": 7564
    },
    "T2214": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan army"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod dmag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228312",
        "tbrcId": "T2214",
        "rank": 7569
    },
    "T1GS148158": {
        "label": [
            {
                "@language": "en",
                "@value": "military prognostication"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dmag rtsis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228312",
        "tbrcId": "T1GS148158",
        "rank": 7574
    },
    "T1165": {
        "label": [
            {
                "@language": "en",
                "@value": "national defense"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal srung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228312",
        "tbrcId": "T1165",
        "rank": 7579
    },
    "O9TAXTBRC2016052KG228362": {
        "sub": [
            "T2568",
            "T363",
            "T2036",
            "T1KG16253",
            "T2409"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khor yug"
            },
            {
                "@language": "en",
                "@value": "environment"
            },
            {
                "@language": "zh-hans",
                "@value": "环境"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "11",
        "rank": 7780
    },
    "T2568": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chu skyon/"
            },
            {
                "@language": "en",
                "@value": "flood damage"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228362",
        "tbrcId": "T2568",
        "rank": 3831
    },
    "T363": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang byung gnod 'tshe/"
            },
            {
                "@language": "en",
                "@value": "natural disasters"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228362",
        "tbrcId": "T363",
        "rank": 4059
    },
    "T2036": {
        "label": [
            {
                "@language": "en",
                "@value": "environmental"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khor yug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228362",
        "tbrcId": "T2036",
        "rank": 7785
    },
    "T1KG16253": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gad snyigs/"
            },
            {
                "@language": "en",
                "@value": "waste"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228362",
        "tbrcId": "T1KG16253",
        "rank": 7790
    },
    "T2409": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa yom/"
            },
            {
                "@language": "en",
                "@value": "earthquakes"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228362",
        "tbrcId": "T2409",
        "rank": 3836
    },
    "O9TAXTBRC2016052KG228372": {
        "sub": [
            "T2586"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sems khams rig pa/"
            },
            {
                "@language": "en",
                "@value": "psychology"
            },
            {
                "@language": "zh-hans",
                "@value": "心理学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "14",
        "rank": 7818
    },
    "T2586": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sems khams rig pa/"
            },
            {
                "@language": "en",
                "@value": "psychology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228372",
        "tbrcId": "T2586",
        "rank": 7823
    },
    "O9TAXTBRC2016052KG228377": {
        "sub": [
            "T2016",
            "T1KG15778"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dod pa'i bstan bcos/"
            },
            {
                "@language": "en",
                "@value": "Kamashastra"
            },
            {
                "@language": "zh-hans",
                "@value": "欲经"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "16",
        "rank": 7845
    },
    "T2016": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dod pa'i bstan bcos/"
            },
            {
                "@language": "sa-x-iast",
                "@value": "kāmaśāstra"
            },
            {
                "@language": "en",
                "@value": "erotics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228377",
        "tbrcId": "T2016",
        "rank": 7850
    },
    "T1KG15778": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khrig gtam/"
        },
        "parent": "O9TAXTBRC2016052KG228377",
        "tbrcId": "T1KG15778",
        "rank": 7855
    },
    "O9TAXTBRC2016052KG228370": {
        "sub": [
            "T1936"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpe mdzod rig pa/"
            },
            {
                "@language": "en",
                "@value": "library science"
            },
            {
                "@language": "zh-hans",
                "@value": "图书馆学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "13",
        "rank": 7807
    },
    "T1936": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpe mdzod rig pa/"
            },
            {
                "@language": "en",
                "@value": "library science"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228370",
        "tbrcId": "T1936",
        "rank": 7812
    },
    "O9TAXTBRC2016052KG228334": {
        "sub": [
            "T00JR764",
            "T8LS1425",
            "T1963",
            "T1791",
            "T1PD108900",
            "T00EGS1017187",
            "T4PD930",
            "T1PD108896",
            "T384",
            "T1PD108882",
            "T1PD108884",
            "T2MS14906",
            "T3JT12490",
            "T1PD108895",
            "T1790"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "法律"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khrims yig"
            },
            {
                "@language": "en",
                "@value": "Law/Legal document"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "9",
        "rank": 7663
    },
    "T00JR764": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzu dpang /"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T00JR764",
        "rank": 3956
    },
    "T8LS1425": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T8LS1425",
        "rank": 7678
    },
    "T1963": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khral/"
            },
            {
                "@language": "en",
                "@value": "taxation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1963",
        "rank": 7718
    },
    "T1791": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhal lce bcu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1791",
        "rank": 7688
    },
    "T1PD108900": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "调解书"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dum khra/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1PD108900",
        "rank": 7708
    },
    "T00EGS1017187": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrig lam/"
            },
            {
                "@language": "en",
                "@value": "national etiquette and customs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T00EGS1017187",
        "rank": 7723
    },
    "T4PD930": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bca' khrims/"
        },
        "parent": "O9TAXTBRC2016052KG228334"
    },
    "T1PD108896": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "布告，告示"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa tshig ('grems yig)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1PD108896",
        "rank": 7698
    },
    "T384": {
        "label": [
            {
                "@language": "en",
                "@value": "law"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khrims/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T384",
        "rank": 7668
    },
    "T1PD108882": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' shog"
            },
            {
                "@language": "zh-hans",
                "@value": "公文，命令，执照"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1PD108882",
        "rank": 7673
    },
    "T1PD108884": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "契约，约据，文约"
            },
            {
                "@language": "en",
                "@value": "written agreement or contract"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gan rgya/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1PD108884",
        "rank": 7713
    },
    "T2MS14906": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri dwags rgyag"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T2MS14906",
        "rank": 7728
    },
    "T3JT12490": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhal lce bcu gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T3JT12490",
        "rank": 7683
    },
    "T1PD108895": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpyad khra/"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1PD108895",
        "rank": 7703
    },
    "T1790": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhal lce bcu drug"
        },
        "parent": "O9TAXTBRC2016052KG228334",
        "tbrcId": "T1790",
        "rank": 7693
    },
    "O9TAXTBRC2016052KG228317": {
        "sub": [
            "T266",
            "T234",
            "T2390"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes yon/"
            },
            {
                "@language": "en",
                "@value": "Education"
            },
            {
                "@language": "zh-hans",
                "@value": "教育"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "6",
        "rank": 7585
    },
    "T266": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "slob deb/"
            },
            {
                "@language": "en",
                "@value": "textbook"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228317",
        "tbrcId": "T266",
        "rank": 7595
    },
    "T234": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "slob gso/"
            },
            {
                "@language": "en",
                "@value": "slob sbyong"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228317",
        "tbrcId": "T234",
        "rank": 7590
    },
    "T2390": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "slob khrid/"
            },
            {
                "@language": "en",
                "@value": "teaching curriculum"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228317",
        "tbrcId": "T2390",
        "rank": 7600
    },
    "O9TAXTBRC2016052KG228350": {
        "sub": [
            "T1PD53279",
            "T388",
            "T3CN9711",
            "T1KG22572",
            "T1176",
            "T1KG10156",
            "T1971",
            "T1KG21080",
            "T1172",
            "T1147",
            "T1KG89007"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "社会学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi tshogs rig pa/"
            },
            {
                "@language": "en",
                "@value": "Sociology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "10",
        "rank": 7734
    },
    "T1PD53279": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos lugs/"
            },
            {
                "@language": "en",
                "@value": "religion"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1PD53279",
        "rank": 7749
    },
    "T388": {
        "label": [
            {
                "@language": "en",
                "@value": "philosophy"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan nyid rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T388",
        "rank": 7754
    },
    "T3CN9711": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "伊斯兰"
            },
            {
                "@language": "en",
                "@value": "Islam"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kha che'i chos lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350"
    },
    "T1KG22572": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang mos ring lugs/"
            },
            {
                "@language": "en",
                "@value": "liberalism"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350"
    },
    "T1176": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khung tsi/"
            },
            {
                "@language": "en",
                "@value": "confucianism"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1176",
        "rank": 7759
    },
    "T1KG10156": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsod sgrub rig pa/"
            },
            {
                "@language": "en",
                "@value": "dialectics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1KG10156",
        "rank": 7774
    },
    "T1971": {
        "label": [
            {
                "@language": "en",
                "@value": "atheism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lha med smra ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1971",
        "rank": 7739
    },
    "T1KG21080": {
        "label": [
            {
                "@language": "en",
                "@value": "socialism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi tshogs ring lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1KG21080",
        "rank": 7769
    },
    "T1172": {
        "label": [
            {
                "@language": "en",
                "@value": "communism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gung khran ring lugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1172",
        "rank": 7744
    },
    "T1147": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "基督教"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ye shu/"
            },
            {
                "@language": "en",
                "@value": "christian"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350",
        "tbrcId": "T1147",
        "rank": 7764
    },
    "T1KG89007": {
        "label": [
            {
                "@language": "sa-deva",
                "@value": "हिन्दू धर्म"
            },
            {
                "@language": "en",
                "@value": "hinduism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "hin+du'i chos/"
            },
            {
                "@language": "zh-hans",
                "@value": "印度教"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228350"
    },
    "O9TAXTBRC2016052KG228291": {
        "sub": [
            "T371",
            "T1907",
            "T1KG20656",
            "T2384",
            "T00JR3375",
            "T294",
            "T1KG22038",
            "T2229",
            "T1KG6063",
            "T1151",
            "T8LS16960",
            "T2CZ5021",
            "T2622",
            "T1175"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srid don/"
            },
            {
                "@language": "en",
                "@value": "Politics"
            },
            {
                "@language": "zh-hans",
                "@value": "政治"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "1",
        "rank": 7455
    },
    "T371": {
        "label": [
            {
                "@language": "en",
                "@value": "public relations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi 'brel/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291"
    },
    "T1907": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lugs zung /"
            },
            {
                "@language": "en",
                "@value": "church and state"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T1907",
        "rank": 7500
    },
    "T1KG20656": {
        "label": [
            {
                "@language": "en",
                "@value": "democracy"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dmangs gtso/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T1KG20656",
        "rank": 7490
    },
    "T2384": {
        "label": {
            "@language": "en",
            "@value": "tibet (china)--officials and employees"
        },
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T2384",
        "rank": 7520
    },
    "T00JR3375": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lugs gnyis kyi bstan bcos/"
            },
            {
                "@language": "en",
                "@value": "(treatises on) the relation between temporal and spiritual matters"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T00JR3375",
        "rank": 7470
    },
    "T294": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsar brje/"
            },
            {
                "@language": "en",
                "@value": "cultural revolution"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T294",
        "rank": 7510
    },
    "T1KG22038": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'gro ba mi'i thob thang /"
            },
            {
                "@language": "en",
                "@value": "human rights"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T1KG22038",
        "rank": 7495
    },
    "T2229": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod rgya 'brel lam/"
            },
            {
                "@language": "en",
                "@value": "sino-tibetan relations"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291"
    },
    "T1KG6063": {
        "label": {
            "@language": "en",
            "@value": "poster -- chinese"
        },
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T1KG6063",
        "rank": 7515
    },
    "T1151": {
        "label": [
            {
                "@language": "en",
                "@value": "freedom struggle"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rang dbang 'thab rtsod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291"
    },
    "T8LS16960": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srid don/"
            },
            {
                "@language": "en",
                "@value": "polity"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T8LS16960",
        "rank": 7460
    },
    "T2CZ5021": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos srid/"
        },
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T2CZ5021",
        "rank": 7485
    },
    "T2622": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bod kyi chab srid/"
        },
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T2622",
        "rank": 7465
    },
    "T1175": {
        "label": {
            "@language": "en",
            "@value": "politics and government"
        },
        "parent": "O9TAXTBRC2016052KG228291",
        "tbrcId": "T1175",
        "rank": 7505
    },
    "O9TAXTBRC2016052KG228308": {
        "sub": [
            "T212"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod rig pa/"
            },
            {
                "@language": "en",
                "@value": "Tibetology"
            },
            {
                "@language": "zh-hans",
                "@value": "藏学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "3",
        "rank": 7537
    },
    "T212": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod rig pa/"
            },
            {
                "@language": "en",
                "@value": "tibetology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228308",
        "tbrcId": "T212",
        "rank": 7542
    },
    "O9TAXTBRC2016052KG228380": {
        "sub": [
            "T1756",
            "T1234",
            "T2278",
            "T2006",
            "T2039",
            "T00JR769",
            "T1CZ57",
            "T405",
            "T1626",
            "T1GS147806",
            "T1PD106852",
            "T2280",
            "T817",
            "T33",
            "T826",
            "T2279",
            "T1CZ26",
            "T2244",
            "T1775",
            "T2283",
            "T1PD106860",
            "T1171",
            "T4CZ16889",
            "T1PD96320",
            "T4CZ16890"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "方术"
            },
            {
                "@language": "en",
                "@value": "Method for examination/Appraisal and appreciation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brtags thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "17",
        "rank": 7861
    },
    "T1756": {
        "label": [
            {
                "@language": "en",
                "@value": "omens"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ltas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1756",
        "rank": 7905
    },
    "T1234": {
        "label": [
            {
                "@language": "en",
                "@value": "methods for examinations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brtag thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1234",
        "rank": 7866
    },
    "T2278": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mar me brtag pa/"
            },
            {
                "@language": "en",
                "@value": "examination of the flame of butter lamps"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2278",
        "rank": 7885
    },
    "T2006": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "metals and alloys in casting and their study"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "li ma brtag pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2006",
        "rank": 7950
    },
    "T2039": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'chi ba brtags pa/"
            },
            {
                "@language": "en",
                "@value": "method of determining death"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2039",
        "rank": 7955
    },
    "T00JR769": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skye rgyud lung bstan/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T00JR769",
        "rank": 7970
    },
    "T1CZ57": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmigs pa brtag pa/"
            },
            {
                "@language": "en",
                "@value": "text on reasoning by dignaga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380"
    },
    "T405": {
        "label": [
            {
                "@language": "en",
                "@value": "dice prognostication"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rno mthong /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T405",
        "rank": 7925
    },
    "T1626": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thod brtag"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1626",
        "rank": 7875
    },
    "T1GS147806": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa glang brtags tshul/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1GS147806",
        "rank": 7895
    },
    "T1PD106852": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byis pa'i rna pra/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1PD106852",
        "rank": 7935
    },
    "T2280": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gar skye brtags pa/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2280",
        "rank": 7900
    },
    "T817": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pra/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T817",
        "rank": 7940
    },
    "T33": {
        "label": [
            {
                "@language": "en",
                "@value": "prophecy"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ma 'ongs lung bstan/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T33",
        "rank": 7965
    },
    "T826": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rmi lam brtag pa/"
            },
            {
                "@language": "zh-hant",
                "@value": "占夢"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T826",
        "rank": 7870
    },
    "T2279": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe tshad brtag pa/"
            },
            {
                "@language": "en",
                "@value": "examination of signs of the length of life"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2279",
        "rank": 7890
    },
    "T1CZ26": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phreng mo/"
            },
            {
                "@language": "en",
                "@value": "system of divination using a rosary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1CZ26",
        "rank": 7910
    },
    "T2244": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'ju thig"
            },
            {
                "@language": "en",
                "@value": "knot prognostication"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380"
    },
    "T1775": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gyi gling brtag thabs/"
            },
            {
                "@language": "en",
                "@value": "criteria for horses"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380"
    },
    "T2283": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brtag ril bsgril ba/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T2283",
        "rank": 7915
    },
    "T1PD106860": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mo/"
            },
            {
                "@language": "en",
                "@value": "divination"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1PD106860",
        "rank": 7930
    },
    "T1171": {
        "label": [
            {
                "@language": "en",
                "@value": "prognostication"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mo dpe/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1171",
        "rank": 7920
    },
    "T4CZ16889": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mi dpyad/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T4CZ16889",
        "rank": 7945
    },
    "T1PD96320": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta dpyad/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T1PD96320",
        "rank": 7960
    },
    "T4CZ16890": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bya rog gi skad brtag pa/"
        },
        "parent": "O9TAXTBRC2016052KG228380",
        "tbrcId": "T4CZ16890",
        "rank": 7880
    },
    "O9TAXTBRC2016052KG228330": {
        "sub": [
            "T1977",
            "T2083",
            "T1148"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa khams/"
            },
            {
                "@language": "zh-hans",
                "@value": "地理"
            },
            {
                "@language": "en",
                "@value": "Geography"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "8",
        "rank": 7642
    },
    "T1977": {
        "label": [
            {
                "@language": "en",
                "@value": "land survey"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa zhib/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228330",
        "tbrcId": "T1977",
        "rank": 7652
    },
    "T2083": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "go la/"
        },
        "parent": "O9TAXTBRC2016052KG228330",
        "tbrcId": "T2083",
        "rank": 7657
    },
    "T1148": {
        "label": [
            {
                "@language": "en",
                "@value": "geography"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa khams rig pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "地理"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228330",
        "tbrcId": "T1148",
        "rank": 7647
    },
    "O9TAXTBRC2016052KG228374": {
        "sub": [
            "T2033",
            "T245"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lugs kyi bstan bcos/"
            },
            {
                "@language": "en",
                "@value": "Nitishastra"
            },
            {
                "@language": "zh-hans",
                "@value": "格言"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "15",
        "rank": 7829
    },
    "T2033": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lugs kyi bstan bcos/"
            },
            {
                "@language": "sa-x-iast",
                "@value": "nītiśāstra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228374",
        "tbrcId": "T2033",
        "rank": 7834
    },
    "T245": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "legs bshad/"
            },
            {
                "@language": "en",
                "@value": "didactic works"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228374",
        "tbrcId": "T245",
        "rank": 7839
    },
    "T10MS14648": {
        "label": [
            {
                "@language": "en",
                "@value": "survey"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhib bsher/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290"
    },
    "O9TAXTBRC2016052KG228321": {
        "sub": [
            "T1KG858",
            "T00KG0635",
            "T2208",
            "T2206",
            "T1939",
            "T1KG15970",
            "T1160",
            "T1184"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Economics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpal 'byor/"
            },
            {
                "@language": "zh-hans",
                "@value": "经济"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "7",
        "rank": 7606
    },
    "T1KG858": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal 'byor/"
            },
            {
                "@language": "en",
                "@value": "economy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T1KG858",
        "rank": 7611
    },
    "T00KG0635": {
        "label": {
            "@language": "en",
            "@value": "world trade organization­-china, china­-commercial policy"
        },
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T00KG0635",
        "rank": 7636
    },
    "T2208": {
        "label": [
            {
                "@language": "en",
                "@value": "commerce"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshong las/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321"
    },
    "T2206": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bal nyo tshong /"
            },
            {
                "@language": "en",
                "@value": "wool trading"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T2206",
        "rank": 7631
    },
    "T1939": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod yig dpe cha tshong ra/"
            },
            {
                "@language": "en",
                "@value": "tibetan book market"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T1939",
        "rank": 7626
    },
    "T1KG15970": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dngul/"
            },
            {
                "@language": "en",
                "@value": "money"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T1KG15970",
        "rank": 4023
    },
    "T1160": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dpal 'byor gong 'phel/"
            },
            {
                "@language": "en",
                "@value": "economic development"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T1160",
        "rank": 7616
    },
    "T1184": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas skor dpal 'byor rig pa/"
            },
            {
                "@language": "en",
                "@value": "tourism"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228321",
        "tbrcId": "T1184",
        "rank": 7621
    },
    "O9TAXTBRC2016052KG228368": {
        "sub": [
            "T1940"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gna' dpyad rig pa/"
            },
            {
                "@language": "en",
                "@value": "Archeology"
            },
            {
                "@language": "zh-hans",
                "@value": "考古"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "12",
        "rank": 7796
    },
    "T1940": {
        "label": [
            {
                "@language": "en",
                "@value": "archeology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gna' dpyad rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228368",
        "tbrcId": "T1940",
        "rank": 7801
    },
    "O9TAXTBRC2016052KG228310": {
        "sub": [
            "T2419"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "女性"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skyes ma/bud med/"
            },
            {
                "@language": "en",
                "@value": "Women"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228290",
        "tbrcId": "4",
        "rank": 7548
    },
    "T2419": {
        "label": [
            {
                "@language": "en",
                "@value": "women"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skyes ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228310",
        "tbrcId": "T2419",
        "rank": 7553
    },
    "O9TAXTBRC2016052KG226384": {
        "sub": [
            "O9TAXTBRC2016052KG226385",
            "O9TAXTBRC2016052KG226388",
            "O9TAXTBRC2016052KG226393",
            "O9TAXTBRC2016052KG226403"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtan tshigs rig pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "因明"
            },
            {
                "@language": "en",
                "@value": "Logic"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "nga",
        "rank": 3358
    },
    "O9TAXTBRC2016052KG226385": {
        "sub": [
            "T370",
            "T3CN2025"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226384"
    },
    "T370": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "量学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshad ma/"
            },
            {
                "@language": "en",
                "@value": "valid knowledge"
            },
            {
                "@language": "sa-x-iast",
                "@value": "Pramāṇa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226385",
        "tbrcId": "T370",
        "rank": 3368
    },
    "T3CN2025": {
        "label": [
            {
                "@language": "en",
                "@value": "indian logic works"
            },
            {
                "@language": "zh-hans",
                "@value": "印度因明典籍"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshad ma'i rgya gzhung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226385"
    },
    "O9TAXTBRC2016052KG226388": {
        "sub": [
            "T96",
            "T1CZ834",
            "T323",
            "T00JR3585"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsdus grwa/"
            },
            {
                "@language": "zh-hans",
                "@value": "摄类学"
            },
            {
                "@language": "en",
                "@value": "Specific Topics in Logic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226384",
        "tbrcId": "2",
        "rank": 3373
    },
    "T96": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsdus grwa/"
            },
            {
                "@language": "zh-hans",
                "@value": "摄类学"
            },
            {
                "@language": "en",
                "@value": "logic manual"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226388",
        "tbrcId": "T96",
        "rank": 3378
    },
    "T1CZ834": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtags gsal/"
            },
            {
                "@language": "en",
                "@value": "critical examination"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226388",
        "tbrcId": "T1CZ834",
        "rank": 3386
    },
    "T323": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan nyid/"
            },
            {
                "@language": "en",
                "@value": "definitive characteristic"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "lakṣaṇa"
            },
            {
                "@language": "zh-hans",
                "@value": "性相"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226388",
        "tbrcId": "T323",
        "rank": 3382
    },
    "T00JR3585": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ldog pa/"
        },
        "parent": "O9TAXTBRC2016052KG226388",
        "tbrcId": "T00JR3585",
        "rank": 3390
    },
    "O9TAXTBRC2016052KG226393": {
        "sub": [
            "T1422",
            "T2353",
            "T614",
            "T1420",
            "T2107",
            "T1247",
            "T1359",
            "T1133",
            "T1363"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "blo rigs/"
            },
            {
                "@language": "zh-hans",
                "@value": "心类学"
            },
            {
                "@language": "en",
                "@value": "Epistemology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226384",
        "tbrcId": "3",
        "rank": 3395
    },
    "T1422": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bem shes ldan min 'du byed gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1422",
        "rank": 3432
    },
    "T2353": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam shes ye shes kun gzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T2353",
        "rank": 3428
    },
    "T614": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sems/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "citta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T614",
        "rank": 3404
    },
    "T1420": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "blo rigs/"
            },
            {
                "@language": "en",
                "@value": "types of awareness"
            },
            {
                "@language": "zh-hans",
                "@value": "心类学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1420",
        "rank": 3400
    },
    "T2107": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam rig"
        },
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T2107",
        "rank": 3416
    },
    "T1247": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam rtog"
        },
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1247",
        "rank": 3420
    },
    "T1359": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sems sems byung /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "citta-caitta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1359",
        "rank": 3408
    },
    "T1133": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yid dang kun gzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1133",
        "rank": 3412
    },
    "T1363": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyon mongs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "klesa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226393",
        "tbrcId": "T1363",
        "rank": 3424
    },
    "O9TAXTBRC2016052KG226403": {
        "sub": [
            "T1235",
            "T1KG18568",
            "T1898",
            "T1KG605",
            "T00EGS1017604",
            "T2GS1330",
            "T1477",
            "T1896",
            "T2003",
            "T1899",
            "T2344",
            "T00EGS1017605",
            "T2024",
            "T1897"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtags rigs/"
            },
            {
                "@language": "en",
                "@value": "Types of Reasonings"
            },
            {
                "@language": "zh-hans",
                "@value": "因类学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226384",
        "tbrcId": "4",
        "rank": 3437
    },
    "T1235": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtags rigs/"
            },
            {
                "@language": "en",
                "@value": "types of reasons"
            },
            {
                "@language": "zh-hans",
                "@value": "因类学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1235",
        "rank": 3442
    },
    "T1KG18568": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kha shags/"
            },
            {
                "@language": "en",
                "@value": "jest"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1KG18568",
        "rank": 3482
    },
    "T1898": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mngon sum (tshad ma)"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1898",
        "rank": 3470
    },
    "T1KG605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsod zlog"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1KG605",
        "rank": 3494
    },
    "T00EGS1017604": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdul cha med/"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T00EGS1017604",
        "rank": 3454
    },
    "T2GS1330": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdzas/"
            },
            {
                "@language": "en",
                "@value": "substance"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T2GS1330",
        "rank": 3450
    },
    "T1477": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgag gzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1477",
        "rank": 3486
    },
    "T1896": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rang don (tshad ma)"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1896",
        "rank": 3462
    },
    "T2003": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyogs chos 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T2003",
        "rank": 3446
    },
    "T1899": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhan don (tshad ma)"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1899",
        "rank": 3466
    },
    "T2344": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thal bzlog"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T2344",
        "rank": 3490
    },
    "T00EGS1017605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skad cig cha med/"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T00EGS1017605",
        "rank": 3458
    },
    "T2024": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub ngag (tshad ma)"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T2024",
        "rank": 3474
    },
    "T1897": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshad ma grub pa/"
        },
        "parent": "O9TAXTBRC2016052KG226403",
        "tbrcId": "T1897",
        "rank": 3478
    },
    "O9TAXTBRC2016052KG228444": {
        "sub": [
            "O9TAXTBRC2016052KG228449",
            "O9TAXTBRC2016052KG228461",
            "O9TAXTBRC2016052KG228451",
            "O9TAXTBRC2016052KG228447",
            "O9TAXTBRC2016052KG228445",
            "O9TAXTBRC2016052KG228458",
            "O9TAXTBRC2016052KG228453"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "参考资料"
            },
            {
                "@language": "en",
                "@value": "Reference Works"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpyad gzhi'i rgyu cha/"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ba",
        "rank": 8110
    },
    "O9TAXTBRC2016052KG228449": {
        "sub": [
            "T1986"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ming mdzod/"
            },
            {
                "@language": "en",
                "@value": "Biographical dictionaries"
            },
            {
                "@language": "zh-hans",
                "@value": "传记宝库"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "3",
        "rank": 8133
    },
    "T1986": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ming mdzod/"
            },
            {
                "@language": "en",
                "@value": "biographical dictionaries"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228449",
        "tbrcId": "T1986",
        "rank": 8137
    },
    "O9TAXTBRC2016052KG228461": {
        "sub": [
            "T2550",
            "T1KG6223",
            "T2252",
            "T2MS14919",
            "T2328",
            "T2DB25490",
            "T2MS14705",
            "T1427",
            "T1CZ838",
            "T1PD108894",
            "T1168",
            "T2CZ10092",
            "T2CZ8059",
            "T2DB5092",
            "T1KG26414",
            "T2MS14704",
            "T2DB25444",
            "T2120",
            "T1PD95792",
            "T359",
            "T2GS1006",
            "T4CZ15582",
            "T2MS13270",
            "T2CZ10136",
            "T1245",
            "T2258",
            "T2389",
            "T1KG24263",
            "T2DB25443",
            "T00KG09827",
            "T2079",
            "T1113",
            "T2CZ10052",
            "T2DB25491",
            "T2080",
            "T818"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "地名"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa ming /"
            },
            {
                "@language": "en",
                "@value": "Place names"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "7",
        "rank": 8193
    },
    "T2550": {
        "label": [
            {
                "@language": "en",
                "@value": "charnal grounds"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dur khrod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2550",
        "rank": 8283
    },
    "T1KG6223": {
        "label": [
            {
                "@language": "en",
                "@value": "foreign"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyi rgyal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1KG6223",
        "rank": 4069
    },
    "T2252": {
        "label": [
            {
                "@language": "en",
                "@value": "places in tantric geography"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnas yul chen po/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461"
    },
    "T2MS14919": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grwa tshang /"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2MS14919",
        "rank": 8228
    },
    "T2328": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsas mkhar/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2328",
        "rank": 8273
    },
    "T2DB25490": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lcags zam/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2DB25490",
        "rank": 8324
    },
    "T2MS14705": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha khang /"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2MS14705",
        "rank": 8223
    },
    "T1427": {
        "label": [
            {
                "@language": "en",
                "@value": "prayer wheel"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ma Ni 'khor lo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1427",
        "rank": 8253
    },
    "T1CZ838": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chu tshan/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1CZ838",
        "rank": 8334
    },
    "T1PD108894": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzong gzhis/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1PD108894",
        "rank": 8278
    },
    "T1168": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbas yul/"
            },
            {
                "@language": "en",
                "@value": "hidden lands"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461"
    },
    "T2CZ10092": {
        "label": [
            {
                "@language": "en",
                "@value": "houses, groups of inhabitants"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tsho ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2CZ10092",
        "rank": 8339
    },
    "T2CZ8059": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo ring /"
            },
            {
                "@language": "en",
                "@value": "monument, stone pillar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2CZ8059",
        "rank": 8268
    },
    "T2DB5092": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa ming /"
            },
            {
                "@language": "en",
                "@value": "place names"
            },
            {
                "@language": "zh-hans",
                "@value": "地名"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2DB5092",
        "rank": 8198
    },
    "T1KG26414": {
        "label": [
            {
                "@language": "en",
                "@value": "world"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dzam gling /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461"
    },
    "T2MS14704": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshad grwa/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2MS14704",
        "rank": 8233
    },
    "T2DB25444": {
        "label": [
            {
                "@language": "en",
                "@value": "cairns"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lab rtse/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2DB25444",
        "rank": 8263
    },
    "T2120": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yul chen/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2120",
        "rank": 8314
    },
    "T1PD95792": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa khra/"
            },
            {
                "@language": "en",
                "@value": "map"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1PD95792",
        "rank": 8203
    },
    "T359": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dgon pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "寺院"
            },
            {
                "@language": "en",
                "@value": "monasteries"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T359",
        "rank": 8208
    },
    "T2GS1006": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnas/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2GS1006",
        "rank": 8299
    },
    "T4CZ15582": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'ol mo lung rings/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T4CZ15582",
        "rank": 8364
    },
    "T2MS13270": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "snow mountain"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gangs ri/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2MS13270",
        "rank": 8309
    },
    "T2CZ10136": {
        "label": [
            {
                "@language": "en",
                "@value": "forest"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nags tshal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2CZ10136",
        "rank": 8329
    },
    "T1245": {
        "label": [
            {
                "@language": "en",
                "@value": "nomadic groups"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'brog sde/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1245",
        "rank": 8344
    },
    "T2258": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtsug lag khang /"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2258",
        "rank": 8218
    },
    "T2389": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bang so/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2389",
        "rank": 8288
    },
    "T1KG24263": {
        "label": [
            {
                "@language": "en",
                "@value": "central school for tibetans"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhi rim slob grwa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461"
    },
    "T2DB25443": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma Ni khang /"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2DB25443",
        "rank": 8248
    },
    "T00KG09827": {
        "label": [
            {
                "@language": "en",
                "@value": "great prayer flag"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dar po che/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T00KG09827",
        "rank": 8258
    },
    "T2079": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kaM tshang sgar chen/"
            },
            {
                "@language": "en",
                "@value": "traveling encampment of the karma pa lama"
            },
            {
                "@language": "zh-hans",
                "@value": "噶玛大营寺"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2079",
        "rank": 8349
    },
    "T1113": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod/"
            },
            {
                "@language": "en",
                "@value": "tibet"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T1113",
        "rank": 8369
    },
    "T2CZ10052": {
        "label": [
            {
                "@language": "en",
                "@value": "nunnery"
            },
            {
                "@language": "zh-hans",
                "@value": "尼姑寺"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "btsun dgon/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461"
    },
    "T2DB25491": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zam pa/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2DB25491",
        "rank": 8319
    },
    "T2080": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub grwa/"
        },
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T2080",
        "rank": 8238
    },
    "T818": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchod rten/"
            },
            {
                "@language": "en",
                "@value": "stupa"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "stūpa"
            },
            {
                "@language": "zh-hans",
                "@value": "窣堵波"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228461",
        "tbrcId": "T818",
        "rank": 8243
    },
    "O9TAXTBRC2016052KG228451": {
        "sub": [
            "T297"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus deb/"
            },
            {
                "@language": "zh-hans",
                "@value": "刊物"
            },
            {
                "@language": "en",
                "@value": "Journals/Periodicals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "4",
        "rank": 8142
    },
    "T297": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dus deb/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "journals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228451",
        "tbrcId": "T297",
        "rank": 8147
    },
    "O9TAXTBRC2016052KG228447": {
        "sub": [
            "T1830"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes bya mdzod/"
            },
            {
                "@language": "en",
                "@value": "Encyclopedia"
            },
            {
                "@language": "zh-hans",
                "@value": "百科书"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "2",
        "rank": 8124
    },
    "T1830": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes bya mdzod/"
            },
            {
                "@language": "en",
                "@value": "encyclopedia"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228447",
        "tbrcId": "T1830",
        "rank": 8128
    },
    "O9TAXTBRC2016052KG228445": {
        "sub": [
            "T220"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshig mdzod/"
            },
            {
                "@language": "en",
                "@value": "Dictionary"
            },
            {
                "@language": "zh-hans",
                "@value": "词典"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "1",
        "rank": 8115
    },
    "T220": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshig mdzod/"
            },
            {
                "@language": "en",
                "@value": "dictionary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228445",
        "tbrcId": "T220",
        "rank": 8119
    },
    "O9TAXTBRC2016052KG228458": {
        "sub": [
            "T1KG15794",
            "T1KG15050"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lag deb/"
            },
            {
                "@language": "zh-hans",
                "@value": "手册"
            },
            {
                "@language": "en",
                "@value": "Handbooks"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "6",
        "rank": 8177
    },
    "T1KG15794": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lag deb/"
            },
            {
                "@language": "en",
                "@value": "handbook"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228458",
        "tbrcId": "T1KG15794",
        "rank": 8181
    },
    "T1KG15050": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "do dam lag deb/"
            },
            {
                "@language": "en",
                "@value": "management handbook"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228458",
        "tbrcId": "T1KG15050",
        "rank": 8187
    },
    "O9TAXTBRC2016052KG228453": {
        "sub": [
            "T2585",
            "T00KG03708",
            "T1983",
            "T8LS16963"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "News"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsar 'gyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "新闻"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228444",
        "tbrcId": "5",
        "rank": 8152
    },
    "T2585": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rlung 'phrin/"
            },
            {
                "@language": "en",
                "@value": "radio broadcasting"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228453",
        "tbrcId": "T2585",
        "rank": 8171
    },
    "T00KG03708": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas tshul/"
            },
            {
                "@language": "en",
                "@value": "news"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228453",
        "tbrcId": "T00KG03708",
        "rank": 8157
    },
    "T1983": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsar 'gyur rtsom rig"
            },
            {
                "@language": "en",
                "@value": "journalistic writing"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228453",
        "tbrcId": "T1983",
        "rank": 4859
    },
    "T8LS16963": {
        "label": [
            {
                "@language": "en",
                "@value": "newspaper"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshags shog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228453",
        "tbrcId": "T8LS16963",
        "rank": 8161
    },
    "O9TAXTBRC2016052KG226920": {
        "sub": [
            "O9TAXTBRC2016052KG226921",
            "O9TAXTBRC2016052KG227153",
            "O9TAXTBRC2016052KG227155"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung 'bum/"
            },
            {
                "@language": "zh-hans",
                "@value": "文集"
            },
            {
                "@language": "en",
                "@value": "Collected Writings"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "tha",
        "rank": 5851
    },
    "O9TAXTBRC2016052KG226921": {
        "sub": [
            "O9TAXTBRC2016052KG227123",
            "T208",
            "O9TAXTBRC2016052KG227143",
            "O9TAXTBRC2016052KG227145",
            "O9TAXTBRC2016052KG227150",
            "O9TAXTBRC2016052KG226927",
            "O9TAXTBRC2016052KG227130",
            "O9TAXTBRC2016052KG227069",
            "O9TAXTBRC2016052KG226923",
            "O9TAXTBRC2016052KG227082",
            "O9TAXTBRC2016052KG227059",
            "O9TAXTBRC2016052KG227137"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung 'bum/"
            },
            {
                "@language": "zh-hans",
                "@value": "文集"
            },
            {
                "@language": "en",
                "@value": "Collected Works"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226920",
        "tbrcId": "tha",
        "rank": 5851
    },
    "O9TAXTBRC2016052KG227123": {
        "sub": [
            "T383",
            "O9TAXTBRC2016052KG227125"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shangs pa/"
            },
            {
                "@language": "en",
                "@value": "Shangpa"
            },
            {
                "@language": "zh-hans",
                "@value": "香巴"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "6",
        "rank": 6795
    },
    "T383": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shangs pa bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227123",
        "tbrcId": "T383",
        "rank": 6800
    },
    "O9TAXTBRC2016052KG227125": {
        "sub": [
            "T1483",
            "T1825",
            "T1587",
            "T1479"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "shangs pa 'brel yod brjod bya/"
            },
            {
                "@language": "en",
                "@value": "shangpa related topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227123",
        "tbrcId": "4",
        "rank": 152
    },
    "T1483": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ni gu chos drug"
            },
            {
                "@language": "en",
                "@value": "six teachings of niguma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227125",
        "tbrcId": "T1483",
        "rank": 6809
    },
    "T1825": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "me tog mkha' spyod dkar dmar/"
        },
        "parent": "O9TAXTBRC2016052KG227125",
        "tbrcId": "T1825",
        "rank": 1991
    },
    "T1587": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "su kha chos drug"
        },
        "parent": "O9TAXTBRC2016052KG227125"
    },
    "T1479": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khyung po mgur brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG227125"
    },
    "T208": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung 'bum/"
            },
            {
                "@language": "en",
                "@value": "collected works"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "T208",
        "rank": 5859
    },
    "O9TAXTBRC2016052KG227143": {
        "sub": [
            "T1542"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "o rgyan bsnyen sgrub/rdo rje gsum gyi bsnyen sgrub/"
            },
            {
                "@language": "en",
                "@value": "Nyendrub"
            },
            {
                "@language": "zh-hans",
                "@value": "宁竹"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "9",
        "rank": 6895
    },
    "T1542": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "o rgyan bsnyen sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG227143",
        "tbrcId": "T1542",
        "rank": 6900
    },
    "O9TAXTBRC2016052KG227145": {
        "sub": [
            "O9TAXTBRC2016052KG227146",
            "O9TAXTBRC2016052KG227148"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phran tshegs/"
            },
            {
                "@language": "en",
                "@value": "Minor school"
            },
            {
                "@language": "zh-hans",
                "@value": "小派系"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "10",
        "rank": 6905
    },
    "O9TAXTBRC2016052KG227146": {
        "sub": [
            "T2054"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bo dong /"
            },
            {
                "@language": "zh-hans",
                "@value": "博东"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227145",
        "tbrcId": "1",
        "rank": 6910
    },
    "T2054": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bo dong /"
        },
        "parent": "O9TAXTBRC2016052KG227146",
        "tbrcId": "T2054",
        "rank": 6913
    },
    "O9TAXTBRC2016052KG227148": {
        "sub": [
            "T1198"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhwa lu/"
            },
            {
                "@language": "zh-hans",
                "@value": "夏鲁"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227145",
        "tbrcId": "2",
        "rank": 6919
    },
    "T1198": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhwa lu/"
        },
        "parent": "O9TAXTBRC2016052KG227148",
        "tbrcId": "T1198",
        "rank": 6922
    },
    "O9TAXTBRC2016052KG227150": {
        "sub": [
            "T808",
            "T1596"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ris med/"
            },
            {
                "@language": "en",
                "@value": "Non-sectarian"
            },
            {
                "@language": "zh-hans",
                "@value": "利美"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921"
    },
    "T808": {
        "label": [
            {
                "@language": "en",
                "@value": "Rimey/nonsectarian"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ris med/"
            },
            {
                "@language": "zh-hans",
                "@value": "利美｜无偏见"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227150",
        "tbrcId": "T808",
        "rank": 1542
    },
    "T1596": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal po bka' 'bum/"
        },
        "parent": "O9TAXTBRC2016052KG227150"
    },
    "O9TAXTBRC2016052KG226927": {
        "sub": [
            "O9TAXTBRC2016052KG226929",
            "O9TAXTBRC2016052KG227009",
            "O9TAXTBRC2016052KG227013",
            "O9TAXTBRC2016052KG226937",
            "O9TAXTBRC2016052KG227049",
            "O9TAXTBRC2016052KG227036",
            "O9TAXTBRC2016052KG227029",
            "T1082"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Nyingma"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnying ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "宁玛"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "2",
        "rank": 5884
    },
    "O9TAXTBRC2016052KG226929": {
        "sub": [
            "T1PD181203",
            "T1093",
            "T1193",
            "T1374",
            "T327",
            "T310",
            "T1370"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "佛语部"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgya che ba bka' ma/"
            },
            {
                "@language": "en",
                "@value": "Kama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "1",
        "rank": 5893
    },
    "T1PD181203": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byang gter bka' brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226929"
    },
    "T1093": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo dgongs 'dus/"
        },
        "parent": "O9TAXTBRC2016052KG226929"
    },
    "T1193": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226929"
    },
    "T1374": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad yongs 'dus/"
        },
        "parent": "O9TAXTBRC2016052KG226929",
        "tbrcId": "T1374",
        "rank": 5927
    },
    "T327": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad bde gshegs 'dus pa (nyang ral nyi ma 'od zer)"
        },
        "parent": "O9TAXTBRC2016052KG226929",
        "tbrcId": "T327",
        "rank": 5913
    },
    "T310": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' ma/"
        },
        "parent": "O9TAXTBRC2016052KG226929",
        "tbrcId": "T310",
        "rank": 5898
    },
    "T1370": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad bka' ma lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226929"
    },
    "O9TAXTBRC2016052KG227009": {
        "sub": [
            "T328",
            "T2604",
            "T463"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "mind treasure"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dgongs gter/"
            },
            {
                "@language": "zh-hans",
                "@value": "意藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "3",
        "rank": 6239
    },
    "T328": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong chen snying thig ('jigs med gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227009",
        "tbrcId": "T328",
        "rank": 6248
    },
    "T2604": {
        "label": [
            {
                "@language": "en",
                "@value": "mind treasure"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dgongs gter/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227009",
        "tbrcId": "T2604",
        "rank": 6244
    },
    "T463": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grub thob thugs tig"
        },
        "parent": "O9TAXTBRC2016052KG227009",
        "tbrcId": "T463",
        "rank": 6252
    },
    "O9TAXTBRC2016052KG227013": {
        "sub": [
            "T521",
            "T1792",
            "T1199",
            "T522",
            "T1AT305",
            "T1889",
            "T478",
            "T1712",
            "T1713",
            "T1098",
            "T1493",
            "T00EGS1016754",
            "T1375",
            "T1242",
            "T1243"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang gter/"
            },
            {
                "@language": "zh-hans",
                "@value": "北藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "4",
        "rank": 6257
    },
    "T521": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma longs sku rdzogs chen tshe dbang /"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T521",
        "rank": 6326
    },
    "T1792": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lcags sdong ma tshar lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1792",
        "rank": 6302
    },
    "T1199": {
        "label": [
            {
                "@language": "en",
                "@value": "northern revelations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "byang gter/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1199",
        "rank": 6261
    },
    "T522": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub lcags sdong ma (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T522",
        "rank": 6278
    },
    "T1AT305": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gcod yul dpa' bo'i thol glu/"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1AT305",
        "rank": 6306
    },
    "T1889": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgongs pa zang thal/"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1889",
        "rank": 6318
    },
    "T478": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum dril sgrub ('jam dbyangs bla ma)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T478",
        "rank": 6322
    },
    "T1712": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po 'gro ba kun grol (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1712",
        "rank": 6282
    },
    "T1713": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub drag po rtsal (byang gter)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1713",
        "rank": 6266
    },
    "T1098": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma rig 'dzin gdung sgrub (rgod ldem 'phru can)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1098",
        "rank": 6274
    },
    "T1493": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig 'dzin 'dus pa (rgod kyi ldem 'phru can)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1493",
        "rank": 6270
    },
    "T00EGS1016754": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen sprul sku snying thig (gar dbang rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T00EGS1016754",
        "rank": 6294
    },
    "T1375": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsam pa lhun grub (rgod ldem 'phru can)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1375",
        "rank": 6290
    },
    "T1242": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma rig 'dzin yongs 'dus (mnga' ris paN chen)"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1242",
        "rank": 6286
    },
    "T1243": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru sgrub thabs yon tan gter mdzod/"
        },
        "parent": "O9TAXTBRC2016052KG227013",
        "tbrcId": "T1243",
        "rank": 6298
    },
    "O9TAXTBRC2016052KG226937": {
        "sub": [
            "T01AG03033",
            "T515",
            "T01AG02947",
            "T1PD45091",
            "T1657",
            "T1183",
            "T1851",
            "T1395",
            "T01JR184",
            "T2305",
            "T00AG01243",
            "T2540",
            "T00AG01402",
            "T2MS11744",
            "T2134",
            "T1686",
            "T530",
            "T1809",
            "T1GS129611",
            "T813",
            "T1115",
            "T1833",
            "T557",
            "T2029",
            "T2597",
            "T00AG01370",
            "T01JR183",
            "T00AG01369",
            "T00AG01281",
            "T537",
            "T1GS129605",
            "T1308",
            "T01AG03059",
            "T1241",
            "T1390",
            "T1371",
            "T1810",
            "T1814",
            "T2CZ9234",
            "T2316",
            "T474",
            "T1GS124492",
            "T2313",
            "T315",
            "T471",
            "T1117",
            "T1325",
            "T00JR3354",
            "T485",
            "T2139",
            "T477",
            "T2596",
            "T539",
            "T2132",
            "T448",
            "T532",
            "T351",
            "T1339",
            "T1101",
            "T543",
            "T1097",
            "T2592",
            "T516",
            "T531",
            "T2312",
            "T1GS129612",
            "T470",
            "T1373",
            "T523",
            "T01AG03005",
            "T1096"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "伏藏部"
            },
            {
                "@language": "en",
                "@value": "Terma"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zab pa gter ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927"
    },
    "T01AG03033": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro gsang 'dus (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01AG03033",
        "rank": 6206
    },
    "T515": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma gsang 'dus (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T515",
        "rank": 5962
    },
    "T01AG02947": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lce btsun snying thig (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01AG02947",
        "rank": 6198
    },
    "T1PD45091": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub dregs pa tshar gcod (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1PD45091",
        "rank": 5954
    },
    "T1657": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po gsang ba 'dus pa ( rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1657",
        "rank": 6018
    },
    "T1183": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad gsang ba yongs rdzogs (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1183",
        "rank": 5950
    },
    "T1851": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun bzang dgongs 'dus (pad+ma gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1851",
        "rank": 6034
    },
    "T1395": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang drag 'bar ba ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1395",
        "rank": 5990
    },
    "T01JR184": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro snying thig (pad+ma las 'brel rtsal)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01JR184",
        "rank": 6050
    },
    "T2305": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "hUM skor snying thig (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2305",
        "rank": 6062
    },
    "T00AG01243": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bi ma snying thig"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T00AG01243",
        "rank": 5946
    },
    "T2540": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen 'khor ba las sgrol (zhig po gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2540",
        "rank": 6102
    },
    "T00AG01402": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong snying yang gsang bla sgrub thig le'i rgya can/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T00AG01402",
        "rank": 5998
    },
    "T2MS11744": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pad+ma snying thig"
            },
            {
                "@language": "en",
                "@value": "cycle of gter ma associated with Yeshe Tsogyal"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2MS11744",
        "rank": 6114
    },
    "T2134": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub thabs shes kha sbyor (yongs dge mi 'gyur rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2134",
        "rank": 6150
    },
    "T1686": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkon mchog spyi 'dus/ ('ja' tshon snying po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1686",
        "rank": 5986
    },
    "T530": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med rtsa gsum dril sgrub (chos rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T530",
        "rank": 6134
    },
    "T1809": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yi dam dgongs 'dus (bsam gtan gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1809",
        "rank": 6126
    },
    "T1GS129611": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru rdo rje drag rtsal (stag sham nus ldan rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1GS129611",
        "rank": 6118
    },
    "T813": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam chos dgongs pa yongs 'dus (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T813",
        "rank": 6158
    },
    "T1115": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma dgongs 'dus rtsa ba'i thugs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1115",
        "rank": 6074
    },
    "T1833": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma bdz+ra/ (yongs dge)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1833",
        "rank": 6146
    },
    "T557": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub gsang ba 'dus pa ( rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T557",
        "rank": 6002
    },
    "T2029": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyad thugs kyi me long ( pad+ma gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2029",
        "rank": 6030
    },
    "T2597": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa gnam lcags spu gri (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2597",
        "rank": 6170
    },
    "T00AG01370": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma drag po rta khyung 'bar ba ('jigs med gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T00AG01370",
        "rank": 6186
    },
    "T01JR183": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpal chen bka' 'dus rgya mtsho ('jigs med gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01JR183",
        "rank": 6182
    },
    "T00AG01369": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong chen snying thig gi bla ma rig 'dzin 'dus pa ('jigs med gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T00AG01369",
        "rank": 6190
    },
    "T00AG01281": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grol thig dgongs pa rang grol/"
        },
        "parent": "O9TAXTBRC2016052KG226937"
    },
    "T537": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma kun 'dus yid bzhin nro bu/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T537",
        "rank": 6078
    },
    "T1GS129605": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gro lod hUm sgrub (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1GS129605",
        "rank": 6166
    },
    "T1308": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig 'dzin byin 'bebs/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1308",
        "rank": 6234
    },
    "T01AG03059": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla sgrub 'od gsal snying thig (chos kyi blo gros)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01AG03059",
        "rank": 6230
    },
    "T1241": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub yang snying 'dus pa (rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1241",
        "rank": 6014
    },
    "T1390": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'ja' tshon pod drug"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1390",
        "rank": 5994
    },
    "T1371": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma drag po ye shes rab 'bar (klong gsal snying po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1371",
        "rank": 6098
    },
    "T1810": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma'i thugs sgrub nor bu rgya mtsho/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1810",
        "rank": 6046
    },
    "T1814": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum yongs rdzogs rdo rje gro lod (yong dge mi 'gyur rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1814",
        "rank": 6142
    },
    "T2CZ9234": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zab mo yang tig"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2CZ9234",
        "rank": 6082
    },
    "T2316": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma tshe yi snying thig (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2316",
        "rank": 6210
    },
    "T474": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum dril sgrub (kar lugs)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T474",
        "rank": 6226
    },
    "T1GS124492": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub rgyal ba 'dus pa (zhig po gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1GS124492",
        "rank": 6106
    },
    "T2313": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gtum po me lceng phreng ba (gu ru chos dbang)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2313",
        "rank": 5966
    },
    "T315": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po yang snying 'dus pa/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T315",
        "rank": 6054
    },
    "T471": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum dril sgrub (sangs rgyas bla ma)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T471",
        "rank": 5978
    },
    "T1117": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma bka' 'dus rin chen gter spungs (rdo rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1117",
        "rank": 6066
    },
    "T1325": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zab lam thugs kyi nor bu (chos rje gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1325",
        "rank": 6130
    },
    "T00JR3354": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong gsal mkha' 'gro snying thig"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T00JR3354",
        "rank": 6086
    },
    "T485": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum kun 'dus (zhig gling)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T485",
        "rank": 6110
    },
    "T2139": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma nor bu rgya mtsho ( pad+ma gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2139",
        "rank": 6038
    },
    "T477": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum 'bras bu ( rat+na gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T477",
        "rank": 6006
    },
    "T2596": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe yang phur gsum (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2596",
        "rank": 6162
    },
    "T539": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru'i mtshan brgya rtsa brgyad kyi bstod pa (klong gsal)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T539",
        "rank": 6094
    },
    "T2132": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rdo rje pha lam (yongs dge)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2132",
        "rank": 6138
    },
    "T448": {
        "label": [
            {
                "@language": "en",
                "@value": "rediscovered teachings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gter ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T448",
        "rank": 5936
    },
    "T532": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum spyi 'dus snying tig (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T532",
        "rank": 6202
    },
    "T351": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro dgongs pa rang grol/ ( karma gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T351",
        "rank": 5982
    },
    "T1339": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma dgongs 'dus (sangs rgyas gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1339",
        "rank": 6026
    },
    "T1101": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum thugs sgrub (sangs rgyas gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1101",
        "rank": 6022
    },
    "T543": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru'i gsol 'debs lam zab le'u bdun ma (kong sprul)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T543",
        "rank": 6222
    },
    "T1097": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma bde mchog 'khor lo (rgya ston pad+ma dbang phyug; mkhyen brtse'i yang gter)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1097",
        "rank": 6214
    },
    "T2592": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam chos sprul sku snying thig (bdud 'dul rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2592",
        "rank": 6154
    },
    "T516": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma yongs rdzogs (nyang ral)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T516",
        "rank": 5970
    },
    "T531": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum zab mo kun 'dus/"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T531",
        "rank": 6178
    },
    "T2312": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin yang phyung nag po (nyang ral)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T2312",
        "rank": 5974
    },
    "T1GS129612": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang thig snying po'i skor ('jam mgon kong sprul)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1GS129612",
        "rank": 6218
    },
    "T470": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gter gzhung /"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T470",
        "rank": 5941
    },
    "T1373": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje snying po (klong gsal snying po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1373",
        "rank": 6090
    },
    "T523": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub rdo rje phreng ba (pad gling lugs)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T523",
        "rank": 6042
    },
    "T01AG03005": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phrin las phur pa'i gnad tig (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T01AG03005",
        "rank": 6194
    },
    "T1096": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub bde gshegs 'dus pa (stag lung sangs rgyas dbon)"
        },
        "parent": "O9TAXTBRC2016052KG226937",
        "tbrcId": "T1096",
        "rank": 6070
    },
    "O9TAXTBRC2016052KG227049": {
        "sub": [
            "T2287",
            "T1AT302",
            "T00AG01273",
            "T805",
            "T1328",
            "T1GS129606",
            "T1119",
            "T00AG01246",
            "T00UNK58"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Visionary teachings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zab mo dag snang /"
            },
            {
                "@language": "zh-hans",
                "@value": "净见部"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "7",
        "rank": 6423
    },
    "T2287": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku gsum rigs 'dus zab tig (mkhyen brtse'i dbang po)"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T2287",
        "rank": 6452
    },
    "T1AT302": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snang bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T1AT302",
        "rank": 6447
    },
    "T00AG01273": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub yang snying kun 'dus (dag snang rgya can)"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T00AG01273",
        "rank": 6442
    },
    "T805": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dag snang /"
            },
            {
                "@language": "en",
                "@value": "visionary experiences"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T805",
        "rank": 6428
    },
    "T1328": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dag snang srin yul ma (rol pa'i rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T1328",
        "rank": 6437
    },
    "T1GS129606": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag po rdo rje gro lod (dag snang rgya can)"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T1GS129606",
        "rank": 6462
    },
    "T1119": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnam chos (mi 'gyur rdo rje)"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T1119",
        "rank": 6467
    },
    "T00AG01246": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dag snang rgya can/"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T00AG01246",
        "rank": 6432
    },
    "T00UNK58": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nam mkha' gsum/"
        },
        "parent": "O9TAXTBRC2016052KG227049",
        "tbrcId": "T00UNK58",
        "rank": 6457
    },
    "O9TAXTBRC2016052KG227036": {
        "sub": [
            "T2140",
            "T00AG01362",
            "T1486",
            "T553",
            "T012AG03489",
            "T00AG01363",
            "T1317",
            "T00AG01364",
            "T1861",
            "T00AG01324",
            "T1815",
            "T533"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gter gsar/"
            },
            {
                "@language": "zh-hans",
                "@value": "新藏"
            },
            {
                "@language": "en",
                "@value": "New rediscovered teachings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "6",
        "rank": 6361
    },
    "T2140": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang gsang thugs kyi phur gcig (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T2140",
        "rank": 6406
    },
    "T00AG01362": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs rje chen po pad+ma gtsug tor (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T00AG01362",
        "rank": 6394
    },
    "T1486": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma'i gsang thig dgongs 'dus (sangs rgyas dbang 'dus)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T1486",
        "rank": 6418
    },
    "T553": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma'i thugs sgrub yid bzhin nor bu bsam pa lhun grub/"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T553",
        "rank": 6414
    },
    "T012AG03489": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchog gling gter gsar/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "the collected revelations of mchog gyur gling pa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T012AG03489",
        "rank": 6370
    },
    "T00AG01363": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen 'khor ba dong sprugs (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T00AG01363",
        "rank": 6402
    },
    "T1317": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma'i thugs sgrub (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T1317",
        "rank": 6410
    },
    "T00AG01364": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zab pa skor bdun (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T00AG01364",
        "rank": 6378
    },
    "T1861": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma'i thugs sgrub bar chad kun sel (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T1861",
        "rank": 6382
    },
    "T00AG01324": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' rab rdo rje snying thig (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T00AG01324",
        "rank": 6374
    },
    "T1815": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub dgongs pa kun 'dus (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T1815",
        "rank": 6398
    },
    "T533": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa gsum tshe zab (mchog gling)"
        },
        "parent": "O9TAXTBRC2016052KG227036",
        "tbrcId": "T533",
        "rank": 6386
    },
    "O9TAXTBRC2016052KG227029": {
        "sub": [
            "T8LS16966",
            "T1GS129609",
            "T01AG03284",
            "T1321",
            "T00AG01245",
            "T01AG03281"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Southern rediscovered teachings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lho gter/"
            },
            {
                "@language": "zh-hans",
                "@value": "南藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "UNKNOWN",
        "rank": 6330
    },
    "T8LS16966": {
        "label": [
            {
                "@language": "en",
                "@value": "southern revelation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lho gter/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T8LS16966",
        "rank": 6336
    },
    "T1GS129609": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gu ru drag dmar (gter bdag gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T1GS129609",
        "rank": 6356
    },
    "T01AG03284": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag (lho gter lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T01AG03284",
        "rank": 6352
    },
    "T1321": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig 'dzin thugs thig (gter bdag gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T1321",
        "rank": 6348
    },
    "T00AG01245": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smin gling rdor sems/"
        },
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T00AG01245",
        "rank": 6344
    },
    "T01AG03281": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang chen gsang ba 'dus pa (gter bdag gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG227029",
        "tbrcId": "T01AG03281",
        "rank": 6340
    },
    "T1082": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnying ma/"
        },
        "parent": "O9TAXTBRC2016052KG226927",
        "tbrcId": "T1082",
        "rank": 5889
    },
    "O9TAXTBRC2016052KG227130": {
        "sub": [
            "T194",
            "O9TAXTBRC2016052KG227132"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhi byed/"
            },
            {
                "@language": "en",
                "@value": "Shije/Chod"
            },
            {
                "@language": "zh-hans",
                "@value": "希解和觉域"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "7",
        "rank": 6830
    },
    "T194": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gcod/"
        },
        "parent": "O9TAXTBRC2016052KG227130",
        "tbrcId": "T194",
        "rank": 6835
    },
    "O9TAXTBRC2016052KG227132": {
        "sub": [
            "T1GS135821",
            "T1GS135820",
            "T003JR4919",
            "T1GS107984"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhi byes gcod 'brel brjod bya/"
            },
            {
                "@language": "en",
                "@value": "Chod related topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227130",
        "tbrcId": "4",
        "rank": 152
    },
    "T1GS135821": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dur gcod/"
        },
        "parent": "O9TAXTBRC2016052KG227132",
        "tbrcId": "T1GS135821",
        "rank": 6849
    },
    "T1GS135820": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gcod yul nyong mongs zhi byed/"
        },
        "parent": "O9TAXTBRC2016052KG227132"
    },
    "T003JR4919": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gcod (bka' ma lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227132",
        "tbrcId": "T003JR4919",
        "rank": 6844
    },
    "T1GS107984": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "akasadhisthana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nam mkha' sgo 'byed/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227132"
    },
    "O9TAXTBRC2016052KG227069": {
        "sub": [
            "O9TAXTBRC2016052KG227070",
            "O9TAXTBRC2016052KG227076",
            "O9TAXTBRC2016052KG227078",
            "O9TAXTBRC2016052KG227080"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Sakya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa skya/"
            },
            {
                "@language": "zh-hans",
                "@value": "萨迦"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921"
    },
    "O9TAXTBRC2016052KG227070": {
        "sub": [
            "T617",
            "T1560",
            "T1PD110728",
            "T00UNK1",
            "T1GS138984"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Sakya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa skya/"
            },
            {
                "@language": "zh-hans",
                "@value": "萨系"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227069"
    },
    "T617": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa skya/"
        },
        "parent": "O9TAXTBRC2016052KG227070",
        "tbrcId": "T617",
        "rank": 6527
    },
    "T1560": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam sbas bshad/"
        },
        "parent": "O9TAXTBRC2016052KG227070",
        "tbrcId": "T1560",
        "rank": 6537
    },
    "T1PD110728": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam 'bras tshogs bshad/"
        },
        "parent": "O9TAXTBRC2016052KG227070",
        "tbrcId": "T1PD110728",
        "rank": 6542
    },
    "T00UNK1": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbas bshad/"
            },
            {
                "@language": "en",
                "@value": "esoteric description"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227070",
        "tbrcId": "T00UNK1",
        "rank": 6547
    },
    "T1GS138984": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam 'bras slob bshad/"
        },
        "parent": "O9TAXTBRC2016052KG227070",
        "tbrcId": "T1GS138984",
        "rank": 6532
    },
    "O9TAXTBRC2016052KG227076": {
        "sub": [
            "T8LS16958"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ngor pa/"
            },
            {
                "@language": "en",
                "@value": "Ngorpa"
            },
            {
                "@language": "zh-hans",
                "@value": "俄系"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227069"
    },
    "T8LS16958": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ngor/"
            },
            {
                "@language": "en",
                "@value": "ngor"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227076",
        "tbrcId": "T8LS16958",
        "rank": 6556
    },
    "O9TAXTBRC2016052KG227078": {
        "sub": [
            "T00AG02467"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshar pa/"
            },
            {
                "@language": "en",
                "@value": "Tsarpa"
            },
            {
                "@language": "zh-hans",
                "@value": "才系"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227069"
    },
    "T00AG02467": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshar pa'i lugs srol/"
        },
        "parent": "O9TAXTBRC2016052KG227078",
        "tbrcId": "T00AG02467",
        "rank": 6565
    },
    "O9TAXTBRC2016052KG227080": {
        "sub": [
            "T8LS16959"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdzong pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "宗巴"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227069",
        "tbrcId": "UNKNOWN",
        "rank": 6570
    },
    "T8LS16959": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdzong pa/"
            },
            {
                "@language": "en",
                "@value": "zongpa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227080",
        "tbrcId": "T8LS16959",
        "rank": 6574
    },
    "O9TAXTBRC2016052KG226923": {
        "sub": [
            "T247",
            "T1710",
            "T1KG22286"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bon/"
            },
            {
                "@language": "en",
                "@value": "Bon"
            },
            {
                "@language": "zh-hans",
                "@value": "苯波"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "1",
        "rank": 5863
    },
    "T247": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bon/"
            },
            {
                "@language": "zh-hans",
                "@value": "苯"
            },
            {
                "@language": "en",
                "@value": "bon"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226923",
        "tbrcId": "T247",
        "rank": 5868
    },
    "T1710": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bon gsar/"
            },
            {
                "@language": "en",
                "@value": "new bon"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226923",
        "tbrcId": "T1710",
        "rank": 5873
    },
    "T1KG22286": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' brten/"
            },
            {
                "@language": "en",
                "@value": "canonical works of bon"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226923",
        "tbrcId": "T1KG22286",
        "rank": 5878
    },
    "O9TAXTBRC2016052KG227082": {
        "sub": [
            "O9TAXTBRC2016052KG227089",
            "T807",
            "O9TAXTBRC2016052KG227087",
            "T2MS13223",
            "T1187",
            "T1682",
            "O9TAXTBRC2016052KG227112",
            "O9TAXTBRC2016052KG227118",
            "O9TAXTBRC2016052KG227116"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "噶举"
            },
            {
                "@language": "en",
                "@value": "Kagyue"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' brgyud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "5",
        "rank": 6580
    },
    "O9TAXTBRC2016052KG227089": {
        "sub": [
            "O9TAXTBRC2016052KG227097",
            "O9TAXTBRC2016052KG227108",
            "O9TAXTBRC2016052KG227110",
            "O9TAXTBRC2016052KG227095",
            "O9TAXTBRC2016052KG227106",
            "O9TAXTBRC2016052KG227093",
            "O9TAXTBRC2016052KG227104",
            "O9TAXTBRC2016052KG227091",
            "T1385"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phag gru bka' brgyud/"
            },
            {
                "@language": "zh-hans",
                "@value": "帕竹噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "2",
        "rank": 6617
    },
    "O9TAXTBRC2016052KG227097": {
        "sub": [
            "T816",
            "T3JT13056",
            "T10MS14532",
            "T3JT13055",
            "T1104",
            "T2447"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "竹巴噶举"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brug pa bka' brgyud/"
            },
            {
                "@language": "en",
                "@value": "Drukpa Kagyu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089"
    },
    "T816": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'brug pa dkar brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T816",
        "rank": 6660
    },
    "T3JT13056": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bar 'brug bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T3JT13056",
        "rank": 6665
    },
    "T10MS14532": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stod 'brug"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T10MS14532",
        "rank": 6671
    },
    "T3JT13055": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smad 'brug bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T3JT13055",
        "rank": 6677
    },
    "T1104": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'ba' ra bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T1104",
        "rank": 6689
    },
    "T2447": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma bdun bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227097",
        "tbrcId": "T2447",
        "rank": 6683
    },
    "O9TAXTBRC2016052KG227108": {
        "sub": [
            "T2MS13229"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "亚桑噶举"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "g.ya' bzang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089",
        "tbrcId": "7",
        "rank": 6716
    },
    "T2MS13229": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "g.ya' bzang bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227108",
        "tbrcId": "T2MS13229",
        "rank": 6720
    },
    "O9TAXTBRC2016052KG227110": {
        "sub": [
            "T2MS13227"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shug gseb/"
            },
            {
                "@language": "zh-hans",
                "@value": "修赛噶举"
            },
            {
                "@language": "en",
                "@value": "Shugseb Kagyue"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089"
    },
    "T2MS13227": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shug gseb bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227110",
        "tbrcId": "T2MS13227",
        "rank": 6730
    },
    "O9TAXTBRC2016052KG227095": {
        "sub": [
            "T2MS13225"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khro phu/"
            },
            {
                "@language": "en",
                "@value": "Trophu Kagyu"
            },
            {
                "@language": "zh-hans",
                "@value": "绰浦噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089"
    },
    "T2MS13225": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khro phu bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227095",
        "tbrcId": "T2MS13225",
        "rank": 6650
    },
    "O9TAXTBRC2016052KG227106": {
        "sub": [
            "T1186"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yel pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "叶尔巴噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089",
        "tbrcId": "6",
        "rank": 6706
    },
    "T1186": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yel pa bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227106",
        "tbrcId": "T1186",
        "rank": 6710
    },
    "O9TAXTBRC2016052KG227093": {
        "sub": [
            "T869"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "stag lung /"
            },
            {
                "@language": "zh-hans",
                "@value": "达隆噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089",
        "tbrcId": "2",
        "rank": 6636
    },
    "T869": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stag lung bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227093",
        "tbrcId": "T869",
        "rank": 6640
    },
    "O9TAXTBRC2016052KG227104": {
        "sub": [
            "T1678"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "玛仓噶举"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "smar pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089",
        "tbrcId": "5",
        "rank": 6696
    },
    "T1678": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smar pa bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227104",
        "tbrcId": "T1678",
        "rank": 6700
    },
    "O9TAXTBRC2016052KG227091": {
        "sub": [
            "T1190"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Drigung Kagyu"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bri gung /"
            },
            {
                "@language": "zh-hans",
                "@value": "直贡噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227089"
    },
    "T1190": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bri gung bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227091",
        "tbrcId": "T1190",
        "rank": 6630
    },
    "T1385": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag gru bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227089",
        "tbrcId": "T1385",
        "rank": 6621
    },
    "T807": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mar pa bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "T807",
        "rank": 6589
    },
    "O9TAXTBRC2016052KG227087": {
        "sub": [
            "T1384"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Barom Kagyu"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ba' rom bka' brgyud/"
            },
            {
                "@language": "zh-hans",
                "@value": "拔绒噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227082"
    },
    "T1384": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'ba' rom bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227087",
        "tbrcId": "T1384",
        "rank": 6611
    },
    "T2MS13223": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dwags po bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "T2MS13223",
        "rank": 6601
    },
    "T1187": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "T1187",
        "rank": 6585
    },
    "T1682": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rngog bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "T1682",
        "rank": 6595
    },
    "O9TAXTBRC2016052KG227112": {
        "sub": [
            "T1386",
            "T1KG8538",
            "T2446"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "噶玛噶举"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kaM tshang bka' brgyud/"
            },
            {
                "@language": "en",
                "@value": "Kamtshang Kagyu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227082"
    },
    "T1386": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "karma bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227112",
        "tbrcId": "T1386",
        "rank": 6741
    },
    "T1KG8538": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnas mdo bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227112",
        "tbrcId": "T1KG8538",
        "rank": 6752
    },
    "T2446": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zur mang bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227112",
        "tbrcId": "T2446",
        "rank": 6746
    },
    "O9TAXTBRC2016052KG227118": {
        "sub": [
            "T1269",
            "T1270",
            "T1268",
            "T1462"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' brgyud 'brel yod brjod bya/"
            },
            {
                "@language": "en",
                "@value": "kagyu related topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227082",
        "tbrcId": "4",
        "rank": 152
    },
    "T1269": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgongs gcig"
        },
        "parent": "O9TAXTBRC2016052KG227118",
        "tbrcId": "T1269",
        "rank": 6788
    },
    "T1270": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhan skyes rnam bzhi ('bri gung lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227118",
        "tbrcId": "T1270",
        "rank": 6783
    },
    "T1268": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen phyag rdzogs zung 'jug"
        },
        "parent": "O9TAXTBRC2016052KG227118",
        "tbrcId": "T1268",
        "rank": 6778
    },
    "T1462": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snyan gyi shog dril bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG227118",
        "tbrcId": "T1462",
        "rank": 6773
    },
    "O9TAXTBRC2016052KG227116": {
        "sub": [
            "T1387"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshal pa bka' brgyud/"
            },
            {
                "@language": "en",
                "@value": "tshalpa kagyu"
            },
            {
                "@language": "zh-hans",
                "@value": "采巴噶举"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227082"
    },
    "T1387": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshal pa bka' brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG227116",
        "tbrcId": "T1387",
        "rank": 6762
    },
    "O9TAXTBRC2016052KG227059": {
        "sub": [
            "O9TAXTBRC2016052KG227060",
            "O9TAXTBRC2016052KG227062",
            "O9TAXTBRC2016052KG227064"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' gdams/"
            },
            {
                "@language": "zh-hans",
                "@value": "噶当"
            },
            {
                "@language": "en",
                "@value": "Kadam and Gelug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "3",
        "rank": 6473
    },
    "O9TAXTBRC2016052KG227060": {
        "sub": [
            "T791"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' gdams/"
            },
            {
                "@language": "en",
                "@value": "Kadam"
            },
            {
                "@language": "zh-hans",
                "@value": "噶当"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227059",
        "tbrcId": "3",
        "rank": 6473
    },
    "T791": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' gdams/"
        },
        "parent": "O9TAXTBRC2016052KG227060",
        "tbrcId": "T791",
        "rank": 6478
    },
    "O9TAXTBRC2016052KG227062": {
        "sub": [
            "T819"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge lugs/"
            },
            {
                "@language": "en",
                "@value": "Gelug"
            },
            {
                "@language": "zh-hans",
                "@value": "格鲁"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227059"
    },
    "T819": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge lugs/"
        },
        "parent": "O9TAXTBRC2016052KG227062",
        "tbrcId": "T819",
        "rank": 6483
    },
    "O9TAXTBRC2016052KG227064": {
        "sub": [
            "T1244",
            "T1357",
            "T1349",
            "T1350"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brel yod brjod bya/"
            },
            {
                "@language": "en",
                "@value": "Special Topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227059",
        "tbrcId": "4",
        "rank": 152
    },
    "T1244": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmigs brtse ma/"
        },
        "parent": "O9TAXTBRC2016052KG227064",
        "tbrcId": "T1244",
        "rank": 6503
    },
    "T1357": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' ldan phyag chen/"
        },
        "parent": "O9TAXTBRC2016052KG227064"
    },
    "T1349": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thig le bcu drug"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sodasabindu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227064",
        "tbrcId": "T1349",
        "rank": 6498
    },
    "T1350": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha chos bdun ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227064",
        "tbrcId": "T1350",
        "rank": 6493
    },
    "O9TAXTBRC2016052KG227137": {
        "sub": [
            "T360",
            "O9TAXTBRC2016052KG227139"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbyor drug"
            },
            {
                "@language": "zh-hans",
                "@value": "觉囊"
            },
            {
                "@language": "en",
                "@value": "Sixfold Yoga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226921",
        "tbrcId": "8",
        "rank": 6865
    },
    "T360": {
        "label": [
            {
                "@language": "sa-x-ewts",
                "@value": "sadangayoga"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sbyor drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227137",
        "tbrcId": "T360",
        "rank": 6870
    },
    "O9TAXTBRC2016052KG227139": {
        "sub": [
            "T012AG03335",
            "T1255",
            "T1823"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sbyor drug 'brel yod brjod bya/"
            },
            {
                "@language": "en",
                "@value": "Jordruk related topics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227137",
        "tbrcId": "4",
        "rank": 152
    },
    "T012AG03335": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus 'khor (jo nang lugs)"
        },
        "parent": "O9TAXTBRC2016052KG227139",
        "tbrcId": "T012AG03335",
        "rank": 6889
    },
    "T1255": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "jo nang bka' babs drug ldan/"
        },
        "parent": "O9TAXTBRC2016052KG227139",
        "tbrcId": "T1255",
        "rank": 6879
    },
    "T1823": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbyor drug stan thog gcig ma/"
        },
        "parent": "O9TAXTBRC2016052KG227139",
        "tbrcId": "T1823",
        "rank": 6884
    },
    "O9TAXTBRC2016052KG227153": {
        "sub": [
            "T1KG21749"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung rtsom/"
            },
            {
                "@language": "en",
                "@value": "Selected Writings"
            },
            {
                "@language": "zh-hant",
                "@value": "著作"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226920",
        "tbrcId": "2",
        "rank": 6945
    },
    "T1KG21749": {
        "label": [
            {
                "@language": "en",
                "@value": "writings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsung rtsom/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227153",
        "tbrcId": "T1KG21749",
        "rank": 6950
    },
    "O9TAXTBRC2016052KG227155": {
        "sub": [
            "T413"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung thor bu/"
            },
            {
                "@language": "en",
                "@value": "Miscellaneous Writings"
            },
            {
                "@language": "zh-hans",
                "@value": "文选"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226920",
        "tbrcId": "3",
        "rank": 6955
    },
    "T413": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung thor bu/"
            },
            {
                "@language": "en",
                "@value": "miscellany"
            }
        ],
        "parent": "O9TAXTBRC2016052KG227155",
        "tbrcId": "T413",
        "rank": 6960
    },
    "O9TAXTBRC2016052KG228410": {
        "sub": [
            "O9TAXTBRC2016052KG228441",
            "O9TAXTBRC2016052KG228428",
            "O9TAXTBRC2016052KG228432",
            "O9TAXTBRC2016052KG228421",
            "O9TAXTBRC2016052KG228439",
            "O9TAXTBRC2016052KG228434",
            "O9TAXTBRC2016052KG228419",
            "O9TAXTBRC2016052KG228411",
            "O9TAXTBRC2016052KG228423",
            "O9TAXTBRC2016052KG228417"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang byung tshan rig"
            },
            {
                "@language": "en",
                "@value": "Natural Sciences"
            },
            {
                "@language": "zh-hans",
                "@value": "自然科学"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "pha",
        "rank": 7977
    },
    "O9TAXTBRC2016052KG228441": {
        "sub": [
            "T375",
            "T1KG22573"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe srog tshan rig"
            },
            {
                "@language": "zh-hans",
                "@value": "生命科学"
            },
            {
                "@language": "en",
                "@value": "Life sciences"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410"
    },
    "T375": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang byung rig pa/"
            },
            {
                "@language": "en",
                "@value": "natural sciences"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228441",
        "tbrcId": "pha",
        "rank": 7977
    },
    "T1KG22573": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe srog tshan rig"
            },
            {
                "@language": "en",
                "@value": "life sciences"
            },
            {
                "@language": "zh-hans",
                "@value": "生命科学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228441"
    },
    "O9TAXTBRC2016052KG228428": {
        "sub": [
            "T2378",
            "T2412",
            "T00KG010092"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phrul las bzo rig"
            },
            {
                "@language": "en",
                "@value": "Technology"
            },
            {
                "@language": "zh-hans",
                "@value": "技术"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "6",
        "rank": 8066
    },
    "T2378": {
        "label": [
            {
                "@language": "en",
                "@value": "science and technology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshan rig lag rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228428",
        "tbrcId": "T2378",
        "rank": 8071
    },
    "T2412": {
        "label": [
            {
                "@language": "en",
                "@value": "computer"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kam pu Tar/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228428",
        "tbrcId": "T2412",
        "rank": 8081
    },
    "T00KG010092": {
        "label": [
            {
                "@language": "en",
                "@value": "information technology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "cha 'phrin lag rtsal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228428",
        "tbrcId": "T00KG010092",
        "rank": 8076
    },
    "O9TAXTBRC2016052KG228432": {
        "sub": [
            "T8LS16961"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsi shing rig pa/"
            },
            {
                "@language": "en",
                "@value": "Botany"
            },
            {
                "@language": "zh-hans",
                "@value": "植物学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "7",
        "rank": 8087
    },
    "T8LS16961": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsi shing rig pa/"
            },
            {
                "@language": "en",
                "@value": "botany"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228432",
        "tbrcId": "T8LS16961",
        "rank": 8092
    },
    "O9TAXTBRC2016052KG228421": {
        "sub": [
            "T1137"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skye dngos rig pa/"
            },
            {
                "@language": "en",
                "@value": "Biology"
            },
            {
                "@language": "zh-hans",
                "@value": "生物学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "4",
        "rank": 8034
    },
    "T1137": {
        "label": [
            {
                "@language": "en",
                "@value": "biology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skye dngos rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228421",
        "tbrcId": "T1137",
        "rank": 8039
    },
    "O9TAXTBRC2016052KG228439": {
        "sub": [
            "T00EGS1016786"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod bya/"
            },
            {
                "@language": "en",
                "@value": "Related topic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "4",
        "rank": 152
    },
    "T00EGS1016786": {
        "label": [
            {
                "@language": "en",
                "@value": "buddhism and science"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nang chos dang tshan rig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228439",
        "tbrcId": "T00EGS1016786",
        "rank": 3741
    },
    "O9TAXTBRC2016052KG228434": {
        "sub": [
            "T1KG26410",
            "T1KG26412",
            "T1KG26411",
            "T8LS16962"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dud 'gro rig pa/"
            },
            {
                "@language": "en",
                "@value": "Zoology"
            },
            {
                "@language": "zh-hans",
                "@value": "动物学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "8",
        "rank": 8098
    },
    "T1KG26410": {
        "label": [
            {
                "@language": "en",
                "@value": "animals"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ri dwags dang gcan gzan/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228434"
    },
    "T1KG26412": {
        "label": [
            {
                "@language": "en",
                "@value": "birds"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dab chags/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228434"
    },
    "T1KG26411": {
        "label": [
            {
                "@language": "en",
                "@value": "domestic animal"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgo phyugs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228434"
    },
    "T8LS16962": {
        "label": [
            {
                "@language": "en",
                "@value": "zoology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dud 'gro rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228434",
        "tbrcId": "T8LS16962",
        "rank": 8103
    },
    "O9TAXTBRC2016052KG228419": {
        "sub": [
            "T1179"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Chemistry"
            },
            {
                "@language": "zh-hans",
                "@value": "化学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdzas sbyor rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "3",
        "rank": 8023
    },
    "T1179": {
        "label": [
            {
                "@language": "en",
                "@value": "chemistry"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdzas rig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228419",
        "tbrcId": "T1179",
        "rank": 8028
    },
    "O9TAXTBRC2016052KG228411": {
        "sub": [
            "T1174",
            "T1805",
            "T2131",
            "T1136",
            "T1135"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "数学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ang rtsis/"
            },
            {
                "@language": "en",
                "@value": "Mathematics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "1",
        "rank": 7982
    },
    "T1174": {
        "label": [
            {
                "@language": "en",
                "@value": "mathematics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ang rtsis/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228411",
        "tbrcId": "T1174",
        "rank": 7987
    },
    "T1805": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ang yig"
        },
        "parent": "O9TAXTBRC2016052KG228411",
        "tbrcId": "T1805",
        "rank": 8007
    },
    "T2131": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdel rtsis/"
            },
            {
                "@language": "en",
                "@value": "calculation using stones"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228411",
        "tbrcId": "T2131",
        "rank": 8002
    },
    "T1136": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshab rtsis/"
            },
            {
                "@language": "en",
                "@value": "algebra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228411",
        "tbrcId": "T1136",
        "rank": 7997
    },
    "T1135": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyibs rtsis/"
            },
            {
                "@language": "en",
                "@value": "geometry"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228411",
        "tbrcId": "T1135",
        "rank": 7992
    },
    "O9TAXTBRC2016052KG228423": {
        "sub": [
            "T1177",
            "T1123",
            "T2621",
            "T1629"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "so nam rig pa/zhing las/"
            },
            {
                "@language": "en",
                "@value": "Agriculture"
            },
            {
                "@language": "zh-hans",
                "@value": "农学"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "5",
        "rank": 8045
    },
    "T1177": {
        "label": [
            {
                "@language": "en",
                "@value": "agriculture"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhing las/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228423",
        "tbrcId": "T1177",
        "rank": 8050
    },
    "T1123": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhing /"
            },
            {
                "@language": "en",
                "@value": "agricultural field"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ksetra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228423",
        "tbrcId": "T1123",
        "rank": 8055
    },
    "T2621": {
        "label": [
            {
                "@language": "en",
                "@value": "tea cultivation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ja shing btab pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228423",
        "tbrcId": "T2621",
        "rank": 8060
    },
    "T1629": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lud sbyor/"
        },
        "parent": "O9TAXTBRC2016052KG228423",
        "tbrcId": "T1629",
        "rank": 3960
    },
    "O9TAXTBRC2016052KG228417": {
        "sub": [
            "T1138"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "物理学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dngos khams rig pa/"
            },
            {
                "@language": "en",
                "@value": "Physics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228410",
        "tbrcId": "2",
        "rank": 8012
    },
    "T1138": {
        "label": [
            {
                "@language": "en",
                "@value": "physics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dngos khams rig pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "物理"
            }
        ],
        "parent": "O9TAXTBRC2016052KG228417",
        "tbrcId": "T1138",
        "rank": 8017
    },
    "O9TAXTBRC2016052KG225383": {
        "sub": [
            "O9TAXTBRC2016052KG226371",
            "O9TAXTBRC2016052KG226334",
            "O9TAXTBRC2016052KG226273",
            "O9TAXTBRC2016052KG226378",
            "O9TAXTBRC2016052KG225715",
            "O9TAXTBRC2016052KG225923",
            "O9TAXTBRC2016052KG225384",
            "O9TAXTBRC2016052KG226275",
            "O9TAXTBRC2016052KG225391"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nang don rig pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "内明学"
            },
            {
                "@language": "en",
                "@value": "Buddhist Study"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ga",
        "rank": 256
    },
    "O9TAXTBRC2016052KG226371": {
        "sub": [
            "T901",
            "T1222",
            "T67",
            "T1146",
            "T1478",
            "T1PD110101"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Stotra/Hymns of praise"
            },
            {
                "@language": "zh-hans",
                "@value": "赞颂"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bstod tshogs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "8",
        "rank": 3334
    },
    "T901": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsngags pa/"
        },
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T901",
        "rank": 3343
    },
    "T1222": {
        "label": [
            {
                "@language": "en",
                "@value": "praise of a sacred place"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnas bstod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T1222",
        "rank": 3342
    },
    "T67": {
        "label": [
            {
                "@language": "en",
                "@value": "bsngags brjod"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bstod pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "讚"
            },
            {
                "@language": "sa-alalc97",
                "@value": "stotra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T67",
        "rank": 3340
    },
    "T1146": {
        "label": [
            {
                "@language": "en",
                "@value": "hymns"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bstod dbyangs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T1146",
        "rank": 3341
    },
    "T1478": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zin tho/"
        },
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T1478",
        "rank": 3344
    },
    "T1PD110101": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bstod tshogs/"
        },
        "parent": "O9TAXTBRC2016052KG226371",
        "tbrcId": "T1PD110101",
        "rank": 3339
    },
    "O9TAXTBRC2016052KG226334": {
        "sub": [
            "T1029",
            "T1732",
            "T10MS12837",
            "T304",
            "T1CZ4665",
            "T4JW5424",
            "T3CN11351",
            "T340",
            "T2398",
            "T00EGS1017673",
            "T10MS14464",
            "T61",
            "T29",
            "T250",
            "T1973",
            "T1372",
            "T1491",
            "T1431",
            "T012AG03703",
            "T1AT670",
            "T2377",
            "T2397",
            "T2608",
            "T11MS31",
            "T4CZ15086",
            "T1KG8787",
            "T11MS21",
            "T2339",
            "T3JT5054",
            "T1763",
            "T132",
            "T1488",
            "T1900",
            "T1CZ56",
            "T476",
            "T916"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa ba dang 'grel pa/"
            },
            {
                "@language": "en",
                "@value": "Root text and commentarie"
            },
            {
                "@language": "zh-hans",
                "@value": "根本论及注疏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383"
    },
    "T1029": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "bhāṣyam"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bshad pa/"
            },
            {
                "@language": "en",
                "@value": "explanation"
            },
            {
                "@language": "zh-hant",
                "@value": "講說"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1029",
        "rank": 3326
    },
    "T1732": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khri bshad/"
            },
            {
                "@language": "en",
                "@value": "speech on investiture"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1732",
        "rank": 3330
    },
    "T10MS12837": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dgongs 'grel/"
            },
            {
                "@language": "en",
                "@value": "commentary on the intent"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T10MS12837",
        "rank": 3302
    },
    "T304": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchan 'grel/"
            },
            {
                "@language": "en",
                "@value": "annotated commentary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T304",
        "rank": 3300
    },
    "T1CZ4665": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam dbye/"
            },
            {
                "@language": "en",
                "@value": "detailed analysis"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1CZ4665",
        "rank": 3314
    },
    "T4JW5424": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang 'grel/"
            },
            {
                "@language": "en",
                "@value": "autocommentary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T4JW5424",
        "rank": 3305
    },
    "T3CN11351": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag mchan/"
        },
        "parent": "O9TAXTBRC2016052KG226334"
    },
    "T340": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'chad thabs/"
            },
            {
                "@language": "en",
                "@value": "method of explaining"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T340",
        "rank": 3320
    },
    "T2398": {
        "label": [
            {
                "@language": "en",
                "@value": "summary"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "don bsdus/"
            },
            {
                "@language": "zh-hans",
                "@value": "概要"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T2398",
        "rank": 3316
    },
    "T00EGS1017673": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyan bdun/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T00EGS1017673",
        "rank": 3312
    },
    "T10MS14464": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ngo sprod/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T10MS14464",
        "rank": 3319
    },
    "T61": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam bshad/"
            },
            {
                "@language": "zh-hant",
                "@value": "論"
            },
            {
                "@language": "en",
                "@value": "detailed commentary"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vyākhyā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334"
    },
    "T29": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "maN+Dal bshad pa/"
            },
            {
                "@language": "en",
                "@value": "explanations of the mandala offering"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T29",
        "rank": 3328
    },
    "T250": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyen bshad/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T250",
        "rank": 3332
    },
    "T1973": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sho bshad/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1973",
        "rank": 3331
    },
    "T1372": {
        "label": [
            {
                "@language": "en",
                "@value": "survey"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khog dbub/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1372",
        "rank": 3318
    },
    "T1491": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dka' 'grel/"
            },
            {
                "@language": "zh-hant",
                "@value": "細疏"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "pañjikā"
            },
            {
                "@language": "en",
                "@value": "commentary on difficult points"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1491",
        "rank": 3301
    },
    "T1431": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyud sde spyi rnam/"
            },
            {
                "@language": "en",
                "@value": "tantra classification"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1431",
        "rank": 3311
    },
    "T012AG03703": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zur phyung /"
            },
            {
                "@language": "en",
                "@value": "supplement"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T012AG03703",
        "rank": 3322
    },
    "T1AT670": {
        "label": [
            {
                "@language": "en",
                "@value": "explanation and elucidation of crucial points and concepts"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnad 'gag gsal byed/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334"
    },
    "T2377": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ming tshig 'grel bshad/"
            },
            {
                "@language": "en",
                "@value": "vocabulary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T2377",
        "rank": 3315
    },
    "T2397": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshig 'grel/"
            },
            {
                "@language": "en",
                "@value": "word by word commentary"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T2397",
        "rank": 3304
    },
    "T2608": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbu zhwa mthong grol/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T2608",
        "rank": 3329
    },
    "T11MS31": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyas bshad/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T11MS31",
        "rank": 3309
    },
    "T4CZ15086": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rab 'byed/"
            },
            {
                "@language": "en",
                "@value": "fully able to separate. analyze, treatise, dissertation, accurate analysis."
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T4CZ15086",
        "rank": 3310
    },
    "T1KG8787": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa ba/"
            },
            {
                "@language": "en",
                "@value": "root text"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1KG8787",
        "rank": 4084
    },
    "T11MS21": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyas 'grel/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T11MS21",
        "rank": 3307
    },
    "T2339": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dka' gnas/"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T2339",
        "rank": 3317
    },
    "T3JT5054": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don 'grel/"
            },
            {
                "@language": "en",
                "@value": "commentary of meaning"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T3JT5054",
        "rank": 3306
    },
    "T1763": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "en",
                "@value": "exegesis of the general meaning"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1763",
        "rank": 3325
    },
    "T132": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "說疏"
            },
            {
                "@language": "en",
                "@value": "commentary"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'grel pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "ṭīkā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T132",
        "rank": 3299
    },
    "T1488": {
        "label": [
            {
                "@language": "en",
                "@value": "syllable by syllable commentary; the words commented on are usually marked with circles beneath"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'bru 'grel/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1488",
        "rank": 3303
    },
    "T1900": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sdom tshig"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1900",
        "rank": 3313
    },
    "T1CZ56": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnam bzhag"
            },
            {
                "@language": "en",
                "@value": "analysis"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T1CZ56",
        "rank": 3308
    },
    "T476": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa tshig"
        },
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T476",
        "rank": 3321
    },
    "T916": {
        "label": [
            {
                "@language": "en",
                "@value": "survey"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi bshad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226334",
        "tbrcId": "T916",
        "rank": 3324
    },
    "O9TAXTBRC2016052KG226273": {
        "sub": [
            "T447"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yig cha/"
            },
            {
                "@language": "en",
                "@value": "Monastic Curriculum"
            },
            {
                "@language": "zh-hans",
                "@value": "经院参考文献"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "5",
        "rank": 3230
    },
    "T447": {
        "label": [
            {
                "@language": "en",
                "@value": "obligatory syllabus"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yig cha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226273",
        "tbrcId": "T447",
        "rank": 3235
    },
    "O9TAXTBRC2016052KG226378": {
        "sub": [
            "T102",
            "T615",
            "T28",
            "T1KG18753",
            "T1765"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "问答"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dris lan dang dgag lan/"
            },
            {
                "@language": "en",
                "@value": "Question and response/polemic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "9",
        "rank": 3346
    },
    "T102": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brgal lan/"
            },
            {
                "@language": "en",
                "@value": "polemic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226378",
        "tbrcId": "T102",
        "rank": 3355
    },
    "T615": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhus lan/"
        },
        "parent": "O9TAXTBRC2016052KG226378",
        "tbrcId": "T615",
        "rank": 3352
    },
    "T28": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dris lan/"
            },
            {
                "@language": "en",
                "@value": "questions and responses"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226378",
        "tbrcId": "T28",
        "rank": 3351
    },
    "T1KG18753": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gleng gzhi/"
            },
            {
                "@language": "en",
                "@value": "topic of discussion"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226378",
        "tbrcId": "T1KG18753",
        "rank": 3354
    },
    "T1765": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dogs slong /"
        },
        "parent": "O9TAXTBRC2016052KG226378",
        "tbrcId": "T1765",
        "rank": 3353
    },
    "O9TAXTBRC2016052KG225715": {
        "sub": [
            "O9TAXTBRC2016052KG225725",
            "O9TAXTBRC2016052KG225717",
            "T2129",
            "O9TAXTBRC2016052KG225727",
            "O9TAXTBRC2016052KG225723",
            "O9TAXTBRC2016052KG225830"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyud/"
            },
            {
                "@language": "zh-hans",
                "@value": "密乘"
            },
            {
                "@language": "en",
                "@value": "Tantra Tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "3",
        "rank": 1605
    },
    "O9TAXTBRC2016052KG225725": {
        "sub": [
            "T971"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor rgyud/"
            },
            {
                "@language": "en",
                "@value": "Yoga Tantra"
            },
            {
                "@language": "zh-hans",
                "@value": "瑜伽部"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "3",
        "rank": 1642
    },
    "T971": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor rgyud/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "yogatantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225725",
        "tbrcId": "T971",
        "rank": 1647
    },
    "O9TAXTBRC2016052KG225717": {
        "sub": [
            "T147",
            "T1992",
            "T2395",
            "T1610",
            "T638"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bya rgyud/"
            },
            {
                "@language": "zh-hans",
                "@value": "事部"
            },
            {
                "@language": "en",
                "@value": "Kriya Tantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "1 ",
        "rank": 1614
    },
    "T147": {
        "label": [
            {
                "@language": "en",
                "@value": "ritual of fasting and silence"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "smyung gnas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225717",
        "tbrcId": "T147",
        "rank": 1627
    },
    "T1992": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bya rgyud/"
            },
            {
                "@language": "zh-hans",
                "@value": "事部"
            },
            {
                "@language": "en",
                "@value": "action tantra"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "kriyātantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225717",
        "tbrcId": "T1992",
        "rank": 1619
    },
    "T2395": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sadaksara"
            },
            {
                "@language": "en",
                "@value": "six-syllable mantra of avalokitesvara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "oM ma Ni pad+me hUM/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225717",
        "tbrcId": "T2395",
        "rank": 3786
    },
    "T1610": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma Ni phan yon/"
        },
        "parent": "O9TAXTBRC2016052KG225717",
        "tbrcId": "T1610",
        "rank": 3791
    },
    "T638": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje rnam 'joms rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225717",
        "tbrcId": "T638",
        "rank": 1623
    },
    "T2129": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyud/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "tantra"
            },
            {
                "@language": "en",
                "@value": "tantras"
            },
            {
                "@language": "zh-hant",
                "@value": "續"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "T2129",
        "rank": 1610
    },
    "O9TAXTBRC2016052KG225727": {
        "sub": [
            "O9TAXTBRC2016052KG225829",
            "T134",
            "T2DB4534",
            "T10MS14285",
            "T1976",
            "T2CN4716",
            "T1875",
            "T626",
            "T1281",
            "T528",
            "T1472",
            "T2095",
            "T1991",
            "T1561",
            "T1584",
            "T2GS1318",
            "T15",
            "T2618",
            "T307",
            "T1460",
            "T1PD109541",
            "T1283",
            "T1506",
            "T2461",
            "T2187",
            "T394",
            "T930",
            "T867",
            "T1914",
            "T1575",
            "T003JR4173",
            "T1GS129193",
            "T003JR4759",
            "T1801",
            "T1877",
            "T1691",
            "T2DB4533",
            "T1120",
            "T2232",
            "T1975",
            "T1874",
            "T1280",
            "T309",
            "T1538",
            "T1547",
            "T1369",
            "T2599",
            "T356",
            "T631",
            "T2MS13266",
            "T202",
            "T32",
            "T1955",
            "T2564",
            "T2MS15107",
            "T633",
            "T1389",
            "T11MS4",
            "T1989",
            "T2063",
            "T466",
            "T4CZ15623",
            "T616",
            "T288",
            "T439",
            "T1294",
            "T10MS11132",
            "T1592",
            "T1264",
            "T2179",
            "T8LS15536",
            "T1876",
            "T00JR397",
            "T2245",
            "T00KG03596",
            "T2096",
            "T1KG1285",
            "T2561",
            "T00AG0341",
            "T163",
            "T2GS1319",
            "T865",
            "T1336",
            "T4CZ2413",
            "T1CZ30",
            "T1878",
            "T629",
            "T1676",
            "T2563",
            "T969",
            "T2086",
            "T4CZ15394",
            "T325",
            "T1880",
            "T623",
            "T00UNK2",
            "T1606",
            "T803",
            "T1576",
            "T1562",
            "T11MS3",
            "T2092"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "无上瑜伽部"
            },
            {
                "@language": "en",
                "@value": "Maha Yoga"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor bla med rgyud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "4",
        "rank": 1653
    },
    "O9TAXTBRC2016052KG225829": {
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T134": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang dag par sbyor ba/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T134",
        "rank": 1668
    },
    "T2DB4534": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a ro'i rdzogs chen/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2DB4534",
        "rank": 1748
    },
    "T10MS14285": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje'i tshig rkang /"
            },
            {
                "@language": "en",
                "@value": "vajra verses"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T10MS14285",
        "rank": 1716
    },
    "T1976": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyis med rgyud (bla med rgyud)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1976",
        "rank": 1660
    },
    "T2CN4716": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang bdag snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2CN4716",
        "rank": 1692
    },
    "T1875": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "man ngag sde/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1875",
        "rank": 1675
    },
    "T626": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "cakrasamvara tantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog rgyud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T626",
        "rank": 1662
    },
    "T1281": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog snyan brgyud yab bka' ras chung lugs/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1281",
        "rank": 1685
    },
    "T528": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma rgyud snying po don gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T528",
        "rank": 1722
    },
    "T1472": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnal 'byor lnga pa/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1472",
        "rank": 1704
    },
    "T2095": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnal 'byor bcu gcig pa/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2095",
        "rank": 1744
    },
    "T1991": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "he ru ka'i rigs kyi rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1991",
        "rank": 1661
    },
    "T1561": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brda' don gsal ba/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1561",
        "rank": 4098
    },
    "T1584": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen ga'u ma/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1584",
        "rank": 1733
    },
    "T2GS1318": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gcod yul bdud bzhi/"
            },
            {
                "@language": "en",
                "@value": "four demons of the gcod system"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2GS1318",
        "rank": 1731
    },
    "T15": {
        "label": [
            {
                "@language": "en",
                "@value": "transferrence"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'pho ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samkranti"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T15",
        "rank": 1713
    },
    "T2618": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen rnal 'byor bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2618",
        "rank": 1702
    },
    "T307": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang sngags/"
            },
            {
                "@language": "en",
                "@value": "tantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T1460": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla med rgyud/"
            },
            {
                "@language": "sa-x-iast",
                "@value": "anuttarayoga tantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T1PD109541": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a ti yo ga"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1PD109541",
        "rank": 1671
    },
    "T1283": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen lhan cig skyes sbyor ('ba' ra lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1283",
        "rank": 1687
    },
    "T1506": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten 'brel snying po/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1506",
        "rank": 1730
    },
    "T2461": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "a nu yo ga"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2461",
        "rank": 1672
    },
    "T2187": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma rgyud (bla med rgyud)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2187",
        "rank": 1664
    },
    "T394": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nA ro chos drug"
            },
            {
                "@language": "en",
                "@value": "six yogas of nāropa (nA ro pa)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T394",
        "rank": 1720
    },
    "T930": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thang stong snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T930",
        "rank": 1691
    },
    "T867": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vidyā"
            },
            {
                "@language": "en",
                "@value": "tantric incantations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rig sngags/"
            },
            {
                "@language": "zh-hans",
                "@value": "明咒"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T1914": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ras chung snyan rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1914",
        "rank": 1688
    },
    "T1575": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dril bu rim lnga /"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1575",
        "rank": 1670
    },
    "T003JR4173": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud bcu bdun/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T003JR4173",
        "rank": 1682
    },
    "T1GS129193": {
        "label": [
            {
                "@language": "en",
                "@value": "verbal marks or signs used in practice"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brda rtags/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1GS129193",
        "rank": 1725
    },
    "T003JR4759": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzong 'phrang /"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T003JR4759",
        "rank": 1676
    },
    "T1801": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnal 'byor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1801",
        "rank": 1701
    },
    "T1877": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shin tu spros med/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1877",
        "rank": 1698
    },
    "T1691": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rlung /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vayu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1691",
        "rank": 1738
    },
    "T2DB4533": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong sde/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2DB4533",
        "rank": 1673
    },
    "T1120": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje'i rnal 'byor/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1120",
        "rank": 1703
    },
    "T2232": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phag mo zab rgya/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2232",
        "rank": 1695
    },
    "T1975": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pha rgyud (bla med rgyud)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1975",
        "rank": 1663
    },
    "T1874": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma ha yo ga"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1874",
        "rank": 1659
    },
    "T1280": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog snyan brgyud (ngam rdzong lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1280",
        "rank": 1686
    },
    "T309": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig 'dzin sngags kyi sde snod/"
        },
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T1538": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum mo/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1538",
        "rank": 1739
    },
    "T1547": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtum mo lam rdzogs/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1547",
        "rank": 1734
    },
    "T1369": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong sde rdo rje zam pa/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1369",
        "rank": 1677
    },
    "T2599": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klong dgu/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2599",
        "rank": 1727
    },
    "T356": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rmi lam/"
            },
            {
                "@language": "en",
                "@value": "dream experiences"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "svapna"
            },
            {
                "@language": "zh-hant",
                "@value": "夢"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T356",
        "rank": 3901
    },
    "T631": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kye rdor rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T631",
        "rank": 1666
    },
    "T2MS13266": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang spyod snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2MS13266",
        "rank": 1690
    },
    "T202": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang snying /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyagarbha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727"
    },
    "T32": {
        "label": [
            {
                "@language": "en",
                "@value": "nine vehicles"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "theg pa rim pa dgu/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T32",
        "rank": 1721
    },
    "T1955": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "pho nya/"
            },
            {
                "@language": "en",
                "@value": "ritual messengers"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1955",
        "rank": 3941
    },
    "T2564": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'od gsal/"
            },
            {
                "@language": "en",
                "@value": "luminosity"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2564",
        "rank": 1740
    },
    "T2MS15107": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'ja' lus/"
            },
            {
                "@language": "en",
                "@value": "rainbow body"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2MS15107",
        "rank": 1724
    },
    "T633": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus 'khor rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T633",
        "rank": 1667
    },
    "T1389": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thod rgal/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1389",
        "rank": 1710
    },
    "T11MS4": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ring brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T11MS4",
        "rank": 1694
    },
    "T1989": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnal 'byor ma'i rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1989",
        "rank": 1665
    },
    "T2063": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khor 'das dbyer med/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2063",
        "rank": 1750
    },
    "T466": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "na rak dong sprugs/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T466",
        "rank": 1711
    },
    "T4CZ15623": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnying rgyud/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "the Nyingma tantras"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T4CZ15623",
        "rank": 1678
    },
    "T616": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor bzhi/"
            },
            {
                "@language": "en",
                "@value": "our yogas"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T616",
        "rank": 1706
    },
    "T288": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "yantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'phrul 'khor/"
            },
            {
                "@language": "en",
                "@value": "yogic postures"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T288",
        "rank": 1712
    },
    "T439": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "yoga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T439",
        "rank": 1705
    },
    "T1294": {
        "label": [
            {
                "@language": "en",
                "@value": "mental class"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sems sde/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1294",
        "rank": 1674
    },
    "T10MS11132": {
        "label": [
            {
                "@language": "en",
                "@value": "oral transmission of cakrasamvara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bde mchog snyan brgyud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T10MS11132",
        "rank": 1683
    },
    "T1592": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsre 'pho/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1592",
        "rank": 1743
    },
    "T1264": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gegs sel gzer lnga /"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1264",
        "rank": 1717
    },
    "T2179": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rim pa bzhi pa (bde mchog)"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2179",
        "rank": 1684
    },
    "T8LS15536": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zla gsang thig le/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T8LS15536",
        "rank": 1669
    },
    "T1876": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spros bcas/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1876",
        "rank": 1696
    },
    "T00JR397": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grong 'jug"
            },
            {
                "@language": "en",
                "@value": "the practice of transferring one's consciousness into another body"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T00JR397",
        "rank": 1714
    },
    "T2245": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2245",
        "rank": 1697
    },
    "T00KG03596": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thabs lam/"
            },
            {
                "@language": "en",
                "@value": "technique"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T00KG03596",
        "rank": 3881
    },
    "T2096": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "langka li/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2096",
        "rank": 1726
    },
    "T1KG1285": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgyu 'phrul/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "maya"
            },
            {
                "@language": "en",
                "@value": "magical display"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1KG1285",
        "rank": 3851
    },
    "T2561": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde stong /"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2561",
        "rank": 1745
    },
    "T00AG0341": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brgyud pa gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T00AG0341",
        "rank": 1709
    },
    "T163": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdzogs rim/"
            },
            {
                "@language": "en",
                "@value": "completion stage, perfection stage, fulfilment stage"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sampannakrama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T163",
        "rank": 1719
    },
    "T2GS1319": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bdud rtsi/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "amṛta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2GS1319",
        "rank": 3911
    },
    "T865": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen yi ge bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T865",
        "rank": 1732
    },
    "T1336": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje theg pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajrayāna"
            },
            {
                "@language": "zh-hant",
                "@value": "金剛乘"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1336",
        "rank": 1658
    },
    "T4CZ2413": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spros med/"
            },
            {
                "@language": "en",
                "@value": "unelaborate, (empowerment)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T4CZ2413",
        "rank": 1699
    },
    "T1CZ30": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bskyed rdzogs/"
            },
            {
                "@language": "en",
                "@value": "stages, utpatti and sampannakrama; development and completion"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1CZ30",
        "rank": 1718
    },
    "T1878": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rab tu spros med/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1878",
        "rank": 1700
    },
    "T629": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "o la pa ti/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T629",
        "rank": 1735
    },
    "T1676": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ngam rdzong snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1676",
        "rank": 1689
    },
    "T2563": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgyu lus/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2563",
        "rank": 1715
    },
    "T969": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag rgya/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mudrā"
            },
            {
                "@language": "zh-hans",
                "@value": "手印"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T969",
        "rank": 1742
    },
    "T2086": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rgya bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2086",
        "rank": 1708
    },
    "T4CZ15394": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zung 'jug"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T4CZ15394",
        "rank": 1746
    },
    "T325": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa rlung thig le/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T325",
        "rank": 1737
    },
    "T1880": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang lam/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1880",
        "rank": 1747
    },
    "T623": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smon lam dbang bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T623",
        "rank": 1736
    },
    "T00UNK2": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje lus/"
            },
            {
                "@language": "en",
                "@value": "vajra body"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T00UNK2",
        "rank": 1723
    },
    "T1606": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snyan brgyud steng sgo chos drug"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1606",
        "rank": 1729
    },
    "T803": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'dus pa mdo/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T803",
        "rank": 1741
    },
    "T1576": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nag po rim bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1576",
        "rank": 1680
    },
    "T1562": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang 'dus rim lnga /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pancakrama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T1562",
        "rank": 1681
    },
    "T11MS3": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nye brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T11MS3",
        "rank": 1693
    },
    "T2092": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "e wam/"
        },
        "parent": "O9TAXTBRC2016052KG225727",
        "tbrcId": "T2092",
        "rank": 1728
    },
    "O9TAXTBRC2016052KG225723": {
        "sub": [
            "T2186"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyod rgyud/"
            },
            {
                "@language": "en",
                "@value": "Upa Tantra"
            },
            {
                "@language": "zh-hans",
                "@value": "行部"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "2",
        "rank": 1632
    },
    "T2186": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyod rgyud/"
            },
            {
                "@language": "en",
                "@value": "caryatantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225723",
        "tbrcId": "T2186",
        "rank": 1637
    },
    "O9TAXTBRC2016052KG225830": {
        "sub": [
            "T1471",
            "T839",
            "T524",
            "T1248",
            "T80",
            "T627",
            "T1CZ29",
            "T00KG02667",
            "T968",
            "T1GS118689",
            "T164",
            "T1GS145346",
            "T1461",
            "T1GS118690",
            "T2135",
            "T475",
            "T1380",
            "T1381",
            "T837",
            "T1213",
            "T2422",
            "T1GS138983",
            "T1848",
            "T012AG03391",
            "T1AT414",
            "T552",
            "T1GS124889",
            "T159",
            "T540",
            "T546",
            "T1GS138982",
            "T2603",
            "T1993",
            "T2315",
            "T1216",
            "T840",
            "T812",
            "T5",
            "T1GS128714",
            "T1068",
            "T1GS147807",
            "T1408",
            "T27",
            "T877",
            "T1181",
            "T00JR2912",
            "T2594",
            "T1AT470",
            "T4",
            "T1318",
            "T1286",
            "T151",
            "T2043",
            "T316",
            "T1465",
            "T317",
            "T1094",
            "T1747",
            "T453",
            "T2168",
            "T1494",
            "T2MS13267",
            "T457",
            "T1",
            "T1CZ48",
            "T1388",
            "T1685",
            "T874",
            "T00UNK53",
            "T1CZ11",
            "T1CZ63",
            "T1476",
            "T1265",
            "T1266",
            "T1464",
            "T1824",
            "T1AT473",
            "T1382",
            "T01AG02845",
            "T1214",
            "T1191",
            "T2297",
            "T957",
            "T555",
            "T1099",
            "T2308",
            "T2371",
            "T01AG03135",
            "T003JR4608",
            "T468",
            "T1425",
            "T1GS145344"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Sadhanas and Mandalas"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgrub dkyil/"
            },
            {
                "@language": "zh-hans",
                "@value": "修法及坛城"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225715",
        "tbrcId": "5",
        "rank": 1752
    },
    "T1471": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpa' bo 'bru gcig pa/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1471",
        "rank": 1825
    },
    "T839": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgra mi snyan gyi tshe sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T839",
        "rank": 1765
    },
    "T524": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med dpal ster tshe sgrub (thang stong lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T524",
        "rank": 1767
    },
    "T1248": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub pa/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1248",
        "rank": 1805
    },
    "T80": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkyil 'khor/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "maṇḍala"
            },
            {
                "@language": "zh-hant",
                "@value": "曼荼羅"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T80",
        "rank": 1763
    },
    "T627": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sngags btu/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T627",
        "rank": 1831
    },
    "T1CZ29": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1CZ29",
        "rank": 1816
    },
    "T00KG02667": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsnyen sgrub las gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T00KG02667",
        "rank": 1829
    },
    "T968": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub dkyil/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T968",
        "rank": 1757
    },
    "T1GS118689": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS118689",
        "rank": 1786
    },
    "T164": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bskyed rim/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "utpattikrama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T164",
        "rank": 1783
    },
    "T1GS145346": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nam mkha'i zas su za ba/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS145346",
        "rank": 1775
    },
    "T1461": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rngog dkyil bdun/"
            },
            {
                "@language": "en",
                "@value": "seven mandalas of the rngog tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1461",
        "rank": 1800
    },
    "T1GS118690": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpa' bo rkyang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS118690",
        "rank": 1828
    },
    "T2135": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lus sbyin/"
            },
            {
                "@language": "zh-hans",
                "@value": "施身法"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2135",
        "rank": 1804
    },
    "T475": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmigs rim/"
            },
            {
                "@language": "en",
                "@value": "stages of a given visualization"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T475",
        "rank": 1784
    },
    "T1380": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bru gsum rdor bzlas/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1380",
        "rank": 1823
    },
    "T1381": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzungs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1381",
        "rank": 1792
    },
    "T837": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub zhag bdun ma (rong ston lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T1213": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs dkyil/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1213",
        "rank": 1764
    },
    "T2422": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha mo'i sgrub mchod (thim phu)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2422",
        "rank": 1819
    },
    "T1GS138983": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam zab nang ma/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS138983",
        "rank": 1795
    },
    "T1848": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzungs kyi sgra bsgrubs/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1848",
        "rank": 1789
    },
    "T012AG03391": {
        "label": [
            {
                "@language": "en",
                "@value": "the six syllable mantra of avalokitesvara as transmitted by thang stong rgyal po"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yi ge drug (thang rgyal lugs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T012AG03391",
        "rank": 1787
    },
    "T1AT414": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dngos grub blang ba/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1AT414",
        "rank": 1791
    },
    "T552": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lus dkyil/"
            },
            {
                "@language": "en",
                "@value": "kayamandala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T552",
        "rank": 1798
    },
    "T1GS124889": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zas kyi rnal 'byor/"
            },
            {
                "@language": "zh-hant",
                "@value": "藥食瑜伽"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS124889",
        "rank": 1790
    },
    "T159": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkyil chog"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T159",
        "rank": 1797
    },
    "T540": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "gurusadhana"
            },
            {
                "@language": "en",
                "@value": "guru practice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T540",
        "rank": 1810
    },
    "T546": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma gsang 'dus snyan brgyud zhag bdun ma/"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T1GS138982": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam zab phyi ma/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS138982",
        "rank": 1793
    },
    "T2603": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshon cha srung ba/"
            },
            {
                "@language": "en",
                "@value": "protection against weapons"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2603",
        "rank": 1782
    },
    "T1993": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje'i rigs kyi rgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T2315": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phur pa'i gnad tig"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T1216": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen lnga ldan/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1216",
        "rank": 1811
    },
    "T840": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe rta zung 'brel 'chi med dpal ster (thang rgyal nye brgyud)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T840",
        "rank": 1769
    },
    "T812": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig 'dzin srog sgrub (nam mkha' 'jigs med)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T812",
        "rank": 6310
    },
    "T5": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon rtogs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "abhisamaya"
            },
            {
                "@language": "en",
                "@value": "visualization"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T5",
        "rank": 1762
    },
    "T1GS128714": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'phrin las lam khyer/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS128714",
        "rank": 1781
    },
    "T1068": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags phreng /"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1068",
        "rank": 1837
    },
    "T1GS147807": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje'i tshe sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS147807",
        "rank": 1766
    },
    "T1408": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srung 'khor/"
            },
            {
                "@language": "zh-hant",
                "@value": "護輪"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1408",
        "rank": 1834
    },
    "T27": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ayuhsadhana"
            },
            {
                "@language": "en",
                "@value": "longevity rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T27",
        "rank": 1770
    },
    "T877": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "修誦"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgom bzlas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T877",
        "rank": 1814
    },
    "T1181": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub skor/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1181",
        "rank": 1759
    },
    "T00JR2912": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgom sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T00JR2912",
        "rank": 1832
    },
    "T2594": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub bdud rtsi 'khyil ba (legs ldan rje)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2594",
        "rank": 1768
    },
    "T1AT470": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1AT470",
        "rank": 1820
    },
    "T4": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma'i rnal 'byor/"
            },
            {
                "@language": "sa-x-ewts",
                "@value": "guruyoga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T4",
        "rank": 1801
    },
    "T1318": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1318",
        "rank": 1780
    },
    "T1286": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam zab thun mong ma yin pa/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1286",
        "rank": 1802
    },
    "T151": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsnyen sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "seva-vidhi"
            },
            {
                "@language": "zh-hans",
                "@value": "近修"
            },
            {
                "@language": "en",
                "@value": "method for invoking"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T151",
        "rank": 1785
    },
    "T2043": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byug bdug brab gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2043",
        "rank": 1839
    },
    "T316": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzogs chen bla sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T316",
        "rank": 1818
    },
    "T1465": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsang sgrub/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guhyasadhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1465",
        "rank": 1808
    },
    "T317": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzogs chen sgrub mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T317",
        "rank": 1817
    },
    "T1094": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1094",
        "rank": 1806
    },
    "T1747": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub chen/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1747",
        "rank": 1840
    },
    "T453": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub khog"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T453",
        "rank": 1836
    },
    "T2168": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgyu lus dbang mo/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2168",
        "rank": 1803
    },
    "T1494": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog sdom gzer bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1494",
        "rank": 1830
    },
    "T2MS13267": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2MS13267",
        "rank": 1776
    },
    "T457": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdag bskyed/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T457",
        "rank": 1813
    },
    "T1": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrub thabs/"
            },
            {
                "@language": "zh-hans",
                "@value": "成就法"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sādhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1",
        "rank": 1758
    },
    "T1CZ48": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrub thabs thun mong ma yin pa/"
            },
            {
                "@language": "en",
                "@value": "uncommon sadhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1CZ48",
        "rank": 1760
    },
    "T1388": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khregs chod/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1388",
        "rank": 1794
    },
    "T1685": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde chen zhing sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1685",
        "rank": 1812
    },
    "T874": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shangs pa bka' brgyud gsang sgrub skor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T874",
        "rank": 1824
    },
    "T00UNK53": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnal 'byor sgo gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T1CZ11": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta mgrin gsang sgrub lugs gnyis/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1CZ11",
        "rank": 1796
    },
    "T1CZ63": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyi sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1CZ63",
        "rank": 1807
    },
    "T1476": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkyil 'khor blos bslang /"
            },
            {
                "@language": "en",
                "@value": "three-dimensional mandala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1476",
        "rank": 1799
    },
    "T1265": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thun bzhi bla ma'i rnal 'byor (kaM tshang lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1265",
        "rank": 1788
    },
    "T1266": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bogs 'don/"
            },
            {
                "@language": "en",
                "@value": "enhancement or progress in practice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1266",
        "rank": 1777
    },
    "T1464": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbrul 'byin/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1464",
        "rank": 1827
    },
    "T1824": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyud sde lnga /"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1824",
        "rank": 1778
    },
    "T1AT473": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi khro'i thugs sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1AT473",
        "rank": 1821
    },
    "T1382": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bam sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1382",
        "rank": 1838
    },
    "T01AG02845": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dril sgrub/"
            },
            {
                "@language": "en",
                "@value": "a combination of multiple sadhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T1214": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdun bskyed/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1214",
        "rank": 1815
    },
    "T1191": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha/"
            },
            {
                "@language": "en",
                "@value": "deities"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1191",
        "rank": 1833
    },
    "T2297": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshig bdun bla sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2297",
        "rank": 1809
    },
    "T957": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrub thabs rgya mtsho/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T957",
        "rank": 1761
    },
    "T555": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi med tshe sgrub bu ston gong khug ma/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T555",
        "rank": 1774
    },
    "T1099": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun bzang thugs gter gyi bla sgrub/ (byang chub gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T2308": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub 'chi med srog thig (mchog gyur gling pa)"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T2371": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las rung /"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T2371",
        "rank": 1822
    },
    "T01AG03135": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro snyan brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225830"
    },
    "T003JR4608": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sgrub (sangs rgyas gsang ba'i lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T003JR4608",
        "rank": 1771
    },
    "T468": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe skor phrin las rgyas pa/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T468",
        "rank": 1772
    },
    "T1425": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "prajnasadhana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "shes rab sgrub tshul/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1425",
        "rank": 1835
    },
    "T1GS145344": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam can gsang sgrub dmar mo/"
        },
        "parent": "O9TAXTBRC2016052KG225830",
        "tbrcId": "T1GS145344",
        "rank": 1826
    },
    "O9TAXTBRC2016052KG225923": {
        "sub": [
            "O9TAXTBRC2016052KG226111",
            "O9TAXTBRC2016052KG226264",
            "O9TAXTBRC2016052KG226188",
            "O9TAXTBRC2016052KG226181",
            "O9TAXTBRC2016052KG226217",
            "O9TAXTBRC2016052KG225924"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "cho ga phyag len/"
            },
            {
                "@language": "zh-hans",
                "@value": "仪轨修法"
            },
            {
                "@language": "en",
                "@value": "Ritual Practice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "4",
        "rank": 2862
    },
    "O9TAXTBRC2016052KG226111": {
        "sub": [
            "T1208",
            "T2231",
            "T326",
            "T1799",
            "T1917",
            "T00KG02749",
            "T1CZ9",
            "T1706",
            "T98",
            "T1651",
            "T398",
            "T1433",
            "T2376",
            "T1GS107986",
            "T2325",
            "T1650",
            "T1200",
            "T410",
            "T012AG03592",
            "T1569",
            "T886",
            "T2051",
            "T1883",
            "T2518",
            "T1470",
            "T1737",
            "T00JR777",
            "T2467",
            "T1739",
            "T1CZ23",
            "T887",
            "T472",
            "T1412",
            "T454",
            "T00JR772",
            "T00JR775",
            "T1240",
            "T003JR156",
            "T339",
            "T1010",
            "T1341",
            "T00KG02669",
            "T1798",
            "T847",
            "T1808",
            "T1304",
            "T1306",
            "T1724",
            "T2299",
            "T1397",
            "T1840",
            "T2250",
            "T1332",
            "T2373",
            "T2375",
            "T1727",
            "T1719",
            "T003JR4920",
            "T1417",
            "T1379",
            "T00JR2544",
            "T003JR67",
            "T1621",
            "T2188",
            "T1340",
            "T2521",
            "T1807",
            "T1322",
            "T1882"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Religious Practices"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las tshogs/"
            },
            {
                "@language": "zh-hans",
                "@value": "事业修法"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "2",
        "rank": 3042
    },
    "T1208": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zor las/"
            },
            {
                "@language": "en",
                "@value": "sorcery. black magic"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1208",
        "rank": 3061
    },
    "T2231": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha 'dre sde brgyad bka' shog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2231",
        "rank": 3116
    },
    "T326": {
        "label": [
            {
                "@language": "en",
                "@value": "preparation of medicine"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sman sgrub/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T326",
        "rank": 3059
    },
    "T1799": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smad las/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1799",
        "rank": 3081
    },
    "T1917": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "jo bskul/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1917",
        "rank": 3105
    },
    "T00KG02749": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor ma bsham tshul/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T00KG02749",
        "rank": 3107
    },
    "T1CZ9": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "glud rdzongs/"
            },
            {
                "@language": "en",
                "@value": "ritual for sending off the ransom"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1CZ9",
        "rank": 3078
    },
    "T1706": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gdab las/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1706",
        "rank": 3097
    },
    "T98": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdos chog"
            },
            {
                "@language": "en",
                "@value": "thread-cross rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T98",
        "rank": 3053
    },
    "T1651": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brgyags rngan/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1651",
        "rank": 3092
    },
    "T398": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "las tshogs/"
            },
            {
                "@language": "zh-hans",
                "@value": "羯磨集"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T398",
        "rank": 3048
    },
    "T1433": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "drug cu pa'i gtor ma/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "drug cu ma'i gtor ma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1433",
        "rank": 3050
    },
    "T2376": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhi ba'i las/"
            },
            {
                "@language": "zh-hant",
                "@value": "息業"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2376",
        "rank": 3103
    },
    "T1GS107986": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "cha bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1GS107986",
        "rank": 3051
    },
    "T2325": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdos chen/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2325",
        "rank": 3104
    },
    "T1650": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sde brgyad bcos thabs/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1650",
        "rank": 3089
    },
    "T1200": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sri mnan/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1200",
        "rank": 3052
    },
    "T410": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "drag po'i gtor chen/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T410",
        "rank": 3094
    },
    "T012AG03592": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdos zlog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T012AG03592",
        "rank": 3073
    },
    "T1569": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma Ni bum sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1569",
        "rank": 3060
    },
    "T886": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor chen/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T886",
        "rank": 3093
    },
    "T2051": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'gres byang /"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2051",
        "rank": 3071
    },
    "T1883": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lab brtsas brtsigs gso/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1883",
        "rank": 3090
    },
    "T2518": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs bzlog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2518",
        "rank": 3117
    },
    "T1470": {
        "label": [
            {
                "@language": "en",
                "@value": "sorcery"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bskrad pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1470",
        "rank": 3108
    },
    "T1737": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gter sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1737",
        "rank": 3085
    },
    "T00JR777": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mnan sreg 'phang /"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T00JR777",
        "rank": 3072
    },
    "T2467": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mnga' gsol/"
            },
            {
                "@language": "zh-hant",
                "@value": "授權"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2467",
        "rank": 3106
    },
    "T1739": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyi bstabs/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1739",
        "rank": 3114
    },
    "T1CZ23": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "btsan mdos/"
            },
            {
                "@language": "en",
                "@value": "preparation of thread-crosses for the violent spirits (btsan)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1CZ23",
        "rank": 3077
    },
    "T887": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "cha gsum gtor ma/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T887",
        "rank": 3054
    },
    "T472": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman mchod/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T472",
        "rank": 3100
    },
    "T1412": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rigs brgyud rgyas par byed pa'i las/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1412",
        "rank": 3110
    },
    "T454": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ril bu sgrub tshul/"
            },
            {
                "@language": "en",
                "@value": "production of medicinal pellets"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T454",
        "rank": 3057
    },
    "T00JR772": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags byang /"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T00JR772",
        "rank": 3069
    },
    "T00JR775": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsgral las/"
        },
        "parent": "O9TAXTBRC2016052KG226111"
    },
    "T1240": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brgya bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1240",
        "rank": 3091
    },
    "T003JR156": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbang sdud/"
            },
            {
                "@language": "en",
                "@value": "bringing under one's power or control"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T003JR156",
        "rank": 3075
    },
    "T339": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khrugs bskang /"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T339",
        "rank": 3064
    },
    "T1010": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig gtad/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1010",
        "rank": 3063
    },
    "T1341": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mkha' 'gro bsu bzlog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1341",
        "rank": 3113
    },
    "T00KG02669": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsad las kyi 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T00KG02669",
        "rank": 3083
    },
    "T1798": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stod las/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1798",
        "rank": 3080
    },
    "T847": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sun zlog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T847",
        "rank": 3065
    },
    "T1808": {
        "label": [
            {
                "@language": "en",
                "@value": "direct wrathful intervention"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mngon spyod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1808",
        "rank": 3079
    },
    "T1304": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dam sri mnan pa/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1304",
        "rank": 3115
    },
    "T1306": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgab 'dre mnan pa/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1306",
        "rank": 3102
    },
    "T1724": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ma Ni ril sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1724",
        "rank": 3058
    },
    "T2299": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mnan thabs/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2299",
        "rank": 3084
    },
    "T1397": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor sgrub chen mo/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1397",
        "rank": 3049
    },
    "T1840": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud bzlog"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1840",
        "rank": 3112
    },
    "T2250": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmod bskul/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2250",
        "rank": 3099
    },
    "T1332": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "srung zlog bsad gsum/"
            },
            {
                "@language": "en",
                "@value": "rituals of protection, aversion, and slaughter"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1332",
        "rank": 3098
    },
    "T2373": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgra bgegs sgrol gtad/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2373",
        "rank": 3068
    },
    "T2375": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshed 'dul/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2375",
        "rank": 3111
    },
    "T1727": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bskang mdos/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1727",
        "rank": 3076
    },
    "T1719": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gter bum sba tshul/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1719",
        "rank": 3096
    },
    "T003JR4920": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsgral mchod/"
            },
            {
                "@language": "en",
                "@value": "liberation offering"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T003JR4920",
        "rank": 3086
    },
    "T1417": {
        "label": [
            {
                "@language": "en",
                "@value": "fire offering"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "me mchod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1417",
        "rank": 3055
    },
    "T1379": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'gugs 'dren/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1379",
        "rank": 3062
    },
    "T00JR2544": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "hUM sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T00JR2544",
        "rank": 3070
    },
    "T003JR67": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sreg blug"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T003JR67",
        "rank": 3087
    },
    "T1621": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog gtad/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1621",
        "rank": 3056
    },
    "T2188": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bka' gtad/"
        },
        "parent": "O9TAXTBRC2016052KG226111"
    },
    "T1340": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las bzhi'i kha bsgyur/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1340",
        "rank": 3109
    },
    "T2521": {
        "label": [
            {
                "@language": "en",
                "@value": "destructive ritual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las mtha'"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T2521",
        "rank": 3101
    },
    "T1807": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ling khrus/"
            },
            {
                "@language": "en",
                "@value": "washing the linga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1807",
        "rank": 3088
    },
    "T1322": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las sbyor/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1322",
        "rank": 3095
    },
    "T1882": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226111",
        "tbrcId": "T1882",
        "rank": 3066
    },
    "O9TAXTBRC2016052KG226264": {
        "sub": [
            "T00JR2881",
            "T1404",
            "T2CN4866",
            "T165",
            "T336",
            "T802",
            "T4CZ15589",
            "T1442"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "杂部"
            },
            {
                "@language": "en",
                "@value": "Miscellany"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "thor bu sna tshogs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "6",
        "rank": 3215
    },
    "T00JR2881": {
        "label": [
            {
                "@language": "en",
                "@value": "signs indicative of success in tantric practice"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "grub rtags/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T00JR2881",
        "rank": 3226
    },
    "T1404": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skye 'chi bar do/"
        },
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T1404",
        "rank": 3220
    },
    "T2CN4866": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dpa' bo/"
        },
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T2CN4866",
        "rank": 3223
    },
    "T165": {
        "label": [
            {
                "@language": "en",
                "@value": "intermediate state"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bar do/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "antarabhava"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T165",
        "rank": 3221
    },
    "T336": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dam tshig"
            },
            {
                "@language": "en",
                "@value": "tantric vow"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samaya"
            },
            {
                "@language": "zh-hans",
                "@value": "三昧耶戒"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T336",
        "rank": 3227
    },
    "T802": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rig 'dzin/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vidyadhara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T802",
        "rank": 3224
    },
    "T4CZ15589": {
        "label": [
            {
                "@language": "en",
                "@value": "root downfalls, root infraction,"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rtsa ltung /"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajrayana-mulangapatti"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T4CZ15589",
        "rank": 3222
    },
    "T1442": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags kyi brtul zhugs/"
        },
        "parent": "O9TAXTBRC2016052KG226264",
        "tbrcId": "T1442",
        "rank": 3225
    },
    "O9TAXTBRC2016052KG226188": {
        "sub": [
            "T1301",
            "T1467",
            "T1407",
            "T1383",
            "T1302",
            "T2449",
            "T1857",
            "T1495",
            "T2MS15108",
            "T00AG01412",
            "T1KG14978",
            "T639",
            "T1303",
            "T1GS129610",
            "T1906",
            "T2MS15109",
            "T2127",
            "T2270",
            "T00JR3355",
            "T1300",
            "T866",
            "T804",
            "T4CZ6660",
            "T2128",
            "T00JR74",
            "T1116",
            "T01AG03137",
            "T2545"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje theg pa'i yo byad sogs/"
            },
            {
                "@language": "zh-hans",
                "@value": "金刚乘法器"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "4",
        "rank": 3130
    },
    "T1301": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rak+ta/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1301",
        "rank": 3153
    },
    "T1467": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tsha tsha/"
            },
            {
                "@language": "en",
                "@value": "clay votive tablets"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1467",
        "rank": 3138
    },
    "T1407": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bya btang yo byad/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1407",
        "rank": 3137
    },
    "T1383": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rlung 'khor/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1383",
        "rank": 3156
    },
    "T1302": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sin+d+hU ra/"
            },
            {
                "@language": "en",
                "@value": "minium"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1302",
        "rank": 3154
    },
    "T2449": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dgang blugs/"
            },
            {
                "@language": "en",
                "@value": "ladle"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2449",
        "rank": 3159
    },
    "T1857": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thun mtshams/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1857",
        "rank": 3160
    },
    "T1495": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam bcu dbang ldan/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1495",
        "rank": 3140
    },
    "T2MS15108": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vajra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2MS15108",
        "rank": 3146
    },
    "T00AG01412": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tsa ka li/"
            },
            {
                "@language": "en",
                "@value": "tsakli"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T00AG01412",
        "rank": 3135
    },
    "T1KG14978": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje theg pa'i yo byad/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1KG14978",
        "rank": 3134
    },
    "T639": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thod pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kapala"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T639",
        "rank": 3144
    },
    "T1303": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skong rdzas/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1303",
        "rank": 3155
    },
    "T1GS129610": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las bum/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1GS129610",
        "rank": 3143
    },
    "T1906": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku gsung thugs rten/"
            },
            {
                "@language": "en",
                "@value": "blessing bestowing objects"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1906",
        "rank": 3161
    },
    "T2MS15109": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dril bu/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "ghanta"
            },
            {
                "@language": "en",
                "@value": "ritual bell"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2MS15109",
        "rank": 3147
    },
    "T2127": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo rje dang dril bu/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2127",
        "rank": 3145
    },
    "T2270": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "btags grol/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2270",
        "rank": 3139
    },
    "T00JR3355": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzas tho/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T00JR3355",
        "rank": 3136
    },
    "T1300": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla rdo/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1300",
        "rank": 3150
    },
    "T866": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dam rdzas/"
            },
            {
                "@language": "en",
                "@value": "sacred substances"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T866",
        "rank": 3141
    },
    "T804": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bram ze skye bdun/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T804",
        "rank": 3142
    },
    "T4CZ6660": {
        "label": [
            {
                "@language": "en",
                "@value": "meditating cord, meditation belt, cord worn round the shoulder and he waist at the time of meditation, 4 inch wide cloth worn by yogi in meditation."
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgom thag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T4CZ6660",
        "rank": 3151
    },
    "T2128": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdud rtsi ril bu/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2128",
        "rank": 3157
    },
    "T00JR74": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "myong grol/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T00JR74",
        "rank": 3148
    },
    "T1116": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog 'khor/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T1116",
        "rank": 3149
    },
    "T01AG03137": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'thor nas/"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T01AG03137",
        "rank": 3152
    },
    "T2545": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe chang /"
        },
        "parent": "O9TAXTBRC2016052KG226188",
        "tbrcId": "T2545",
        "rank": 3158
    },
    "O9TAXTBRC2016052KG226181": {
        "sub": [
            "T173",
            "T1PD92832",
            "T18",
            "T012AG03496",
            "T1236",
            "T1PD92833"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsangs mchod/"
            },
            {
                "@language": "zh-hans",
                "@value": "烟供"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "3",
        "rank": 3119
    },
    "T173": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsangs mchod/"
        },
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T173",
        "rank": 3123
    },
    "T1PD92832": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mnol bsang /"
        },
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T1PD92832",
        "rank": 3125
    },
    "T18": {
        "label": [
            {
                "@language": "en",
                "@value": "fragrant burnt offerings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bsangs gsur/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T18",
        "rank": 3126
    },
    "T012AG03496": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyes sel/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "purification of misdeeds"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T012AG03496",
        "rank": 3128
    },
    "T1236": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu bsangs/"
        },
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T1236",
        "rank": 3124
    },
    "T1PD92833": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grib bsang /"
        },
        "parent": "O9TAXTBRC2016052KG226181",
        "tbrcId": "T1PD92833",
        "rank": 3127
    },
    "O9TAXTBRC2016052KG226217": {
        "sub": [
            "T1AT471",
            "T2121",
            "T2249",
            "T1559",
            "T1017",
            "T2189",
            "T1AT437",
            "T145",
            "T2MS20121",
            "T1212",
            "T879",
            "T2MS12423",
            "T1GS147800",
            "T00JR690",
            "T1473",
            "T2579",
            "T544",
            "T1CZ49",
            "T680",
            "T312",
            "T330",
            "T1709",
            "T1GS147797",
            "T2253",
            "T00JR3437",
            "T1753",
            "T936",
            "T138",
            "T536",
            "T1617",
            "T933",
            "T637",
            "T11",
            "T430",
            "T1831",
            "T2272",
            "T2615",
            "T860",
            "T2147",
            "T937",
            "T843",
            "T2CZ10088",
            "T225",
            "T1480",
            "T1915",
            "T1309"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Liturgies"
            },
            {
                "@language": "zh-hans",
                "@value": "常用法本"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos spyod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "5",
        "rank": 3163
    },
    "T1AT471": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sdig sgrib rang grol/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1AT471",
        "rank": 3177
    },
    "T2121": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtsa sngags/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2121",
        "rank": 3175
    },
    "T2249": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyal ba gnyid kyi rnal 'byor/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2249",
        "rank": 3205
    },
    "T1559": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rang byin rlabs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1559",
        "rank": 3212
    },
    "T1017": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bzlas pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "重诵"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1017",
        "rank": 3181
    },
    "T2189": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yig brgya/"
            },
            {
                "@language": "zh-hans",
                "@value": "百字明"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2189",
        "rank": 3197
    },
    "T1AT437": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod rdzas byin rlabs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1AT437",
        "rank": 3199
    },
    "T145": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma rgyang 'bod/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "calling upon the lama from afar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T145",
        "rank": 3189
    },
    "T2MS20121": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bar do thos grol/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2MS20121",
        "rank": 3186
    },
    "T1212": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan brjod/"
            },
            {
                "@language": "en",
                "@value": "reciting the names"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1212",
        "rank": 3202
    },
    "T879": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags ltung bcos thabs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T879",
        "rank": 3210
    },
    "T2MS12423": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bar chad lam sel/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2MS12423",
        "rank": 3213
    },
    "T1GS147800": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs mchod brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1GS147800",
        "rank": 3184
    },
    "T00JR690": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde smon/"
            },
            {
                "@language": "en",
                "@value": "prayer to be reborn in sukhavati, the western paradise of amitabha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T00JR690",
        "rank": 3208
    },
    "T1473": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "smon lam lnga /"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1473",
        "rank": 3209
    },
    "T2579": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'don 'grigs/"
            },
            {
                "@language": "en",
                "@value": "compilation of recitations"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2579",
        "rank": 3170
    },
    "T544": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyun khyer/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T544",
        "rank": 3188
    },
    "T1CZ49": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rkang brgyad bstod pa/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1CZ49",
        "rank": 3178
    },
    "T680": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'jam dbyangs gang blo ma'i bstod pa mar grags pa/"
            },
            {
                "@language": "en",
                "@value": "manjughosa known as the one practiced with the hymn \"since with intelligence...\""
            },
            {
                "@language": "sa-alalc97",
                "@value": "manjughosa purnamatistotrasadhanakirtita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T680",
        "rank": 3194
    },
    "T312": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdor sems bsgom bzlas/"
            },
            {
                "@language": "en",
                "@value": "vajrasattva contemplation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T312",
        "rank": 3192
    },
    "T330": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs mchod/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "gaṇacakrapuja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T330",
        "rank": 3182
    },
    "T1709": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrol ma maN+Dal bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1709",
        "rank": 3196
    },
    "T1GS147797": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsgom bzlas/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1GS147797",
        "rank": 3190
    },
    "T2253": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mgon po'i rtsa sngags/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2253",
        "rank": 3207
    },
    "T00JR3437": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skyes 'phreng gsol 'debs/"
            },
            {
                "@language": "en",
                "@value": "reverential petitions to the previous lives of a lama"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T00JR3437",
        "rank": 3174
    },
    "T1753": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1753",
        "rank": 3171
    },
    "T936": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchod phreng /"
            },
            {
                "@language": "en",
                "@value": "garland of offering; offering verses"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T936",
        "rank": 3191
    },
    "T138": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma mchod pa'i cho ga"
            },
            {
                "@language": "sa-alalc97",
                "@value": "gurupuja"
            },
            {
                "@language": "en",
                "@value": "worship of the guru"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T138",
        "rank": 3183
    },
    "T536": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ngag 'don/"
            },
            {
                "@language": "en",
                "@value": "liturgical recitation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T536",
        "rank": 3185
    },
    "T1617": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta gzungs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1617",
        "rank": 3206
    },
    "T933": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "dhāraṇi"
            },
            {
                "@language": "zh-hant",
                "@value": "陀羅尼"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzungs sngags/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T933",
        "rank": 3200
    },
    "T637": {
        "label": [
            {
                "@language": "en",
                "@value": "recitation method"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'don thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T637",
        "rank": 3172
    },
    "T11": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla brgyud gsol 'debs/"
            },
            {
                "@language": "en",
                "@value": "prayers to teachers of the lineage of transmission"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T11",
        "rank": 3201
    },
    "T430": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' ldan lha brgya ma/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T430",
        "rank": 3195
    },
    "T1831": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bstan pa rgyas pa'i smon lam/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1831",
        "rank": 3173
    },
    "T2272": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thos grol/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2272",
        "rank": 3187
    },
    "T2615": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "drag sngags/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2615",
        "rank": 3176
    },
    "T860": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten 'brel snying po'i gzungs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T860",
        "rank": 3193
    },
    "T2147": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ba dz+ra gu ru/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2147",
        "rank": 3211
    },
    "T937": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs 'khor/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ganacakra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T937",
        "rank": 3179
    },
    "T843": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stong mchod/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T843",
        "rank": 3203
    },
    "T2CZ10088": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "deng rabs/"
            },
            {
                "@language": "en",
                "@value": "modern times"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T2CZ10088",
        "rank": 3169
    },
    "T225": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos spyod/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T225",
        "rank": 3168
    },
    "T1480": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ngag byin rlabs/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1480",
        "rank": 3198
    },
    "T1915": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla mgon/"
        },
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1915",
        "rank": 3204
    },
    "T1309": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thun drug"
            },
            {
                "@language": "en",
                "@value": "six meditation sessions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226217",
        "tbrcId": "T1309",
        "rank": 3180
    },
    "O9TAXTBRC2016052KG225924": {
        "sub": [
            "T1GS129601",
            "T1020",
            "T1707",
            "T1095",
            "T1237",
            "T1902",
            "T1635",
            "T416",
            "T455",
            "T473",
            "T1220",
            "T1258",
            "T1639",
            "T00JR760",
            "T1AT452",
            "T1456",
            "T1GS128716",
            "T2276",
            "T1CZ5",
            "T1CZ19",
            "T1CZ25",
            "T2038",
            "T872",
            "T2610",
            "T4CZ363957",
            "T2104",
            "T1GS108144",
            "T249",
            "T1720",
            "T1783",
            "T1GS110789",
            "T00JR2086",
            "T1647",
            "T8",
            "T2517",
            "T467",
            "T1437",
            "T1GS145345",
            "T1GS147798",
            "T1GS110790",
            "T1633",
            "T2336",
            "T1481",
            "T2601",
            "T1699",
            "T1458",
            "T175",
            "T1451",
            "T845",
            "T810",
            "T978",
            "T00JR399",
            "T438",
            "T1GS6338",
            "T00KG02665",
            "T870",
            "T419",
            "T35",
            "T00JR624",
            "T1044",
            "T1226",
            "T10MS14317",
            "T00JR763",
            "T19",
            "T21",
            "T1015",
            "T1781",
            "T4CZ15132",
            "T1AT306",
            "T2415",
            "T2136",
            "T1648",
            "T26",
            "T799",
            "T1CZ67",
            "T1GS107985",
            "T4CZ359084",
            "T1726",
            "T940",
            "T1307",
            "T1405",
            "T944",
            "T1GS9766",
            "T1619",
            "T961",
            "T1292",
            "T4CZ15519",
            "T1228",
            "T1027",
            "T1221",
            "T1256",
            "T1961",
            "T00JR761",
            "T2625",
            "T012AG03493",
            "T1447",
            "T00JR2124",
            "T2629",
            "T2541",
            "T3JT5431",
            "T1CZ12",
            "T1703",
            "T1GS108146",
            "T1692",
            "T1219",
            "T1GS129190",
            "T1206",
            "T00JR2087",
            "T1742",
            "T9",
            "T48",
            "T1366",
            "T1501",
            "T1GS47741",
            "T1634",
            "T00JR3374",
            "T2352",
            "T2602",
            "T2084",
            "T1459",
            "T1257",
            "T846",
            "T885",
            "T1683",
            "T1CZ20",
            "T2471",
            "T1CZ24",
            "T2511",
            "T00KG02746",
            "T00JR758",
            "T1736",
            "T00JR140",
            "T22",
            "T2210",
            "T1782",
            "T1AT307",
            "T1310",
            "T1348",
            "T1820",
            "T1AT500",
            "T1870",
            "T7",
            "T2311",
            "T1GS129188",
            "T1GS129189",
            "T3JT5301",
            "T00JR771",
            "T00AG01323",
            "T1367",
            "T2335",
            "T897",
            "T2600",
            "T1GS129192",
            "T829",
            "T2050",
            "T844",
            "T941",
            "T883",
            "T977",
            "T1209",
            "T437",
            "T1GS10764",
            "T1CZ17",
            "T01JR188",
            "T012AG03702",
            "T1CZ22",
            "T458",
            "T1028",
            "T1GS151639",
            "T1225",
            "T00JR762",
            "T1CZ27",
            "T2042",
            "T1GS108510",
            "T93",
            "T905",
            "T1AT269",
            "T00KG02673",
            "T170",
            "T1GS108147",
            "T895",
            "T1GS129191",
            "T00KG02658",
            "T1PD114150",
            "T00KG02676",
            "T1743"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "仪轨"
            },
            {
                "@language": "en",
                "@value": "Ritual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "cho ga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225923",
        "tbrcId": "1",
        "rank": 2867
    },
    "T1GS129601": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rjes chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129601",
        "rank": 2901
    },
    "T1020": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "drag po'i sbyin sreg"
            },
            {
                "@language": "en",
                "@value": "wrathful fire puja"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1020",
        "rank": 2893
    },
    "T1707": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten 'bul/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1707",
        "rank": 3032
    },
    "T1095": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "don dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1095",
        "rank": 2881
    },
    "T1237": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1237",
        "rank": 2920
    },
    "T1902": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tsha gsur/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1902",
        "rank": 2907
    },
    "T1635": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo tog la phan pa'i cho ga"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1635",
        "rank": 2957
    },
    "T416": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhi ba'i sbyin sreg"
            },
            {
                "@language": "en",
                "@value": "pacifying fire ritual"
            },
            {
                "@language": "sa-alalc97",
                "@value": "santikahomavidhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T416",
        "rank": 2890
    },
    "T455": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byin 'bebs/"
            },
            {
                "@language": "en",
                "@value": "descent of blessings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T455",
        "rank": 2992
    },
    "T473": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T473",
        "rank": 2879
    },
    "T1220": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'phrin bcol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1220",
        "rank": 2930
    },
    "T1258": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdo'i bcud len/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T1639": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srid spel/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1639",
        "rank": 3965
    },
    "T00JR760": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skul byang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR760",
        "rank": 2914
    },
    "T1AT452": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1AT452",
        "rank": 2877
    },
    "T1456": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thog srung /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1456",
        "rank": 2962
    },
    "T1GS128716": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lus srung /"
            },
            {
                "@language": "en",
                "@value": "protection of the body"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS128716",
        "rank": 3013
    },
    "T2276": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "za yig"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2276",
        "rank": 3002
    },
    "T1CZ5": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "baM skongs/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ5",
        "rank": 2927
    },
    "T1CZ19": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sde brgyad gser skyems/"
            },
            {
                "@language": "en",
                "@value": "a drink offering to the eight classes of Gods and Spirits"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ19",
        "rank": 2975
    },
    "T1CZ25": {
        "label": [
            {
                "@language": "en",
                "@value": "ransom for livestock"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phyugs glud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ25",
        "rank": 2959
    },
    "T2038": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gegs sel/"
            },
            {
                "@language": "en",
                "@value": "removal of obstacles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2038",
        "rank": 2981
    },
    "T872": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "char 'bebs cho ga"
            },
            {
                "@language": "en",
                "@value": "rain making rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T2610": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "glud chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2610",
        "rank": 2954
    },
    "T4CZ363957": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "arga"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T2104": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod rol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2104",
        "rank": 2905
    },
    "T1GS108144": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "grib srung /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS108144",
        "rank": 3018
    },
    "T249": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsol mchod/"
            },
            {
                "@language": "en",
                "@value": "rituals for offering"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T249",
        "rank": 2983
    },
    "T1720": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'byung bzhi gtor ma/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1720",
        "rank": 2970
    },
    "T1783": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sgrib sbyang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1783",
        "rank": 3000
    },
    "T1GS110789": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang gi sta gon/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS110789",
        "rank": 2884
    },
    "T00JR2086": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srog 'dren/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR2086",
        "rank": 2911
    },
    "T1647": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas chen byin 'bebs/"
            },
            {
                "@language": "en",
                "@value": "blessing special sites"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1647",
        "rank": 3001
    },
    "T8": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbyin sreg"
            },
            {
                "@language": "en",
                "@value": "fire offering"
            },
            {
                "@language": "zh-hant",
                "@value": "燒施護摩"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "homa"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T8",
        "rank": 2888
    },
    "T2517": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bum bskyed/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2517",
        "rank": 2899
    },
    "T467": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skong chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T467",
        "rank": 2928
    },
    "T1437": {
        "label": [
            {
                "@language": "en",
                "@value": "clearing of obstacles"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bar chad zlog pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1437",
        "rank": 2980
    },
    "T1GS145345": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe sring /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS145345",
        "rank": 2998
    },
    "T1GS147798": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa brtag bslang sbyang sbyang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS147798",
        "rank": 3023
    },
    "T1GS110790": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdag dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS110790",
        "rank": 2878
    },
    "T1633": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'bu srung /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1633",
        "rank": 2972
    },
    "T2336": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshin po 'drer mi 'ong ba/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2336",
        "rank": 3011
    },
    "T1481": {
        "label": [
            {
                "@language": "en",
                "@value": "cremation rites"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sku gdung sreg chog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1481",
        "rank": 2933
    },
    "T2601": {
        "label": [
            {
                "@language": "en",
                "@value": "protection against poisoning"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dug nad srung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2601",
        "rank": 3036
    },
    "T1699": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzungs chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1699",
        "rank": 2938
    },
    "T1458": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1458",
        "rank": 2923
    },
    "T175": {
        "label": [
            {
                "@language": "en",
                "@value": "purification ritual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sbyang chog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T175",
        "rank": 2940
    },
    "T1451": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rkun ma srung ba/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1451",
        "rank": 3040
    },
    "T845": {
        "label": [
            {
                "@language": "en",
                "@value": "nail burning"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sen mo bsreg pa'i cho ga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T845",
        "rank": 2918
    },
    "T810": {
        "label": [
            {
                "@language": "en",
                "@value": "funeral rites"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnas lung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T810",
        "rank": 2941
    },
    "T978": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ro sreg"
            },
            {
                "@language": "en",
                "@value": "cremation rituals, funeral rite"
            },
            {
                "@language": "zh-hans",
                "@value": "火葬"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T978",
        "rank": 2934
    },
    "T00JR399": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsol kha/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR399",
        "rank": 2951
    },
    "T438": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bka' bsgo"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ajna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T438",
        "rank": 2994
    },
    "T1GS6338": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sha brgya zan brgya/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS6338",
        "rank": 2976
    },
    "T00KG02665": {
        "label": [
            {
                "@language": "en",
                "@value": "arrangement for a sadhana practice"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "las khrigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T870": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman bla mdo chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T870",
        "rank": 2887
    },
    "T419": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bcu cha'i sbyin sreg"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T419",
        "rank": 2894
    },
    "T35": {
        "label": [
            {
                "@language": "en",
                "@value": "vessel burial ritual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bum sgrub/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T35",
        "rank": 3025
    },
    "T00JR624": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmar byang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR624",
        "rank": 2913
    },
    "T1044": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byin rlabs/"
            },
            {
                "@language": "en",
                "@value": "blessing"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T1226": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'dod gsol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1226",
        "rank": 2991
    },
    "T10MS14317": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbang bskur/"
            },
            {
                "@language": "zh-hant",
                "@value": "灌頂"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "abhiṣeka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T00JR763": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang bzlog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR763",
        "rank": 2915
    },
    "T19": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bskang gso/"
            },
            {
                "@language": "en",
                "@value": "regular propitiation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T19",
        "rank": 2988
    },
    "T21": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkyil 'khor dbang chog"
            },
            {
                "@language": "en",
                "@value": "initiations into the mandala"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mandala abhiseka vidhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T21",
        "rank": 2882
    },
    "T1015": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa ts+tshA gdab chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1015",
        "rank": 2898
    },
    "T1781": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam rgyal bum sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1781",
        "rank": 3006
    },
    "T4CZ15132": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lag mchod/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "samvaradhisthana"
            },
            {
                "@language": "en",
                "@value": "hand offering, personal skull cup"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T4CZ15132",
        "rank": 3038
    },
    "T1AT306": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bgegs gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1AT306",
        "rank": 2990
    },
    "T2415": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdos glud/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2415",
        "rank": 2952
    },
    "T2136": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyas pa'i sbyin sreg"
            },
            {
                "@language": "en",
                "@value": "increasing fire ritual"
            },
            {
                "@language": "sa-alalc97",
                "@value": "paustikahoma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2136",
        "rank": 2892
    },
    "T1648": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lha srin dam bsgrags/"
            },
            {
                "@language": "en",
                "@value": "binding deities to oath"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1648",
        "rank": 3020
    },
    "T26": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gser skyems/"
            },
            {
                "@language": "en",
                "@value": "golden water offerings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T26",
        "rank": 2977
    },
    "T799": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sog bzlog"
            },
            {
                "@language": "en",
                "@value": "turning back of mongol invaders"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T799",
        "rank": 2916
    },
    "T1CZ67": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mig gzungs/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T1GS107985": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "brul gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS107985",
        "rank": 2996
    },
    "T4CZ359084": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nang mchod/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "rakta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T1726": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bzlog bsgyur/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1726",
        "rank": 2978
    },
    "T940": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gdung mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T940",
        "rank": 3027
    },
    "T1307": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa dpyad/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1307",
        "rank": 3028
    },
    "T1405": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "nagahomavidhi"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "klu'i sbyin sreg"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1405",
        "rank": 2895
    },
    "T944": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal mtshan 'dzugs chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T944",
        "rank": 2903
    },
    "T1GS9766": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "su rU pa'i gtor ma/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS9766",
        "rank": 2948
    },
    "T1619": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rta glud/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1619",
        "rank": 2956
    },
    "T961": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshes bcu'i dus mchod/"
            },
            {
                "@language": "en",
                "@value": "tenth day rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T961",
        "rank": 3029
    },
    "T1292": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ting nge 'dzin gyi dbang ('ba' ra lugs)"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1292",
        "rank": 2880
    },
    "T4CZ15519": {
        "label": [
            {
                "@language": "en",
                "@value": "protect from awareness mantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rig sngags srung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T4CZ15519",
        "rank": 3039
    },
    "T1228": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gshegs gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1228",
        "rank": 2982
    },
    "T1027": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byis pa srung ba/"
            },
            {
                "@language": "en",
                "@value": "protection of children"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1027",
        "rank": 3030
    },
    "T1221": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rlung rta/"
            },
            {
                "@language": "en",
                "@value": "wind horse"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1221",
        "rank": 2989
    },
    "T1256": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chu'i bcud len/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T1961": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phung po dur 'jug"
            },
            {
                "@language": "en",
                "@value": "disposal of the dead"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1961",
        "rank": 3012
    },
    "T00JR761": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrus dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR761",
        "rank": 2886
    },
    "T2625": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gto/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T012AG03493": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri khrus/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T012AG03493",
        "rank": 2965
    },
    "T1447": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byabs khrus/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1447",
        "rank": 3031
    },
    "T00JR2124": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bskyed chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR2124",
        "rank": 2909
    },
    "T2629": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "las bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2629",
        "rank": 2896
    },
    "T2541": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmag bzlog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2541",
        "rank": 2999
    },
    "T3JT5431": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmar gsur/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T3JT5431",
        "rank": 2974
    },
    "T1CZ12": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhi rgyas dbang gi sbyin sreg gi cho ga"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ12",
        "rank": 2889
    },
    "T1703": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thod dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1703",
        "rank": 2883
    },
    "T1GS108146": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gri thogs srung ba/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS108146",
        "rank": 3016
    },
    "T1692": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyal chen tho 'dzugs/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1692",
        "rank": 2904
    },
    "T1219": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor 'bul/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1219",
        "rank": 2987
    },
    "T1GS129190": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'dzul chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129190",
        "rank": 3010
    },
    "T1206": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1206",
        "rank": 2924
    },
    "T00JR2087": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmod bzlog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR2087",
        "rank": 2912
    },
    "T1742": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chu gtor/"
            },
            {
                "@language": "zh-hans",
                "@value": "水食子"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1742",
        "rank": 2921
    },
    "T9": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brtan bzhugs/"
            },
            {
                "@language": "en",
                "@value": "prayer for long life"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T9",
        "rank": 2931
    },
    "T48": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "abhisekavidhi"
            },
            {
                "@language": "en",
                "@value": "initiation method"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T48",
        "rank": 2875
    },
    "T1366": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor ma brgya rtsa/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1366",
        "rank": 2971
    },
    "T1501": {
        "label": [
            {
                "@language": "en",
                "@value": "frost prevention"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sad srung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1501",
        "rank": 2960
    },
    "T1GS47741": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chab gtor 'jam dpal ma/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS47741",
        "rank": 2947
    },
    "T1634": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo glud/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1634",
        "rank": 2958
    },
    "T00JR3374": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhag thabs/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR3374",
        "rank": 3021
    },
    "T2352": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dngos gzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T2602": {
        "label": [
            {
                "@language": "en",
                "@value": "protection from bandits and thieves"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dgra jag chom kun srung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2602",
        "rank": 2997
    },
    "T2084": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rdzas srung /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2084",
        "rank": 3019
    },
    "T1459": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chu sbyin/"
            },
            {
                "@language": "en",
                "@value": "offerings of water"
            },
            {
                "@language": "zh-hans",
                "@value": "水施"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1459",
        "rank": 2966
    },
    "T1257": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "me tog gi bcud len/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T846": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chi bslu/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T846",
        "rank": 3026
    },
    "T885": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mig 'byed/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T885",
        "rank": 2908
    },
    "T1683": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byang chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1683",
        "rank": 2942
    },
    "T1CZ20": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dug sel/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ20",
        "rank": 3034
    },
    "T2471": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten bskyed/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2471",
        "rank": 3035
    },
    "T1CZ24": {
        "label": [
            {
                "@language": "en",
                "@value": "gdon ransom for protecting children"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "byis pa'i gdon glud/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ24",
        "rank": 2955
    },
    "T2511": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhabs brtan/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2511",
        "rank": 3005
    },
    "T00KG02746": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsad mnan/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T00JR758": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "me dbang /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR758",
        "rank": 2945
    },
    "T1736": {
        "label": [
            {
                "@language": "en",
                "@value": "exhortation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bskul ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1736",
        "rank": 2993
    },
    "T00JR140": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T22": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "g.yang 'gugs/"
            },
            {
                "@language": "en",
                "@value": "prosperity rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T22",
        "rank": 2969
    },
    "T2210": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2210",
        "rank": 2876
    },
    "T1782": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lho sgo"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1782",
        "rank": 3916
    },
    "T1AT307": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yon sbyong /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1AT307",
        "rank": 3007
    },
    "T1310": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkar gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1310",
        "rank": 2949
    },
    "T1348": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzungs 'bul/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1348",
        "rank": 2986
    },
    "T1820": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod rten brgya rtsa brgyad gdab pa'i cho ga"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1820",
        "rank": 2917
    },
    "T1AT500": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dkar gsur/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1AT500",
        "rank": 2973
    },
    "T1870": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dgongs rdzogs/"
            },
            {
                "@language": "en",
                "@value": "funeral rituals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1870",
        "rank": 3033
    },
    "T7": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "anujna"
            },
            {
                "@language": "en",
                "@value": "permission to invoke"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rjes gnang /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T7",
        "rank": 2885
    },
    "T2311": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbang gi sbyin sreg"
            },
            {
                "@language": "en",
                "@value": "magnetizing fire ritual"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2311",
        "rank": 2891
    },
    "T1GS129188": {
        "label": [
            {
                "@language": "en",
                "@value": "protection of the state"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "srid srung /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129188",
        "rank": 3008
    },
    "T1GS129189": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srung ba rdo rje'i go cha/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129189",
        "rank": 3009
    },
    "T3JT5301": {
        "label": [
            {
                "@language": "en",
                "@value": "rain stopping rituals"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "char gcod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T3JT5301",
        "rank": 2964
    },
    "T00JR771": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dgra mnan/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR771",
        "rank": 2946
    },
    "T00AG01323": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs gnyis spel ba'i cho ga"
            },
            {
                "@language": "en",
                "@value": "ritual for the increase of the two accumulations (bsod nams kyi tshogs dang ye shes kyi tshogs)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00AG01323",
        "rank": 2902
    },
    "T1367": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod gtor/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1367",
        "rank": 2929
    },
    "T2335": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa slong /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2335",
        "rank": 3003
    },
    "T897": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byad 'grol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T897",
        "rank": 2932
    },
    "T2600": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nad gdon bar chad spyir srung ba/"
            },
            {
                "@language": "en",
                "@value": "protection from disease, evil spirits, and obstacles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2600",
        "rank": 3024
    },
    "T1GS129192": {
        "label": [
            {
                "@language": "en",
                "@value": "protection from property loss"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "god kha srung ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129192",
        "rank": 3015
    },
    "T829": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "maN+Dal bzhi pa/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T829",
        "rank": 2897
    },
    "T2050": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bgegs bskrad/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2050",
        "rank": 2925
    },
    "T844": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skra bsreg pa'i cho ga"
            },
            {
                "@language": "en",
                "@value": "hair burning"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T941": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bcud len/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rasayana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T941",
        "rank": 2995
    },
    "T883": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gdon sgrol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T883",
        "rank": 2979
    },
    "T977": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "cho ga"
            },
            {
                "@language": "en",
                "@value": "ritual text"
            },
            {
                "@language": "zh-hans",
                "@value": "法事"
            },
            {
                "@language": "sa-x-iast",
                "@value": "vidhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T977",
        "rank": 2872
    },
    "T1209": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor bsngos/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1209",
        "rank": 2919
    },
    "T437": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ser lam gcod pa/"
            },
            {
                "@language": "en",
                "@value": "hail prevention"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T437",
        "rank": 2963
    },
    "T1GS10764": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sa chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS10764",
        "rank": 2939
    },
    "T1CZ17": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ser srung /"
            },
            {
                "@language": "en",
                "@value": "hail and rain protection"
            },
            {
                "@language": "zh-hans",
                "@value": "防雹"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ17",
        "rank": 2961
    },
    "T01JR188": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gnas sbyong /"
            },
            {
                "@language": "en",
                "@value": "purifying an abode (of sentient beings)"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T01JR188",
        "rank": 2943
    },
    "T012AG03702": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman rak gtor gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T012AG03702",
        "rank": 2967
    },
    "T1CZ22": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal mdos/"
            },
            {
                "@language": "en",
                "@value": "ritual for the creation of thread-cross for the gyalpo spirits"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1CZ22",
        "rank": 2953
    },
    "T458": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtor dbang /"
            },
            {
                "@language": "en",
                "@value": "torma empowerment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T458",
        "rank": 2906
    },
    "T1028": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "開光"
            },
            {
                "@language": "en",
                "@value": "consecration"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rab gnas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1028",
        "rank": 2985
    },
    "T1GS151639": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla 'gugs/"
            },
            {
                "@language": "en",
                "@value": "summoning of the bla spirit"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS151639",
        "rank": 3022
    },
    "T1225": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe 'gugs/"
        },
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T00JR762": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bzlog thabs/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00JR762",
        "rank": 2935
    },
    "T1CZ27": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lho sgo'i cho ga"
            },
            {
                "@language": "en",
                "@value": "ritual of the south gate"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924"
    },
    "T2042": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pho nya sgom bskul/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T2042",
        "rank": 3004
    },
    "T1GS108510": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyan 'dren khrus gsol/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS108510",
        "rank": 2950
    },
    "T93": {
        "label": [
            {
                "@language": "en",
                "@value": "method of purification"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bskang chog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T93",
        "rank": 2926
    },
    "T905": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gtor bzlog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T905",
        "rank": 2984
    },
    "T1AT269": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zlog pa/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1AT269",
        "rank": 2936
    },
    "T00KG02673": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rab gnas zhag bdun ma/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00KG02673",
        "rank": 2944
    },
    "T170": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtor ma/"
            },
            {
                "@language": "en",
                "@value": "offering cakes"
            },
            {
                "@language": "sa-alalc97",
                "@value": "bali"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T170",
        "rank": 2968
    },
    "T1GS108147": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kha zas 'ong bar byed pa/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS108147",
        "rank": 3017
    },
    "T895": {
        "label": [
            {
                "@language": "en",
                "@value": "purificatory methods through the use of water"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khrus chog"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T895",
        "rank": 2900
    },
    "T1GS129191": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jigs pa brgyad srung ba/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1GS129191",
        "rank": 3014
    },
    "T00KG02658": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyir bzlog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00KG02658",
        "rank": 2910
    },
    "T1PD114150": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo chog"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1PD114150",
        "rank": 2937
    },
    "T00KG02676": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rims bsrung /"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T00KG02676",
        "rank": 3037
    },
    "T1743": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsur mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225924",
        "tbrcId": "T1743",
        "rank": 2922
    },
    "O9TAXTBRC2016052KG225384": {
        "sub": [
            "T350",
            "T00AG01549",
            "T308",
            "T1871",
            "T3CN2024",
            "T770"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "General Topics of Buddhist Studies"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383"
    },
    "T350": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod brgyud nang bstan/"
            },
            {
                "@language": "en",
                "@value": "tibetan buddhism"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T350",
        "rank": 270
    },
    "T00AG01549": {
        "label": [
            {
                "@language": "en",
                "@value": "buddhist councils"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bka' bsdu gsum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T00AG01549",
        "rank": 278
    },
    "T308": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khor lo gsum/"
            },
            {
                "@language": "en",
                "@value": "dharmachakras"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T308",
        "rank": 274
    },
    "T1871": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyud sde bzhi/"
            },
            {
                "@language": "zh-hans",
                "@value": "四部密续"
            },
            {
                "@language": "en",
                "@value": "four classes of tantra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T1871",
        "rank": 286
    },
    "T3CN2024": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sde snod gsum/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "tripiṭaka"
            },
            {
                "@language": "zh-hans",
                "@value": "三藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T3CN2024",
        "rank": 282
    },
    "T770": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nang bstan/"
            },
            {
                "@language": "en",
                "@value": "buddhism"
            },
            {
                "@language": "zh-hans",
                "@value": "佛教"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225384",
        "tbrcId": "T770",
        "rank": 266
    },
    "O9TAXTBRC2016052KG226275": {
        "sub": [
            "T1364",
            "T945",
            "T1284",
            "T157",
            "T1548",
            "T1593",
            "T1888",
            "T1532",
            "T10MS14575",
            "T1550",
            "T00JR990",
            "T1112",
            "T200",
            "T00JR398",
            "T1531",
            "T2366",
            "T1541",
            "T00KG02675",
            "T1GS147793",
            "T1695",
            "T46",
            "T1288",
            "T1577",
            "T1287",
            "T1534",
            "T1267",
            "T1552",
            "T1551",
            "T1259",
            "T11MS10",
            "T1GS108077",
            "T1253",
            "T395",
            "T1697",
            "T1262",
            "T1735",
            "T1545",
            "T01AG03287",
            "T1544",
            "T01JR141",
            "T2178",
            "T1GS108508",
            "T11MS11",
            "T1698",
            "T2GS1099",
            "T00KG02748",
            "T1966",
            "T1273",
            "T1272",
            "T1GS118660",
            "T1594",
            "T1555",
            "T1016",
            "T11MS42",
            "T1530",
            "T1155",
            "T1CZ28",
            "T2065"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Instructional Manuals"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khrid yig"
            },
            {
                "@language": "zh-hans",
                "@value": "讲义"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "6",
        "rank": 3239
    },
    "T1364": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snying po don gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1364",
        "rank": 3284
    },
    "T945": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rlung khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T945",
        "rank": 3247
    },
    "T1284": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten 'brel rab bdun khrid 'brug lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1284",
        "rank": 3264
    },
    "T157": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbang bshad/"
            },
            {
                "@language": "en",
                "@value": "explanation of the empowerment"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T157",
        "rank": 3272
    },
    "T1548": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yon po bsrang ba/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1548",
        "rank": 3277
    },
    "T1593": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbyor drug (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1593",
        "rank": 3261
    },
    "T1888": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bla ma yang tig"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1532": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyan ras gzigs kyi dmar khrid dpal mo lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T10MS14575": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsangs yig"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T10MS14575",
        "rank": 3279
    },
    "T1550": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rgya chen po yi ge med pa (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T00JR990": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kaH thog khrid chen bcu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T00JR990",
        "rank": 3265
    },
    "T1112": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sems khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1112",
        "rank": 3253
    },
    "T200": {
        "label": [
            {
                "@language": "en",
                "@value": "instructional manual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "khrid yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T200",
        "rank": 3245
    },
    "T00JR398": {
        "label": [
            {
                "@language": "en",
                "@value": "instructions on the dark retreat"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mun khrid/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T00JR398",
        "rank": 3250
    },
    "T1531": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyan ras gzigs kyi dmar khrid tshem bu lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1531",
        "rank": 3267
    },
    "T2366": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmar khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T2366",
        "rank": 3252
    },
    "T1541": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus 'khor snyan brgyud (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1541",
        "rank": 3291
    },
    "T00KG02675": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nang khrid kyi zhal shes/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T00KG02675",
        "rank": 3255
    },
    "T1GS147793": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe khrid 'chi med rdo rje'i srog shing /"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1GS147793",
        "rank": 3258
    },
    "T1695": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dag snang zhing sbyong /"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1695",
        "rank": 3274
    },
    "T46": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdor gsang sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T46",
        "rank": 3289
    },
    "T1288": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrid chung brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1288",
        "rank": 3269
    },
    "T1577": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bde mchog dkar po (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1287": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrid chen brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1287",
        "rank": 3268
    },
    "T1534": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "spyan ras gzigs kyi dmar khrid zla rgyal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1534",
        "rank": 3270
    },
    "T1267": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen zur mang snyan rgyud lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1552": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo rgyud bsre ba/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1552",
        "rank": 3285
    },
    "T1551": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mchod rten drung thob/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1551",
        "rank": 3292
    },
    "T1259": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1259",
        "rank": 3248
    },
    "T11MS10": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lung /"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T11MS10",
        "rank": 3278
    },
    "T1GS108077": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dga' ldan 'pho khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1GS108077",
        "rank": 3259
    },
    "T1253": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "jo nang khrid brgya/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1253",
        "rank": 3262
    },
    "T395": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lta khrid/"
            },
            {
                "@language": "en",
                "@value": "instruction on the view"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T395",
        "rank": 3254
    },
    "T1697": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang spyod mnal lam ma/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1697",
        "rank": 3273
    },
    "T1262": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rlung sems gnyis med/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1262",
        "rank": 3288
    },
    "T1735": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnga chings/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1735",
        "rank": 3283
    },
    "T1545": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zab pa'i tshul dgu/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1545",
        "rank": 3276
    },
    "T01AG03287": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbang tho/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T01AG03287",
        "rank": 3287
    },
    "T1544": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsam mi khyab/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1544",
        "rank": 3275
    },
    "T01JR141": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmar mchod 'gogs byed/"
            },
            {
                "@language": "en",
                "@value": "abolition of animal sacrifice"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T01JR141",
        "rank": 3282
    },
    "T2178": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rwa khrid rwa rtse sems 'dzin/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T2178",
        "rank": 3260
    },
    "T1GS108508": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgya ra'i lta khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1GS108508",
        "rank": 3281
    },
    "T11MS11": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T11MS11",
        "rank": 3244
    },
    "T1698": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mal gcod/"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T2GS1099": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shog dril/"
            },
            {
                "@language": "en",
                "@value": "instructional scroll"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T2GS1099",
        "rank": 3280
    },
    "T00KG02748": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T00KG02748",
        "rank": 3249
    },
    "T1966": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1966",
        "rank": 3256
    },
    "T1273": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen yan lag bdun ldan/"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1272": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen tshal lugs/"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1GS118660": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pra khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1GS118660",
        "rank": 3251
    },
    "T1594": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sems khrid yid bzhin nor bu/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1594",
        "rank": 3257
    },
    "T1555": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sems kyi bar chad sel ba (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275"
    },
    "T1016": {
        "label": [
            {
                "@language": "en",
                "@value": "method of reading"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "klog thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1016",
        "rank": 3286
    },
    "T11MS42": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "don khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T11MS42",
        "rank": 3246
    },
    "T1530": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshe dpag med dkar po (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1530",
        "rank": 3290
    },
    "T1155": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ro snyoms (khrid)"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1155",
        "rank": 3263
    },
    "T1CZ28": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsnyen yig"
            },
            {
                "@language": "en",
                "@value": "rituals for the sevasadhana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T1CZ28",
        "rank": 3271
    },
    "T2065": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen dmar khrid/"
        },
        "parent": "O9TAXTBRC2016052KG226275",
        "tbrcId": "T2065",
        "rank": 3266
    },
    "O9TAXTBRC2016052KG225391": {
        "sub": [
            "O9TAXTBRC2016052KG225530",
            "O9TAXTBRC2016052KG225656",
            "O9TAXTBRC2016052KG225512",
            "O9TAXTBRC2016052KG225398",
            "O9TAXTBRC2016052KG225452",
            "O9TAXTBRC2016052KG225392"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Sutra Tradition"
            },
            {
                "@language": "zh-hans",
                "@value": "显乘"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mdo phyogs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225383",
        "tbrcId": "2",
        "rank": 295
    },
    "O9TAXTBRC2016052KG225530": {
        "sub": [
            "O9TAXTBRC2016052KG225531",
            "O9TAXTBRC2016052KG225556",
            "O9TAXTBRC2016052KG225575",
            "O9TAXTBRC2016052KG225651"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam rim blo sbyong /"
            },
            {
                "@language": "en",
                "@value": "Mind Training Literature"
            },
            {
                "@language": "zh-hans",
                "@value": "修心道次"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "5",
        "rank": 828
    },
    "O9TAXTBRC2016052KG225531": {
        "sub": [
            "T1291",
            "T1109",
            "T1429",
            "T1881",
            "T1GS147790",
            "T238",
            "T012AG03497",
            "T1512",
            "T1352",
            "T1750",
            "T393",
            "T161",
            "T1PD109547",
            "T1487",
            "T1223",
            "T1GS10599",
            "T541",
            "T2GS1322",
            "T1217",
            "T1PD109546",
            "T1600",
            "T1167",
            "T1344",
            "T1355"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics of Mind Training"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225530",
        "tbrcId": "1",
        "rank": 833
    },
    "T1291": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri chos yon tan kun byung gi ma drug"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1291",
        "rank": 922
    },
    "T1109": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ri chos/"
            },
            {
                "@language": "en",
                "@value": "retreat manuals"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1109",
        "rank": 914
    },
    "T1429": {
        "label": [
            {
                "@language": "en",
                "@value": "conduct of life"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dge sdig blang dor/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1429",
        "rank": 874
    },
    "T1881": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "theg pa chen po'i blo sbyong /"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1881",
        "rank": 854
    },
    "T1GS147790": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge sbyor/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1GS147790",
        "rank": 866
    },
    "T238": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam rim/"
            },
            {
                "@language": "zh-hans",
                "@value": "道次第"
            },
            {
                "@language": "en",
                "@value": "graduated approach"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T238",
        "rank": 838
    },
    "T012AG03497": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos bshad/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "explanation of the dharma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T012AG03497",
        "rank": 902
    },
    "T1512": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyes bu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1512",
        "rank": 858
    },
    "T1352": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bstan rim/"
            },
            {
                "@language": "en",
                "@value": "stages of teachings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1352",
        "rank": 842
    },
    "T1750": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs chos/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1750",
        "rank": 910
    },
    "T393": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "blo sbyong /"
            },
            {
                "@language": "en",
                "@value": "mind training"
            },
            {
                "@language": "zh-hans",
                "@value": "修心"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T393",
        "rank": 850
    },
    "T161": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phan yon/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "anuśamsā"
            },
            {
                "@language": "zh-hans",
                "@value": "功德"
            },
            {
                "@language": "en",
                "@value": "benefits to be derived"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T161",
        "rank": 882
    },
    "T1PD109547": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'chad nyan/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1PD109547",
        "rank": 894
    },
    "T1487": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyes dmigs/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1487",
        "rank": 878
    },
    "T1223": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yon tan bkod pa/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1223",
        "rank": 886
    },
    "T1GS10599": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jug sgo rnam gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1GS10599",
        "rank": 930
    },
    "T541": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyams len/"
            },
            {
                "@language": "en",
                "@value": "practice"
            },
            {
                "@language": "zh-hans",
                "@value": "修持"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T541",
        "rank": 862
    },
    "T2GS1322": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge ba/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T2GS1322",
        "rank": 870
    },
    "T1217": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang phyogs so bdun/"
            },
            {
                "@language": "en",
                "@value": "37 factors of enlightenment"
            },
            {
                "@language": "zh-hans",
                "@value": "三十七道品"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1217",
        "rank": 846
    },
    "T1PD109546": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bshad sgrub/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1PD109546",
        "rank": 898
    },
    "T1600": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ri chos snying po ma drug"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1600",
        "rank": 918
    },
    "T1167": {
        "label": [
            {
                "@language": "en",
                "@value": "idioms"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpe chos/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1167",
        "rank": 906
    },
    "T1344": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbyor chos drug"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1344",
        "rank": 890
    },
    "T1355": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "khrom bshad/"
        },
        "parent": "O9TAXTBRC2016052KG225531",
        "tbrcId": "T1355",
        "rank": 926
    },
    "O9TAXTBRC2016052KG225556": {
        "sub": [
            "O9TAXTBRC2016052KG225562",
            "T1285",
            "T1KG8469",
            "O9TAXTBRC2016052KG225560",
            "O9TAXTBRC2016052KG225571",
            "T179",
            "O9TAXTBRC2016052KG225565"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "The System of the General Vehicles"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "theg pa thun mong gi lam sbyong /"
            },
            {
                "@language": "zh-hans",
                "@value": "共乘修心道次"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225530",
        "tbrcId": "2",
        "rank": 935
    },
    "O9TAXTBRC2016052KG225562": {
        "sub": [
            "T2061",
            "T2088"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "死亡无常"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chi ba mi rtag pa/"
            },
            {
                "@language": "en",
                "@value": "The death and impermanence"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "2",
        "rank": 962
    },
    "T2061": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi rtag pa/"
            },
            {
                "@language": "en",
                "@value": "impermanence"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225562",
        "tbrcId": "T2061",
        "rank": 971
    },
    "T2088": {
        "label": [
            {
                "@language": "en",
                "@value": "death and impermanence"
            },
            {
                "@language": "zh-hans",
                "@value": "死亡无常"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'chi ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225562",
        "tbrcId": "T2088",
        "rank": 967
    },
    "T1285": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lam zab thun mong /"
        },
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "T1285",
        "rank": 948
    },
    "T1KG8469": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "blo ldog rnam bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "T1KG8469",
        "rank": 944
    },
    "O9TAXTBRC2016052KG225560": {
        "sub": [
            "T1KG8470"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dal 'byor rnyed dka'"
            },
            {
                "@language": "en",
                "@value": "The difficulty of finding the freedoms and endowments"
            },
            {
                "@language": "zh-hans",
                "@value": "人身难得"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "1",
        "rank": 952
    },
    "T1KG8470": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dal 'byor rnyed dka'"
        },
        "parent": "O9TAXTBRC2016052KG225560",
        "tbrcId": "T1KG8470",
        "rank": 957
    },
    "O9TAXTBRC2016052KG225571": {
        "sub": [
            "T2126",
            "T00EGS1017686",
            "T2GS1007"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "las rgyu 'bras/"
            },
            {
                "@language": "zh-hans",
                "@value": "因果业报"
            },
            {
                "@language": "en",
                "@value": "The causes and consequences of karmic actions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "4",
        "rank": 1002
    },
    "T2126": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "las rgyu 'bras/"
            },
            {
                "@language": "zh-hans",
                "@value": "因果业报"
            },
            {
                "@language": "en",
                "@value": "karmic causality"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225571",
        "tbrcId": "T2126",
        "rank": 1007
    },
    "T00EGS1017686": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "hetu phala"
            },
            {
                "@language": "zh-hans",
                "@value": "因果"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgyu 'bras/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225571",
        "tbrcId": "T00EGS1017686",
        "rank": 1011
    },
    "T2GS1007": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge 'bras/"
        },
        "parent": "O9TAXTBRC2016052KG225571",
        "tbrcId": "T2GS1007",
        "rank": 1015
    },
    "T179": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thun mong sngon 'gro/"
            },
            {
                "@language": "zh-hans",
                "@value": "共同前行"
            },
            {
                "@language": "en",
                "@value": "general preliminaries"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "T179",
        "rank": 940
    },
    "O9TAXTBRC2016052KG225565": {
        "sub": [
            "T1413",
            "T210",
            "T1908",
            "T2367",
            "T1556"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "khor ba'i sdug bsngal/"
            },
            {
                "@language": "en",
                "@value": "The sufferings of samsara"
            },
            {
                "@language": "zh-hans",
                "@value": "轮回痛苦"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225556",
        "tbrcId": "3",
        "rank": 976
    },
    "T1413": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khor ba/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samsara"
            },
            {
                "@language": "zh-hans",
                "@value": "轮回"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225565",
        "tbrcId": "T1413",
        "rank": 981
    },
    "T210": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "出离心"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nges 'byung /"
            },
            {
                "@language": "en",
                "@value": "desire to escape the cyclic existence; revulsion at samsara"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samsaran nihsaranam"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225565",
        "tbrcId": "T210",
        "rank": 989
    },
    "T1908": {
        "label": [
            {
                "@language": "en",
                "@value": "solitude"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dben pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225565",
        "tbrcId": "T1908",
        "rank": 997
    },
    "T2367": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'khrul snang /"
        },
        "parent": "O9TAXTBRC2016052KG225565",
        "tbrcId": "T2367",
        "rank": 985
    },
    "T1556": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sdug bsngal gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225565",
        "tbrcId": "T1556",
        "rank": 993
    },
    "O9TAXTBRC2016052KG225575": {
        "sub": [
            "O9TAXTBRC2016052KG225577",
            "O9TAXTBRC2016052KG225600",
            "O9TAXTBRC2016052KG225591",
            "O9TAXTBRC2016052KG225581",
            "T1254",
            "O9TAXTBRC2016052KG225629"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "The system of the special vehicles"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "theg chen thun min gyi lam sbyong /"
            },
            {
                "@language": "zh-hans",
                "@value": "大乘修心道次"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225530",
        "tbrcId": "3",
        "rank": 1021
    },
    "O9TAXTBRC2016052KG225577": {
        "sub": [
            "T1693",
            "T1GS108073",
            "T2081"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bshes gnyen bsten tshul/"
            },
            {
                "@language": "en",
                "@value": "The attending on a spiritual friend"
            },
            {
                "@language": "zh-hans",
                "@value": "拜师"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "1",
        "rank": 1030
    },
    "T1693": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mos gus/"
            },
            {
                "@language": "en",
                "@value": "respect, interest, admiration, devotion"
            },
            {
                "@language": "zh-hans",
                "@value": "仰慕｜敬重｜敬信｜虔敬"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225577",
        "tbrcId": "T1693",
        "rank": 1039
    },
    "T1GS108073": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtang rag"
            },
            {
                "@language": "zh-hans",
                "@value": "酬谢"
            },
            {
                "@language": "en",
                "@value": "thanksgiving"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225577",
        "tbrcId": "T1GS108073",
        "rank": 1043
    },
    "T2081": {
        "label": [
            {
                "@language": "en",
                "@value": "guru-disciple relationship"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma bsten tshul/"
            },
            {
                "@language": "zh-hans",
                "@value": "如何拜师"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225577",
        "tbrcId": "T2081",
        "rank": 1035
    },
    "O9TAXTBRC2016052KG225600": {
        "sub": [
            "T1887",
            "T1362",
            "T1784",
            "T444",
            "T337",
            "T1092",
            "T1051",
            "T849",
            "T2247",
            "T932",
            "T433",
            "T2560",
            "T479",
            "T140",
            "T343",
            "T23",
            "T1938",
            "T34",
            "T1689",
            "T1892",
            "T1721",
            "T2001",
            "T2183",
            "T2356",
            "T859",
            "T529",
            "T880",
            "T412"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "The way of accumulating merits and purification obscurations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs gsog sgrib sbyong /"
            },
            {
                "@language": "zh-hans",
                "@value": "积资除障"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "4",
        "rank": 1128
    },
    "T1887": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyes rabs gsol 'debs/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1887",
        "rank": 1217
    },
    "T1362": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "maN+Dal gyi mchod pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "maṇḍalapūjāvidhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1362",
        "rank": 1157
    },
    "T1784": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "积聚资粮"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bsod nams bsag thabs/"
            },
            {
                "@language": "en",
                "@value": "accumulating merit"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1784",
        "rank": 1133
    },
    "T444": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshe thar/"
            },
            {
                "@language": "en",
                "@value": "animal liberation"
            },
            {
                "@language": "zh-hans",
                "@value": "放生"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T444",
        "rank": 1197
    },
    "T337": {
        "label": [
            {
                "@language": "en",
                "@value": "confession of misdeeds"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bshags pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "忏悔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T337",
        "rank": 1177
    },
    "T1092": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ltung bshags/"
            },
            {
                "@language": "en",
                "@value": "confessions of error"
            },
            {
                "@language": "zh-hans",
                "@value": "悔罪｜忏悔"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1092",
        "rank": 1189
    },
    "T1051": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mchod bstod/"
            },
            {
                "@language": "zh-hans",
                "@value": "供赞"
            },
            {
                "@language": "en",
                "@value": "offerings and praises"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1051",
        "rank": 1173
    },
    "T849": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rgyun bshags/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T849",
        "rank": 1193
    },
    "T2247": {
        "label": [
            {
                "@language": "en",
                "@value": "purify evil deeds and obscurations"
            },
            {
                "@language": "zh-hans",
                "@value": "消除罪障的方法"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sdig sgrib sbyong thabs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T2247",
        "rank": 1137
    },
    "T932": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yan lag bdun pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "七支"
            },
            {
                "@language": "en",
                "@value": "seven branches"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T932",
        "rank": 1141
    },
    "T433": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag 'tshal/"
            },
            {
                "@language": "zh-hans",
                "@value": "礼敬"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vandanam"
            },
            {
                "@language": "en",
                "@value": "prostrations"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T433",
        "rank": 1145
    },
    "T2560": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sdig bshags/"
            },
            {
                "@language": "zh-hans",
                "@value": "忏罪"
            },
            {
                "@language": "en",
                "@value": "method for making amends for transgressions"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T2560",
        "rank": 1181
    },
    "T479": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mar me'i smon lam/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T479",
        "rank": 1233
    },
    "T140": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsol 'debs/"
            },
            {
                "@language": "en",
                "@value": "reverential petition"
            },
            {
                "@language": "zh-hans",
                "@value": "祈请"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T140",
        "rank": 1213
    },
    "T343": {
        "label": [
            {
                "@language": "en",
                "@value": "requesting"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skul ba/"
            },
            {
                "@language": "zh-hans",
                "@value": "请求"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T343",
        "rank": 1209
    },
    "T23": {
        "label": [
            {
                "@language": "en",
                "@value": "turning the merit"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bsngo ba/"
            },
            {
                "@language": "zh-hans",
                "@value": "回向"
            },
            {
                "@language": "sa-alalc97",
                "@value": "parinamana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T23",
        "rank": 1221
    },
    "T1938": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "me tog mchod pa/"
            },
            {
                "@language": "en",
                "@value": "worship with flowers, flower offerings"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1938",
        "rank": 1165
    },
    "T34": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "smon lam/"
            },
            {
                "@language": "en",
                "@value": "prayer"
            },
            {
                "@language": "zh-hans",
                "@value": "祈愿"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "praṇidhāna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T34",
        "rank": 1229
    },
    "T1689": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bkra shis/"
            },
            {
                "@language": "zh-hans",
                "@value": "吉祥"
            },
            {
                "@language": "en",
                "@value": "auspicious"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "maṅgalam"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1689",
        "rank": 1241
    },
    "T1892": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skong bshags/"
            },
            {
                "@language": "en",
                "@value": "confession and purification"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1892",
        "rank": 1185
    },
    "T1721": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas stong mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T1721",
        "rank": 1161
    },
    "T2001": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bsngo smon shis brjod/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T2001",
        "rank": 1237
    },
    "T2183": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ja mchod/"
            },
            {
                "@language": "en",
                "@value": "tea ceremonies"
            },
            {
                "@language": "zh-hans",
                "@value": "茶供"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T2183",
        "rank": 1169
    },
    "T2356": {
        "label": [
            {
                "@language": "en",
                "@value": "pilgrimage"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gnas bskor/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T2356",
        "rank": 1201
    },
    "T859": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T859",
        "rank": 1149
    },
    "T529": {
        "label": [
            {
                "@language": "zh-hant",
                "@value": "供養"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mchod pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "pūjanā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T529",
        "rank": 1153
    },
    "T880": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "随喜"
            },
            {
                "@language": "en",
                "@value": "rejoice"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rjes yi rangs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T880",
        "rank": 1205
    },
    "T412": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsngo smon/"
            },
            {
                "@language": "en",
                "@value": "prayers turning the merit"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225600",
        "tbrcId": "T412",
        "rank": 1225
    },
    "O9TAXTBRC2016052KG225591": {
        "sub": [
            "T4CZ15473",
            "T1GS108652",
            "T352",
            "T2064",
            "T1694",
            "T338",
            "T465",
            "T209"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang chub sems bskyed/"
            },
            {
                "@language": "en",
                "@value": "The way of develop awakening mind"
            },
            {
                "@language": "zh-hans",
                "@value": "发心"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "3",
        "rank": 1090
    },
    "T4CZ15473": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshad med bzhi/"
            },
            {
                "@language": "en",
                "@value": "the four immeasurables"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T4CZ15473",
        "rank": 1119
    },
    "T1GS108652": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "smon sems/"
            },
            {
                "@language": "en",
                "@value": "mind of aspiration"
            },
            {
                "@language": "zh-hans",
                "@value": "愿菩提心"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T1GS108652",
        "rank": 1107
    },
    "T352": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "入菩提心"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'jug sems/"
            },
            {
                "@language": "en",
                "@value": "the bodhicitta of entering"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T352",
        "rank": 1111
    },
    "T2064": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang chub sems/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "bodhicitta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T2064",
        "rank": 1099
    },
    "T1694": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "byams snying rje/"
        },
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T1694",
        "rank": 1115
    },
    "T338": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "发心"
            },
            {
                "@language": "en",
                "@value": "generating bodhicitta"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sems bskyed/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T338",
        "rank": 1095
    },
    "T465": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "skyabs sems/"
        },
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T465",
        "rank": 1123
    },
    "T209": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "samutthana"
            },
            {
                "@language": "en",
                "@value": "motivation, intention"
            },
            {
                "@language": "zh-hans",
                "@value": "动机｜起心动念"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "kun slong /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225591",
        "tbrcId": "T209",
        "rank": 1103
    },
    "O9TAXTBRC2016052KG225581": {
        "sub": [
            "T1760",
            "T139",
            "T108",
            "T2464",
            "T3CN2026",
            "T1297",
            "T1492",
            "T4CZ16772",
            "T386"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "The way of taking refuge"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skyabs su 'gro tshul/"
            },
            {
                "@language": "zh-hans",
                "@value": "皈依"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "2",
        "rank": 1048
    },
    "T1760": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkon mchog gsum/"
            },
            {
                "@language": "en",
                "@value": "three jewels"
            },
            {
                "@language": "zh-hant",
                "@value": "三寶"
            },
            {
                "@language": "sa-alalc97",
                "@value": "triratna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T1760",
        "rank": 1057
    },
    "T139": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "资粮田"
            },
            {
                "@language": "en",
                "@value": "field of merit"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tshogs zhing /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T139",
        "rank": 1073
    },
    "T108": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "如来相好"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan dpe/"
            },
            {
                "@language": "en",
                "@value": "major and minor marks"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T108",
        "rank": 1077
    },
    "T2464": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge 'dun/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sangha"
            },
            {
                "@language": "zh-hans",
                "@value": "僧伽"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581"
    },
    "T3CN2026": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spangs rtogs/"
            },
            {
                "@language": "zh-hans",
                "@value": "断证"
            },
            {
                "@language": "en",
                "@value": "abandoning and realization"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T3CN2026",
        "rank": 1085
    },
    "T1297": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "skyabs 'gro/"
            },
            {
                "@language": "zh-hans",
                "@value": "皈依"
            },
            {
                "@language": "en",
                "@value": "taking refuge"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T1297",
        "rank": 1053
    },
    "T1492": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung dbyangs yan lag drug cu/"
            },
            {
                "@language": "en",
                "@value": "sixty aspects of melodious speech"
            },
            {
                "@language": "zh-hans",
                "@value": "六十种妙音支分"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T1492",
        "rank": 1081
    },
    "T4CZ16772": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos/"
            },
            {
                "@language": "zh-hans",
                "@value": "法"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dharma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T4CZ16772",
        "rank": 1065
    },
    "T386": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "buddhaḥ"
            },
            {
                "@language": "zh-hans",
                "@value": "佛"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sangs rgyas/"
            },
            {
                "@language": "en",
                "@value": "buddha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225581",
        "tbrcId": "T386",
        "rank": 1061
    },
    "T1254": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thun min sngon 'gro/"
            },
            {
                "@language": "en",
                "@value": "special preliminaries"
            },
            {
                "@language": "zh-hans",
                "@value": "不共前行"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "T1254",
        "rank": 1026
    },
    "O9TAXTBRC2016052KG225629": {
        "sub": [
            "T1246",
            "T1AT672",
            "T1696",
            "T545",
            "T1511",
            "T2184",
            "T1AT453",
            "T1722",
            "T00KG02742",
            "T3CN2033",
            "T1263",
            "T456",
            "T4CZ16839",
            "T1KG1401",
            "T1402",
            "T1185",
            "T58",
            "T1CZ58",
            "T1249",
            "T3CN2034",
            "T613"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "实践修持"
            },
            {
                "@language": "en",
                "@value": "The way of implement"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nyams len dngos gzhi/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225575",
        "tbrcId": "5",
        "rank": 1246
    },
    "T1246": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgom rim/"
            },
            {
                "@language": "en",
                "@value": "stages of meditation"
            },
            {
                "@language": "zh-hans",
                "@value": "禅修次第"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1246",
        "rank": 1271
    },
    "T1AT672": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rig pa ngo sprod/"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1AT672",
        "rank": 1586
    },
    "T1696": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos brgyad mgo snyoms/"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1696",
        "rank": 1295
    },
    "T545": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lam khyer/"
            },
            {
                "@language": "en",
                "@value": "proceed along the path"
            },
            {
                "@language": "zh-hans",
                "@value": "道用"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T545",
        "rank": 1303
    },
    "T1511": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sems nyid ngal gso/"
            },
            {
                "@language": "zh-hans",
                "@value": "心性休息"
            },
            {
                "@language": "en",
                "@value": "aking ease in mind itself"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1511",
        "rank": 1594
    },
    "T2184": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnas gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T2184",
        "rank": 1578
    },
    "T1AT453": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos lnga /"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1AT453",
        "rank": 1582
    },
    "T1722": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes rab/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prajna"
            },
            {
                "@language": "zh-hans",
                "@value": "智慧"
            },
            {
                "@language": "en",
                "@value": "wisdom"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1722",
        "rank": 1263
    },
    "T00KG02742": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhal shes/"
            },
            {
                "@language": "en",
                "@value": "oral directions"
            },
            {
                "@language": "zh-hans",
                "@value": "亲训"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T00KG02742",
        "rank": 1287
    },
    "T3CN2033": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "六度"
            },
            {
                "@language": "en",
                "@value": "six paramitas"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "phar phyin drug"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T3CN2033",
        "rank": 1251
    },
    "T1263": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku gsum ngo sprod/"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1263",
        "rank": 1590
    },
    "T456": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "man ngag"
            },
            {
                "@language": "en",
                "@value": "method used in practice"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "upadeśa"
            },
            {
                "@language": "zh-hans",
                "@value": "指示"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T456",
        "rank": 1283
    },
    "T4CZ16839": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbyin pa/"
            },
            {
                "@language": "zh-hans",
                "@value": "布施"
            },
            {
                "@language": "en",
                "@value": "generosity"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T4CZ16839",
        "rank": 1259
    },
    "T1KG1401": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhal chems/"
            },
            {
                "@language": "en",
                "@value": "testament"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1KG1401",
        "rank": 3841
    },
    "T1402": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "shar sgom/"
        },
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1402",
        "rank": 1275
    },
    "T1185": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyod pa/"
            },
            {
                "@language": "en",
                "@value": "conduct/ behavior"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "carya"
            },
            {
                "@language": "zh-hans",
                "@value": "行为"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1185",
        "rank": 1291
    },
    "T58": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhal gdams/"
            },
            {
                "@language": "en",
                "@value": "instruction"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629"
    },
    "T1CZ58": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gtong len/"
            },
            {
                "@language": "en",
                "@value": "the giving and taking [practice of bodhi chitta]"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1CZ58",
        "rank": 1299
    },
    "T1249": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgom pa/"
            },
            {
                "@language": "zh-hant",
                "@value": "觀想｜观想"
            },
            {
                "@language": "en",
                "@value": "contemplation"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "bhāvanā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T1249",
        "rank": 1267
    },
    "T3CN2034": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsdu dngos bzhi/"
            },
            {
                "@language": "en",
                "@value": "four means of magnetizing"
            },
            {
                "@language": "zh-hans",
                "@value": "四摄法"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T3CN2034",
        "rank": 1255
    },
    "T613": {
        "label": [
            {
                "@language": "en",
                "@value": "instruction"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "avavāda"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gdams ngag"
            },
            {
                "@language": "zh-hant",
                "@value": "語訣"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225629",
        "tbrcId": "T613",
        "rank": 1279
    },
    "O9TAXTBRC2016052KG225651": {
        "sub": [
            "T1358",
            "T1335",
            "T1504",
            "T1289"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsdus don/"
            },
            {
                "@language": "zh-hans",
                "@value": "概要"
            },
            {
                "@language": "en",
                "@value": "Summary Topics of Mind Training"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225530",
        "tbrcId": "4",
        "rank": 1309
    },
    "T1358": {
        "label": [
            {
                "@language": "en",
                "@value": "Three Main Points of the Path"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lam gyi gnad gsum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225651",
        "tbrcId": "T1358",
        "rank": 1318
    },
    "T1335": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lta sgom spyod/"
            },
            {
                "@language": "en",
                "@value": "view, contemplation & practice"
            },
            {
                "@language": "zh-hans",
                "@value": "见、修、行"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225651",
        "tbrcId": "T1335",
        "rank": 1314
    },
    "T1504": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhen pa bzhi bral/"
            },
            {
                "@language": "zh-hans",
                "@value": "远离四种执着"
            },
            {
                "@language": "en",
                "@value": "free from the four attachments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225651",
        "tbrcId": "T1504",
        "rank": 1326
    },
    "T1289": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge sbyor bdun pa/"
        },
        "parent": "O9TAXTBRC2016052KG225651",
        "tbrcId": "T1289",
        "rank": 1330
    },
    "O9TAXTBRC2016052KG225656": {
        "sub": [
            "O9TAXTBRC2016052KG225657",
            "O9TAXTBRC2016052KG225664",
            "O9TAXTBRC2016052KG225694"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grub mtha'"
            },
            {
                "@language": "en",
                "@value": "Siddhanta/Tenet"
            },
            {
                "@language": "zh-hans",
                "@value": "宗理"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "6",
        "rank": 1336
    },
    "O9TAXTBRC2016052KG225657": {
        "sub": [
            "T2023",
            "T385",
            "T1215",
            "T381",
            "T299",
            "T2192"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "en",
                "@value": "General Topics of Siddhanta"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225656",
        "tbrcId": "1",
        "rank": 1341
    },
    "T2023": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dogs gcod/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "clarification of doubtful points"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T2023",
        "rank": 1362
    },
    "T385": {
        "label": [
            {
                "@language": "en",
                "@value": "philosophical schools"
            },
            {
                "@language": "sa-alalc97",
                "@value": "siddhanta"
            },
            {
                "@language": "zh-hans",
                "@value": "宗派"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "grub mtha'"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T385",
        "rank": 1346
    },
    "T1215": {
        "label": [
            {
                "@language": "en",
                "@value": "analytic treatment"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mtha' gcod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T1215",
        "rank": 1358
    },
    "T381": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lta ba/"
            },
            {
                "@language": "zh-hans",
                "@value": "见地"
            },
            {
                "@language": "en",
                "@value": "view"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T381",
        "rank": 1350
    },
    "T299": {
        "label": [
            {
                "@language": "en",
                "@value": "literary criticism"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dpyad pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "vicāra"
            },
            {
                "@language": "zh-hans",
                "@value": "伺察"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T299",
        "rank": 1354
    },
    "T2192": {
        "label": [
            {
                "@language": "en",
                "@value": "founder of a new tradition"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "shing rta'i srol 'byed/"
            },
            {
                "@language": "zh-hans",
                "@value": "founder of a new tradition开山祖师"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225657",
        "tbrcId": "T2192",
        "rank": 1366
    },
    "O9TAXTBRC2016052KG225664": {
        "sub": [
            "T632",
            "T4CZ16807",
            "T1KG5756",
            "T3CN2032",
            "T1502",
            "T2GS1043",
            "T4CZ16806",
            "T1764",
            "T00EGS1017603",
            "T1873",
            "T2077",
            "T1KG3717",
            "T2055",
            "T3JT4939",
            "T3CN2031",
            "T2058",
            "T233",
            "T00EGS1017602",
            "T2060",
            "T00EGS1017607",
            "T942",
            "T2343",
            "T2MS12316",
            "T1441",
            "T1528",
            "T2342",
            "T00EGS1017606",
            "T2194",
            "T2072"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Indian Schools"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'phags yul gyi grub mtha'"
            },
            {
                "@language": "zh-hans",
                "@value": "印度学派"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225656",
        "tbrcId": "2",
        "rank": 1371
    },
    "T632": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "tattvam"
            },
            {
                "@language": "zh-hans",
                "@value": "真性"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "de kho na nyid/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T632",
        "rank": 1440
    },
    "T4CZ16807": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor spyod pa'i sa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "yogacaraya bhumi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T4CZ16807",
        "rank": 1488
    },
    "T1KG5756": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos kyi sdom bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1KG5756",
        "rank": 1448
    },
    "T3CN2032": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdo sde pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Sautrantika"
            },
            {
                "@language": "zh-hans",
                "@value": "经部"
            },
            {
                "@language": "en",
                "@value": "Sautrantika school,"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T3CN2032",
        "rank": 1380
    },
    "T1502": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "anatmamudra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bdag med chos kyi phyag rgya/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1502",
        "rank": 1452
    },
    "T2GS1043": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "nyan thos/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sravaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2GS1043",
        "rank": 1408
    },
    "T4CZ16806": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "yogacara"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnal 'byor spyod pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T4CZ16806",
        "rank": 1484
    },
    "T1764": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dbu ma lta ba/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1764",
        "rank": 1400
    },
    "T00EGS1017603": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhan skyes spong tshul/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T00EGS1017603",
        "rank": 1476
    },
    "T1873": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "三乘"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "theg pa gsum/"
            },
            {
                "@language": "en",
                "@value": "The three yanas/ vehicles"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "triyāna"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1873",
        "rank": 1404
    },
    "T2077": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde gshegs snying po/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "de bzhin gshegs pa'i snying po"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2077",
        "rank": 1456
    },
    "T1KG3717": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "theg chen/"
            },
            {
                "@language": "zh-hans",
                "@value": "大乘"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahayāna"
            },
            {
                "@language": "en",
                "@value": "great vehical"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1KG3717",
        "rank": 1420
    },
    "T2055": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "cittamātra"
            },
            {
                "@language": "zh-hans",
                "@value": "唯识"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sems tsam pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2055",
        "rank": 1384
    },
    "T3JT4939": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "pratekyabuddha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rang rgyal/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T3JT4939",
        "rank": 1412
    },
    "T3CN2031": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bye brag smra ba/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Vaibhashika"
            },
            {
                "@language": "en",
                "@value": "Vaibhashika school"
            },
            {
                "@language": "zh-hans",
                "@value": "毘婆沙部｜有部"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T3CN2031",
        "rank": 1376
    },
    "T2058": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhi lam 'bras bu/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2058",
        "rank": 1428
    },
    "T233": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbu ma/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "madhyamaka"
            },
            {
                "@language": "en",
                "@value": "middle way"
            },
            {
                "@language": "zh-hans",
                "@value": "中道"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T233",
        "rank": 1388
    },
    "T00EGS1017602": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "kun btags spong tshul/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T00EGS1017602",
        "rank": 1472
    },
    "T2060": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "theg dman/"
            },
            {
                "@language": "zh-hans",
                "@value": "小乘"
            },
            {
                "@language": "en-x-mixed",
                "@value": "hinayana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2060",
        "rank": 1416
    },
    "T00EGS1017607": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhig pa dngos po/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T00EGS1017607",
        "rank": 1468
    },
    "T942": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "drang nges/"
            },
            {
                "@language": "en",
                "@value": "provisional meaning and true meaning"
            },
            {
                "@language": "zh-hant",
                "@value": "了义不了义｜了義不了義"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T942",
        "rank": 1460
    },
    "T2343": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "thal 'gyur pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Prasangika"
            },
            {
                "@language": "zh-hans",
                "@value": "应成派"
            },
            {
                "@language": "en",
                "@value": "consequentialist"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2343",
        "rank": 1396
    },
    "T2MS12316": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phar phyin theg pa/"
            },
            {
                "@language": "en",
                "@value": "paramitayana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2MS12316",
        "rank": 1424
    },
    "T1441": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bden gnyis/"
            },
            {
                "@language": "en",
                "@value": "the two truths"
            },
            {
                "@language": "sa-alalc97",
                "@value": "dvayasatya, satyadvaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1441",
        "rank": 1432
    },
    "T1528": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbas don/"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T1528",
        "rank": 1464
    },
    "T2342": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rang rgyud pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "Svatantrika"
            },
            {
                "@language": "zh-hans",
                "@value": "自续派"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2342",
        "rank": 1392
    },
    "T00EGS1017606": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "chos can mthun snang dgag mi dgag"
        },
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T00EGS1017606",
        "rank": 1480
    },
    "T2194": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "法界"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "dharmadhātu"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos dbyings/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2194",
        "rank": 1444
    },
    "T2072": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "空性"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "stong nyid/"
            },
            {
                "@language": "en",
                "@value": "emptiness"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "śūnyatā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225664",
        "tbrcId": "T2072",
        "rank": 1436
    },
    "O9TAXTBRC2016052KG225694": {
        "sub": [
            "T1261",
            "T2GS1324",
            "T235",
            "T2CN4664",
            "T820",
            "T1543",
            "T1334",
            "T1788",
            "T2056",
            "T2177",
            "T2057",
            "T2193",
            "T199",
            "T1169",
            "T2076",
            "T1356",
            "T1546",
            "T1514",
            "T354",
            "T2392"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "藏地学派"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod kyi lta grub/"
            },
            {
                "@language": "en",
                "@value": "Tibetan Schools"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225656",
        "tbrcId": "3",
        "rank": 1493
    },
    "T1261": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag chen lhan cig skyes sbyor/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1261",
        "rank": 1570
    },
    "T2GS1324": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "法身本智"
            },
            {
                "@language": "en",
                "@value": "dharmakaya wisdom"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos sku'i ye shes/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2GS1324",
        "rank": 1598
    },
    "T235": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrub brgyud brgyad/"
            },
            {
                "@language": "en",
                "@value": "eight practice systems"
            },
            {
                "@language": "zh-hans",
                "@value": "八大修证传承"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T235",
        "rank": 1498
    },
    "T2CN4664": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdzogs dbu gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2CN4664",
        "rank": 1554
    },
    "T820": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thugs chen phyag chen zung 'jug"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T820",
        "rank": 1562
    },
    "T1543": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "道果"
            },
            {
                "@language": "en",
                "@value": "path and result"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lam 'bras/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1543",
        "rank": 1538
    },
    "T1334": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbu ma chen po/"
            },
            {
                "@language": "zh-hans",
                "@value": "大中观"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahamadyamika"
            },
            {
                "@language": "en",
                "@value": "great middle way"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1334",
        "rank": 1526
    },
    "T1788": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "phyag rdzogs zung 'jug"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1788",
        "rank": 1558
    },
    "T2056": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnas lugs/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2056",
        "rank": 1514
    },
    "T2177": {
        "label": [
            {
                "@language": "en",
                "@value": "intrinsic emptiness"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rang stong /"
            },
            {
                "@language": "zh-hans",
                "@value": "自空"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2177",
        "rank": 1518
    },
    "T2057": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "hwa shang lta ba/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2057",
        "rank": 1550
    },
    "T2193": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas kyi snying po/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2193",
        "rank": 1546
    },
    "T199": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag chen/"
            },
            {
                "@language": "zh-hans",
                "@value": "大手印"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "mahāmudrā"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T199",
        "rank": 1530
    },
    "T1169": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzhan stong /"
            },
            {
                "@language": "en",
                "@value": "extrinsic emptiness"
            },
            {
                "@language": "zh-hans",
                "@value": "他空"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1169",
        "rank": 1522
    },
    "T2076": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gnyug ma'i sems/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2076",
        "rank": 1566
    },
    "T1356": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khor 'das dbyer med/"
            },
            {
                "@language": "en",
                "@value": "inseparable samsara and nirvana"
            },
            {
                "@language": "zh-hans",
                "@value": "轮涅无别"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1356",
        "rank": 1506
    },
    "T1546": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lhan cig skyes grub/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1546",
        "rank": 1574
    },
    "T1514": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "srid zhi mnyam nyid/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T1514",
        "rank": 1510
    },
    "T354": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdzogs chen/"
            },
            {
                "@language": "en",
                "@value": "great perfection"
            },
            {
                "@language": "zh-hans",
                "@value": "大圆满"
            },
            {
                "@language": "sa-alalc97",
                "@value": "mahasanti"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T354",
        "rank": 1534
    },
    "T2392": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "snang stong dbyer med/"
        },
        "parent": "O9TAXTBRC2016052KG225694",
        "tbrcId": "T2392",
        "rank": 1502
    },
    "O9TAXTBRC2016052KG225512": {
        "sub": [
            "O9TAXTBRC2016052KG225513",
            "O9TAXTBRC2016052KG225515",
            "O9TAXTBRC2016052KG225528"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon pa'i sde snod/"
            },
            {
                "@language": "en",
                "@value": "Abhidhamma Pitaka"
            },
            {
                "@language": "zh-hans",
                "@value": "论藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "4",
        "rank": 748
    },
    "O9TAXTBRC2016052KG225513": {
        "sub": [
            "T59"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics of Abhidhamma Pitaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225512",
        "tbrcId": "1",
        "rank": 753
    },
    "T59": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "abhidharma"
            },
            {
                "@language": "zh-hans",
                "@value": "俱舍"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225513"
    },
    "O9TAXTBRC2016052KG225515": {
        "sub": [
            "T2627",
            "T2MS14791",
            "T00KG07620",
            "T4CZ15520",
            "T2281",
            "T00EGS1017856",
            "T1981",
            "T2551",
            "T1361",
            "T2394",
            "T2211",
            "T1GS1589"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung don bye brag"
            },
            {
                "@language": "en",
                "@value": "Specific Topics of Abhidhamma Pitaka"
            },
            {
                "@language": "zh-hans",
                "@value": "分支主题"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225512",
        "tbrcId": "2",
        "rank": 763
    },
    "T2627": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "四圣谛"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "catvāri āryasatyāni"
            },
            {
                "@language": "en",
                "@value": "cattāri ariyasaccāni"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bden pa bzhi/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2627",
        "rank": 768
    },
    "T2MS14791": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "krtyuga"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdzogs ldan/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2MS14791",
        "rank": 808
    },
    "T00KG07620": {
        "label": [
            {
                "@language": "en",
                "@value": "past and future life"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skye ba snga phyi/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T00KG07620",
        "rank": 784
    },
    "T4CZ15520": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbang po lnga /"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "pañcendriyāṇi"
            },
            {
                "@language": "zh-hans",
                "@value": "五根"
            },
            {
                "@language": "en",
                "@value": "five sense"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T4CZ15520",
        "rank": 796
    },
    "T2281": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rten 'brel/"
            },
            {
                "@language": "zh-hans",
                "@value": "缘起"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2281",
        "rank": 776
    },
    "T00EGS1017856": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "劫波"
            },
            {
                "@language": "sa-alalc97",
                "@value": "kalpa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bskal pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T00EGS1017856",
        "rank": 804
    },
    "T1981": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phung po lnga /"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "5 aggregates"
            },
            {
                "@language": "zh-hans",
                "@value": "五蕴"
            },
            {
                "@language": "en",
                "@value": "Five Components"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T1981",
        "rank": 792
    },
    "T2551": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "snod bcud kyi 'jig rten/"
            },
            {
                "@language": "en",
                "@value": "cosmos"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2551",
        "rank": 800
    },
    "T1361": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rten 'brel bcu gnyis/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pratityasamutpada"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T1361",
        "rank": 772
    },
    "T2394": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dmyal ba/"
            },
            {
                "@language": "en",
                "@value": "hell"
            },
            {
                "@language": "sa-alalc97",
                "@value": "naraka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2394",
        "rank": 812
    },
    "T2211": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rten 'brel bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T2211",
        "rank": 780
    },
    "T1GS1589": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'byung ba bzhi/"
            },
            {
                "@language": "en",
                "@value": "four elements"
            },
            {
                "@language": "zh-hans",
                "@value": "四大"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225515",
        "tbrcId": "T1GS1589",
        "rank": 788
    },
    "O9TAXTBRC2016052KG225528": {
        "sub": [
            "T1231"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod don gnad/"
            },
            {
                "@language": "en",
                "@value": "Related Topics of Abhidhamma Pitaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225512",
        "tbrcId": "4",
        "rank": 152
    },
    "T1231": {
        "label": [
            {
                "@language": "sa-x-iast",
                "@value": "dharmaparyāya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos kyi rnam grangs/"
            },
            {
                "@language": "en",
                "@value": "numerical categories"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225528",
        "tbrcId": "T1231",
        "rank": 822
    },
    "O9TAXTBRC2016052KG225398": {
        "sub": [
            "O9TAXTBRC2016052KG225399",
            "O9TAXTBRC2016052KG225406",
            "O9TAXTBRC2016052KG225427"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdo sde'i sde snod/"
            },
            {
                "@language": "en",
                "@value": "Sutta Pitaka"
            },
            {
                "@language": "zh-hans",
                "@value": "经藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "2",
        "rank": 318
    },
    "O9TAXTBRC2016052KG225399": {
        "sub": [
            "T1GS104308",
            "T2008",
            "T2005",
            "T00UNK196",
            "T769",
            "T00AG0322"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "en",
                "@value": "General Topics of Sutta Pitika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225398",
        "tbrcId": "1",
        "rank": 323
    },
    "T1GS104308": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sbrul mdo/"
        },
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T1GS104308",
        "rank": 344
    },
    "T2008": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dkon brtsegs/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "ratnakuta"
            },
            {
                "@language": "zh-hans",
                "@value": "宝积经"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T2008",
        "rank": 336
    },
    "T2005": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sher phyin/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "prajñāpāramitā"
            },
            {
                "@language": "en",
                "@value": "prajnaparamita sutra"
            },
            {
                "@language": "zh-hant",
                "@value": "般若波羅蜜多"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T2005",
        "rank": 332
    },
    "T00UNK196": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nges don gyi mdo/"
        },
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T00UNK196",
        "rank": 348
    },
    "T769": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdo/"
            },
            {
                "@language": "zh-hans",
                "@value": "经"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sutra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T769",
        "rank": 328
    },
    "T00AG0322": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phal chen/"
            },
            {
                "@language": "zh-hans",
                "@value": "华严经"
            },
            {
                "@language": "en",
                "@value": "avatamsaka"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Avataṃsaka Sūtra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225399",
        "tbrcId": "T00AG0322",
        "rank": 340
    },
    "O9TAXTBRC2016052KG225406": {
        "sub": [
            "T2212",
            "T2273",
            "T2MS14792",
            "T211",
            "T313",
            "T2271",
            "T1091",
            "T1988",
            "T2026",
            "T2117",
            "T2277",
            "T36",
            "T1230",
            "T2261",
            "T1748",
            "T2025",
            "T2004",
            "T2274",
            "T1796",
            "T333"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung don bye brag"
            },
            {
                "@language": "zh-hans",
                "@value": "分支主题"
            },
            {
                "@language": "en",
                "@value": "Specific Topics of Sutta Pitika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225398",
        "tbrcId": "2",
        "rank": 353
    },
    "T2212": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "stong nyid snying rje/"
        },
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T2273": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grol ba bcu gcig"
            },
            {
                "@language": "en",
                "@value": "11 types of liberation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T2MS14792": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don dam bden pa/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "paramaartha satya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T211": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsam gzugs/"
            },
            {
                "@language": "en",
                "@value": "thought and form"
            },
            {
                "@language": "sa-alalc97",
                "@value": "rupadhatu arupyadhatu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T211",
        "rank": 374
    },
    "T313": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "samatha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhi gnas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T313",
        "rank": 386
    },
    "T2271": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mthong grol 'khor lo/"
        },
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T1091": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "道地"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sa lam/"
            },
            {
                "@language": "en",
                "@value": "paths and bhumis"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T1091",
        "rank": 378
    },
    "T1988": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ting 'dzin/"
            },
            {
                "@language": "zh-hans",
                "@value": "三摩地"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "samādhi"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T1988",
        "rank": 382
    },
    "T2026": {
        "label": [
            {
                "@language": "en",
                "@value": "three gates of emancipation"
            },
            {
                "@language": "zh-hans",
                "@value": "三解脱门"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rnam thar sgo gsum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T2026",
        "rank": 410
    },
    "T2117": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhugs gnas/"
        },
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T2117",
        "rank": 398
    },
    "T2277": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon shes/"
            },
            {
                "@language": "en",
                "@value": "prescience"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T2277",
        "rank": 394
    },
    "T36": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phar phyin/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "paramita"
            },
            {
                "@language": "zh-hans",
                "@value": "般若"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T36",
        "rank": 358
    },
    "T1230": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'gog snyoms/"
        },
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T1230",
        "rank": 402
    },
    "T2261": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan gsol ba/"
            },
            {
                "@language": "en",
                "@value": "document bestowing name"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T1748": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge 'dun nyi shu/"
            },
            {
                "@language": "zh-hans",
                "@value": "二十僧伽"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T1748",
        "rank": 370
    },
    "T2025": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dngos po brgyad/"
            },
            {
                "@language": "zh-hans",
                "@value": "八事"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T2025",
        "rank": 362
    },
    "T2004": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "myang 'das/"
        },
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T2004",
        "rank": 406
    },
    "T2274": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grol ba drug"
            },
            {
                "@language": "en",
                "@value": "six liberations, the six types of liberation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406"
    },
    "T1796": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lhag mthong /"
            },
            {
                "@language": "sa-alalc97",
                "@value": "vipasyana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T1796",
        "rank": 390
    },
    "T333": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "don bdun cu/"
            },
            {
                "@language": "en",
                "@value": "seventy topics"
            },
            {
                "@language": "zh-hans",
                "@value": "七十义"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225406",
        "tbrcId": "T333",
        "rank": 366
    },
    "O9TAXTBRC2016052KG225427": {
        "sub": [
            "T1KG9082",
            "T1353",
            "T1GS148709",
            "T1130",
            "T1GS138846",
            "T2202",
            "T2078",
            "T2MS5993",
            "T1414",
            "T01JR185",
            "T535",
            "T1PD106850",
            "T2009",
            "T634",
            "T2MS14938",
            "T2090",
            "T1490",
            "T1132",
            "T2027",
            "T2363",
            "T2345",
            "T1AT296",
            "T2351",
            "T2GS224"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Related Topics of Sutta Pitika"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod gzhi/"
            },
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225398",
        "tbrcId": "4",
        "rank": 152
    },
    "T1KG9082": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yang dag mtha'"
        },
        "parent": "O9TAXTBRC2016052KG225427"
    },
    "T1353": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bde ba can/"
            },
            {
                "@language": "zh-hans",
                "@value": "极乐净土"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sukhavati"
            },
            {
                "@language": "en",
                "@value": "paradise of amitabha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1353",
        "rank": 444
    },
    "T1GS148709": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "akanistha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'og min zhing /"
            },
            {
                "@language": "zh-hant",
                "@value": "密严剎土"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1GS148709",
        "rank": 480
    },
    "T1130": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lcang lo can/"
            },
            {
                "@language": "en",
                "@value": "pure land of Vajrapani"
            },
            {
                "@language": "zh-hans",
                "@value": "杨柳宫"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1130",
        "rank": 456
    },
    "T1GS138846": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zangs mdog dpal ri/"
            },
            {
                "@language": "zh-hans",
                "@value": "铜色吉祥山"
            },
            {
                "@language": "en",
                "@value": "copper colored mountain"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1GS138846",
        "rank": 476
    },
    "T2202": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "g.yu lo bkod/"
            },
            {
                "@language": "en",
                "@value": "Yulo Köpa"
            },
            {
                "@language": "zh-hans",
                "@value": "璁叶庄严刹土"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2202",
        "rank": 460
    },
    "T2078": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "dharmakāya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chos sku/"
            },
            {
                "@language": "zh-hans",
                "@value": "法身"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2078",
        "rank": 428
    },
    "T2MS5993": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byams chos sde lnga /"
            },
            {
                "@language": "en",
                "@value": "five treatises of maitreya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2MS5993",
        "rank": 488
    },
    "T1414": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kun rdzob bden pa/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "samvrtisatya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427"
    },
    "T01JR185": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mtshan 'bum/"
            },
            {
                "@language": "en",
                "@value": "litany of names"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T01JR185",
        "rank": 484
    },
    "T535": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sku gsum/"
            },
            {
                "@language": "en",
                "@value": "three buddha bodies"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "trikāya"
            },
            {
                "@language": "zh-hans",
                "@value": "三身"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T535",
        "rank": 420
    },
    "T1PD106850": {
        "label": [
            {
                "@language": "en",
                "@value": "Pure Lands"
            },
            {
                "@language": "zh-hans",
                "@value": "净土"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhing khams/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Kṣetra"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1PD106850",
        "rank": 440
    },
    "T2009": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mngon dga'i zhing /"
            },
            {
                "@language": "zh-hans",
                "@value": "妙喜世界"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "abhirati"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2009",
        "rank": 448
    },
    "T634": {
        "label": [
            {
                "@language": "en",
                "@value": "Shambhala"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sham+b+ha la/"
            },
            {
                "@language": "zh-hans",
                "@value": "香巴拉"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Śambhalaḥ"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T634",
        "rank": 468
    },
    "T2MS14938": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'phags lam yan lag brgyad/"
            },
            {
                "@language": "en",
                "@value": "eightfold noble path"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427"
    },
    "T2090": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kun rdzob byang sems/"
            },
            {
                "@language": "en",
                "@value": "conventional bodhichitta"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427"
    },
    "T1490": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sku bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1490",
        "rank": 424
    },
    "T1132": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "po ta la/"
            },
            {
                "@language": "en",
                "@value": "Potala"
            },
            {
                "@language": "zh-hans",
                "@value": "普陀山"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1132",
        "rank": 452
    },
    "T2027": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtag pa dang mi rtag pa/"
        },
        "parent": "O9TAXTBRC2016052KG225427"
    },
    "T2363": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "longs sku/"
            },
            {
                "@language": "en",
                "@value": "Enjoyment Body"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "sambhogakaya"
            },
            {
                "@language": "zh-hans",
                "@value": "报身"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2363",
        "rank": 432
    },
    "T2345": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sprul sku/"
            },
            {
                "@language": "en",
                "@value": "apparitional body"
            },
            {
                "@language": "zh-hans",
                "@value": "化身"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "nirmanakaya"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2345",
        "rank": 436
    },
    "T1AT296": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pad+ma drwa ba'i gling /"
        },
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T1AT296",
        "rank": 472
    },
    "T2351": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dga' ldan/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Tuṣita"
            },
            {
                "@language": "zh-hans",
                "@value": "兜率天"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2351",
        "rank": 464
    },
    "T2GS224": {
        "label": [
            {
                "@language": "en-x-mixed",
                "@value": "four supplementary topics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zur bkol bzhi/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225427",
        "tbrcId": "T2GS224",
        "rank": 492
    },
    "O9TAXTBRC2016052KG225452": {
        "sub": [
            "O9TAXTBRC2016052KG225453",
            "O9TAXTBRC2016052KG225456",
            "O9TAXTBRC2016052KG225482"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dul ba'i sde snod/"
            },
            {
                "@language": "en",
                "@value": "Vinaya Pitaka"
            },
            {
                "@language": "zh-hans",
                "@value": "律藏"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "3",
        "rank": 498
    },
    "O9TAXTBRC2016052KG225453": {
        "sub": [
            "T1426",
            "T55"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics of Vinaya Pitika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225452",
        "tbrcId": "1",
        "rank": 503
    },
    "T1426": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tshul khrims/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sila"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225453",
        "tbrcId": "T1426",
        "rank": 512
    },
    "T55": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'dul ba/"
            },
            {
                "@language": "sa-x-ewts",
                "@value": "vinaya"
            },
            {
                "@language": "en-x-mixed",
                "@value": "monastic discipline"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225453",
        "tbrcId": "T55",
        "rank": 508
    },
    "O9TAXTBRC2016052KG225456": {
        "sub": [
            "T82",
            "T2098",
            "T8LS15040",
            "T8LS16583",
            "T2413",
            "T1856",
            "T1611",
            "T1489",
            "T2MS11339",
            "T881",
            "T1757",
            "T2364",
            "T2GS1005",
            "T2578",
            "T868",
            "T809",
            "T1894",
            "T949",
            "T2GS983",
            "T1368",
            "T2KG2755",
            "T1298",
            "T1787",
            "T2195",
            "T1967"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Specific Topics of Vinaya Pitika"
            },
            {
                "@language": "zh-hans",
                "@value": "分支主题"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung don bye brag"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225452",
        "tbrcId": "2",
        "rank": 517
    },
    "T82": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "bhiksuni"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dge slong ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "比丘尼"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T82",
        "rank": 554
    },
    "T2098": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge bsnyen/"
            },
            {
                "@language": "zh-hans",
                "@value": "优婆塞"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "aup'asaka"
            },
            {
                "@language": "en",
                "@value": "Upasaka"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2098",
        "rank": 530
    },
    "T8LS15040": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge slong gi sdom pa/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T8LS15040",
        "rank": 570
    },
    "T8LS16583": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyir bcos/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "pratividhAn"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T8LS16583",
        "rank": 614
    },
    "T2413": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "素食"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dkar zas/"
            },
            {
                "@language": "en",
                "@value": "vegetarianism"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2413",
        "rank": 586
    },
    "T1856": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "varsika"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dbyar gnas/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1856",
        "rank": 578
    },
    "T1611": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge slong /"
            },
            {
                "@language": "en",
                "@value": "Bhiksu"
            },
            {
                "@language": "zh-hans",
                "@value": "比丘"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "Bhikṣu"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1611",
        "rank": 550
    },
    "T1489": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "nyes byas/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1489",
        "rank": 610
    },
    "T2MS11339": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'dul ba'i ltung ba/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2MS11339",
        "rank": 602
    },
    "T881": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "so thar bshags sdom/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T881",
        "rank": 558
    },
    "T1757": {
        "label": [
            {
                "@language": "en",
                "@value": "upasika"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dge bsnyen ma/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "aup'as'ik'a"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1757",
        "rank": 534
    },
    "T2364": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dgag dbye/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "pravarana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2364",
        "rank": 582
    },
    "T2GS1005": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bsnyen gnas/"
            },
            {
                "@language": "en",
                "@value": "One-day precepts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2GS1005",
        "rank": 526
    },
    "T2578": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "pham ltung /"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2578",
        "rank": 606
    },
    "T868": {
        "label": [
            {
                "@language": "en",
                "@value": "monastic ordination manuals"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'dul ba'i cho ga"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T868",
        "rank": 594
    },
    "T809": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gso sbyong /"
            },
            {
                "@language": "zh-hant",
                "@value": "布薩"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "poṣadha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T809",
        "rank": 574
    },
    "T1894": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "尼姑"
            },
            {
                "@language": "en",
                "@value": "nuns"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dge tshul ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1894",
        "rank": 542
    },
    "T949": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gzhi gsum/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T949",
        "rank": 590
    },
    "T2GS983": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "别解脱戒"
            },
            {
                "@language": "en",
                "@value": "Pratimoksha vows"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "so thar sdom pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2GS983",
        "rank": 522
    },
    "T1368": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge slong gi bslab bya/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1368",
        "rank": 566
    },
    "T2KG2755": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge slong ma'i bslab bya/"
        },
        "parent": "O9TAXTBRC2016052KG225456"
    },
    "T1298": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sdom pa 'bog chog"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1298",
        "rank": 598
    },
    "T1787": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dge tshul gyi bslab bya/"
        },
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1787",
        "rank": 562
    },
    "T2195": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "shikshamana"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dge slob ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "式叉摩那尼"
            },
            {
                "@language": "en",
                "@value": "nun with six precepts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T2195",
        "rank": 546
    },
    "T1967": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge tshul/"
            },
            {
                "@language": "zh-hans",
                "@value": "沙弥"
            },
            {
                "@language": "en",
                "@value": "novice monk"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "shramanera"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225456",
        "tbrcId": "T1967",
        "rank": 538
    },
    "O9TAXTBRC2016052KG225482": {
        "sub": [
            "O9TAXTBRC2016052KG225483",
            "O9TAXTBRC2016052KG225497",
            "O9TAXTBRC2016052KG225503"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'brel yod brjod gzhi/"
            },
            {
                "@language": "zh-hans",
                "@value": "相关主题"
            },
            {
                "@language": "en",
                "@value": "Related Topics of Vinaya Pitika"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225452",
        "tbrcId": "4",
        "rank": 152
    },
    "O9TAXTBRC2016052KG225483": {
        "sub": [
            "T2082",
            "T2359",
            "T10MS14283",
            "T1895",
            "T1754",
            "T450",
            "T167",
            "T1251",
            "T376",
            "T620",
            "T1755",
            "T1980",
            "T2MS14920"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chos pa dang 'brel ba/"
            },
            {
                "@language": "zh-hans",
                "@value": "相关宗教名衔"
            },
            {
                "@language": "en",
                "@value": "Related religious titles"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225482",
        "tbrcId": "1",
        "rank": 624
    },
    "T2082": {
        "label": [
            {
                "@language": "en",
                "@value": "students"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "śiṣya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "slob ma/"
            },
            {
                "@language": "zh-hans",
                "@value": "徒"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T2082",
        "rank": 645
    },
    "T2359": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lha pa/"
        },
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T2359",
        "rank": 677
    },
    "T10MS14283": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grub thob/"
            },
            {
                "@language": "en",
                "@value": "siddha"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T10MS14283",
        "rank": 673
    },
    "T1895": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phyag mdzod/"
            },
            {
                "@language": "zh-hans",
                "@value": "执事"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T1895",
        "rank": 657
    },
    "T1754": {
        "label": [
            {
                "@language": "en",
                "@value": "upholders of the dharma"
            },
            {
                "@language": "zh-hans",
                "@value": "传承大士"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bstan 'dzin skyes chen/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T1754",
        "rank": 641
    },
    "T450": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gter ston/"
            },
            {
                "@language": "en",
                "@value": "revealers of hidden treasures"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T450",
        "rank": 669
    },
    "T167": {
        "label": [
            {
                "@language": "en",
                "@value": "teacher"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guru"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bla ma/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T167",
        "rank": 633
    },
    "T1251": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "lo tsA ba/"
            },
            {
                "@language": "en",
                "@value": "translators"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T1251",
        "rank": 649
    },
    "T376": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mkhan po/"
            },
            {
                "@language": "zh-hans",
                "@value": "堪布"
            },
            {
                "@language": "en",
                "@value": "Abbot"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T376",
        "rank": 629
    },
    "T620": {
        "label": [
            {
                "@language": "sa-x-ndia",
                "@value": "vajrācārya"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdo rje slob dpon/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T620",
        "rank": 637
    },
    "T1755": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sbyin bdag"
            },
            {
                "@language": "en",
                "@value": "patrons of the dharma"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T1755",
        "rank": 661
    },
    "T1980": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sngags pa/"
        },
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T1980",
        "rank": 665
    },
    "T2MS14920": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dge bshes/"
            },
            {
                "@language": "en",
                "@value": "Geshe"
            },
            {
                "@language": "zh-hans",
                "@value": "格西"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225483",
        "tbrcId": "T2MS14920",
        "rank": 653
    },
    "O9TAXTBRC2016052KG225497": {
        "sub": [
            "T201",
            "T1400",
            "T1858",
            "T1161",
            "T11MS5"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sdom pa dang 'brel ba/"
            },
            {
                "@language": "en",
                "@value": "Related with vows"
            },
            {
                "@language": "zh-hans",
                "@value": "相关戒律"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225482",
        "tbrcId": "2",
        "rank": 682
    },
    "T201": {
        "label": [
            {
                "@language": "en",
                "@value": "three vows"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sdom gsum/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225497",
        "tbrcId": "T201",
        "rank": 687
    },
    "T1400": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang chub sems dpa'i sdom pa/"
            },
            {
                "@language": "en",
                "@value": "discipline of the bodhisattva"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225497",
        "tbrcId": "T1400",
        "rank": 691
    },
    "T1858": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gsang sngags sdom pa/"
        },
        "parent": "O9TAXTBRC2016052KG225497",
        "tbrcId": "T1858",
        "rank": 695
    },
    "T1161": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "smad 'dul/"
            },
            {
                "@language": "en",
                "@value": "lower vinaya transmission"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225497",
        "tbrcId": "T1161",
        "rank": 703
    },
    "T11MS5": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sdom brgyud/"
        },
        "parent": "O9TAXTBRC2016052KG225497",
        "tbrcId": "T11MS5",
        "rank": 699
    },
    "O9TAXTBRC2016052KG225503": {
        "sub": [
            "T2CN5081",
            "T10",
            "T329",
            "T2DB4587",
            "T2099",
            "T1GS129194",
            "T1GS130095",
            "T409"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Related monastic regulations"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bca' yig dang 'brel ba/"
            },
            {
                "@language": "zh-hans",
                "@value": "相关寺规"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225482",
        "tbrcId": "3",
        "rank": 708
    },
    "T2CN5081": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "mdo sde'i mchod pa/"
        },
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T2CN5081",
        "rank": 725
    },
    "T10": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bca' yig"
            },
            {
                "@language": "en",
                "@value": "monastery regulations"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T10",
        "rank": 713
    },
    "T329": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tshogs gtam/"
        },
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T329",
        "rank": 729
    },
    "T2DB4587": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dus mchod/"
        },
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T2DB4587",
        "rank": 721
    },
    "T2099": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "gaNDi/"
        },
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T2099",
        "rank": 733
    },
    "T1GS129194": {
        "label": [
            {
                "@language": "en",
                "@value": "conduct"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyad pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T1GS129194",
        "rank": 737
    },
    "T1GS130095": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sman bzhi/"
        },
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T1GS130095",
        "rank": 741
    },
    "T409": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgrigs yig"
            },
            {
                "@language": "en",
                "@value": "procedural manual"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225503",
        "tbrcId": "T409",
        "rank": 717
    },
    "O9TAXTBRC2016052KG225392": {
        "sub": [
            "T2002",
            "T1229",
            "T1KG4314",
            "T1KG21096",
            "T3JT12491"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "spyi don/"
            },
            {
                "@language": "zh-hans",
                "@value": "总目"
            },
            {
                "@language": "en",
                "@value": "General Topics of Sutra Tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225391",
        "tbrcId": "1",
        "rank": 300
    },
    "T2002": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mdo phyogs/"
            },
            {
                "@language": "en",
                "@value": "Sutra Tradition"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225392",
        "tbrcId": "T2002",
        "rank": 305
    },
    "T1229": {
        "label": [
            {
                "@language": "en",
                "@value": "The five essential subjects and treatises"
            },
            {
                "@language": "zh-hans",
                "@value": "五部大论"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung chen bka' pod lnga /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225392",
        "tbrcId": "T1229",
        "rank": 309
    },
    "T1KG4314": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "grags chen bco brgyad/"
            },
            {
                "@language": "en",
                "@value": "the 18 renowned scriptures"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225392"
    },
    "T1KG21096": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "显密八大典籍"
            },
            {
                "@language": "en",
                "@value": "eight essential subjects"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung chen brgyad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225392",
        "tbrcId": "T1KG21096",
        "rank": 290
    },
    "T3JT12491": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gzhung chen bcu gsum/"
            },
            {
                "@language": "en",
                "@value": "Thirteen major philosophical texts"
            }
        ],
        "parent": "O9TAXTBRC2016052KG225392",
        "tbrcId": "T3JT12491",
        "rank": 313
    },
    "O9TAXTBRC2016052KG226418": {
        "sub": [
            "O9TAXTBRC2016052KG226419",
            "O9TAXTBRC2016052KG226427",
            "O9TAXTBRC2016052KG226441",
            "O9TAXTBRC2016052KG226460",
            "O9TAXTBRC2016052KG226472"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgra rig pa/"
            },
            {
                "@language": "en",
                "@value": "Linguistics and Philology"
            },
            {
                "@language": "zh-hans",
                "@value": "声明"
            }
        ],
        "parent": "O9TAXTBRC201605",
        "tbrcId": "ca",
        "rank": 3500
    },
    "O9TAXTBRC2016052KG226419": {
        "sub": [
            "T1GS10598",
            "T282",
            "T1849",
            "T283",
            "T2349",
            "T1850",
            "T966"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "梵文文法"
            },
            {
                "@language": "en",
                "@value": "Sanskrit grammar"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "legs sbyar gyi sgra mdo/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226418",
        "tbrcId": "1",
        "rank": 3505
    },
    "T1GS10598": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rtags gsum/"
        },
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T1GS10598",
        "rank": 3529
    },
    "T282": {
        "label": [
            {
                "@language": "sa-x-iast",
                "@value": "vyākaraṇa"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgra mdo/"
            },
            {
                "@language": "en",
                "@value": "sanskrit grammar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T282",
        "rank": 3509
    },
    "T1849": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "ting mtha'"
        },
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T1849",
        "rank": 3533
    },
    "T283": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dbyangs can sgra mdo/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "sarasvativyakarana"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T283",
        "rank": 3521
    },
    "T2349": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "sandhi"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mtshams sbyor lnga pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T2349",
        "rank": 3525
    },
    "T1850": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "tsAn+dra pa (sgra)"
        },
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T1850",
        "rank": 3517
    },
    "T966": {
        "label": [
            {
                "@language": "sa-alalc97",
                "@value": "katantra"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ka lA pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226419",
        "tbrcId": "T966",
        "rank": 3513
    },
    "O9TAXTBRC2016052KG226427": {
        "sub": [
            "T213",
            "T2191",
            "T2512",
            "T1680",
            "T4CZ16893",
            "T2346",
            "T214",
            "T1974",
            "T2022",
            "T2347",
            "T186",
            "T2623",
            "T2348"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "藏文文法"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bod kyi brda sprod rig pa/"
            },
            {
                "@language": "en",
                "@value": "Tibetan grammar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226418",
        "tbrcId": "2",
        "rank": 3538
    },
    "T213": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yig gzugs/"
            },
            {
                "@language": "en",
                "@value": "calligraphy"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T213",
        "rank": 3595
    },
    "T2191": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sa mtha'"
            },
            {
                "@language": "en",
                "@value": "post final or border"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2191",
        "rank": 3571
    },
    "T2512": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "yi ge"
        },
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2512",
        "rank": 3591
    },
    "T1680": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "la don/"
        },
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T1680",
        "rank": 3559
    },
    "T4CZ16893": {
        "label": [
            {
                "@language": "en",
                "@value": "tibetan grammar"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brda sprod/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T4CZ16893",
        "rank": 3543
    },
    "T2346": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "rnam dbye brgyad/"
        },
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2346",
        "rank": 3555
    },
    "T214": {
        "label": [
            {
                "@language": "en",
                "@value": "abbreviations & contractions"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skung yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T214",
        "rank": 3599
    },
    "T1974": {
        "label": [
            {
                "@language": "en",
                "@value": "correspondence, official"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "yig rigs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T1974",
        "rank": 4834
    },
    "T2022": {
        "label": [
            {
                "@language": "en",
                "@value": "verb forms"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dus gsum yi ge"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2022",
        "rank": 3587
    },
    "T2347": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bya byed/"
        },
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2347",
        "rank": 3563
    },
    "T186": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sum rtags/"
            },
            {
                "@language": "en",
                "@value": "grammar"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T186",
        "rank": 3547
    },
    "T2623": {
        "label": [
            {
                "@language": "en",
                "@value": "verb"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "bya tshig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2623",
        "rank": 3583
    },
    "T2348": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "bdag gzhan/"
        },
        "parent": "O9TAXTBRC2016052KG226427",
        "tbrcId": "T2348",
        "rank": 3567
    },
    "O9TAXTBRC2016052KG226441": {
        "sub": [
            "T2387",
            "T1KG22693",
            "T2119",
            "T265",
            "T1PD95804",
            "T1KG6167",
            "T2591",
            "T1KG6138",
            "T2416",
            "T378",
            "T1KG6119",
            "T2388",
            "T2KG200021",
            "T228",
            "T1158",
            "T2KG200020",
            "T3CN16182",
            "T287"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "语言学"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skad brda rig pa/"
            },
            {
                "@language": "en",
                "@value": "Linguistics"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226418",
        "tbrcId": "3",
        "rank": 3604
    },
    "T2387": {
        "label": [
            {
                "@language": "en",
                "@value": "gyalrong dialect"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgyal rong skad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T2387",
        "rank": 3650
    },
    "T1KG22693": {
        "label": [
            {
                "@language": "en",
                "@value": "nepali"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ne pa li/"
            },
            {
                "@language": "sa-deva",
                "@value": "नेपाली"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T2119": {
        "label": [
            {
                "@language": "en",
                "@value": "alphabets and scripts"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ka mad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T265": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bod skad/"
            },
            {
                "@language": "en",
                "@value": "tibetan language"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T265",
        "rank": 3615
    },
    "T1PD95804": {
        "label": [
            {
                "@language": "en",
                "@value": "mongolian language"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sog skad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T1PD95804",
        "rank": 3630
    },
    "T1KG6167": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgya skad/"
            },
            {
                "@language": "en",
                "@value": "chinese language"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T1KG6167",
        "rank": 3620
    },
    "T2591": {
        "label": [
            {
                "@language": "en",
                "@value": "sanskrit literature"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rgya dkar yig ge"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T2591",
        "rank": 3625
    },
    "T1KG6138": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgya yig"
            },
            {
                "@language": "en",
                "@value": "chinese script"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T2416": {
        "label": [
            {
                "@language": "en",
                "@value": "dzongkha"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rdzong kha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T2416",
        "rank": 3645
    },
    "T378": {
        "label": [
            {
                "@language": "en",
                "@value": "lhasa dialect"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "lha sa kha skad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T378",
        "rank": 3635
    },
    "T1KG6119": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yul shul skad/"
            },
            {
                "@language": "en",
                "@value": "yulshul dialect"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T1KG6119",
        "rank": 3660
    },
    "T2388": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dwags po skad/"
        },
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T2388",
        "rank": 3655
    },
    "T2KG200021": {
        "label": [
            {
                "@language": "en",
                "@value": "burmese"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "b+har ma'i skad yig"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T228": {
        "label": [
            {
                "@language": "en",
                "@value": "linguistics"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skad brda rig pa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T228",
        "rank": 3610
    },
    "T1158": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "a mdo kha skad/"
            },
            {
                "@language": "en",
                "@value": "amdo dialect"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T1158",
        "rank": 3640
    },
    "T2KG200020": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brkos yig"
            },
            {
                "@language": "en",
                "@value": "inscription"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T3CN16182": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rdo brkos yi ge"
            },
            {
                "@language": "en",
                "@value": "stone inscriptions"
            },
            {
                "@language": "zh-hans",
                "@value": "石刻碑文"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441"
    },
    "T287": {
        "label": [
            {
                "@language": "en",
                "@value": "japanese language"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "nyi hong skad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226441",
        "tbrcId": "T287",
        "rank": 3665
    },
    "O9TAXTBRC2016052KG226460": {
        "sub": [
            "T2262",
            "T1252",
            "T218",
            "T443",
            "T1157",
            "T1KG3848",
            "T143",
            "T1893",
            "T794",
            "T1985",
            "T240"
        ],
        "label": [
            {
                "@language": "en",
                "@value": "Orthography and Translation"
            },
            {
                "@language": "zh-hans",
                "@value": "正字学及翻译"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dag yig dang sgra sgyur/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226418"
    },
    "T2262": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brda sprod kun bshad gyi mtshan/"
            },
            {
                "@language": "en",
                "@value": "grammatical name"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T2262",
        "rank": 3705
    },
    "T1252": {
        "label": [
            {
                "@language": "en",
                "@value": "translation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "sgra sgyur/"
            },
            {
                "@language": "zh-hans",
                "@value": "翻译"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T1252",
        "rank": 3695
    },
    "T218": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dag yig"
            },
            {
                "@language": "en",
                "@value": "spelling"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T218",
        "rank": 3675
    },
    "T443": {
        "label": [
            {
                "@language": "en",
                "@value": "archaic spellings"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brda rnying /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T443",
        "rank": 3579
    },
    "T1157": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sgyur yig"
            },
            {
                "@language": "en",
                "@value": "translation"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T1157",
        "rank": 3690
    },
    "T1KG3848": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tha snyad zur bkol/"
            },
            {
                "@language": "en",
                "@value": "index"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T1KG3848",
        "rank": 3700
    },
    "T143": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ming don brda bkrol/"
            },
            {
                "@language": "en",
                "@value": "explanation of terms"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460"
    },
    "T1893": {
        "label": [
            {
                "@language": "en",
                "@value": "explanations of difficult words"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "brda bkrol/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T1893",
        "rank": 3575
    },
    "T794": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhe sa/"
        },
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T794",
        "rank": 3781
    },
    "T1985": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brda chad rig pa/"
            },
            {
                "@language": "en",
                "@value": "study of terminology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T1985",
        "rank": 3685
    },
    "T240": {
        "label": [
            {
                "@language": "en",
                "@value": "dictionaries, bilingual, trilingual and quadrilingual"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "skad shan sbyar/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226460",
        "tbrcId": "T240",
        "rank": 3680
    },
    "O9TAXTBRC2016052KG226472": {
        "sub": [
            "O9TAXTBRC2016052KG226473",
            "O9TAXTBRC2016052KG226489",
            "O9TAXTBRC2016052KG226496",
            "O9TAXTBRC2016052KG226520"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tha snyad sna tshogs/"
            },
            {
                "@language": "en",
                "@value": "Various Terminology"
            },
            {
                "@language": "zh-hans",
                "@value": "各种术语"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226418"
    },
    "O9TAXTBRC2016052KG226473": {
        "sub": [
            "T1197",
            "T1KG26408",
            "T3CN21895",
            "T1KG24275",
            "T1128",
            "T1KG14134",
            "T1KG26409",
            "T1KG18540",
            "T177",
            "T2KG209122",
            "T4CZ366107",
            "T1KG89005",
            "T3CN21715",
            "T1KG16182",
            "T8LS41949"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "一般术语"
            },
            {
                "@language": "en",
                "@value": "General Terminology"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "spyir btang tha snyad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226472"
    },
    "T1197": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "tha snyad/"
            },
            {
                "@language": "en",
                "@value": "terminology"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473",
        "tbrcId": "T1197",
        "rank": 3716
    },
    "T1KG26408": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "brda rtags/"
            },
            {
                "@language": "en",
                "@value": "sign"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T3CN21895": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "paN+Di ta/"
            },
            {
                "@language": "zh-hans",
                "@value": "班智达"
            },
            {
                "@language": "en",
                "@value": "pandita"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T1KG24275": {
        "label": [
            {
                "@language": "en",
                "@value": "schedule"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mdzad rim/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T1128": {
        "label": [
            {
                "@language": "en",
                "@value": "names"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "ming /"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473",
        "tbrcId": "T1128",
        "rank": 4074
    },
    "T1KG14134": {
        "label": [
            {
                "@language": "en",
                "@value": "peace and happiness"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhi bde/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473",
        "tbrcId": "T1KG14134",
        "rank": 3891
    },
    "T1KG26409": {
        "label": [
            {
                "@language": "en",
                "@value": "travel"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'grim 'grul/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T1KG18540": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'tshe med zhi ba/"
            },
            {
                "@language": "en",
                "@value": "non-violence and peace"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473",
        "tbrcId": "T1KG18540",
        "rank": 3886
    },
    "T177": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bla brgyud/"
            },
            {
                "@language": "en",
                "@value": "teacher-student transmission"
            },
            {
                "@language": "sa-alalc97",
                "@value": "guru-sisya-parampara"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T2KG209122": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "thal phreng /"
        },
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T4CZ366107": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rim byon/"
            },
            {
                "@language": "en",
                "@value": "successively"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T1KG89005": {
        "label": [
            {
                "@language": "zh-hans",
                "@value": "巴利文佛书"
            },
            {
                "@language": "en",
                "@value": "buddhist texts in pali"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "pA li'i chos dpe/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T3CN21715": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ta la'i lo ma'i dpe cha/"
            },
            {
                "@language": "en",
                "@value": "palm leaves manuscripts"
            },
            {
                "@language": "zh-hans",
                "@value": "贝叶经"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "T1KG16182": {
        "label": [
            {
                "@language": "en",
                "@value": "self immolation"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "rang lus mer sreg"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473",
        "tbrcId": "T1KG16182",
        "rank": 3896
    },
    "T8LS41949": {
        "label": [
            {
                "@language": "en",
                "@value": "report"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "snyan tho/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226473"
    },
    "O9TAXTBRC2016052KG226489": {
        "sub": [
            "T1770",
            "T347",
            "T11MS60",
            "T1KG5399",
            "T229",
            "T1KG5001"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "经论相关术语"
            },
            {
                "@language": "en",
                "@value": "Terminology related to the scriptures"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gsung rab 'brel yod tha snyad/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226472"
    },
    "T1770": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mkhas 'jug"
            },
            {
                "@language": "en",
                "@value": "discussions of terms and categories"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T1770",
        "rank": 4089
    },
    "T347": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "shes bya/"
            },
            {
                "@language": "en",
                "@value": "object of scholastic knowledge"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T347",
        "rank": 3721
    },
    "T11MS60": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "zhib 'jug"
        },
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T11MS60",
        "rank": 3969
    },
    "T1KG5399": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rig byed/"
            },
            {
                "@language": "sa-alalc97",
                "@value": "veda"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T1KG5399",
        "rank": 3821
    },
    "T229": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rig gnas/"
            },
            {
                "@language": "en",
                "@value": "arts & sciences"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T229",
        "rank": 4064
    },
    "T1KG5001": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gsung bshad/"
            },
            {
                "@language": "en",
                "@value": "lecture"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226489",
        "tbrcId": "T1KG5001",
        "rank": 4079
    },
    "O9TAXTBRC2016052KG226496": {
        "sub": [
            "T00EGS1018105",
            "T1PD96325",
            "T1649",
            "T2393",
            "T1195",
            "T1312",
            "T2MS14902",
            "T1KG5878",
            "T1144",
            "T1KG18545",
            "T1163",
            "T1KG18543",
            "T1233",
            "T1KG5732",
            "T404",
            "T2400",
            "T2028",
            "T1196",
            "T2481",
            "T424",
            "T2MS14922",
            "T2017",
            "T1398"
        ],
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi rigs dang yul srol/"
            },
            {
                "@language": "en",
                "@value": "Races and customs"
            },
            {
                "@language": "zh-hans",
                "@value": "民族及民俗"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226472"
    },
    "T00EGS1018105": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ja/"
            },
            {
                "@language": "en",
                "@value": "tea"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T00EGS1018105",
        "rank": 3998
    },
    "T1PD96325": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lo gsar/"
        },
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1PD96325",
        "rank": 3761
    },
    "T1649": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "phud/"
            },
            {
                "@language": "en",
                "@value": "first fruits offering"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1649",
        "rank": 3766
    },
    "T2393": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ga'u/"
            },
            {
                "@language": "en",
                "@value": "charm boxes"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2393",
        "rank": 4318
    },
    "T1195": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "lho pa/"
        },
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1195",
        "rank": 3921
    },
    "T1312": {
        "label": [
            {
                "@language": "en",
                "@value": "hats"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "zhwa/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1312",
        "rank": 3988
    },
    "T2MS14902": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "mi rigs/"
            },
            {
                "@language": "en",
                "@value": "ethnicity"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2MS14902",
        "rank": 3806
    },
    "T1KG5878": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rigs bzhi/"
            },
            {
                "@language": "en",
                "@value": "four castes"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1KG5878",
        "rank": 3826
    },
    "T1144": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "dmangs srol/"
        },
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1144",
        "rank": 3751
    },
    "T1KG18545": {
        "label": [
            {
                "@language": "en",
                "@value": "tobacco"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "tha ma kha/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1KG18545",
        "rank": 4013
    },
    "T1163": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bag ston/"
            },
            {
                "@language": "en",
                "@value": "marriage customs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1163",
        "rank": 3776
    },
    "T1KG18543": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "sha"
            },
            {
                "@language": "en",
                "@value": "meat"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1KG18543",
        "rank": 4003
    },
    "T1233": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "rgyan/"
            },
            {
                "@language": "en",
                "@value": "liturgical ornaments"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1233",
        "rank": 3816
    },
    "T1KG5732": {
        "label": [
            {
                "@language": "en",
                "@value": "festival, holiday, celebration"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "dus ston/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1KG5732",
        "rank": 3756
    },
    "T404": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "zhal 'bag"
            },
            {
                "@language": "en",
                "@value": "masks"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496"
    },
    "T2400": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "gri/"
            },
            {
                "@language": "en",
                "@value": "swords and knives"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2400",
        "rank": 3951
    },
    "T2028": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "yul srol/"
            },
            {
                "@language": "en-x-mixed",
                "@value": "customs"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2028",
        "rank": 3746
    },
    "T1196": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "chang /"
            },
            {
                "@language": "en",
                "@value": "alcoholic drink"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T1196",
        "rank": 4008
    },
    "T2481": {
        "label": [
            {
                "@language": "en",
                "@value": "custome and ornaments"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "chas gos/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2481",
        "rank": 3811
    },
    "T424": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kha zas/"
            },
            {
                "@language": "en",
                "@value": "food and beverages"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T424",
        "rank": 3993
    },
    "T2MS14922": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "man ju rigs/"
            },
            {
                "@language": "en",
                "@value": "manchu ethnicity"
            },
            {
                "@language": "zh-hans",
                "@value": "满族"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2MS14922",
        "rank": 4037
    },
    "T2017": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "'jang pa/"
        },
        "parent": "O9TAXTBRC2016052KG226496",
        "tbrcId": "T2017",
        "rank": 4033
    },
    "T1398": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "sangs rgyas dus chen/"
        },
        "parent": "O9TAXTBRC2016052KG226496"
    },
    "O9TAXTBRC2016052KG226520": {
        "sub": [
            "T1853",
            "T898",
            "T2450",
            "T1GS149122",
            "T1913",
            "T884",
            "T1KG18505",
            "T1CZ5010",
            "T1758",
            "T2448"
        ],
        "label": [
            {
                "@language": "zh-hans",
                "@value": "法器法物"
            },
            {
                "@language": "en",
                "@value": "Offering and Ritual materials"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "mchod pa'i yo byad sogs/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226472"
    },
    "T1853": {
        "label": [
            {
                "@language": "en",
                "@value": "gilding"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "gser 'phru/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1853",
        "rank": 3936
    },
    "T898": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "'khor lo/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "cakra"
            },
            {
                "@language": "zh-hant",
                "@value": "輪"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T898",
        "rank": 3906
    },
    "T2450": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "bum pa/"
            },
            {
                "@language": "en",
                "@value": "vase"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T2450",
        "rank": 3926
    },
    "T1GS149122": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "byang bu/"
            },
            {
                "@language": "en",
                "@value": "painted wooden tablets"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1GS149122",
        "rank": 3946
    },
    "T1913": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "kha btags/"
            },
            {
                "@language": "en",
                "@value": "ceremonial offering scarves"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1913",
        "rank": 3871
    },
    "T884": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "klu bum/"
        },
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T884",
        "rank": 3931
    },
    "T1KG18505": {
        "label": {
            "@language": "bo-x-ewts",
            "@value": "jo dar/"
        },
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1KG18505",
        "rank": 3979
    },
    "T1CZ5010": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "ring bsrel/"
            },
            {
                "@language": "sa-x-ndia",
                "@value": "shariram"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1CZ5010",
        "rank": 3796
    },
    "T1758": {
        "label": [
            {
                "@language": "bo-x-ewts",
                "@value": "dar gshams/"
            },
            {
                "@language": "en",
                "@value": "prayer banners"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T1758",
        "rank": 3984
    },
    "T2448": {
        "label": [
            {
                "@language": "en",
                "@value": "rosary"
            },
            {
                "@language": "bo-x-ewts",
                "@value": "'phreng ba/"
            }
        ],
        "parent": "O9TAXTBRC2016052KG226520",
        "tbrcId": "T2448",
        "rank": 4028
    }
}